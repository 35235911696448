
@mixin faicon($icon) {
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  font-style: normal;
  display: inline-block;
  text-decoration: inherit;
  line-height: 1;
  content: fa-content($icon);
}

@mixin faicon-before($icon, $padding: 0) {
  &:before {
    @include faicon($icon);
    color: #000000;
    padding-right: $padding;
  }
}

@mixin faicon-light($icon) {
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
  font-style: normal;
  display: inline-block;
  text-decoration: inherit;
  line-height: 1;
  content: fa-content($icon);
}

@mixin faicon-light-before($icon, $padding: 0) {
  &:before {
    @include faicon-light($icon);
    padding-right: $padding;
  }
}

@mixin faicon-regular($icon) {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-decoration: inherit;
  line-height: 1;
  content: fa-content($icon);
}

@mixin faicon-regular-before($icon, $padding: 0) {
  &:before {
    @include faicon-regular($icon);
    padding-right: $padding;
  }
}
