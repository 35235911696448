.s-salestools-admin {
    .c-section--content {
        margin-left: 13px;
    }
}

.s-salestools-admin--filter {
    .c-section--content {
        padding: 15px 10px;
    }

    margin-top: 5px;
    margin-bottom: 20px;

    &.c-section.c-section--folded > .c-section--heading {
        background: var(--sco-color-grey-400);
        color: var(--sco-color-white);

        &:hover {
            background: var(--sco-color-grey-600);
        }
    }
}