html:has(.s-home-mitarbeiter-dashboard .swal2-shown) {
  overflow: hidden;
}

.s-home-mitarbeiter-dashboard {

  .s-home-mitarbeiter-dashboard--grid-tile {
    min-height: 385px;
  }

  #wichtige-infos-card-container {
    .wichtige-info-card {
      margin-bottom: var(--sco-spacing-8);

      .wichtige-info-content-wrapper {
        @extend .flex--row;

        .wichtige-info-icon {
          margin-right: var(--sco-spacing-8);
        }
        .wichtige-info-content {
          width: 100%;

          &,* {
            font-family: var(--sco-font-family-lato-black);
          }

          p, hr {
            margin-bottom: var(--sco-spacing-4);
          }
        }
      }
    }
  }

  /* Blende die Impulse-Kachel aus, wenn dort das emptycontent Element drin auftaucht */
  #s-home-homedashboard--card-IMPULSE:has(div#s-home-homedashboard--card-IMPULSE-emptycontent) {
    display: none;
  }


  #s-home-homedashboard--card-TERMINE {
    grid-column: 1 / span 8;

    @include screen-xs {
      grid-column: 1 / span 4;
    }

    @include screen-s {
      grid-column: 1 / span 8;
    }

    @include screen-m {
      grid-column: 1 / span 8;
    }
  }

  #s-home-homedashboard--card-AUFGABEN {
    grid-column: 1 / span 8;

    @include screen-xs {
      grid-column: 1 / span 4;
    }

    @include screen-s {
      grid-column: 1 / span 8;
    }

    @include screen-m {
      grid-column: 1 / span 8;
    }
  }

  #s-home-homedashboard--card-TICKETS {
  }

  .c-dashboard--grid-layout {
    grid-auto-flow: row dense;
  }

  .activityDetails .c-card--header-title {
    max-width: fit-content;
  }
}

//Aussehen
.s-home-mitarbeiter-dashboard--grid-layout {
  background-color: var(--sco-color-grey-100);
  padding: 20px;
  justify-content: center;
  grid-auto-flow: row dense;

  .widget {
    background-color: var(--sco-color-white);
    /*    padding: 20px;*/

  }

}

.s-mitarbeiter-dashboard-settings-box-cards {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 2px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  margin-bottom: 10px;

  .c-sortable-item {
    display: flex;
    background: var(--sco-color-white);
  }
}

.s-mitarbeiter-dashboard-settings-box-card-left {
  flex: 1;
}

.s-mitarbeiter-dashboard-settings-box-card-right {
  flex: 0;
  flex-basis: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.s-home-mitarbeiter-dashboard--nachricht {
  box-shadow: none;

  &.read {
    .s-home-mitarbeiter-dashboard--nachricht-gelesen-button {
      display: none;
    }
  }

  &.unread {
    .s-home-mitarbeiter-dashboard--nachricht-ungelesen-button {
      display: none;
    }
  }

  .sender-info {
    display: flex;
    gap: 20px;
  }

  .attachments {
    hr {
      margin: 24px 0 16px 0;
      color: var(--sco-color-black);
    }

    .c-button--content {
      font-size: 14px;
    }
  }
}

.c-message-list--item {
  display: grid;
  height: 100%;
  grid-template-columns: [first-line] 4px [line2] auto [line3] auto [last-line];
  grid-template-rows: 32px auto;
  grid-template-areas:
          "read-status   sender-name    icons-and-date"
          "read-status   subject        subject";

  /* Area Zuordnungen */
  .c-message-list--item-sender {
    grid-area: sender-name;
  }

  .c-message-list--item-subject {
    grid-area: subject;
    max-height: 2lh;
    overflow-y: hidden
  }

  .c-message-list--icons {
    grid-area: icons-and-date;
    justify-self: right;
  }

  .c-message-list--icon-sender-type {
    margin-right: var(--sco-spacing-8);
  }

  .c-message-list--icon-documents {
    margin-right: var(--sco-spacing-8);
  }

}

.s-home-mitarbeiter-dashboard--nachricht-button {
  cursor: pointer;
  width: calc(100% - 20px);
}

.s-home-mitarbeiter-dashboard--nachricht-dialog {
  .c-card--header-wrapper-left {
    --nachricht-dialog-anzahl-buttons: 6;
    width: calc(100% - (var(--nachricht-dialog-anzahl-buttons) * 47px));

    .c-card--header-title {
      text-align: left;
    }
  }

  .c-card--content {
    text-align: left;

    .content {
      white-space: pre-line;
      min-height: 3em;
      max-height: 8em;
      overflow: auto;
    }

    .attachments {
      margin-top: 10px;

      .attachment {
        display: block;
        background: none;
        border: none;
        padding: 0;
        color: var(--sco-color-black);
      }
    }

    .referenz-link {
      background: none;
      border: none;
      padding: 0;
      color: var(--sco-color-black);
    }
  }

  .c-card--header-right {
    justify-content: flex-end;
  }
}

.s-home-mitarbeiter-dashboard--contextmenu-button {
  width: 30%;
}

.s-home-mitarbeiter-dashboard--contextmenu-button-watch-active {
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px; /* Adjust thickness if needed */
    background-color: red; /* Adjust color if needed */
    transform: rotate(45deg);
    transform-origin: center;
  }
}

.s-home-mitarbeiter-dashboard--search-placeholder {
  overflow-y: scroll;
  margin-top: 20px;
  height: 70vh;

  .c-spinner {
    top: 20px;
  }
}

.s-home-mitarbeiter-dashboard--search-table {
  .s-home-mitarbeiter-dashboard--search-table--hit-rate-col {
    white-space: nowrap;
  }
}

.s-home-mitarbeiter-dashboard--event-dialog {
  .c-card--header-wrapper-left {
    max-width: 90%;
  }

  .s-home-mitarbeiter-dashboard--event-dialog--grid {
    display: grid;
    grid-template-columns: min-content auto;
    grid-row-gap: var(--sco-spacing-4);
    grid-column-gap: var(--sco-spacing-8);

    @include screen-m {
      grid-row-gap: var(--sco-spacing-4);
      grid-column-gap: var(--sco-spacing-8);
    }

    @include screen-l {
      grid-row-gap: var(--sco-spacing-4);
      grid-column-gap: var(--sco-spacing-12);
    }

    @include screen-xl {
      grid-row-gap: var(--sco-spacing-8);
      grid-column-gap: var(--sco-spacing-16);
    }

    @include screen-xxl {
      grid-row-gap: var(--sco-spacing-12);
      grid-column-gap: var(--sco-spacing-16);
    }

    .s-home-mitarbeiter-dashboard--event-dialog--main-info-datetime {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: center;

      @include screen-m {
        flex-direction: row;
      }

      @include screen-l {
        flex-direction: row;
      }

      @include screen-xl {
        flex-direction: row;
      }
      @include screen-xxl {
        flex-direction: row;
      }

      .c-calendar-icon {
        padding-top: 0;
      }

      .s-home-mitarbeiter-dashboard--event-dialog--main-info-time {
        margin-top: var(--sco-spacing-8);

        @include screen-m {
          margin-top: 0;
          margin-left: var(--sco-spacing-12);
        }

        @include screen-l {
          margin-top: 0;
          margin-left: var(--sco-spacing-16);
        }

        @include screen-xl {
          margin-top: 0;
          margin-left: var(--sco-spacing-16);
        }

        @include screen-xxl {
          margin-top: 0;
          margin-left: var(--sco-spacing-16);
        }
      }
    }

    .s-home-mitarbeiter-dashboard--event-dialog--main-info-text {
      max-height: 45vh;
      overflow-y: auto;
      flex-grow: 1;
    }
  }

  #s-home-mitarbeiter-dashboard--event-dialog--zusatzreferenz-section, #s-home-mitarbeiter-dashboard--event-dialog--teilnehmer-section {
    .simple-div-table--table .simple-div-table--table-body {
      text-align: start;
    }
  }

  .s-home-mitarbeiter-dashboard--event-dialog--status-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center;

    @include screen-m {
      flex-direction: row;
    }

    @include screen-l {
      flex-direction: row;
    }

    @include screen-xl {
      flex-direction: row;
    }

    @include screen-xxl {
      flex-direction: row;
    }

    button {
      width: 100%;

      @include screen-m {
        width: 33.3%;
        max-width: 250px;
      }

      @include screen-l {
        width: 33.3%;
        max-width: 250px;
      }

      @include screen-xl {
        width: 33.3%;
        max-width: 250px;
      }

      @include screen-xxl {
        width: 33.3%;
        max-width: 250px;
      }

      &:not(:last-of-type) {
        margin-bottom: var(--sco-spacing-4);

        @include screen-m {
          margin-bottom: 0;
          margin-right: var(--sco-spacing-4);
        }

        @include screen-l {
          margin-bottom: 0;
          margin-right: var(--sco-spacing-8);
        }

        @include screen-xl {
          margin-bottom: 0;
          margin-right: var(--sco-spacing-12);
        }

        @include screen-xxl {
          margin-bottom: 0;
          margin-right: var(--sco-spacing-12);
        }
      }
    }

  }
}

