// blah
@import "datatables.net-dt/css/jquery.dataTables";
@import "datatables.net-responsive-dt/css/responsive.dataTables";

$cell-height: 30px;
$table-border-color: $color-border--normal;
$table--cellpadding: 10px;

.c-table--wrapper {

  &.c-table--no-grid-lines {
    td, th {
      border-bottom: none;
    }
  }

  &.c-table--vertical-top {
    td, th {
      vertical-align: top;
    }
  }

  td, th {
    vertical-align: middle;
    border-bottom: 1px solid $table-border-color;
    text-align: left;

    padding: 0 $table--cellpadding;

    &:first-child {
      padding-left: 15px;
    }

    &:last-child {
      padding-right: 15px;
    }
  }

  th {
    text-align: left;
    height: $cell-height;

    &.sorting {
      cursor: pointer;

      &:hover {
        color: $color-link--green-hover;
      }
    }

    &.textRight {
      text-align: right;
    }

    &.textCenter {
      text-align: center;
    }

  }

  td {
    height: $cell-height;

    &.textRight {
      text-align: right;
    }

    &.textCenter {
      text-align: center;
    }

    .c-checkbox {
      height: unset;
    }
  }

  tr:last-child {
    td {
      border-bottom: 0;
    }
  }


  .expandable_row {
    background: var(--sco-color-grey-230);
  }

  .bottom {
    display: grid;
    grid-template-columns: auto max-content;

    .infoDisplay {
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      align-items: baseline;
    }

    .dataTables_paginate .paginate_button {
      padding: 0;
    }
  }

  .dataTables_info {
    display: inline-block;
    margin-right: 15px;
  }

  .dataTables_length {
    display: inline-block;
    margin-right: 15px;

    select {
      background: none;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
    }
  }

  .expandable_header {
    width: 20px;
  }

  .expandable_icon_open {
    @include faicon-before($fa-var-plus-square);

    &:before {
      cursor: pointer;
      color: var(--sco-color-green-500);
    }

    &:hover::before {
      color: var(--sco-color-primary);
    }
  }

  .expandable_icon_closed {
    @include faicon-before($fa-var-minus-square);

    &:before {
      cursor: pointer;
      color: var(--sco-color-green-500);
    }

    &:hover::before {
      color: var(--sco-color-primary);
    }
  }

  .expandable_hidden {
    display: none;
  }

  .ui-helper-hidden {
    display: none !important;
  }

  td.expandable_td {
    padding-left: 38px;
    padding-right: 0px;
  }

  .paginate_button {
    margin: 0px 2px;
    border-radius: 1px;
    //background: var(--sco-color-grey-400);
    color: var(--sco-color-primary);
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;

    &.current {
      background: $color-link--green;
      color: var(--sco-color-white);
    }

    &:hover {
      cursor: pointer;
      background: $color-link--green-hover;
      color: var(--sco-color-white);
    }

  }

  .paginate_button.next {
    @include faicon-before($fa-var-angle-right);
  }

  .paginate_button.previous {
    @include faicon-before($fa-var-angle-left);
  }

  .paginate_button.first {
    @include faicon-before($fa-var-angle-double-left);
  }

  .paginate_button.last {
    @include faicon-before($fa-var-angle-double-right);
  }

  .paginate_button.next,
  .paginate_button.previous,
  .paginate_button.first,
  .paginate_button.last {
    vertical-align: top;
    text-align: left;
    overflow: hidden;

    /* The before element will cover the complete size */
    &:before {
      position: relative;
      visibility: visible;
      height: 30px;
      width: 30px;
      line-height: 30px;
      vertical-align: middle;
      text-align: center;
    }
  }


  th[data-sort-property] {
    cursor: pointer;
    color: var(--sco-color-grey-700);

    div {
      display: inline-block;
    }

    &:hover,
    &[aria-sort] {
      div {
        color: var(--sco-color-grey-700);
        text-decoration: underline;
      }
    }

    &[aria-sort="ascending"]:after {
      @include fontawesome-icon();
      content: fa-content($fa-var-caret-down);
      margin-left: 10px;
      font-size: var(--sco-font-size-headline6);
    }

    &[aria-sort="descending"]:after {
      @include fontawesome-icon();
      content: fa-content($fa-var-caret-up);
      margin-left: 10px;
      font-size: var(--sco-font-size-headline5);
    }
  }

  tfoot {
    background: var(--sco-color-white);

    td {
      padding: 1em 10px 0.4em;
    }

    &:before {
      content : ' ';
      height: 20px;
      display: block;
    }
  }
}

.c-table--fixed-header thead {
  background: var(--sco-color-white);
  position: sticky;
  top: 0;
  z-index:10;
}

.c-table--col-width-auto {

}


@keyframes tableFadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.c-table--track-parent-table-layout {
  animation: tableFadeIn 0.5s;
}

$table--column-width: 80px;
$table--columns: 10;

@for $ncolswidth from 1 through $table--columns {
  .c-table--col-width-#{$ncolswidth} {
    width: $table--column-width * $ncolswidth;
  }
}
