.s-projekt-projectpassport {

  .s-projekt-projectpassport--project-image {
    display: block;
    margin: auto;
  }

  .s-projekt-projectpassport--title-panel-top {
    margin-bottom: 15px;

    .c-card--header {
      background-color: #78b928;
    }
  }

  .s-projekt-projectpassport--main-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
  }

  .s-projekt-projectpassport--container {
    flex-basis: calc(45%);
    flex-shrink: 0;
    flex-grow: 1;
    margin-right: 10px;
  }

  .s-projekt-projectpassport--grid-layout {
    margin-top: 10px;
  }

  .s-projekt-projectpassport--grid-layout {
    justify-content: flex-start;

    .c-dashboard-flex-tile {
      flex-basis: calc(90%);
      flex-shrink: 0;
      margin-top: 10px;
      flex-grow: 1;
      height: auto;

      @include wider-than-desktop {
        flex-basis: calc(50% - 0.3em - 5px);
      }
    }

    .s-projekt-projectpassport--grid-header {
      diplay: contents;
    }
  }

  .s-projekt-projectpassport--grid-layout-cards {
    justify-content: flex-start;

    .s-projekt-projectpassport--grid-layout-cards-card {
      flex-basis: calc(50%);
      flex-shrink: 0;
      padding-top: 5px;
      flex-grow: 0;
      height: auto;
      box-shadow: none;

      table {
        font-size: 12px;
      }

      @include wider-than-desktop {
        flex-basis: calc(50% - 0.3em - 5px);
      }

      .s-projekt-projectpassport--grid-layout-cards-card-header {
        text-align: left;
        background-color: var(--sco-color-white);
        padding-top: 1px
      }

      .s-projekt-projectpassport--grid-layout-cards-card-content {
        padding: 5px 15px;
        //line-height: 1.4;
        height: calc(100% - 50px);
      }

      .s-projekt-projectpassport--grid-layout-cards-card-bold {
        font-weight: bold;
        font-size: var(--sco-font-size-headline5);
      }

      box-shadow: #00a2d1;

    }
  }

  .s-projekt-projectpassport--grid-layout-table {
    justify-content: flex-start;

    .simple-div-table--table {
      display: table;
      width: 100%;
      border: 0;
    }

    .simple-div-table--table-body {
      display: table-row-group;
    }

    .simple-div-table--table-row:nth-child(even) {
      display: table-row;
      height: auto;
      background: white;
    }

    .simple-div-table--table-row:nth-child(odd) {
      display: table-row;
      height: auto;
      background: var(--sco-color-grey-100);
    }

    .simple-div-table--table-heading {
      display: table-header-group;
      font-weight: bold;
    }

    .simple-div-table--table-head {
      //border-right: 0.5px solid : var(--sco-color-grey-100);;
      display: table-cell;
      padding: 4px 16px;
      vertical-align: bottom;
      font-weight: bold;
    }

    .simple-div-table--table-cell-style {
      //border-right: 0.5px solid var(--sco-color-grey-300);
      display: table-cell;
      padding: 0 2px 0 2px;
      vertical-align: top;
      //padding-top: 8px;
      //padding-bottom: 8px;
    }
  }

  .simple-text {
    padding: 2px 2px 2px 2px;
    display: contents;
  }


  .timeline {
    border-left: 4px solid #78b928;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    color: #333;
    margin-left: 10px;
    letter-spacing: 0.5px;
    position: relative;
    line-height: 1.4em;
    padding: 20px;
    //list-style: none;
    text-align: left;
  }

  .timeline .event {
    border-bottom: 1px solid rgba(160, 160, 160, 0.2);
    padding-bottom: 15px;
    margin-bottom: 20px;
    position: relative;
  }

  .timeline .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
  }

  .timeline .event:before,
  .timeline .event:after {
    position: absolute;
    display: block;
    top: 0;
  }

  .timeline .event:before {
    left: -177.5px;
    color: #212121;
    content: attr(data-date);
    text-align: right;
    /*  font-weight: 100;*/

    font-size: var(--sco-font-size-headline5);
    min-width: 120px;
  }

  .timeline .event:after {
    box-shadow: 0 0 0 4px #78b928;
    left: -28px;
    background: #212121;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    content: "";
    top: 5px;
  }
}

@media print {
  // Diese Einstellung gilt für alle Ausdrucke und kann beim Drucken auch nicht mehr verändert werden, deswegen lieber weglassen!
  //@page {
  //  size: landscape;
  //}

  #print {
    display: none;
  }

  #back {
    display: none;
  }

  #createTermin {
    display: none;
  }

  #createAufgabe {
    display: none;
  }

  #save {
    display: none;
  }

  .s-projekt-projectpassport {
    * {
      margin: 0 !important;
      padding: 0 !important;
      font-size: 7.5pt;
    }
  }

  .s-projekt-projectpassport--title-panel-top {
    display: none;
  }

  .s-projekt-projectpassport--title-panel {
    width: 100%;
  }

  .s-projekt-projectpassport {
    width: auto;
    left: 0;
    top: 0;

    .c-header {
      display: none;
    }
  }


  .s-projekt-projectpassport--grid-layout-cards-card {
    box-shadow: none;
    border: 1px solid gray;
    width: 50%;

    table {
      font-size: 7.5pt;
    }

  }

  .c-percentCircle--container {
    padding-right: 15px;
  }

  .c-dashboard-flex-tile {
    min-height: 200px;
  }
}

