.c-content--main {
    flex: 1 1 auto;
    padding:0 $content-padding 0 $content-padding;
    display: flex;
    flex-direction: column;
    min-height: 0; // fixes overflow/scrolling issues in FF/Edge/Safari: https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts/
    background-color: var(--sco-color-grey-200);

    &:before {
        content: '';
        display: block;
        height: 20px;
        flex: 0;
        flex-basis: 12px;
    }

    @media print {
        display: block;
    }
}

.c-content--main-grid {
    padding:0 48px 0 48px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px 24px;

    @include screen-xs {
        padding:0 16px 0 16px;
        grid-template-columns: repeat(4, 1fr);
        gap: 12px 12px;
    }

    @include screen-s {
        padding:0 16px 0 16px;
        grid-template-columns: repeat(8, 1fr);
        gap: 16px 16px;
    }

    @include screen-m {
        padding:0 24px 0 24px;
        grid-template-columns: repeat(8, 1fr);
        gap: 16px 16px;
    }

    @include screen-l {
        padding:0 32px 0 32px;
        gap: 16px 16px;
    }

    @include screen-l {
        padding:0 40px 0 40px;
    }

    & {
        min-height: 0; // fixes overflow/scrolling issues in FF/Edge/Safari: https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts/
    }

    @media print {
        display: block;
    }
}
