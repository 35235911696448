.s-ticket-custom-va-colorsampleorder {
  .c-panel.s-ticket-custom-va-colorsampleorder-collapsable-panel .c-panel--title, .c-panel.s-ticket-custom-va-colorsampleorder-collapsable-panel .c-panel--sub-title {
    cursor: pointer;
  }

  .c-panel.s-ticket-custom-va-colorsampleorder-collapsable-panel .c-panel--sub-title {
    padding: 5px 15px 5px 15px;
  }

  #refresh-btn {
    margin-top: 0;
    top: -3px;
    height: 39px;
    width: 39px;
  }

  .c-input--text-wrapper.kostenstelle {
    top: -12px;
  }

  fieldset.musterentnahme {
    margin-left: #{$gutter};
  }

  fieldset.musterentnahme legend {
    font-size: $fontsize-label-default;
    font-weight: bolder;
  }

  .unsaved-changes-marker {
    display: inline-block;
    //  visibility: hidden;
    background-image: repeating-linear-gradient(45deg, #0000 0%, #0000 4%, #78b9283a 4%, #78b9283a 8%, #0000 8%);
    padding: 0 10px 0 10px;
  }

  .c-panel--title-actions {
    cursor: default;
  }

  #orderList {
    counter-reset: order-counter 0;
  }

  .orderTitle {
    counter-increment: order-counter 1;
  }

  .orderTitle::before {
    content: counter(order-counter) ". ";
  }
}