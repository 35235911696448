.s-mitarbeiter-instanz-compare {

    .s-mitarbeiter-instanz-compare--table {
        display: table;
        width: 100%;
        border: 0;

        .s-mitarbeiter-instanz-compare--table-heading {
            display: table-header-group;
        }

        .s-mitarbeiter-instanz-compare--table-body {
            display: table-row-group;
        }

        .s-mitarbeiter-instanz-compare--table-row {
            display: table-row;
        }

        .s-mitarbeiter-instanz-compare--table-head {
            display: table-cell;
        }

        .s-mitarbeiter-instanz-compare--table-cell {
            display: table-cell;
        }
    }

    .s-mitarbeiter-instanz-compare--mitinst-info {

        &.s-mitarbeiter-instanz-compare--mitinst-info-h {
            .c-card--header {
                color: var(--sco-color-white);
                background-color: var(--sco-color-primary);

                .c-button--iconbutton:hover {
                    color: var(--sco-color-white);
                }
            }
        }

        &.s-mitarbeiter-instanz-compare--mitinst-info-z {

        }
    }
}