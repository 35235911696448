$hierarchical-checkbox--outer-padding: 5px;

.c-hierarchical-checkbox--outer {

  background: var(--sco-color-grey-600);
  color: var(--sco-color-white);
  padding: $hierarchical-checkbox--outer-padding 40px $hierarchical-checkbox--outer-padding 35px;
  position: relative;

  cursor: pointer;

  &:hover {
    background: var(--sco-color-grey-700);

  }

  .c-hierarchical-checkbox--outer-label {
    display: inline-block;
  }

  &:after {
    @include faicon($fa-var-angle-right);
    position: absolute;
    right: 10px;
    top: 8px;
    font-size: var(--sco-font-size-headline5);
  }

  .c-hierarchical-checkbox--outer-checkbox {
    display: inline-block;
    color: var(--sco-color-primary);
  }

  .c-hierarchical-checkbox--outer-checkbox{
    border: 2px solid var(--sco-color-white);
    height: 18px;
    width: 18px;
    position: absolute;
    padding: 2px;
    left: 10px;
    top: 7px;

    &:before {
      content: '';
      display: inline-block;
      text-decoration: inherit;
      position: absolute;
      font-size: $fontsize-label-default;
    }
  }
}

.c-hierarchical-checkbox {

  margin-bottom: $pannel--padding;

  .c-hierarchical-checkbox--inner {
    display: none;
  }
  &.c-hierarchical-checkbox--expanded {
    .c-hierarchical-checkbox--inner {
      display: block;
    }
    .c-hierarchical-checkbox--outer:after {
      @include faicon($fa-var-angle-down);
    }
  }

  &.c-hierarchical-checkbox--checked {
    .c-hierarchical-checkbox--outer-checkbox:before {
      @include faicon($fa-var-square-full);
    }
  }

  &.c-hierarchical-checkbox--checked-some {
    .c-hierarchical-checkbox--outer-checkbox:before {
      opacity: 0.7;
    }
  }

}

.c-hierarchical-checkbox--inner {
  padding: $pannel--padding $pannel--padding 0px $pannel--padding;

  > .c-slider {
    margin-top: 10px;
  }
}

.c-hierarchical-checkbox--category {
  margin-bottom: 5px;

  .c-hierarchical-checkbox--category-label {
    border-bottom: 1px solid $color-text--gray-dark;
    font-family: var(--sco-font-family-lato-light);
    position: relative;
    user-select: none;
    padding: 5px 5px;

    cursor: pointer;

    &:hover {
      border-bottom: 2px solid $color-text--gray-dark;
      padding-bottom: 4px;
    }

    &:after {
      @include faicon($fa-var-angle-right);
      position: absolute;
      right: 10px;
      top: 2px;
      font-size: var(--sco-font-size-headline5);
    }
  }
  .c-hierarchical-checkbox--category-inner {
    display: none;
    padding: 5px 5px 0px 5px;

    .c-checkbox {
      height: 30px;

      label {
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.c-hierarchical-checkbox--category-expanded {
  .c-hierarchical-checkbox--category-label:after {
    @include faicon($fa-var-angle-down);
  }

  .c-hierarchical-checkbox--category-inner {
    display: block;
  }
}