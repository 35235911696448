/* Map um Farbklassen zu generieren */
$colorMap: (
        "greenDefault": $color-green--default,
        "green10": $color-green--10,
        "greenLight" : $color-green--light,
        "greenMiddle" : $color-green--middle,
        "greenDark" : $color-green--dark,
        "orange10" : $color-orange--10,
        "orangeLight" : $color-orange--light,
        "orangeDefault" : $color-orange--default,
        "redDefault" : $color-red--default,
        "red10" : $color-red--10,
        "gray152" : $color-gray--152,
        "gray120" : $color-gray--120,
        "gray90" : $color-gray--90,
        "gray40" : $color-gray--40,
        "black100" : $color-black--100,
        "blueLight" : $color-blue--light,
        "blueDefault" : $color-blue--default,
        "blueMiddle" : $color-blue--middle,
        "blueDark" : $color-blue--dark,
        "white" : $color-white,
        "kunde" : $color-icon-kunde,
        "planer" : $color-icon-planer,
        "projekt" : $color-icon-projekt,
);

@each $colorname, $colorvalue in $colorMap {
    /* Generiert z.B. die css Klasse .global--color-greenDefault */
    .global--color-#{$colorname} {
        color: $colorvalue;
    }

    /* Generiert z.B. die css Klasse .global--background-greenDefault */
    .global--background-#{$colorname} {
        background: $colorvalue;
    }

    /* Generiert z.B. die css Klasse .global--borderColor-greenDefault */
    .global--borderColor-#{$colorname} {
        border-color: $colorvalue;
    }

    @each $direction in top,bottom,left,right {
        /* Generiert z.B. die css Klasse .global--borderColor-top-greenDefault */
        .global--borderColor-#{$direction}-#{$colorname} {
            border-color-#{$direction}: $colorvalue;
        }
    }

    /* Generiert z.B. die css Klasse .svg--fill-greenDefault */
    .svg--fill-#{$colorname} {
        fill: $colorvalue;
    }
    /* Generiert z.B. die css Klasse .svg--stroke-greenDefault */
    .svg--stroke-#{$colorname} {
        stroke: $colorvalue;
    }
}
