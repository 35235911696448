.s-kpm-scorecard {
  .c-dashboard-flex-tile {
    @include number-of-dashboard-tiles-per-row(2);

    @include mobile {
      @include number-of-dashboard-tiles-per-row(1);
    }

    @include tabletPortrait {
      @include number-of-dashboard-tiles-per-row(1);
    }

    @include tabletLandscape {
      @include number-of-dashboard-tiles-per-row(2);
    }

    @include wider-than-desktop {
      @include number-of-dashboard-tiles-per-row(2);
    }
  }
}


#s-kpm--card-KPM_SC_NETWORK {

  .c-kpm--scorecard-networt-content {
    width: 100%;
    height: 100%;
  }

  .c-kpmnetwork {

    width: 100%;
    height: 100%;

    &> svg {
      height: 100%;
      width: 100%;
      cursor: pointer;
    }

  }
}

#s-kpm--card-KPM_SC_REVENUE {
  @include number-of-dashboard-tiles-per-row(1);
  overflow: auto;

  .c-table--wrapper td {
    vertical-align: top;
    padding-top: 5px;
  }
}

.s-kpm-scorecard .c-dialog--heading {
  text-decoration: underline;
}

#s-kpm-scorecard-questionaire-form-disabled,
#s-kpm-scorecard-questionaire-form {

  .s-kpm-scorecard__questionaire-qtitle {
    margin-top: 20px;
  }
  .s-kpm-scorecard__questionaire-qsubtitle {
    margin-bottom: $content-padding*0.5;
  }

  .s-kpm-scorecard__questionaire-radios {

    td,th {
      background: var(--sco-color-grey-300);

      border-right: 0.5px solid var(--sco-color-grey-300);
      display: table-cell;
      padding: 4px 8px;
      vertical-align: middle;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    th {
      background: var(--sco-color-grey-100);
    }

    tr:nth-child(odd) td {
      background: var(--sco-color-white);
    }

    tr:nth-child(odd):last-child td {
      border-bottom: 1px solid var(--sco-color-grey-300);
    }
  }

  .s-kpm-scorecard__questionaire-radioquestion {
    width: 50%;
    text-align: left;
  }

  .c-star-rating {
    &:before, span:before {
      font-size: var(--sco-font-size-headline3);
    }
  }

  .c-checkbox {
    height: 30px;
  }

  #kpm-questionaire-kpm-arten .c-dropdown--wrapper {
    top: -10px;
  }
}

.s-kpm-scorecard__questionaire-buttonbar {
  text-align: right;
}
.s-kpm-scorecard__questionaire-help-button-back {
  display: none;
}
.s-kpm-scorecard__questionaire-help {
  display: none;
}

.s-kpm-scorecard__questionaire,
.s-kpm-scorecard__questionaire-help-content {
  text-align: left;

  h2 {
    font-size: var(--sco-font-size-headline5);
    margin-top: $content-padding;
    margin-bottom: $content-padding*0.5;
    text-decoration: underline;
  }

  h3 {
    font-size: var(--sco-font-size-headline5);
    margin-bottom: $content-padding*0.5;
    text-decoration: underline;
  }

  p{
    font-size: var(--sco-font-size-headline5);
  }
}
.s-kpm-scorecard__questionaire-help-content-example {
  margin: 0 auto;
  display: block;
  height: 300px;
  margin-top: $content-padding*0.5;
}
