.s-projekt-alufit-dashboard {
    .s-projekt-alufit--grid-tile {
        grid-column: span 12;

        @include screen-xs {
            grid-column: span 4;
        }

        @include screen-s {
            grid-column: span 8;
        }

        @include screen-m {
            grid-column: span 8;
        }

        @include screen-xl {
            grid-column: span 6;
        }

        @include screen-xxl {
            grid-column: span 4;
        }
    }

    .s-projekt-alufit-dashboard--title-panel {
        margin-bottom: 12px;
    }

    #s-alufit-dashboard--card-PROJECTDATA {
        grid-row: span 3;

        .s-alufit-dashboard--projectdata-section-divider td {
            border-bottom-width: 6px;
            border-bottom-style: double;
        }
    }

    #s-alufit-dashboard--card-PROJECT_ATTRACTIVENESS {
        grid-row: span 3;

        .s-alufit-dashboard--attractiveness-summary-row td {
            border-top-width: 6px;
            border-top-style: double;
            border-top-color: var(--sco-color-grey-300);
            font-family: var(--sco-font-family-lato-bold);
            border-right: none;
            padding-bottom: 0;
        }

        .s-alufit-dashboard--attractiveness-summary-row-2 td {
            font-family: var(--sco-font-family-lato-bold);
            border-right: none;
            padding-top: 0;
            text-align: right;
            height: auto;
        }

        .s-alufit-dashboard--attractiveness-history-header {
            font-family: var(--sco-font-family-lato-bold);
            margin-bottom: 8px;
        }

        .s-alufit-dashboard--attractiveness-history {
            display: flex;
            justify-content: space-around;

            .s-alufit-dashboard--attractiveness-history-entry {
                margin-left: 12px;
                margin-right: 12px;

                .s-alufit-dashboard--attractiveness-history-month {
                    .c-calendar-icon {
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 8px;
                    }
                }
            }
        }

    }

    #s-alufit-dashboard--card-PRODUCTS {
    }

    #s-alufit-dashboard--card-STAKEHOLDER {
    }

    #s-alufit-dashboard--card-TIMETABLE {
    }

    #s-alufit-dashboard--card-DOKUMENTE {
        @include screen-xl {
            grid-column: span 12;
        }

        @include screen-xxl {
            grid-column: span 8;
        }
    }
}

/* Dialog für Timetable */
.s-projekt-alufit-dashboard-timetable-wrapper {
    max-width: 100%;
    position: relative;
    text-align: left;
    display: flex;
}

.s-projekt-alufit-dashboard-timetable-left-spacer {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 550px;
}
.s-projekt-alufit-dashboard-timetable-scroll {
    flex: 1;
    overflow-x: scroll;
}
.s-projekt-alufit-dashboard-timetable {
    .s-projekt-alufit-dashboard-timetable-headtr {
        height: 30px;
    }
    .s-projekt-alufit-dashboard-timetable-bodytr {
        height: 60px;
    }
    th {
        height: 30px;
        position: relative;
    }
    td {
        height: 60px;
        position: relative;
    }

    .s-projekt-alufit-dashboard-timetable-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    .s-projekt-alufit-dashboard-timetable-statictd {
        width: 100px;
        position: absolute;
        &:nth-child(1) {
            left: 0;
        }
        /* Colum Start */
        &:nth-child(2) {
            left: 110px;
        }
        /* Colum Start */
        &:nth-child(3) {
            left: 310px;
        }
        /* Colum Sum */
        &:nth-child(4) {
            left: 500px;
        }
    }
    .s-projekt-alufit-dashboard-timetable-statictd--startend {
        width: 200px;
    }

    .s-projekt-alufit-dashboard-timetable-statictd-sum {
        width: 50px;
        .s-projekt-alufit-dashboard-timetable-content {
            justify-content: center;
        }
    }
}

.s-projekt-alufit-dashboard-timetable-month-th .s-projekt-alufit-dashboard-timetable-content {
    min-width: 60px;
    justify-content: center;
}

.s-projekt-alufit-dashboard-timetable-month-td {
    min-width: 80px;
    position: relative;
    text-align: center;
}
.s-projekt-alufit-dashboard-timetable-month-td--active:before {
    content:'';
    background: rgba(18, 176, 17, 0.29);
    height: 25px;
    width: 100%;
    position: absolute;
    left:0;
    top: 50%;
    transform: translateY(-50%);

    &:first-child {
        border-left: 10px solid transparent;
        border-top-left-radius:10px;
        border-bottom-left-radius:10px;
    }
    &:last-child {
        border-right: 10px solid transparent;
        border-top-right-radius:10px;
        border-bottom-right-radius:10px;
    }
}

.s-projekt-alufit-dashboard-timetable-month-td--first:before {
    border-left: 10px solid transparent;
    border-top-left-radius:10px;
    border-bottom-left-radius:10px;
}
.s-projekt-alufit-dashboard-timetable-month-td--last:before {
    border-right: 10px solid transparent;
    border-top-right-radius:10px;
    border-bottom-right-radius:10px;
}
.s-projekt-alufit-dashboard-timetable-month-input-formatted {
    position: relative;
    z-index: 1;
    text-align: right;
    width: 90px;
    border: 1px solid rgba(0,0,0,0.5);
    border-radius: 5px;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        opacity: 1;
        margin-left: 3px;
    }
}


tr.s-projekt-alufit-dashboard-timetable-bodytr:not([data-phase='INVOICING']) .s-projekt-alufit-dashboard-timetable-month-input {
    padding-right: 20px;
    width: 70px;
}

.s-projekt-alufit-dashboard-timetable-month-input-wrapper {
    position: relative;
    width: 100px;

    /* position the unit to the right of the wrapper */
    &:after {
        pointer-events: none;
        position: absolute;
        top: 3px;
        right: 10px;
        transition: all .05s ease-in-out;
        z-index:2;
    }

    /* handle Firefox (arrows always shown) */
    @supports (-moz-appearance:none) {
        &::after {
            right: 1.5em;
        }
    }
}

tr.s-projekt-alufit-dashboard-timetable-bodytr:not([data-phase='INVOICING']) .s-projekt-alufit-dashboard-timetable-month-input-wrapper {
    width: 80px;

    &:after {
        right: 10px;
        content: '%';
    }
}

.s-projekt-alufit-dashboard-timetable-check--success {
    .s-projekt-alufit-dashboard-timetable-content {
        @include faicon-before($fa-var-check-circle);
        color: var(--sco-color-primary);
    }
}

.s-projekt-alufit-dashboard-timetable-check--error {
    .s-projekt-alufit-dashboard-timetable-content {
        //@include faicon-before($fa-var-exclamation-circle);
        color: var(--sco-color-red-600);
        font-weight: bold;
    }
}

.s-projekt-alufit-dashboard-timetable-check--undecided{
    .s-projekt-alufit-dashboard-timetable-content {
        @include faicon-before($fa-var-question-circle);
        color: $color-blue--default;
    }
}


.s-projekt-alufit-dashboard-timetable-card-check {
    font-size: var(--sco-font-size-headline4);
    text-align: center;
}

.s-projekt-alufit-dashboard-project-attractiveness-component .simple-div-table--table {
    input, select, div.c-dropdown--container, span.select2-selection {
        background-color: unset;
    }
}