$tabs--tabbar-height: 48px;

.c-tabs {
  .ui-tabs-nav {
    height: $tabs--tabbar-height;
    line-height: $tabs--tabbar-height;
    vertical-align: middle;
    text-align: left;
    font-size: var(--sco-font-size-headline6);

    //background: var(--sco-color-grey-100);

    margin: 0px;
    list-style-type: none;
    padding-left: var(--sco-spacing-16);
    padding-right: var(--sco-spacing-16);

    @include screen-xs{
      padding-left: var(--sco-spacing-4);
      padding-right: var(--sco-spacing-4);
    }

    @include screen-s{
      padding-left: var(--sco-spacing-4);
      padding-right: var(--sco-spacing-4);
    }

    @include screen-m{
      padding-left: var(--sco-spacing-8);
      padding-right: var(--sco-spacing-8);
    }

    @include screen-l{
      padding-left: var(--sco-spacing-12);
      padding-right: var(--sco-spacing-12);
    }

    > li {
      display: inline-block;

      height: $tabs--tabbar-height;
      line-height: $tabs--tabbar-height;
      vertical-align: middle;
      text-align: center;

      //width: 160px;
      padding-left: var(--sco-spacing-16);
      padding-right: var(--sco-spacing-16);

      font-weight: 500;
      a {
        color: var(--sco-color-grey-700);
        &:hover {
          color: var(--sco-color-primary);
        }

        @include screen-xs {
          display: none;
        }

        @include screen-s {
          display: none;
        }
      }

      &:hover {
        cursor: pointer;
      }
      &.ui-tabs-active {
        //font-family: var(--sco-font-family-lato-bold);
        font-weight: normal;
        border-bottom: solid 1px var(--sco-color-primary);
        background-color: var(--sco-color-grey-200);
        a {
          color: var(--sco-color-primary);
        }
      }

      strong {
        font-family: var(--sco-font-family-lato-regular);
      }

      &:before {
        margin-right: var(--sco-spacing-4);
      }

      @include mobile() {
        &:before {
          margin-right: 0px;
        }
      }
    }
  }
  .tab {
    transition: opacity 0.26s ease;
  }
  &.ui-tabs-vertical {
    min-width: 55em;
    display: flex;

    .ui-tabs-nav {
      padding: .2em .1em .2em .2em;
      min-width: 12em;
      flex: 0;
    }
    .ui-tabs-nav li {
      clear: left;
      width: 100%;
      border-bottom-width: 1px !important;
      border-right-width: 0 !important;
      margin: 0 -1px .2em 0;
    }
    .ui-tabs-nav li a {
      display:block;
    }
    .ui-tabs-nav li.ui-tabs-active {
      padding-bottom: 0;
      border-right-width: 1px;
    }
    &.ui-tabs .ui-tabs-panel {
      padding: 1em;
      min-width: 40em;
    }
    &.tabSet ul.ui-tabs-nav li.ui-state-active strong {
      background-image: none;
      display: inline;
    }
  }

  .c-tab-background--green {
    background-color: var(--sco-color-primary);
  }

  .c-tab-background--grey-020{
    background-color: var(--sco-color-grey-020);
  }

  .c-tab-background--grey-100{
    background-color: var(--sco-color-grey-100);
  }

  .c-tab-background--grey-200{
    background-color: var(--sco-color-grey-200);
  }

  .c-tab-background--grey-230{
    background-color: var(--sco-color-grey-230);
  }

  .c-tab-background--grey-300{
    background-color: var(--sco-color-grey-300);
  }

  .c-tab-background--grey-350{
    background-color: var(--sco-color-grey-350);
  }

  .c-tab-background--grey-400{
    background-color: var(--sco-color-grey-400);
  }

  .c-tab-background--grey-500{
    background-color: var(--sco-color-grey-500);
  }

  .c-tab-background--grey-600{
    background-color: var(--sco-color-grey-600);
  }

  .c-tab-background--kunde {
    background-color: var(--sco-color-kunde-background);
  }

  .c-tab-background--planer {
    background-color: var(--sco-color-planer-background);
  }

  .c-tab-background--projekt {
    background-color: var(--sco-color-projekt-background);
  }
}
