.s-kpm-dashboard {

  .c-htmlwysiwygeditor[data-readonly=true] {
    border: 0px;
    padding: 0px;
  }

  #s-kpm--card-KPM_ANALYSIS {
    flex-basis: calc(100% - 10px);
    flex-grow: 1;

    #s-kpm--card-KPM_ANALYSIS__charts {
      display: flex;
      flex-wrap: wrap;
      >div {
        flex: 1 0 300px;           /* Grow and shrink, minimum width 200px */
        width: 300px;
        box-sizing: border-box;    /* Helps with padding and borders inside columns */
        padding: 1rem;

      }
    }

    .s-kpm--card-KPM_ANALYSIS__chart-title {
      font-size: 1.2em;
      text-align: center;
      position: relative;
    }
    .s-kpm--card-KPM_ANALYSIS__chart-dropdown {
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 10;
      border: 0px;
      color: $color-gray--40;
    }
  }

  #s-kpm--card-KPM_STRATEGY {
    flex-basis: calc((100%/2) - 10px);

    .c-card--header {
      background: var(--sco-color-primary);
    }

    .c-card--header-title {
      color: var(--sco-color-white);
    }

    .ui-tabs-panel.ui-widget-content {
      padding-top: 10px;
    }
  }

  #s-kpm--card-KPM_PUBLICATIONS {
    flex-basis: calc((100%/2) - 10px);
  }

  #s-kpm--card-KPM_CHANCES {
    flex-basis: calc((100%/2) - 10px);
    min-height: 210px;
  }

  #s-kpm--card-KPM_RISKS {
    flex-basis: calc((100%/2) - 10px);
    min-height: 210px;
  }

  #s-kpm--card-KPM_STEPS {
    flex-basis: calc(100% - 10px);
    min-height: 210px;
  }

  #s-kpm--card-KPM_ESTIMATED_POTENTIAL {
    flex-basis: calc((100%/2) - 10px);
    min-height: 210px;
  }

  #s-kpm--card-KPM_RAMP_UP {
    flex-basis: calc((100%/2) - 10px);
    min-height: 210px;
  }

  #s-kpm--card-KPM_PDFS {
    flex-basis: calc(100% - 10px);
    min-height: 500px;

    .kpm-pdf-tab {
      margin-top: var(--sco-spacing-8);
    }
  }

  #s-kpm-dashboard-chances-form,
  #s-kpm-dashboard-risks-form,
  #s-kpm-dashboard-strategy-form, {
    > strong {
      margin-bottom:15px;
      display: block;
    }

    .c-htmlwysiwygeditor {
      min-height: 150px;
    }
  }
}


.s-kpm--publication-entry {

  margin-bottom: 10px;
  display: block;
  padding: 10px;

  &:after {
    clear: both;
    content: "";
    display: block;
  }

  &--expanded {
    background: var(--sco-color-grey-100);
    .s-kpm--publication-entry-details {
      display: block;
    }
  }
}

.s-kpm--publication-entry-icon {
  border: 1px solid black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  float: left;
}

.s-kpm--publication-entry-content {
  float: left;
  width: calc(100% - 30px);
  padding-left: 10px;

  &--expandable {

    .s-kpm--publication-entry-title {
      cursor: pointer;
      position: relative;
      font-weight: bold;
      color: var(--sco-color-primary);
    }
  }
}

.s-kpm--publication-entry-date {
  font-size: var(--sco-font-size-headline6);
}

.s-kpm--publication-entry-title {
  font-size: var(--sco-font-size-headline5);
}

.s-kpm--publication-entry-details {
  padding-top: 10px;
  display: none;
}


.s-kpm-dashboard__steps-empty {
  display: none;
}
.s-kpm-dashboard__steps-table {
  th {
    text-align: left;
  }
}
.s-kpm-dashboard__steps-item-art {
  width: 30px;
}
.s-kpm-dashboard__steps-item-expand {
  width: 20px;
  cursor: pointer;

  &:before {
    @include fontawesome-icon();
    content: fa-content($fa-var-caret-right);
    font-weight: 900; //solid
    font-size: var(--sco-font-size-headline5);
  }
}
.s-kpm-dashboard__steps-item-expand--expanded {
  &:before {
    @include fontawesome-icon();
    content: fa-content($fa-var-caret-down);
    font-weight: 900; //solid
    font-size: var(--sco-font-size-headline5);
  }
}

.s-kpm-dashboard__steps-item-actions {
  width: 40px;
  text-align: right;
}

.s-kpm-dashboard__steps-item-info-td {
  pre {
    font-family: var(--sco-font-family-lato-regular);
    font-size: var(--sco-font-size-headline5);
    border-left: 5px solid var(--sco-color-grey-300);
    padding-left: 10px;
    margin-left: 35px;
    margin-bottom: 10px;
  }
}