.s-vertriebskampagnen-massnahmen-translation {

  .c-card {
    margin-bottom: 20px;
  }

  .c-card--header {
    color: var(--sco-color-white);
    padding: 10px 15px;
  }

  .c-card--content {
    padding-bottom: 10px;
    background: rgba(255, 255, 255, 1.0);
  }

}