.s-projekt-projecttracker {

  .s-projekt-projecttracker--grid-layout {
    margin-top: 10px;
  }

  .s-projekt-projecttracker--grid-layout {
    justify-content: flex-start;

    .c-dashboard-flex-tile {
      margin-top: 10px;
      flex-grow: 1;
      height: auto;
      min-height: auto;

      @include wider-than-desktop {
        flex-basis: calc(33% - 0.3em - 5px);
      }
    }
  }

  #s-projekt-projecttracker--card-ACHIEVEMENTLEVEL {
    @include tabletPortrait {
      order: 1;
    }
    @include tabletLandscape {
      order: 1;
    }
  }

  #projecttracker-masterdata-marge-editdialog-button {
    cursor: pointer;
  }

  .s-projekt-projecttracker--authority-table {
    tr.s-projekt-projecttracker--authority-table-second-header-row {
      td {
        background-color: var(--sco-color-grey-100);
      }
    }

    td.s-projekt-projecttracker--authority-stakeholder-head,
    td.s-projekt-projecttracker--authority-relationship-head {
      width: 90px;
    }

    tr.s-projekt-projecttracker--authority-table-row {
      &:hover td {
        background-color: var(--sco-color-grey-100);
      }

      td.s-projekt-projecttracker--authority-stakeholder-cell,
      td.s-projekt-projecttracker--authority-relationship-cell {
        text-align: center;
      }

      td:nth-child(4), td:nth-child(7) {
        border-right: 1px solid var(--sco-color-grey-600);
      }

      .s-projekt-projecttracker--authority-table-name {
        border-right: 1px solid var(--sco-color-grey-600);
        min-width: 200px;
        text-align: left;

        @include tabletPortrait {
          min-width: 150px;
        }
      }
    }
  }

  .s-projekt-projecttracker--question-table {
    td.s-projekt-projecttracker--question-cell {
      text-align: center;
    }
  }

  .s-projekt-projecttracker--phaseDevelopment-table {
    tr:last-child td {
      border-top: 1px solid var(--sco-color-grey-600);
    }
  }

  .s-projekt-projecttracker--vertical-divide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: 30em;

    .s-projekt-projecttracker--vertical-divide-item {
      flex-basis: 50%;
      flex-grow: 1;

      &.upper-item {
        min-height: 240px;
      }
    }
  }

  .s-projekt-projecttracker--master-data-history-table {
    td.history-value {
      padding-left: 10px;
    }
    td.history-trend:not(:last-child) {
      border-right: 1px solid var(--sco-color-grey-600);
    }
  }

  @media print {
    //@page {
    //  size: portrait;
    //}

    #print {
      display: none;
    }
    #back {
      display: none;
    }

    .hide-on-mobile {
      display: block !important;
      max-width: none;
    }
    .hide-on-tablet {
      display: none !important;
    }

    .s-projekt-projecttracker--title-panel-top {
      display: none;
    }

    .s-projekt-projecttracker--title-panel {
      width: 100%;
    }

    * {
      overflow: visible !important;
//      margin: 0 !important;
//      padding: 0 !important;
    }

    html, body {
      height: 100%;
      overflow: hidden;
      font-size: 7.5pt;
    }

    .c-header {
      display: none;
    }

    #projekt-projecttracker--select-vbr_container {
      display: none;
    }

    .s-projekt-projecttracker {
      width: auto;
      left: 0;
      top: 0;

      .c-header {
        display: none;
      }
    }

    .s-projekt-projecttracker--card-root {
      /* Display Block nehmen, da Flex-Layout und einige andere display Optionen für Print Layouts nur die erste Seite drucken */
      float: none;
      display: block;
      }

    .s-projekt-projecttracker--grid-tile {
      box-shadow: none;
      border: 1px solid gray;
      width: 100%;
      min-height: 0px !important;

      table {
        font-size: 7.5pt;
      }
    }

    .s-projekt-projecttracker--grid-tile,
    .s-projekt-projecttracker--vertical-divide,
    .s-projekt-projecttracker--vertical-divide-item {
      // Die Steuerung des Seitenumbruchs funktioniert nicht! Warum??
      page-break-inside : avoid;
      min-height: 169px !important;

    }

    .timeline {
      visibility: visible !important;
      transform: none;
      width: 100%;
      padding: 20px !important;
      padding-bottom: 100%;
    }

  }

  .timeline {
    border-left: 4px solid #78b928;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    color: #333;
    margin-left: 10px;
    letter-spacing: 0.5px;
    position: relative;
    line-height: 1.4em;
    padding: 20px;
    //list-style: none;
    text-align: left;
  }

  .timeline .event {
    border-bottom: 1px solid rgba(160, 160, 160, 0.2);
    padding-bottom: 15px;
    margin-bottom: 20px;
    position: relative;
  }

  .timeline .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
  }

  .timeline .event:before,
  .timeline .event:after {
    position: absolute;
    display: block;
    top: 0;
  }

  .timeline .event:before {
    left: -177.5px;
    color: #212121;
    content: attr(data-date);
    text-align: right;
    /*  font-weight: 100;*/

    font-size: var(--sco-font-size-headline5);
    min-width: 120px;
  }

  .timeline .event:after {
    box-shadow: 0 0 0 4px #78b928;
    left: -28px;
    background: #212121;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    content: "";
    top: 5px;
  }

  .s-projekt-projecttracker--grid-layout-table {
    justify-content: flex-start;

    .simple-div-table--table {
      display: table;
      width: 100%;
      border: 0;
    }

    .simple-div-table--table-body {
      display: table-row-group;
    }

    .simple-div-table--table-row:nth-child(even){
      display: table-row;
      height: auto;
      background: white;
    }

    .simple-div-table--table-row:nth-child(odd){
      display: table-row;
      height: auto;
      background: var(--sco-color-grey-100);
    }

    .simple-div-table--table-heading {
      display: table-header-group;
      font-weight: bold;
    }

    .simple-div-table--table-head {
      //border-right: 0.5px solid : var(--sco-color-grey-100);;
      display: table-cell;
      padding: 4px 16px;
      vertical-align: bottom;
      font-weight: bold;
    }

    .simple-div-table--table-cell-style {
      //border-right: 0.5px solid var(--sco-color-grey-300);
      display: table-cell;
      padding: 0 2px 0 2px;
      vertical-align: top;
      //padding-top: 8px;
      //padding-bottom: 8px;
    }
  }

  .authority-info--table {
    th {
      text-align: left;
      margin-bottom: 5px;
      border-bottom: 1px solid $color-border--dark;
    }

    td.info-icon, td.info-text {
      text-align: left;
    }

  }

  .s-projekt-projecttracker--grid-tile {
    .projecttracker-opportunitiesRisks-edit-editorWrapper {
      .c-htmlwysiwygeditor {
        border: none;
        padding: 0;

        .ql-container {
          border: none;

          p {
            margin: 0;
          }
        }
      }
    }
  }
}