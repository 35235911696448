.c-listmenu--item {
  display: block;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;

  &:hover {
    background: var(--sco-color-grey-100);
  }

  &.c-listmenu--active {
    background: var(--sco-color-grey-100);
  }
}