@import "components.datetimes-commons";

.c-input--date {
  @extend .datetimes-commons;
}

.c-input--date-picker--dayButton {
  &.Mui-selected {
    background-color: var(--sco-color--picker-selected);
  }
  &.Mui-selected:focus {
    background-color: var(--sco-color--picker-selected);
  }

  &:hover {
    background-color: var(--sco-color--picker-hovered);
  }
}

/**
 Der Popper wird in der Desktop Variante geöffnet unter der Komponente geöffnet
 */
.c-input--date-picker-popper {
  .MuiDialogActions-root.MuiPickersLayout-actionBar {
    justify-content: center;
  }

  .MuiPickersDay-root {
    @extend .c-input--date-picker--dayButton
  }
}

/**
 Der Dialog wird in der Mobile Variante geöffnet
 */
.c-input--date-picker-dialog {
  .MuiPickersLayout-toolbar {
    align-items: center;

    .MuiPickersToolbar-content {
      justify-content: center;
    }
  }
  .MuiPickersLayout-actionBar {
    justify-content: center;
  }
  .MuiPickersDay-root {
    @extend .c-input--date-picker--dayButton
  }
}