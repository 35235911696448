$color-white: var(--sco-color-white);
$color-white-hover: var(--sco-color-grey-020);
$color-black: var(--sco-color-black);

$color-gray--10:  var(--sco-color-grey-930);
$color-gray--40:  var(--sco-color-grey-700);
$color-gray--60:  var(--sco-color-grey-600);
$color-gray--78:  var(--sco-color-grey-500);
$color-gray--90:  var(--sco-color-grey-400);
$color-gray--120: var(--sco-color-grey-300);
$color-gray--130: var(--sco-color-grey-230);
$color-gray--152: var(--sco-color-grey-100);
$color-gray--152-hover: var(--sco-color-grey-020);

$color-black--10:  var(--sco-color-grey-200);
$color-black--25:  var(--sco-color-grey-350);
$color-black--40:  var(--sco-color-grey-400);
$color-black--60:  var(--sco-color-grey-600);
$color-black--85:  var(--sco-color-grey-800);
$color-black--100: var(--sco-color-black);

$color-anthracite--10:  var(--sco-color-anthracite-100);
$color-anthracite--25:  var(--sco-color-anthracite-200);
$color-anthracite--40:  var(--sco-color-anthracite-300);
$color-anthracite--60:  var(--sco-color-anthracite-400);
$color-anthracite--85:  var(--sco-color-anthracite-600);
$color-anthracite--100: var(--sco-color-anthracite-800);

$color-green--10:      var(--sco-color-green-100);
$color-green--light:   var(--sco-color-green-200);
$color-green--default: var(--sco-color-primary);
$color-green--middle:  var(--sco-color-green-500);
$color-green--dark:    var(--sco-color-green-800);

$color-orange--10:      var(--sco-color-orange-100);
$color-orange--light:   var(--sco-color-orange-150);
$color-orange--default: var(--sco-color-orange-200);
$color-orange--dark:    var(--sco-color-orange-500);

$color-red--10:      var(--sco-color-red-200);
$color-red--light:   var(--sco-color-red-500);
$color-red--default: var(--sco-color-red-600);
$color-red--dark:    var(--sco-color-red-800);

$color-blue--light:   var(--sco-color-blue-200);
$color-blue--default: var(--sco-color-blue-400);
$color-blue--middle:  var(--sco-color-blue-600);
$color-blue--dark:    var(--sco-color-blue-800);

$color-silver: var(--sco-color-silver);

$color-dark--violet: darkviolet;

$color-background--dark: var(--sco-color-grey-700);

$color-border--light: var(--sco-color-grey-400);
$color-border--light2: var(--sco-color-grey-300);
$color-border--dark: var(--sco-color-grey-600);
$color-border--dark2: var(--sco-color-grey-700);

$color-border--normal: var(--sco-color-grey-300);
$color-border--focus: var(--sco-color-primary);
$color-border--invalid: var(--sco-color-red-600);
$color-border--hover: var(--sco-color-black);

$color-text--default: var(--sco-color-grey-700);
$color-text--gray: var(--sco-color-grey-300);
$color-text--gray-dark: var(--sco-color-grey-400);
$color-text--white: var(--sco-color-white);
$color-text--input: var(--sco-color-grey-930);
$color-text--input-disabled: var(--sco-color-grey-600);
$color-text--label: var(--sco-color-grey-930);
$color-text--placeholder: var(--sco-color-grey-600);

$color-link--green: var(--sco-color-primary);
$color-link--gray: var(--sco-color-grey-700);
$color-link--green-hover: var(--sco-color-green-500);

$color-icon-kunde: var(--sco-color-kunde-background);
$color-icon-planer: var(--sco-color-planer-background);
$color-icon-projekt: var(--sco-color-projekt-background);
$color-icon-kpm: var(--sco-color-primary);