// SETTINGS
@import "settings/settings.config";
@import "../node_modules/inuitcss/settings/settings.core";
@import "settings/settings.global";
@import "tools/tools.breakpoints";
@import "settings/settings.colors";
@import "settings/settings.variables";

@import "utilities/fontawesome_mixins";
@import "utilities/material-ui-theme";

// TOOLS
@import "../node_modules/inuitcss/tools/tools.font-size";
@import "../node_modules/inuitcss/tools/tools.clearfix";
//@import "../node_modules/sass-mq/mq";
@import "tools/tools.font-awesome";

// GENERIC: Low-specificity, far-reaching rulesets (e.g. resets).
@import "../node_modules/inuitcss/generic/generic.box-sizing";
@import "../node_modules/inuitcss/generic/generic.normalize";
@import "../node_modules/inuitcss/generic/generic.reset";
@import "../node_modules/inuitcss/generic/generic.shared";

$generics-fonts-define-rules: true;
@import "generic/generic.fonts";
@import "generic/generic.focus";
@import "generic/generic.print";
@import "generic/generic.simple-div-table";
@import "generic/generic.input.scss";
@import "generic/generic.design";

// ELEMENTS: Unclassed HTML elements (e.g. a {}, blockquote {}, address {}).
@import "../node_modules/inuitcss/elements/elements.page";
@import "../node_modules/inuitcss/elements/elements.headings";
@import "../node_modules/inuitcss/elements/elements.images";
@import "../node_modules/inuitcss/elements/elements.tables";

@import "elements/elements.page";
@import "elements/elements.links";
@import "elements/elements.input";
@import "elements/elements.number-highlight";
@import '../node_modules/sweetalert2/src/sweetalert2';

// OBJECTS: Objects, abstractions, and design patterns (e.g. .o-layout {}).
@import "objects/objects.hyperform";
@import "objects/objects.clear";
@import "objects/objects.layout";
@import "objects/objects.hidden";

// COMPONENTS: Discrete, complete chunks of UI (e.g. .c-carousel {}).
@import "components/components.master-container"; // has to be before navigation
@import "components/components.navigation";
@import "components/components.header";
@import "components/components.footer";
@import "components/components.content";
@import "components/components.input";
@import "components/input/components.pickable-complete";
@import "components/components.textarea";
@import "components/components.datetimepicker";
@import "components/datetimes/components.timepicker";
@import "components/datetimes/components.datepicker";
@import "components/datetimes/components.monthpicker";
@import "components/datetimes/components.weekpicker";
@import "components/components.checkbox";
@import "components/components.radio";
@import "components/components.radioButtonGroup";
@import "components/components.spinner";
@import "components/components.dropdown";
@import "components/components.buttons";
@import "components/components.button-collapsible";
@import "components/components.card";
@import "components/components.card.content";
@import "components/components.table";
@import "components/components.table.design.storybook";
@import "components/components.panel";
@import "components/components.tabs";
@import "components/components.section";
@import "components/components.notification";
@import "components/components.upload";
@import "components/components.dialog";
@import "components/components.colored-tile";
@import "components/components.listmenu";
@import "components/components.sortableItem";
@import "components/components.hierarchical-checkbox";
@import "components/components.slider";
@import "components/components.map";
@import "components/components.calendar-icon";
@import "components/components.progress-tracker";
@import "components/components.icon";
@import "components/components.tellink";
@import "components/components.emaillink";
@import "components/components.item-list";
@import "components/components.websitelink";
@import "components/components.maplink";
@import "components/components.focus-info";
@import "components/components.info-tool-tip";
@import "components/components.star-rating";
@import "components/components.strategy-section";
@import "components/components.dashboard-flex-layout";
@import "components/components.dashboard-grid-layout";
@import "components/components.dashboard-teaser-tile-grid";
@import "components/components.html-wysiwyg-editor";
@import "components/components.activity-timeline";
@import "components/components.iconizedText";
@import "components/components.sidebar";
@import "components/components.datalist";
@import "components/components.filtersearch";
@import "components/components.sidebar";
@import "components/components.backToTopButton";
@import "components/components.kpmnetwork";
@import "components/components.kpmexternnetwork";
@import "components/components.mitarbeiterpopup";
@import "components/components.mitarbeiterdetails";
@import "components/components.image-upload-with-preview";
@import "components/components.wizard";
@import "components/components.infobox";

@import "components/message/components.tooltip";

@import "components/calendar/calendar";
@import "components/calendar/calendarOverwrite";
@import "components/calendar/aufgaben";

@import "components/charts/components.percentBar";
@import "components/charts/components.percentCircle";
@import "components/charts/components.trendIcon";
@import "components/charts/components.colorIndicator";
@import "components/charts/components.donutChart";

@import "~animate.css/animate.css";

/*
 * Klassen aus unserem global, color usw. Kontexten sind dafür gedacht, über eine CSS Klasse bestimmte Eigenschaften an einem Element zu setzen.
 * Damit diese die Möglichkeit haben, durch Komponenten vorgegebene Standardwerte zu überschreiben, müssen diese HINTER den Komponenten im CSS generiert werden.
 */
@import "generic/global";
@import "generic/colors";
@import "generic/flexbox";

// UTILITIES: High-specificity, very explicit selectors. Overrides and helper classes (e.g. .u-hidden {}).
//@import "../node_modules/inuitcss/utilities/utilities.widths";
//@import "../node_modules/inuitcss/utilities/utilities.headings";
//@import "../node_modules/inuitcss/utilities/utilities.spacings";

@import "screens/cps/screens.login";
@import "screens/cps/home/screens.mitarbeiter-dashboard";
@import "screens/cps/home/screens.mitarbeiter-calendar";
@import "screens/cps/home/screens.nachrichten_list";
@import "screens/cps/screens.activity";
@import "screens/cps/salestools/screens.salestools";
@import "screens/cps/salestools/screens.salestoolsadmin";
@import "screens/cps/screens.project_map";
@import "screens/cps/screens.tml";
@import "screens/cps/projekt/screens.vorschlagsliste.scss";
@import "screens/cps/abgleichtool/abgleichtool.scss";
@import "screens/administration/wahlfelder/screens.wahlfeld-search";
@import "screens/administration/wahlfelder/screens.wahlfeldertranslation";
@import "screens/administration/wahlfelder/screens.wahlfeld-compare";
@import "screens/administration/vertriebskampagnen/massnahmen/screens.vertriebskampagnen.massnahmentranslation";
@import "screens/administration/benutzer/benutzerantrag";
@import "screens/administration/forecastpflege/forecastpflege";
@import "screens/administration/mitarbeiter/screens.mitarbeiter";
@import "screens/administration/mitarbeiterinstanz/screens.mitarbeiterinstanz-compare";
@import "screens/administration/wahlfeldgruppe/screens.wahlfeldgruppe-compare";
@import "screens/ticket/screens.objektbeschlaege";
@import "screens/ticket/screens.vertretung";
@import "screens/ticket/custom/va/colorsampleorder";
@import "screens/cps/kunde/screens.kunde-projektvorschlagliste";
@import "screens/cps/kunde/dashboard/screens.kunde-dashboard";
@import "screens/cps/kunde/kondition/screens.kunde-kondition";
@import "screens/cps/kunde/kundensuche/screens.kundensuche-map";
@import "screens/cps/messe/screens.nachbereitung";
@import "screens/cps/messe/screens.nachbereitung-import";
@import "screens/cps/planer/screens.create";
@import "screens/cps/planer/screens.ibau";
@import "screens/cps/projekt/screens.projecttracker";
@import "screens/cps/projekt/screens.expose";
@import "screens/cps/projekt/screens.kundenanschreiben";

@import "screens/cps/referenzprojekt/screens.referenzprojekt";
@import "screens/cps/projekt/screens.projectpassport";
@import "screens/cps/projekt/screens.aftersales";
@import "screens/cps/projekt/screens.alufit";
@import "screens/cps/projekt/screens.kitfertigung";
@import "screens/cps/projekt/screens.nachhaltigkeit";
@import "screens/cps/projekt/screens.create";
@import "screens/cps/projekt/screens.bulkedit";
@import "screens/cps/projekt/screens.produktgruppen-systeme";
@import "screens/cps/projekt/screens.produktgruppen-forecast";
@import "screens/cps/kunde/kitfirmenliste/screens.kunde-kitfirmenliste";
@import "screens/cps/screens.ticket_list";
@import "screens/cps/screens.massnahmenplan";
@import "screens/cps/nachhaltigkeit/screens.nachhaltigkeit";
@import "screens/cps/verwaltung/gebietsreform";
@import "screens/cps/report/screens.massnahmen";
@import "screens/cps/report/screens.report-overview";

@import "screens/cps/kpm/screens.kpm-dashboard";
@import "screens/cps/kpm/screens.kpm-offers";
@import "screens/cps/kpm/screens.kpm-network";
@import "screens/cps/kpm/screens.kpm-externnetwork";
@import "screens/cps/kpm/screens.kpm-scorecard";
@import "screens/cps/bis/screens.bis.configurator";
@import "screens/cps/bis/screens.bis.list";
@import "screens/cps/endverbraucher/screens.endverbraucher";
