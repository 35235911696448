.c-mitarbeiterdetails {
  .c-mitarbeiterdetails__img--visible {
    height: 160px;
    width: 160px;
    border-radius: 80px;
    border: 1px solid #444;

    position: relative;
    right: 0;
    top: 0;
  }
}