.s-report-overview {
  #report-overview-table {
    button.report-overview--markierung-button.markiert {
      color: var(--sco-color-primary);
    }
    button.report-overview--deaktivieren-button.deaktiviert {
      color: var(--sco-color-primary);
    }

    .report-overview--name-th {
      min-width: 250px;

      @include screen-s {
        min-width: 150px;
      }
      @include screen-m {
        min-width: 200px;
      }
    }

    .report-overview--name-icon {
      width: 14px;
      margin-right: 6px;

      @include screen-s {
        display: none;
      }
      @include screen-m {
        display: none;
      }
    }

    .report-overview--admin-column {
      @include screen-s {
        display: none;
      }
    }
  }

  #button-zeige-deaktivierte-reports.o-hidden {
    display: none !important;
  }

}