$c-section--heading-background: var(--sco-color-grey-700);
$c-section--heading-text: var(--sco-color-white);
$c-section--folded-background: var(--sco-color-grey-500);
$c-section--folded-text: var(--sco-color-white);

$c-section--storybook-background: #f2f2f2;
$c-section--storybook-text: var(--sco-color-grey-700);

.c-section {

  .c-section--heading {
    height: 40px;
    line-height: 1;
    padding: 0px 45px 0px 15px;
    background: $c-section--heading-background;
    color: $c-section--heading-text;
    vertical-align: middle;
    cursor: pointer;
    position: relative;

    span {
      color: $c-section--folded-text;
    }

    .c-section--title {
      display: block;
      line-height: 1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .c-section--title-actions {
      margin-left: 10px;
      font-size: var(--sco-font-size-headline5);
      vertical-align: middle;
      line-height: 1;

      position: absolute;
      right: 45px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.c-section--folded {
    > .c-section--heading {
      background: $c-section--folded-background;
      color: $c-section--folded-text;
      span {
        color: $c-section--folded-text;
      }
    }
  }

  &.c-section--foldable:not(.design-storybook) {

    .c-section--heading:after {
      @include faicon($fa-var-chevron-down);
      margin-left: 10px;
      font-size: var(--sco-font-size-headline5);
      vertical-align: middle;
      line-height: 1;

      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.c-section--folded > .c-section--heading:after {
      content: fa-content($fa-var-chevron-right);
    }
  }

  .c-section--content {
    margin-top: 5px;
  }

  &.design-storybook {
    .c-section--heading {
      background: $c-section--storybook-background;
      color: $c-section--storybook-text;
      line-height: 24px;
      min-height: 56px;

      padding: var(--sco-spacing-16) var(--sco-spacing-24);
      margin-bottom: var(--sco-spacing-8);

      span {
        color: $c-section--storybook-text;
      }
    }

    &.c-section--folded {
      > .c-section--heading {
        background: $c-section--storybook-background;
        color: $c-section--storybook-text;
        span {
          color: $c-section--storybook-text;
        }
      }
    }

    &.c-section--foldable {
      .c-section--heading:after {
        content: "\2212";
        margin-left: 8px;
        font-size: var(--sco-font-size-headline5);
        font-weight: 400;
        line-height: 24px;
        vertical-align: middle;

        position: absolute;
        right: var(--sco-spacing-24);
        top: 50%;
        transform: translateY(-50%);
      }

      &.c-section--folded > .c-section--heading:after {
        content: "+";
      }
    }

    .c-section--content {
      border: 1px solid $c-section--storybook-background;
      overflow: hidden;
      padding: var(--sco-spacing-16) var(--sco-spacing-24);
      margin: var(--sco-spacing-8) 0;
    }
  }
}
