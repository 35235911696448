.s-kpm-network {

  .c-filtersearch__wrapper {
    margin-top: 14px;
  }

  .c-filtersearch__body {
  }

  .c-filtersearch__filter-search {
    width: 120px;
  }

  .c-filtersearch__filters {
    flex-basis: 280px;
  }

  .c-filtersearch__results {
    width: calc(100% - 400px);
    position: relative;
  }

  .c-kpmnetwork {
    height: calc(100vH - 240px);
    position: relative;

    > svg {
      height: 100%;
      width: 100%;
    }
  }

  .c-kpmnetwork--legend {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    .c-kpmnetwork--legend-item {
      flex: 1;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
    }

    .c-kpmnetwork--legend-item-icon {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      flex-grow: 0;
      margin-right: 10px;

      .c-icon {
        color: #666;
        font-size: var(--sco-font-size-headline3);
      }
    }

    .c-kpmnetwork--legend-item-desc {
      flex-grow: 0;
      line-height: 30px;
      margin-right: 20px;
      white-space: nowrap;
    }

    .c-kpmnetwork--legend-actual-node {
      border: 3px solid #666;
    }

    .c-kpmnetwork--legend-northdata-link {
      background: #007388;
      color: #fff;
      line-height: 30px;
      text-align: center;
    }

    .c-kpmnetwork--legend-northdata-more {
      background: #f18700;
      color: #fff;
      line-height: 30px;
      text-align: center;
    }

    .c-kpmnetwork--legend-northdata-office-type {
      background-image:
              repeating-linear-gradient(45deg,
                      #78b928 0px,
                      #78b928 5px, #bcd998 5px,
                      #bcd998 8px, #78b928 8px);
    }


  }

  .c-kpmnetwork--legend-spacer {
    height: 10px;
    border-top: 1px solid #ccc;
  }

}