.c-filtersearch__body {
  display: flex;
  width: 100%;
}

/* filters */

.c-filtersearch__filter-container {
  margin-bottom: 14px;
}

.c-filtersearch__filters {
  flex-basis: 300px;
  flex-shrink: 1;
  flex-grow: 0;

  .c-filtersearch__active-filters .c-filtersearch__filter-boundary {
    margin-bottom: 15px;

    .c-filtersearch__active-filter-title {
      background-color: $color-gray--152;
    }
  }
}

.c-filtersearch__filter-ajax-wrapper {
  min-height: 80px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  will-change: transform;
}

.c-filtersearch__checkbox-filter .c-checkbox,
.c-filtersearch__checkbox-single-filter .c-checkbox,
.c-filtersearch__active-filters .c-checkbox,
.c-filtersearch__radiogroup-filter .c-radio {
  height: auto;
  min-height: 27px;
  margin:3px 0px;

  label {
    flex-grow: 1;
  }

  .c-icon {
    flex-grow: 0;
    flex-shrink: 0;
    font-size: var(--sco-font-size-headline6);

    border-radius: 50%;
    width: 25px;
    height: 25px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--sco-color-white);
  }
}

/*  results  */

.c-filtersearch__results {
  flex: 1;
  margin-left: 30px;
  overflow: hidden;

  >.c-spinner {
    margin-top: 80px;
  }
}

.c-filtersearch__results-head {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: row;

  .c-dropdown--wrapper {
    flex: 0;
    flex-basis: 200px;
  }

  h2 {
    flex: 1;
    font-size: var(--sco-font-size-headline5);
    margin-bottom: 7px;
    font-weight: 300;
  }
}


/* Active Search Filters are hidden at the beginning */
.c-filtersearch__filter-container[data-name="active_filters"] {
  display: none;
}

/* Active Filter Search Title Style */
.c-filtersearch__active-filter-title {
  font-weight: bold;
}

.c-filtersearch__filter-clear {
  position: absolute;
  right: 0;
  font-size: 0.9em;
  color: $color_gray--120;

  &:hover {
    color: $color_gray--90;
  }
}

//Slim Layout
.c-filtersearch__slim {
  .c-filtersearch__filters {
    flex-basis: 150px;

    .c-card--header.c-filtersearch__filter-searchable {
      flex-direction: row;
    }

    .c-filtersearch__checkbox-filter .c-checkbox label {
      overflow-wrap: anywhere;
    }
  }

  .c-filtersearch__button-hide  {
    cursor: pointer;
    margin-left: 7px;
    margin-top: 1em;
  }

  .c-filtersearch__button-show  {
    cursor: pointer;
    margin-left: 7px;
    margin-top: 1em;
  }

  .c-filtersearch__results {
    margin-left: 7px;
  }
}
