.s-kpm-externnetwork {

  .c-filtersearch__wrapper {
    margin-top: 14px;
  }

  .c-filtersearch__body {
  }

  .c-filtersearch__filter-search {
    width: 120px;
  }

  .c-filtersearch__filters {
    flex-basis: 280px;
  }

  .c-filtersearch__results {
    width: calc(100% - 400px);
    position: relative;
  }

  .c-kpmexternnetwork {
    height: calc(100vh - 260px);

    > svg {
      height: 100%;
      width: 100%;
    }
  }

  .c-kpmexternnetwork--legend {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    .c-kpmexternnetwork--legend-item {
      flex: 1;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
    }

    .c-kpmexternnetwork--legend-item-icon {
      height: 40px;
      width: 40px;
      border-radius: 100%;
      flex-grow: 0;
      margin-right: 10px;

      .c-icon {
        color: white;
        position: relative;
        top: 6px;
        left: 9px;
        font-size: var(--sco-font-size-headline3);
      }
      &.c-kpmexternnetwork--legend-kunde-kfm-beziehung {
        background-color: #84cfe4;
      }
      &.c-kpmexternnetwork--legend-kunde {
        background-color: #78b928;
      }
      &.c-kpmexternnetwork--legend-planer {
        background-color: #78b928;

        .c-icon {
          left: 5px;
          top: 7px;
        }
      }
    }

    .c-kpmexternnetwork--legend-item-desc {
      flex-grow: 0;
      line-height: 30px;
      margin-top: 5px;
      margin-right: 20px;
      white-space: nowrap;
    }

    .c-kpmexternnetwork--legend-actual-node {
      border: 3px solid #666;
    }

  }

  .c-kpmexternnetwork--legend-spacer {
    height: 10px;
    border-top: 1px solid #ccc;
  }

}

#s-kpmexternnetwork-infodialog-details-spinner {
  transform: inherit;
  display: none;
}