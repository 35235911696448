.s-projekt-kitfertigung {
  .s-projekt-kitfertigung--grid-layout {
    justify-content: flex-start;

    .c-dashboard-flex-tile {
      margin-top: 10px;
      flex-grow: 1;
      height: auto;
      min-height: auto;
      flex-basis: calc(50% - 10px);

      @include tabletPortrait {
        flex-basis: calc(100% - 10px);
      }
      @include tabletLandscape {
        flex-basis: calc(100% - 10px);
      }
      @include mobile {
        flex-basis: calc(100% - 10px);
      }

    }

    #s-projekt-kitfertigung--card-PS_KIT_FIRMENLISTE {
      >.c-card--content {
        max-height: 380px;
        overflow-y: scroll;

        @include tablet {
          max-height: none;
          overflow-y: visible;
        }
        @include mobile {
          max-height: none;
          overflow-y: visible;
        }

        .kit-produktgruppe-header {
          font-weight: bold;
          margin-top: 10px;
        }

        .unlink-button {
          height: 16px;
          width: 16px;
          min-width: 16px;
          margin-top: 0;
          padding: 6px 6px 6px 6px;
        }

        .unlink-button::before {
          font-size: 0.8em !important;
        }

      }
    }
  }
}