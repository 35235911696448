.c-master-container {
	width: 100%;
	min-height: 100%;
	height: auto;
	overflow-x: hidden;
	overflow-y: hidden;
	position: absolute;
	left: 0px;
	top: 0px;
	background: var(--sco-color-grey-200);
	display: flex;
	flex-direction: column;
}

.c-sidebar ~ .c-master-container {
	padding-right: 30px;

	@media(max-height: 768px) {
		padding-right: 20px;
	}
}

@media print {
	.c-master-container {
		position: static !important;
		display: block;
		overflow: visible;
	}
}