@mixin mobile {
	@media (max-width: #{$tablet-width - 1px}) {
		@content;
	}
}

@mixin mobileLandscape {
	@media (max-width: #{$tablet-width - 1px}) and (orientation: landscape) {
		@content;
	}
}

@mixin mobilePortrait {
	@media (max-width: #{$tablet-width - 1px}) and (orientation: portrait) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

@mixin tabletLandscape {
	@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) and (orientation: landscape) {
		@content;
	}
}

@mixin tabletPortrait {
	@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) and (orientation: portrait) {
		@content;
	}
}

@mixin smaller-than-desktop {
	@media (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-width}) {
		@content;
	}
}

@mixin wider-than-desktop {
	@media (min-width: #{$fhd-width}) {
		@content;
	}
}

@mixin screen-xs {
	@media (max-width: #{$screen-s-min-width - 1px}) {
		@content;
	}
}

@mixin screen-s {
	@media (min-width: #{$screen-s-min-width}) and (max-width: #{$screen-m-min-width - 1px}) {
		@content;
	}
}

@mixin screen-m {
	@media (min-width: #{$screen-m-min-width}) and (max-width: #{$screen-l-min-width - 1px}) {
		@content;
	}
}

@mixin screen-l {
	@media (min-width: #{$screen-l-min-width}) and (max-width: #{$screen-xl-min-width - 1px}) {
		@content;
	}
}

@mixin screen-xl {
	@media (min-width: #{$screen-xl-min-width}) and (max-width: #{$screen-xxl-min-width - 1px}) {
		@content;
	}
}

@mixin screen-xxl {
	@media (min-width: #{$screen-xxl-min-width}) {
		@content;
	}
}

.hide-on-mobile {
	@include mobile {
		display: none!important;
	}
}

.hide-on-tablet {
	@include tablet {
		display: none!important;
	}
	@include tabletLandscape {
		display: none!important;
	}
	@include tabletPortrait {
		display: none!important;
	}
}

.hide-on-tablet-portrait {
	@include tabletPortrait {
		display: none!important;
	}
}


.debug-screen-xs {
	@include screen-xs {
		display: block!important;
	}
}
.debug-screen-s {
	@include screen-s {
		display: block!important;
	}
}
.debug-screen-m {
	@include screen-m {
		display: block!important;
	}
}
.debug-screen-l {
	@include screen-l {
		display: block!important;
	}
}

.debug-screen-xl {
	@include screen-xl {
		display: block!important;
	}
}
.debug-screen-xxl {
	@include screen-xxl {
		display: block!important;
	}
}