.s-planer-create {
    .s-planer-create--duplicates-grid-layout {
        justify-content: flex-start;

        .s-planer-create--duplicates-grid-tile {
            flex-shrink: 1;
            flex-grow: 0;
            @include number-of-dashboard-tiles-per-row(2);
            min-height: 0;
            margin: 5px;

            &.c-card--with-content-scrollable {
                min-height: 300px;
            }

            @include mobile {
                @include number-of-dashboard-tiles-per-row(1);
            }

            @include tabletPortrait {
                @include number-of-dashboard-tiles-per-row(1);
            }

            @include tabletLandscape {
                @include number-of-dashboard-tiles-per-row(2);
            }

            @include wider-than-desktop {
                @include number-of-dashboard-tiles-per-row(3);
            }

            .c-card--header {
                padding: 4px 16px;
                min-height: auto;
            }
        }
    }

    .s-planer-create--no-duplicates-found-panel, .s-planer-create--duplicates-card {
        margin-top: 10px;
        margin-bottom: 15px;

        .c-card--header-title {
            white-space: normal;
        }

        .c-card--header-wrapper-left {
            flex-grow: 4;
        }
    }
}