.c-button {
    cursor: pointer;
    touch-action: manipulation;
    user-select: none;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    position: relative;
}

.c-button--iconbutton {
    box-sizing: border-box;
    border-radius: 50%;
    border: 0 transparent;
    height: 32px;
    line-height: 28px;
    text-align: left;
    vertical-align: middle;
    width: 32px;
    min-width: 32px;
    background: transparent;
    color: var(--sco-color-grey-700);
    position: relative;
    display: inline-block;
    &:after {
        content:' ';
        display: block;
    }
    &:before {
        font-size: var(--sco-font-size-headline4);
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &#s-messagelist-mark-all-unread {
        margin-top: 4px;
    }

    &:focus {
        //color: var(--sco-color-primary);
    }

    &:hover {
        color: var(--sco-color-primary);
    }

    &:active {
        color: var(--sco-color-primary);
        background-color: var(--sco-color-grey-600);
    }

    &.c-button--mini {

        height: var(--sco-spacing-16);
        width: var(--sco-spacing-16);
        min-width: var(--sco-spacing-16);

        &:before {
            font-size: var(--sco-font-size-headline6);
        }
    }

    &.c-button--iconbutton-green {
        border-radius: 0;
        color: var(--sco-color-white);
        font-size: var(--sco-font-size-headline6);
        line-height: 1.4;

        background-color: var(--sco-color-primary);
        &:focus {
            background-color: var(--sco-color-primary);
            color: var(--sco-color-white);
        }

        &:hover {
            background-color: var(--sco-color-green-800);
            color: var(--sco-color-white);
        }

        &:active {
            background-color: var(--sco-color-green-500);
            color: var(--sco-color-white);
        }

        &.corner-round {
            border-radius: 50px;
        }

        .c-button--custom-iconcontent {
            font-size: var(--sco-font-size-headline4);
            display: inline-block;
            position: absolute;
            bottom: 0;
            left: 15%;
        }
    }

    &.c-button--cardheader-iconbutton-green {
        font-size: var(--sco-font-size-headline6);
        width: 24px;
        height: 24px;
        min-width: 24px;
        color: var(--sco-color-primary);

        &:focus {
            color: var(--sco-color-green-800);
        }

        &:hover {
            color: var(--sco-color-green-800);
        }

        &:active {
            color: var(--sco-color-green-800);
        }

        &.corner-round {
            border-radius: 50px;
        }

        &:before {
            font-size: var(--sco-font-size-headline5);
        }
    }

    &.c-button--iconbutton-red  {
        @extend .c-button--iconbutton-green;
        background-color: var(--sco-color-red-600);
        &:focus {
            background-color: var(--sco-color-red-600);
        }
        &:hover {
            background-color: var(--sco-color-red-800);
        }

        &:active {
            background-color: var(--sco-color-red-800);
        }

    }

    &.c-button--cardheader-iconbutton-red {
        @extend .c-button--iconbutton-green;

        color: var(--sco-color-red-600);

        &:focus {
            color: var(--sco-color-red-800);
        }

        &:hover {
            color: var(--sco-color-red-800);
        }

        &:active {
            color: var(--sco-color-red-800);
        }
    }

    &.c-button--iconbutton-anthracite {
        @extend .c-button--iconbutton-green;
        background-color: var(--sco-color-anthracite-200);
        &:focus {
            background-color: var(--sco-color-anthracite-300);
        }
        &:hover {
            background-color: var(--sco-color-anthracite-300);
        }

        &:active {
            background-color: var(--sco-color-anthracite-300);
        }

    }

    &.c-button--iconbutton-secondary {
        @extend .c-button--iconbutton-green;
        background-color: var(--sco-color-grey-700);
        &:focus {
            background-color: var(--sco-color-grey-800);
        }
        &:hover {
            background-color: var(--sco-color-grey-800);
        }

        &:active {
            background-color: var(--sco-color-grey-800);
        }

    }

    &.c-button--cardheader-iconbutton-anthracite {
        @extend .c-button--cardheader-iconbutton-green;

        color: var(--sco-color-anthracite-200);

        &:focus {
            color: var(--sco-color-anthracite-300);
        }

        &:hover {
            color: var(--sco-color-anthracite-300);
        }

        &:active {
            color: var(--sco-color-anthracite-300);
        }
    }

    &.c-button--cardheader-iconbutton-white {
        @extend .c-button--cardheader-iconbutton-green;

        color: var(--sco-color-white);

        &:focus {
            color: var(--sco-color-anthracite-200);
        }

        &:hover {
            color: var(--sco-color-anthracite-200);
        }

        &:active {
            color: var(--sco-color-anthracite-200);
        }
    }


    &.c-button--iconbutton-green--10  {
        @extend .c-button--iconbutton-green;
        background-color: $color-green--10;
        &:focus {
            background-color: var(--sco-color-primary);
        }
        &:hover {
            background-color: var(--sco-color-green-800);
        }

        &:active {
            background-color: var(--sco-color-green-500);
        }

    }

    &.c-button--cardheader-iconbutton-green--10 {
        @extend .c-button--iconbutton-green;

        color: var(--sco-color-primary);

        &:focus {
            color: var(--sco-color-green-800);
        }

        &:hover {
            color: var(--sco-color-green-800);
        }

        &:active {
            color: var(--sco-color-green-800);
        }
    }

}

.c-button--icontextbutton,
.c-button--textbutton,
a.c-button--icontextbutton:link ,
a.c-button--textbutton:link {
    position: relative;
    display: inline-block;
    padding: 10px 16px 8px 16px;
    color: var(--sco-color-white);
    font-size: var(--sco-font-size-headline6);
    line-height: 1.2;
    background-color: var(--sco-color-primary);
    text-decoration: none!important;
    border: 1px solid var(--sco-color-primary);
    min-width: 64px;
    
    &:focus {
        background-color: var(--sco-color-primary);
        color: var(--sco-color-white);
        border: 1px solid var(--sco-color-primary);
    }
    
    &:hover {
        background-color: var(--sco-color-green-800);
        color: var(--sco-color-white);
        border: 1px solid var(--sco-color-green-800);
    }

    &:active {
        background-color: var(--sco-color-green-500);
        color: var(--sco-color-white);
        border: 1px solid var(--sco-color-green-500);
    }

    &:before  {
        margin-right: 5px;
    }
}

.c-button--mini {
    padding: 6px 10px 6px 10px;
}

.c-button--secondary {
    background: var(--sco-color-grey-700);
}

.c-button--secondary-small {
    background: var(--sco-color-grey-600);
    padding: 6px 10px 6px 10px;

    &:hover {
        color: var(--sco-color-white);
        background-color: var(--sco-color-grey-700);
    }
}

.c-button--negative {
    background: var(--sco-color-red-600);
    color: var(--sco-color-white);

    &:hover {
        background-color: $color-red--light;
    }

    &:focus {
        background-color: $color-red--light;
        color: var(--sco-color-white);
    }

    &:active {
        background-color: var(--sco-color-red-800);
        color: var(--sco-color-white);
    }
}

.c-button--light-orange {
    background: $color-orange--light;
    color: var(--sco-color-white);

    &:hover {
        background-color: var(--sco-color-orange-200);
    }

    &:focus {
        background-color: $color-orange--light;
        color: var(--sco-color-white);
    }

    &:active {
        background-color: $color-orange--light;
        color: var(--sco-color-white);
    }
}

.c-button--disabled, .c-button:disabled {
    background: var(--sco-color-grey-100);
    color: var(--sco-color-grey-400);
    cursor: not-allowed;

    &:hover, &:focus, &:active {
        background: var(--sco-color-grey-100);
        color: var(--sco-color-grey-400);
    }
}

.c-button--light {
    background: var(--sco-color-white);
    color: var(--sco-color-grey-600);
}

.c-button--content {
    font-family: var(--sco-font-family-lato-light);
    font-size: var(--sco-font-size-headline5);
    color: inherit;
}

.c-button--badge {
    border-radius: 50%;
    font-size: 10px;
    padding: 2px 5px;
    text-align: center;
    background-color: var(--sco-color-red-600);
    color: var(--sco-color-white);
    line-height: 10px;
    position: absolute;
    bottom: 5px;
    right: -10px;
    height: 15px;
}

.o-col-inner > .c-button {
    margin-top: 7px;
}
.o-col-inner > .c-button--icontextbutton {
    width: 100%;
    max-width: 300px;
}

.o-col-inner > .c-button--iconbutton {
    margin-top: 12px;
}


/* ##########  dropdown menu  #########*/

// Add Arrow to Btn
.c-button--with-dropdown-menu {
    width: var(--sco-spacing-32);
    min-width: var(--sco-spacing-32);
    margin-right: var(--sco-spacing-16);

    &:before {
        left: calc(50%);
        //color: var(--sco-color-primary);
    }

    &:after {
        @include fontawesome-icon();
        content: fa-content($fa-var-chevron-down);
        font-weight: 900; //solid

        position: absolute;
        right: -16px;
        height: 100%;
        width: 16px;
        font-size: var(--sco-font-size-headline6);
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--sco-color-white);
        background: var(--sco-color-primary);
        border-left: 1px solid $color-green--10;
        transition: all 0.2s ease-in-out;
    }

    &:hover:after {
        background: var(--sco-color-green-800);
    }

}

// Menu Style
.c-button--dropdown-menu {
    background: white;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 2px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    min-width: 200px;
    max-width: 300px;
}
.c-button--dropdown-menu-item {
    display: block;
    padding: 6px;
    //white-space: nowrap;
    position: relative;

    i { /*Icon*/
        position: absolute;
        display: block;
        top: 7px;
        left: 4px;
        width: 15px;
        text-align: center;
    }

    u { /*Highlight*/
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 3px;
        height: 100%;
        text-align: center;
    }

    span { /*Text*/
        display: block;
        padding-left: 15px;
    }

    // Normal v2ui button included as isDropdownItem=true in a dropdown-button-menu
    &.button-item {
        display: inline-block;
        height: 100%;

        span {
            height: 100%;
            display: inline-block;
            padding-left: 2px;
        }

        &:hover {
            background: $color-gray--152;
        }
    }
}
.c-button--dropdown-menu-item:hover {
    display: block;
    padding: 6px;
    background: var(--sco-color-grey-100);
}

.c-button--dropdown-menu-empty {
    display: inline-block;
    padding: 5px;
}
.c-button--dropdown-menu-info-item {
    display: block;
    padding: 6px;
    position: relative;
    background: $color-gray--152;

    i { /*Icon*/
        position: absolute;
        display: block;
        top: 7px;
        left: 4px;
        width: 15px;
        text-align: center;
    }

    span { /*Text*/
        display: block;
        padding-left: 15px;
    }
}
// Schriftart und Groesse setzen, wenn der Button im alten V1-Layout als Dropdown-Item integriert ist
body:has(#masterContainer) {
    .c-button--dropdown-menu {
        .c-button--dropdown-menu-item.button-item span {
            font: 12px arial, helvetica, sans-serif;
        }
    }
}