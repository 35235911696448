.c-websitelink {

  .c-icon {
    margin-right: 5px;
    display: inline-block;
    transform: scaleX(1.2) scaleY(1.2);
  }

  .c-icon--mirror{
    transform: scaleX(-1.2) scaleY(1.2);
  }

  .c-icon:before {
    position: relative;
    left: 2px;
    color: var(--sco-color-grey-700);
  }

  .green.c-icon::before {
    color: var(--sco-color-primary);
  }

  .c-icon--mirror:before {
    left: -2px;
  }

  a {
    text-decoration: underline;
  }
}