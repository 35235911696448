@import "../settings/settings.colors";

$c-calendar-icon--width: 55px;
$c-calendar-icon--fontsize-day: 16px;
$c-calendar-icon--fontsize-month: 12px;
$c-calendar-icon--fontsize-year: 10px;
$c-calendar-icon--fontsize-time: 16px;

.c-calendar-icon {
  width: $c-calendar-icon--width;
}

.c-calendar-icon--sheet {
  border-radius: 5px;
  border: 1px solid $color-border--dark2;
}

.c-calendar-icon--year {
  font-size: $c-calendar-icon--fontsize-year;
  text-align: center;
  color: $color-text--gray-dark;
  margin-top: 2px;
}

.c-calendar-icon--day {
  color: var(--sco-color-grey-700);
  font-size: $c-calendar-icon--fontsize-day;
  text-align: center;
  padding: 4px 0px;
}

.c-calendar-icon--month {
  background: var(--sco-color-red-600);
  color: var(--sco-color-white);
  font-size: $c-calendar-icon--fontsize-month;
  text-align: center;
  padding: 1px;
  text-transform: uppercase;
}

.c-calendar-icon--time {
  font-size: $c-calendar-icon--fontsize-time;
  text-align: center;
  color: $color-text--gray-dark;
  margin-top: 2px;
}