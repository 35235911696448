.c-footer {
  flex: 0;
	background: var(--sco-color-primary);
    height: 30px;
    flex-basis: 30px;
	width: calc(100% - (2*#{$content-padding}));
	margin: 10px $content-padding;
	padding: 5px 0px 5px 30px;
	//position: absolute;
	//bottom:0px;
	
	* {
 	    font-size: var(--sco-font-size-headline5);
	    font-family: var(--sco-font-family-lato-regular);
	    color: var(--sco-color-white);
	}

  a {
      text-decoration: none;
      padding-right: 30px;
  }

  @media print {
    display: none;
  }
}

//.c-sidebar ~ .c-master-container > .c-footer {
//  width: calc(100% - (2*#{$content-padding}) - 30px);
//}

.c-footer--copy {
    float: left;
}

.c-footer--navigation {
    float: right;
    
    ul {
        margin: 0px;
        padding: 0px;
        list-style-type: none;
        li {
            display: inline-block;
            
                    
        }
    }
}
