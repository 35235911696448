.s-projekt-expose {
  .o-col-inner {
    //sonst fehlt die Hälfte, weil es erst unterhalb der Kachel angezeigt würde
    //aber nur manchmal - beim nächsten build kann die Angabe ggf. wieder überflüssig sein
    height: unset;
  }

  .container_flex_cols {
    display: flex;
    flex-direction: row;
    gap: 15px;

    & > * {
      width: 100%;
    }
  }

  #planer-table_wrapper {
    overflow: auto;
  }

  .vbr-list {
    display: flex;
    flex-wrap: wrap;
  }

  .c-card-section-header {
    padding: 0 0 0 10px;
  }

  .c-radio-wrapper {
    height: 30px;
    .o-col {
      height: 100%;
      .o-col-inner {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .c-checkbox--readonly .c-checkbox--box + .c-checkbox--virtualbox{
    .c-checkbox--virtualbox-check {
      color: var(--sco-color-grey-600);
    }
    background-color: var(--sco-color-grey-200);
  }

  .imageDokumentItem {
    display: flex;
    justify-content: space-between;
    margin: 3px 0 3px 0;
    padding: 0.4em;
    padding-left: 1.5em;
    border: 1px solid;
    border-color: #CFCFCF;

    .c-checkbox.c-checkbox--labelbehind {
      gap: 10px;
    }
    .c-checkbox label {
      position: unset;
      top: unset;
      height: unset;
    }
  }
  .ui-sortable-handle {
    background-color: white;
  }
  .sortable-placeholder {
    height: 100px;
    background-color: var(--sco-color-grey-200);
    animation: pulse 5s infinite;

    border: 1px solid;
    border-color: #CFCFCF;
    text-align: left;
    &::before {
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
      //content: '\f324\e122\f323';
      content: '\f324 \f324 \f324';
    }
    @keyframes pulse {
      0% {
        color: var(--sco-color-grey-200);
      }
      100% {
        color: var(--sco-color-grey-800);
      }
    }
  }

  #c-card--schueco-serviceleistung {
    .c-dropdown--wrapper {
      height: 100%;
      .c-dropdown--container {
        height: 100%;
        .select2-container--default .select2-selection--multiple {
          height: 95%;
          .select2-selection__rendered {
            white-space: normal;
            overflow: auto;
          }
        }
      }
    }
  }

}
