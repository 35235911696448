.datetimes-commons {
  .MuiFormControl-root {
    border-bottom: $c-input--border solid var(--sco-color-grey-300);

    &[disabled] {
      border-bottom: $c-input--border solid $color-border--normal;
    }
    &.c-readonly {
      &:focus {
        border-bottom: $c-input--border solid $color-border--normal;
      }
      &:focus-within {
        border-bottom: $c-input--border solid $color-border--normal;
      }
      &:hover {
        border-bottom: $c-input--border solid $color-border--normal;
      }
      &:hover:not([disabled]):not(:focus):not(:focus-within):not(.o-hyperform--invalid) {
        border-bottom: $c-input--border solid $color-border--normal;
      }

      border-bottom: $c-input--border solid $color-border--normal;
    }

    &:hover:not([disabled]):not(:focus):not(:focus-within):not(.o-hyperform--invalid) {
      border-bottom: $c-input--border-focus solid $color-border--hover;
    }

    &:focus-within, &:focus {
      border-bottom: $c-input--border-focus solid $color-border--focus;
    }

    input {
      border-bottom: none;

      &[disabled] {
        border-bottom: none;
      }

      &:hover:not([disabled]):not(:focus):not(.o-hyperform--invalid) {
        border-bottom: none;
      }

      &:focus {
        border-bottom: none;
      }
    }

    .MuiInputBase-input[readonly] {
      color: $color-text--input-disabled;
    }

    /** Hier wird der Rahmen konfiguriert */
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    /** Hier wird der Wrapper für den Uhrzeit-Button konfiguriert */
    .MuiInputAdornment-root {
      margin-left: 0;

      /** Hier wird der Uhrzeit-Button konfiguriert */
      .MuiButtonBase-root {
        padding: var(--sco-spacing-4);
        //left: -32px;
        top: 4px;
      }
    }
  }

  .c-input--picker-empty {
    .MuiInputBase-input {
      color: $color-text--placeholder;
    }
  }
}