$color-checkbox--readonly-unchecked: var(--sco-color-grey-400);
$color-checkbox--readonly-checked: var(--sco-color-grey-600);

$color-checkbox--readwrite-unchecked: $color-text--label;
$color-checkbox--readwrite-checked: $color-text--input;
$color-checkbox--readwrite-hover: $color-text--input;


.c-checkbox--box {
  display: none;
}

.c-checkbox--box + .c-checkbox--virtualbox {
  border: 2px solid $color-checkbox--readwrite-unchecked;
  width: 16px;
  height: 16px;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  transition: all 0.18s;

  .c-checkbox--virtualbox-check {
    display: block;
    height: 12px;
    font-size: 12px;
    @include faicon-before($fa-var-check);
    color: var(--sco-color-primary);

    position: relative;
    opacity: 0;
    &:before {
      position: absolute;
      left: 50%;
      top: calc(50% + 1px);
      transform: translateY(-50%) translateX(-50%);
    }
  }
}

.c-checkbox--box:checked + .c-checkbox--virtualbox {
  border: 2px solid $color-checkbox--readwrite-checked;
  .c-checkbox--virtualbox-check {
    opacity: 1;
  }
}

.c-checkbox:hover .c-checkbox--virtualbox {
  border: 2px solid $color-checkbox--readwrite-hover;
}

.c-checkbox--readonly {
  pointer-events:none;

  .c-checkbox--virtualbox {
    border: 2px solid $color-checkbox--readonly-unchecked !important;
  }

  input:checked ~ .c-checkbox--virtualbox {
    border: 2px solid $color-checkbox--readonly-checked !important;
  }
}
.c-checkbox {
  display: flex;
  user-select: none;
  height: var(--sco-input-wrapper-height);
  position: relative;

  label {
    height: 20px;

    font-size: var(--sco-font-size-headline6);
    top: 0px;
    position: absolute;

    color: $color-text--label;
    font-family: var(--sco-font-family-lato-regular);

  }
  label + .c-checkbox--boxwrap {
    margin-top: 2px;
  }

  &.c-checkbox:hover {
    label {
      color: $color-link--green-hover;
    }
  }
}

.c-checkbox--boxwrap {
  height: 16px;
  flex-shrink: 0;
}

.c-checkbox--labelinline {
  display: flex;
  flex-direction: row;
  align-items: center;

  .c-checkbox--boxwrap {
    display: inline-block;
    margin-left: 10px;
    margin-right: 0px;
  }

  label {
    display: inline-block;
    vertical-align: middle;

    line-height: inherit;
    font-size: inherit;

    height: auto;
    position: static;
    top: unset;
    left: unset;

    //top: 50%;
    //position: absolute;
    //transform: translateY(-50%);
    //left: 31px;
  }

  &:not(.c-checkbox--labelbehind) {
    label {
      margin-right: 10px;
    }
  }

  &.c-checkbox--labelbehind {
    .c-checkbox--boxwrap {
      margin-left: 0px;
      margin-right: 10px;
    }
  }

  &.c-checkbox:hover,
  &.c-checkbox--checked {
    label {
      color: $color-text--input;
    }
  }
}

.c-checkbox {

  display: flex;
  align-items: center;

  cursor: pointer;

  label {
    cursor: pointer;
    color: $color-text--label;
    font-family: var(--sco-font-family-lato-light);
  }
}
