.c-mitarbeiterpopup {
  width: auto;
  display: inline-block;
  position: relative;

  &:hover {
    cursor: pointer;

    span {
      color: $color-link--green-hover;
    }
  }

  .c-mitarbeiterpopup__img--visible ~ a,
  .c-mitarbeiterpopup__img--visible ~ span {
    padding-left: 25px;
  }

  &.c-mitarbeiterpopup--with-picture {
    min-height: 20px;

    .c-mitarbeiterpopup__img--visible {
      height: 20px;
      width: 20px;
      border-radius: 10px;

      position: absolute;
      left: 0;
      //top:3px;

      display: inline;
    }
  }
}