$c-input--border: 1px;
$c-input--border-focus: 2px;

.c-input--text {
    border-bottom: $c-input--border solid $color-border--normal;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding: 24px 0 0 0;
    height: $input--height;
    line-height: 17px;
    font-family: var(--sco-font-family-lato-regular);
    font-size: var(--sco-font-size-headline5);
    font-weight: normal;
    position: relative;
    width: 100%;

    outline: none;
    box-shadow: none;
    
    color: $color-text--input;
    
    transition: all 0.2s;

    display: block;
    
    caret-color: $color-border--focus;

    &[disabled] {
        border-bottom: $c-input--border solid $color-border--normal;
        padding: 24px 0 1px 0;
        background: none;        
        color: $color-text--input-disabled;
        -webkit-opacity: 1;
        -webkit-text-fill-color: $color-text--input-disabled;
        -moz-appearance: textfield;
    }

    &.c-input-empty[disabled]:before {
        content: '---';
        display: inline-block;
    }
    
    &:hover:not([disabled]):not(:focus):not(.o-hyperform--invalid) {
        border-bottom: $c-input--border-focus solid $color-border--hover;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        padding-bottom:0;
    }
    
    &:focus {
        border-bottom: $c-input--border-focus solid $color-border--focus;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        padding-bottom:0;
    } 

    &.o-hyperform--invalid {
        border-bottom: $c-input--border-focus solid $color-border--invalid;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        padding-bottom:0;
    }

    &::placeholder {
        color: $color-text--placeholder;
        font-family: var(--sco-font-family-lato-light);
    }
}

a .c-input--text[disabled] {
    pointer-events: none;
    color: var(--sco-color-grey-700);
    text-decoration: underline;
}

a:hover .c-input--text[disabled] {
    pointer-events: none;
    color: $color-link--green;
    -webkit-text-fill-color: unset;
}

.c-input--text-wrapper {
    display: block;
    position: relative;
    height: var(--sco-input-wrapper-height);
    margin-bottom: 4px;

    label {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        pointer-events: none;
        cursor: text;
        transition: all 0.2s;
        color: $color-text--label;
        font-family: var(--sco-font-family-lato-regular);
        font-size: var(--sco-font-size-headline6);
        line-height: 1.2;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
    }

    input:focus ~ label {
        font-family: var(--sco-font-family-lato-black);
        font-size: var(--sco-font-size-headline6);
        //top: 0;
        color: $color-border--focus;
    }

    input:not(.c-input--empty) ~ label,
    input[disabled] ~ label,
    input[placeholder] ~ label,
    div:not(.c-input--empty) ~ label,
    {
        font-family: var(--sco-font-family-lato-regular);
        //top: 0;
    }

    /* has to be in a separate blocks, otherwise firefox ignores the other selectors */
    input:-webkit-autofill ~ label {
        font-size: var(--sco-font-size-paragraph);
        font-family: var(--sco-font-family-lato-regular);
        //top: 0;
    }

}

.c-input--text-wrapper:has(input[disabled]):after {
    content: var(--sco-icon-input);
    display: block;
    position: absolute;
    right: 10px;
    top: 12px;
    line-height: 44px;
    font-size: 16px;
    color: var(--sco-color-input--disabled);
    font-family: "Font Awesome 6 Pro";
    font-weight: 100;
}

.c-input--text-wrapper:has(input[disabled]):before {
    content: var(--sco-icon-input--disabled);
    display: block;
    position: absolute;
    right: 7px;
    top: 12px;
    line-height: 44px;
    font-size: 18px;
    color: var(--sco-color-input--disabled);
    font-family: "Font Awesome 6 Pro";
    font-weight: 100;
}

.c-input--text-wrapper.ui-helper-hidden {
    display: none;
}


.c-input--search {
    /* Wird z.B. im Sales-Tools Header verwendet */
    display: inline-block;
    height: unset;
    margin-bottom: 0px;

    .c-input--text {
        padding: 0;
        border-bottom: 0px;

        &:-webkit-autofill {
            -webkit-text-fill-color: var(--sco-color-grey-930);
            -webkit-box-shadow: 0 0 0px 1000px var(--sco-color-grey-200) inset;
            -webkit-transition: background-color 5000s ease-in-out 0s;
        }
    }

    &:after {
        content: fa-content($fa-var-search);
        display: block;
        position: absolute;
        right: 10px;
        top: 0;
        line-height: 44px;
        font-size: 20px;
        font-family: "Font Awesome 6 Pro";
    }
}


/* Google Places Autocomplete Styling */

.pac-container {
    border-radius: 0px;
}

.pac-item {
    font-family: var(--sco-font-family-lato-regular);
    border-top: 0px;
    padding: 3px 10px;
}

.c-input--googleplaces {
    position: relative;
}

.c-input--googleplaces-detectlocation {

    &:after {
        background: white;
        padding-left: 5px;
        padding-right: 10px;
        content: fa-content($fa-var-map-marker-alt);
        display: block;
        position: absolute;
        right: 0px;
        bottom: 16px;
        font-size: var(--sco-font-size-headline4);
        font-family: "Font Awesome 6 Pro";
        cursor: pointer;

    }

    color: $color-text--gray;
    &:hover {
        color: var(--sco-color-grey-700);
    }
}

.c-input--googleplaces-clearx {
    &:after {
        background: white;
        padding-left: 10px;
        padding-right: 5px;
        content: fa-content($fa-var-times);
        display: block;
        position: absolute;
        right: 27px;
        bottom: 14px;
        font-size: var(--sco-font-size-headline4);
        font-family: "Font Awesome 6 Pro";
        cursor: pointer;

    }

    color: $color-text--gray;
    &:hover {
        color: var(--sco-color-grey-700);
    }
}

.c-info-tool-tip {

}

.c-input--content-right {
    position: absolute;
    right: 15px;
    top: calc(50% - 2px);
    transform: translateY(-50%);
    background: var(--sco-color-white);
    padding: 0 5px 0 10px;

    .c-button--iconbutton {
        height: 26px;
        width: 26px;
        min-width: 26px;

        &:before {
            font-size: var(--sco-font-size-headline5);
        }
    }
}

.c-input--text[type=number] ~ .c-input--content-right {
    left: 15px;
    right: unset;
    padding: 0 10px 0 5px;
}