.s-kunden-anschreiben {
  .o-col-inner {
    //sonst fehlt die Hälfte, weil es erst unterhalb der Kachel angezeigt würde
    //aber nur manchmal - beim nächsten build kann die Angabe ggf. wieder überflüssig sein
    height: unset;
  }
  .c-header--titlebuttons {
    //sonst sind iconbuttons und icontextbuttons nicht gleich groß
    display: flex;
    justify-content: end;
    align-items: end;
    gap: 5px;
    & > .c-button--icontextbutton {
      padding: 4px 16px;
      height: 32px;
    }
  }

  .uiHidden {
    display: none;
  }

  .container_flex_cols {
    display: flex;
    flex-direction: row;
    gap: 15px;

    & > * {
      width: 100%;
    }
  }

  #planer-table_wrapper {
    overflow: auto;
  }

  .vbr-list {
    display: flex;
    flex-wrap: wrap;
  }

  .c-card-section-header {
    padding: 0 0 0 10px;
  }

  .c-radio-wrapper {
    height: 30px;
    .o-col {
      height: 100%;
      .o-col-inner {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .c-checkbox--readonly .c-checkbox--box + .c-checkbox--virtualbox{
    .c-checkbox--virtualbox-check {
      color: var(--sco-color-grey-600);
    }
    background-color: var(--sco-color-grey-200);
  }

  .imageDokumentItem {
    display: flex;
    justify-content: space-between;
    margin: 3px 0 3px 0;
    padding: 0.4em;
    padding-left: 1.5em;
    border: 1px solid;
    border-color: #CFCFCF;

    .c-checkbox.c-checkbox--labelbehind {
      gap: 10px;
    }
    .c-checkbox label {
      position: unset;
      top: unset;
      height: unset;
    }
  }
  .ui-sortable-handle {
    background-color: white;
  }
  .sortable-placeholder {
    height: 100px;
    background-color: var(--sco-color-grey-200);
    animation: pulse 5s infinite;

    border: 1px solid;
    border-color: #CFCFCF;
    text-align: left;
    &::before {
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
      //content: '\f324\e122\f323';
      content: '\f324 \f324 \f324';
    }
    @keyframes pulse {
      0% {
        color: var(--sco-color-grey-200);
      }
      100% {
        color: var(--sco-color-grey-800);
      }
    }
  }

  #c-card--schueco-serviceleistung {
    .c-dropdown--wrapper {
      height: 100%;
      .c-dropdown--container {
        height: 100%;
        .select2-container--default .select2-selection--multiple {
          height: 95%;
          .select2-selection__rendered {
            white-space: normal;
            overflow: auto;
          }
        }
      }
    }
  }

  #c-card--dokumente {
    .c-card--content {
      display: flex;
      flex-direction: column;

      .dokumente {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        #dokumente-ordner-list, #linked-doc-list {
          flex-wrap: wrap;
        }

        & > div, #dokumente-list > div, #dokumente-ordner-list > div, #linked-doc-list > div {
          display: flex;
          align-items: center;
        }

        .c-checkbox--disabled {
          background-color: var(--sco-color-grey-200);
        }
      }
    }
  }

  #c-card--kunden {
    .c-card--content-scrollable {
      display: grid;
      height: unset;
    }
    .kunden-mail-hidden {
      display: none;
    }
    #firmenliste_datalist {
      --col-count: 24;
      display: grid;
      //grid-auto-columns: auto;
      grid-template-columns: repeat(24, 1fr);
      background-color: var(--sco-color-grey-100);

      * {
        grid-column-end: span 3;
      }

      .row-start {
        grid-column-start: 1;
        display: flex;

        &.asp .uncheck-radio {
          height: 18px;
          &.hidden {
            visibility: hidden;
          }
        }
      }

      .headline {
        grid-column: 1 / calc(var(--col-count) - 12);
        font-family: var(--sco-font-family-lato-regular);
        font-size: var(--sco-font-size-headline6);
        padding: 10px;
        strong {
          font-family: var(--sco-font-family-lato-bold);
        }
      }
      .buttons {
        grid-column: calc(var(--col-count) - 12) / calc(var(--col-count) + 1);
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 0.5em;
        padding-right: var(--sco-spacing-8);
      }
      .c-dropdown--output {
        white-space: nowrap;
        background-color: var(--sco-color-grey-100);
      }

      .asp {
        background-color: white;
        padding: 2px 0 2px 10px;

        .o-col-inner {
          padding: 0;
        }

        .c-checkbox {
          height: 20px;
        }

        // es müssen in der ftl genauso viele shorts wie longs vergeben werden, sonst geht die Verteilung der cols nicht auf
        &.short {
          grid-column-end: span 1;
        }
        &.long {
          grid-column-end: span 5;
        }

        &.highlightRed {
          .c-radio--virtualradio:before {
            color: #f0aaaa;
            content: "\f111";
            font-weight: 900;
          }

          .c-radio--input:checked + .c-radio--virtualradio:before {
            color: #f06666;
            content: "\f192";
          }
        }
      }
      .asp-headline {
        background-color: white;
        padding: 5px 0 5px 10px;
        font-family: var(--sco-font-family-lato-bold);

        // es müssen in der ftl genauso viele shorts wie longs vergeben werden, sonst geht die Verteilung der cols nicht auf
        &.short {
          grid-column-end: span 1;
        }
        &.long {
          grid-column-end: span 5;
        }
      }
    }
  }


  .previewContent {
    text-align: left;
  }
}
