.c-info-tool-tip {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  display: block;

  &:after {
    background: white;
    content: fa-content($fa-var-info-circle);
    display: block;
    font-size: var(--sco-font-size-headline4);
    font-family: "Font Awesome 6 Pro";
    cursor: pointer;
  }
  color: $color-text--gray-dark;

}

.c-card--header-right .c-info-tool-tip {
  position: unset;
  right: unset;
  top: unset;
  transform: unset;
  &:after {
    background: transparent;
  }
}

//.tooltipster-content {
//  h2 {
//    font-size: var(--sco-font-size-headline5);
//    color: var(--sco-color-white);
//    margin-top: $content-padding;
//  }
//
//  h3 {
//    font-size: var(--sco-font-size-headline5);
//    color: var(--sco-color-white);
//  }
//
//  p{
//    font-size: var(--sco-font-size-headline5);
//    color: var(--sco-color-white);
//  }
//}