.s-endverbraucher--show {

  .s-endverbraucher-show--grid-layout {
    justify-content: flex-start;

    .c-dashboard-flex-tile {
      margin-top: 10px;
      flex-grow: 1;
      flex-basis: calc(50% - 10px);
      //height: auto;
      //min-height: auto;
    }
  }

  #s-endverbraucher-show--card-STAMMDATEN {
    flex-basis: calc(100% - 10px);
    min-height: 80px;
  }
}