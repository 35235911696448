.s-project-map--popup {

  margin-right: -22px;

  .c-spinner {
    transform: none;
  }

}

.s-project-map {
  .c-master-container {
    max-height: 100%;

    > .c-content--main {
      min-height: 0;

      > .o-row {
        align-items: stretch;

        > .o-col {
          max-height: 100%;
          overflow: auto;
        }
      }
    }
  }

  @include mobile() {
    .c-master-container {
      max-height: initial;
    }
    .s-project-map--map {
      height: 383px;
    }
  }

  @include tabletPortrait() {
    .c-master-container {
      max-height: initial;

      > .c-content--main {
        > .o-row {
          align-content: flex-start;

          // Map-Column
          > .o-col:nth-child(0) {
            height: 350px;
          }
          // Filter-Column
          > .o-col:nth-child(1) {
            height: 350px;
          }
        }
      }
    }
    .s-project-map--map {
      height: 350px;
    }
  }

  .s-project-map--popup {
    width: 300px;
    font-size: var(--sco-font-size-headline5);

    @include mobile() {
      font-size: var(--sco-font-size-headline6);
      width: 260px;
    }

  }

  .s-project-map--popup-nr {

    //var(--sco-font-size-paragraph);
    display: block;
  }
  .s-project-map--popup-name {
    font-weight: bold;
    //var(--sco-font-size-paragraph);
    display: block;
  }
  .s-project-map--popup-address {
    margin-bottom: 10px;
    //var(--sco-font-size-paragraph);
    border-bottom: 2px solid $color-green--middle;
    display: block;
  }
  .s-project-map--popup-row {
    display: flex;
    margin-bottom: 5px;
    text-align: right;
    justify-items: stretch;
    > div {
      flex: 1;
    }
  }
  .s-project-map--popup-label {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 190px;
    text-align: left;

    @include mobile() {
      font-size: var(--sco-font-size-paragraph);
      flex-basis: 112px;
    }
  }
  .s-project-map--popup-buttons {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-bottom: -5px;

    > a {
      display: inline-block;
      flex-grow: 1;
      flex-basis: 120px;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }

  // IE height fix
  .s-project-map--iefix {
    display: table;
    width: 100%;
  }

  .gm-style-iw > div {
    overflow: visible !important;
  }

  .gm-style-iw > div > div {
    overflow: visible !important;
  }
}