.c-dateEntityItemlist {
  display: flex;
  flex-direction: column;

  .c-dateEntityItemlist-item {
    display: grid;
    height: 100%;
    align-items: center;
    grid-template-columns: [calendar] 50px [icons] 32px [content] auto [action-button] 20px [row-end];
    grid-template-areas:
          "date-first  icon-first  content-first  action-button"
          "date-second icon-second content-second action-button"
          "date-third  icon-third  content-third  action-button";

    /* Area Zuordnungen */

    .c-dateEntityItemlist-item--date-base {
      height: 100%;
      font-size: var(--sco-font-size-paragraph);
      font-family: var(--sco-font-family-lato-light);
      text-align: center;
    }

    .c-dateEntityItemlist-item--day {
      @extend .c-dateEntityItemlist-item--date-base;
      grid-area: date-first;
    }

    .c-dateEntityItemlist-item--month {
      @extend .c-dateEntityItemlist-item--date-base;
      grid-area: date-second;
    }

    .c-dateEntityItemlist-item--year {
      @extend .c-dateEntityItemlist-item--date-base;
      grid-area: date-third;
    }

    .c-dateEntityItemlist-item--icon-base {
      height: 100%;
      font-size: var(--sco-font-size-paragraph);
      text-align: center;
    }

    .c-dateEntityItemlist-item-icon-number {
      @extend .c-dateEntityItemlist-item--icon-base;
      grid-area: icon-first;
    }

    .c-dateEntityItemlist-item-icon-entity {
      @extend .c-dateEntityItemlist-item--icon-base;
      grid-area: icon-second;
    }

    .c-dateEntityItemlist-item-icon-location {
      @extend .c-dateEntityItemlist-item--icon-base;
      grid-area: icon-third;

    }

    .c-dateEntityItemlist-item--content-base {
      font-size: var(--sco-font-size-paragraph);
      margin-left: var(--sco-spacing-8);
    }

    .c-dateEntityItemlist-item-content-number {
      @extend .c-dateEntityItemlist-item--content-base;
      grid-area: content-first;
    }

    .c-dateEntityItemlist-item-content-name {
      @extend .c-dateEntityItemlist-item--content-base;
      grid-area: content-second;
      font-family: var(--sco-font-family-lato-black);
    }

    .c-dateEntityItemlist-item-content-location {
      @extend .c-dateEntityItemlist-item--content-base;
      grid-area: content-third;
    }

    .c-dateEntityItemlist-item--contextmenu-button {
      grid-area: action-button;
      font-size: var(--sco-font-size-paragraph);
    }

    &:last-child{
      border-bottom: none;
    }

    border-bottom: 2px solid $color-black--10;
    transition: background-color 0.3s;

    //margin-top: var(--sco-spacing-4);
    //padding-bottom: var(--sco-spacing-4);

    /*@include screen-m {
      margin-top: var(--sco-spacing-4);
      padding-bottom: var(--sco-spacing-4);
    }

    @include screen-l {
      margin-top: var(--sco-spacing-8);
      padding-bottom: var(--sco-spacing-8);
    }

    @include screen-xl {
      margin-top: var(--sco-spacing-8);
      padding-bottom: var(--sco-spacing-8);
    }
    @include screen-xxl {
      margin-top: var(--sco-spacing-12);
      padding-bottom: var(--sco-spacing-12);
    }*/
  }

  .c-dateEntityItemlist-item:first-child{
    margin-top: 0;
  }

  .c-dateEntityItemlist-item:hover {
    background-color: var(--sco-color-grey-100) !important;
  }

  .c-calendar-icon {
    padding-top: 8px;
  }

  .c-calendar-icon--month--projekt, .c-calendar-icon--month--top-vorgaenge.c-calendar-icon--month--top-vorgaenge--PROJEKT {
    @extend .c-calendar-icon--month;
    background-color: var(--sco-color-primary);
  }

  .c-calendar-icon--month--kunde, .c-calendar-icon--month--top-vorgaenge.c-calendar-icon--month--top-vorgaenge--KUNDE {
    @extend .c-calendar-icon--month;
    background-color: $color-blue--default;
  }

  .c-calendar-icon--month--planer, .c-calendar-icon--month--top-vorgaenge.c-calendar-icon--month--top-vorgaenge--PLANER {
    @extend .c-calendar-icon--month;
    background-color: var(--sco-color-orange-200);
  }

  .c-calendar-icon--month--top-vorgaenge {
    @extend .c-calendar-icon--month;
    background-color: var(--sco-color-grey-600);
  }

  .c-calendar-list--day, .c-calendar-list--month, .c-calendar-list--year {
    margin: 0;
    font-family: var(--sco-font-family-lato-light);
    font-size: var(--sco-font-size-paragraph);
    text-align: center;
    line-height: 1.0em;
  }

  .c-calendar-list--month {
    margin: var(--sco-spacing-8) 0;

    @include screen-s {
      margin: var(--sco-spacing-4) 0;
    }
  }

  .c-calendar-list--item-name {
    margin: var(--sco-spacing-8) 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include screen-s {
      margin: var(--sco-spacing-4) 0;
    }
  }

  .c-calendar-list--icon-column {
    margin-left: 8px;
    margin-right: 0;
    margin-top: 4px;
    margin-bottom: 2px;

    .c-calendar-list--icon {
      margin-right: 0;
      margin-top: 4px;
      margin-bottom: 2px;
      line-height: 1.0em;
    }
  }

  .c-calendar-list--item-number {
    margin: 0;
    padding: 2px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .c-calendar-list--item-detail {
    margin: 0;
    color: var(--sco-color-grey-800);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.c-label-value-list {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-row-gap: 8px;

  .c-label-value-list--item {
    align-content: center;
    border-bottom: 2px solid $color-black--10;
  }
}

.c-title-comment-list {
  .flex-container {
    display: flex;
    flex-direction: column;
  }

  .c-title-comment-list--item {
    border-bottom: 2px solid $color-black--10;
    transition: background-color 0.3s;
  }
  .c-title-comment-list--item:hover {
    background-color: var(--sco-color-grey-100) !important;
  }
}

.c-message-list {
  .flexbox-item-second {
    display: flex;
    flex-direction: column;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
  }

  .c-message-list--item {
    border-bottom: 2px solid $color-black--10;
    transition: background-color 0.3s;

    .c-message-list--item-sender{
      //margin: 0;
      color: var(--sco-color-grey-800);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .c-message-list--item-subject{
      //margin-bottom: var(--sco-spacing-8);
      color: var(--sco-color-grey-800);
      //white-space: nowrap;
      //overflow: hidden;
      //text-overflow: ellipsis;
    }
  }

  .c-message-list--item:hover {
    background-color: var(--sco-color-grey-100) !important;
  }

  .read {
    padding-left: 20px;
    border-left: 5px solid #FFFFFF;
  }

  .unread {
    padding-left: 20px;
    border-left: 5px solid;
  }
}

.contextmenu-button {
  align-self: center;
  margin-left: auto;
}

