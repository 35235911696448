.s-bis-configurator {

  .c-card--header {
    padding: 4px 16px;
    min-height: 48px;

    .c-card--header-wrapper-left {
      min-width: 0;
      flex-basis: unset;
    }
  }

  .c-bis-configurator--configform .c-card {

    @include screen-xs {
      grid-column: span 4;
    }
    @include screen-s {
      grid-column: span 8;
    }
    @include screen-m {
      grid-column: span 4;
    }
    @include screen-l {
      grid-column: span 4;
    }
    @include screen-xl {
      grid-column: span 6;
    }
    @include screen-xxl {
      grid-column: span 4;
    }

  }

  #s-bis-configurator--card-configure-project {

    @include screen-xs {
      grid-column: span 4;
    }
    @include screen-s {
      grid-column: span 8;
    }
    @include screen-m {
      grid-column: span 8;
    }
    @include screen-l {
      grid-column: span 8;
      grid-row: span 3;
    }
    @include screen-xl {
      grid-column: span 6;
      grid-row: span 3;
    }
    @include screen-xxl {
      grid-column: span 4;
      grid-row: span 3;
    }
  }

  #s-bis-configurator--card-project-duplicates {
    min-height: 600px;

    @include screen-xs {
      grid-column: span 4;
      order: 99!important;
    }
    @include screen-s {
      grid-column: span 8;
      order: 99!important;
    }
    @include screen-m {
      grid-column: span 8;
      order: 99!important;
    }
    @include screen-l {
      grid-column: span 12;
      order: 99!important;
    }
    @include screen-xl {
      grid-column: span 12;
      order: 99!important;
    }
    @include screen-xxl {
      grid-column: 9 / span 4;
      grid-row: span 3;
    }
  }



  //sonst sind iconbuttons und icontextbuttons nicht gleich groß
  .c-header--titlebuttons {
    float: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    column-gap: 10px;
    height: 26px;
    line-height: 26px;

    & > .c-button--icontextbutton {
      padding: 4px 16px;
      height: 32px;
    }
  }

  .c-button--content {
    @include screen-xs {
      font-size: 0;
    }
    @include screen-s {
      font-size: 0;
    }
  }

  .c-header--line2 .c-header--heading {
    display: flex;
    width: 100%;

    .c-header--titlebuttons {
      justify-self: right;
      align-items: start;
      padding-top: 4px;

      .badge {
        border-radius: .25rem;
        line-height: 1.95;
        text-align: center;
        white-space: nowrap;
        padding: .017em .4em;
        font-size: var(--sco-font-size-headline5);
      }

      #backofficeStatusBadge {
        background-color: var(--sco-color-primary);
        color: var(--sco-color-white);
      }
    }
  }

  #configure-project-fragment {
    h3 {
      font-family: var(--sco-font-family-lato-bold);
      font-size: var(--sco-font-size-paragraph);
      margin-bottom: 5px;
    }
  }

  #assign {
    h4 {
      font-family: var(--sco-font-family-lato-bold);
      font-size: var(--sco-font-size-paragraph);
      margin-bottom: 5px;
    }
  }

  .available-planner.content .content-header {
    font-family: var(--sco-font-family-lato-bold);
    font-size: var(--sco-font-size-paragraph);
    margin-bottom: 5px;
  }

  .o-row {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .c-card {
    height: 100%;
  }

  #texts {
    .c-tabs--tabbar-indicator {
      margin-bottom: 15px;
    }
  }

  .vbr-checkboxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;

    & > div {
      width: 100%;
    }
  }

  #s-bis-configurator--card-selected-planners {
    grid-column: span 12;

    @include screen-xs {
      grid-column: span 4;
    }
    @include screen-s {
      grid-column: span 8;
    }
    @include screen-m {
      grid-column: span 8;
    }
  }

  #s-bis-configurator--card-delivered-planners, #s-bis-configurator--card-available-planners, #psPlanerSuche {
    grid-column: span 4;

    @include screen-s {
      grid-column: span 8;
    }
  }

  .available-planner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0 5px 0;
    border-bottom: solid $color-border--normal 1px;

    .content-header {
      cursor: pointer;
    }
  }

  .matching-planner-container {
    display: flex;
    justify-content: space-between;
  }

  #placeHolder-plannerMatches {
    .new-planner-container {
      padding: 5px 0 5px 0;
      border-bottom: solid $color-border--normal 1px;
      margin-bottom: 5px;
    }
  }

  #psPlanerSuche {
    #placeholderPlanerResults {
      .planerdetails {
        .details {
          display: flex;
          justify-content: space-between;
          padding: 5px 0 5px 0;
          border-bottom: solid $color-border--normal 1px;
          margin-bottom: 5px;
        }
      }
    }
  }

  #plannerDefinition {
    th:not(:first-child) {
      text-align: center;
    }
  }

  #map-card {
    flex-grow: 1;

    .c-card--content {
      display: grid;
    }
  }

  #cpv-card {
    flex-grow: 1;
  }

  #watchSdbProject.active {
    background-color: $color-blue--middle;
  }

  #saveBtn {
    font-size: var(--sco-font-size-headline5);
  }

  .duplicates {
    .c-section--foldable {
      .c-section--heading {
        background-color: var(--sco-color-grey-100);
        color: $color-black--100;
        min-height: 64px;
      }
    }
  }

  #s-bis-configurator--save-btn {
    display: none;
  }

  .c-card:has(#bisConfigurator .ui-wizard-tabs-container #configure.active) {
    #s-bis-configurator--save-btn {
      display: inline-block;
    }
  }
}
