.s-kunde-kondition {

    .swal2-content {
        & .c-dashboard-flex-tile {
            height: 150px;
        }
    }

    .c-dashboard-flex-tile {
        min-height: 300px;
    }

    .s-kunde-konditionen--button-tile {
        flex-grow: 0;
        height: 100px;

        @include mobile {
            flex-basis: 100%;
        }

        @include tabletPortrait {
            flex-basis: calc(50% - 10px);
        }

        @include tabletLandscape {
            flex-basis: calc(50% - 10px);
        }

        @include wider-than-desktop {
            flex-basis: calc(33% - 10px);
        }
    }

    .s-kunde-konditionen--project-tile {
        flex-shrink: 1;
        flex-grow: 1;
        min-height: 300px;

        @include mobile {
            flex-basis: 100%;
        }

        @include tabletPortrait {
            flex-basis: calc(50% - 10px);
        }

        @include tabletLandscape {
            flex-basis: calc(50% - 10px);
        }

        @include wider-than-desktop {
            flex-basis: calc(33% - 10px);
        }

        .c-button--iconbutton {
          &.konditionsantrag-aktiv {
            background-color: $color-green--10;
          }
        }
    }


   .simple-div-table--table-cell-style {
       @include tabletPortrait {
           font-size: 12px;
       }
   }

    .simple-div-table--table-row {
        display: table-row;
        height: 30px;
    }

    .s-kunde-konditionen--project-image--container {
        height: 200px;
        background-color: var(--sco-color-grey-100);
    }

    .s-kunde-konditionen--project-image {
        max-height: 200px;
    }

    .c-card--header {
        min-height: 72px;
    }

    .c-card--header-icon-link {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .c-card--header-compressed .c-card--header-title {
        font-size: var(--sco-font-size-headline5);
    }
  
    .c-card--header-compressed .c-card--header-label {
        font-size: var(--sco-font-size-headline5);
    }

    .s-kunde-konditionen-card-background-no-project {
        background: var(--sco-color-grey-100);
        font-size: var(--sco-font-size-headline2);
        width: 100%;
        height: 100%;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 300px;
    }

    .s-kunde-kondition--legend {
        padding-top: 10em;
    }

    .s-kunde-kondition--legend > span {
        display: block;
    }

    .s-kunde-kondition--legend > span::before {
        content:'';
        width: 14px;
        height: 14px;
        display: block;
        float: left;
        margin: 3px 3px 0 0;
    }

    .s-kunde-kondition--legend-background-blueDefault::before {
        background: $color-blue--default;
    }

    .s-kunde-konditionen.c-card--header-compressed .c-card--header-title {

        @include screen-xs {
          max-width: 300px;
        }

        @include screen-s {
          max-width: 240px;
        }

        @include screen-m {
          max-width: 268px;
        }

        @include screen-l {
          max-width: 268px;
        }

    }

  .s-kunde-konditionen--project-tile {
    & .c-card--header-wrapper-left {
      max-width: 90%;
    }
  }

  .s-kunde-konditionen--projectname-container {
    overflow: hidden;
    text-overflow: ellipsis;
  }

}