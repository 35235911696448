//@import "jquery-ui/themes/base/accordion.css";
.s-referenzprojekt {
  .ui-widget.ui-accordion {
    .ui-accordion-header {
      padding: 9px;
      margin: 0;
      margin-bottom: 0.5em;
    font-size: var(--sco-font-size-headline5);
      height: 35px;
    background: var(--sco-color-grey-400);
    color: var(--sco-color-white);

      &:hover {
      background: var(--sco-color-grey-600);
      }
      .ui-accordion-header-icon {
        font-family: "Font Awesome 6 Pro";
        position: relative;
        left: calc(100% - 20px);
      }
      .ui-icon-triangle-1-s:before {
        content: "\f107";
      }
      .ui-icon-triangle-1-e:before {
        content: "\f105";
      }
      .error-indicator {
        display: none;
      color: var(--sco-color-red-600);
      }
    }
    .ui-accordion-header.ui-accordion-header-active {
      background: $c-section--heading-background;
      color: $c-section--heading-text;
    }
    .ui-accordion-content {
      margin-top: 5px;
      margin-left: 30px;
      padding-bottom: 0.5em;
    }
  }
  #add_beteiligte_button {
    margin-bottom: 12px;
  }
  .c-panel--sub-title {
    padding: 15px;
  }

  #beteiligtenliste {
    margin-top: 15px;
  }
  button[name=delete-system] {
    margin: 7px 0px;
  }

  #produktgruppen-systeme-search-button {
    margin-bottom: 7px;
  }

  .beteiligter button.add-beteiligter {
    display: none;
  }
  //removed/disabled beteiligte
  .beteiligter.disabled .c-card--header-title {
    text-decoration: line-through;
  }
  .beteiligter.disabled .c-card--content   {
    display: none;
  }
  .beteiligter.disabled button.delete-beteiligter {
    display: none;
  }
  .beteiligter.disabled button.add-beteiligter {
    display: inline-block;
  }
  //textbuttons - not iconbuttons -> so as much width as needed!
  .c-card--header-right button {
    min-width: auto;
  }

  .download-archive-dialog-content  {
    .select-buttons {
      justify-content: center;
      width: 100%;

      .select-button {
        margin-left: var(--sco-spacing-8);
        margin-right: var(--sco-spacing-8);
      }
    }
  }
}
