.s-messagelist {

  .c-check-all {
    margin-left: 10px;
    font-size: 22px;
    cursor: pointer;
  }

  .dataTables_processing {
    display: none !important;
  }

  #s-messagelist__resultlist {
    td {
      white-space: nowrap;
    }

    .open-detailsdialog-button {
      border: none;
      background: none;

      &.message-unread {
        span {
          font-family: var(--sco-font-family-lato-black);
          font-size: var(--sco-font-size-paragraph);
        }
      }

      .c-button--content {
        font-family: var(--sco-font-family-lato-regular);
        font-size: var(--sco-font-size-paragraph);
        color: var(--sco-color-grey-700);
        padding-top: var(--sco-spacing-8);
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  //Slim Layout
  .c-filtersearch__slim {
    .c-filtersearch__filters {
      @include screen-xxl {
        flex-basis: 600px;
      }

      @include screen-xl {
        flex-basis: 400px;
      }

      @include screen-l {
        flex-basis: 250px;
      }

      @include screen-m {
        flex-basis: 200px;
      }
    }
  }
}

