$progress-height: 40px;

$progress-white-dot-size: 16px;
$progress-white-dot-border-radius: $progress-white-dot-size / 2;

$progress-white-bar-left: $progress-white-dot-size;
$progress-white-bar-length: 90px;
$progress-white-bar-height: 4px;
$progress-white-bar-top: ($progress-white-dot-size - $progress-white-bar-height) / 2;

$progress-green-dot-size: 10px;
$progress-green-dot-border-radius: $progress-green-dot-size / 2;
$progress-green-dot-left: ($progress-white-dot-size - $progress-green-dot-size) / 2;
$progress-green-dot-top: ($progress-white-dot-size - $progress-green-dot-size) / 2;

$progress-green-bar-length: $progress-white-bar-length + ($progress-white-dot-size - $progress-green-dot-size);
$progress-green-bar-length-unfinished: 30px;
$progress-green-bar-left: $progress-green-dot-size;
$progress-green-bar-height: 2px;
$progress-green-bar-top: ($progress-green-dot-size - $progress-green-bar-height ) / 2;

$progress-step-width: $progress-white-dot-size + $progress-white-bar-length;
$progress-step-width-last: $progress-white-dot-size;

.c-progress-tracker {
    line-height: 1;
    padding-bottom: $progress-height;
}

.c-progress-tracker--step {
    position: relative;
    width: $progress-step-width;
    float: left;
    height: $progress-height;

    &:last-child {
        width: $progress-step-width-last;
    }
}


/* White Bar */
.c-progress-tracker--step-background {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 2;
}
.c-progress-tracker--step-background-dot {
    border-radius: $progress-white-dot-border-radius;
    height: $progress-white-dot-size;
    width: $progress-white-dot-size;
    display: inline-block;
    background: white;
    position: absolute;
    left: 0px;
    top: 0px;
}
.c-progress-tracker--step-background-bar {
    position: absolute;
    display: block;
    background: white;
    left: $progress-white-bar-left;
    top: $progress-white-bar-top;
    height: $progress-white-bar-height;
    width: $progress-white-bar-length;
}


/* White Bar - Shadow */
.c-progress-tracker--step-background-shadow {
    @extend .c-progress-tracker--step-background;
    z-index: 1;
}
.c-progress-tracker--step-background-dot-shadow {
    @extend .c-progress-tracker--step-background-dot;
    box-shadow: 0px 3px 10px 3px rgba(0,0,0,0.36);
}
.c-progress-tracker--step-background-bar-shadow {
    @extend .c-progress-tracker--step-background-bar;
    box-shadow: 0px 3px 10px 3px rgba(0,0,0,0.36);
}


/* Green Dot */
.c-progress-tracker--step-inner {
    border-radius: $progress-green-dot-border-radius;
    height: $progress-green-dot-size;
    width: $progress-green-dot-size;
    display: inline-block;
    position: absolute;
    top: $progress-green-dot-top;
    left: $progress-green-dot-left;

    z-index: 3;
}


/* Green Bar (finished step) */
.c-progress-tracker--step-bar {
    width: $progress-green-bar-length;
    height: $progress-green-bar-height;
    position: absolute;
    left: $progress-green-bar-left;
    top: $progress-green-bar-top;
    opacity: 0.7;
}

/* Green Bar (unfinished step) */
.c-progress-tracker--step-bar-unfinished {
    width: $progress-green-bar-length-unfinished;
    height: $progress-green-bar-height;
    position: absolute;
    left: $progress-green-bar-left;
    top: $progress-green-bar-top;
    opacity: 0.7;
}

/* Label */
.c-progress-tracker--step-label {
    position: absolute;
    bottom: -20px;
    left: 50%;
    font-size: var(--sco-font-size-headline6);
    transform: translateX(-50%);
}