.c-infobox {
  padding: 24px;
  border-width: 1px;
  border-style: solid;
  width: 100%;

  display: inline-flex;
  border-color: var(--sco-color-grey-600);

  .c-infobox-icon, .c-infobox-message {
    color: var(--sco-color-grey-600);
  }

  &.c-infobox-orange {
    border-color: var(--sco-color-orange-200);

    .c-infobox-icon, .c-infobox-message {
      color: var(--sco-color-orange-200);
    }
  }

  &.c-infobox-blue {
    border-color: var(--sco-color-blue-400);

    .c-infobox-icon, .c-infobox-message {
      color: var(--sco-color-blue-400);
    }
  }

  &.c-infobox-red {
    border-color: var(--sco-color-red-600);

    .c-infobox-icon, .c-infobox-message {
      color: var(--sco-color-red-600);
    }
  }

  &.c-infobox-green {
    border-color: var(--sco-color-primary);

    .c-infobox-icon, .c-infobox-message {
      color: var(--sco-color-primary);
    }
  }

  .c-infobox-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 16px;
    font-size: var(--sco-font-size-headline4);
  }

  .c-infobox-message {
    .icon-message-container {
      .icon-message-icon, .icon-message-link {
        color: var(--sco-color-grey-700);
        text-decoration: none;
      }

      .icon-message-icon {
        display: inline-block;
        padding-right: 8px;
      }

      .icon-message-link {
        display: inline-block;
      }
    }
  }
}
