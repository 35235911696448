:root {

  --sco-input-wrapper-height: 36px;

  --sco-spacing-4: 4px;
  --sco-spacing-8: 8px;
  --sco-spacing-12: 12px;
  --sco-spacing-16: 16px;
  --sco-spacing-24: 24px;
  --sco-spacing-32: 32px;
  --sco-spacing-40: 40px;
  --sco-spacing-48: 48px;
  --sco-spacing-64: 64px;

  --sco-font-family-univers: univers, Helvetica, Arial, sans-serif;

  --sco-font-family-lato-regular: Lato-Regular, Helvetica, Arial, sans-serif;
  --sco-font-family-lato-black: Lato-Black, Helvetica, Arial, sans-serif;
  --sco-font-family-lato-bold: Lato-Bold, Helvetica, Arial, sans-serif;
  --sco-font-family-lato-light: Lato-Light, Helvetica, Arial, sans-serif;
  --sco-font-family-lato-italic: Lato-Italic, Helvetica, Arial, sans-serif;
  --sco-font-family-lato-blackitalic: Lato-BlackItalic, Helvetica, Arial, sans-serif;


  --sco-icon-font-awesome: "Font Awesome 5 Free";

  --sco-font-size-xs-headline1: 34px;
  --sco-font-size-s-headline1: 34px;
  --sco-font-size-m-headline1: 36px;
  --sco-font-size-l-headline1: 39px;
  --sco-font-size-xl-headline1: 39px;
  --sco-font-size-xxl-headline1: 39px;

  --sco-font-size-xs-headline2: 27px;
  --sco-font-size-s-headline2: 27px;
  --sco-font-size-m-headline2: 29px;
  --sco-font-size-l-headline2: 31px;
  --sco-font-size-xl-headline2: 31px;
  --sco-font-size-xxl-headline2: 31px;

  --sco-font-size-xs-headline3: 21px;
  --sco-font-size-s-headline3: 21px;
  --sco-font-size-m-headline3: 23px;
  --sco-font-size-l-headline3: 25px;
  --sco-font-size-xl-headline3: 25px;
  --sco-font-size-xxl-headline3: 25px;

  --sco-font-size-xs-headline4: 18px;
  --sco-font-size-s-headline4: 18px;
  --sco-font-size-m-headline4: 18px;
  --sco-font-size-l-headline4: 20px;
  --sco-font-size-xl-headline4: 20px;
  --sco-font-size-xxl-headline4: 20px;

  --sco-font-size-xs-headline5: 14px;
  --sco-font-size-s-headline5: 14px;
  --sco-font-size-m-headline5: 14px;
  --sco-font-size-l-headline5: 16px;
  --sco-font-size-xl-headline5: 16px;
  --sco-font-size-xxl-headline5: 16px;

  --sco-font-size-xs-headline6: 12px;
  --sco-font-size-s-headline6: 12px;
  --sco-font-size-m-headline6: 12px;
  --sco-font-size-l-headline6: 13px;
  --sco-font-size-xl-headline6: 13px;
  --sco-font-size-xxl-headline6: 13px;

  --sco-font-size-xs-paragraph: 12px;
  --sco-font-size-s-paragraph: 12px;
  --sco-font-size-m-paragraph: 14px;
  --sco-font-size-l-paragraph: 14px;
  --sco-font-size-xl-paragraph: 16px;
  --sco-font-size-xxl-paragraph: 16px;

  --sco-font-size-xs-paragraphsmall: 10px;
  --sco-font-size-s-paragraphsmall: 10px;
  --sco-font-size-m-paragraphsmall: 10px;
  --sco-font-size-l-paragraphsmall: 10px;
  --sco-font-size-xl-paragraphsmall: 10px;
  --sco-font-size-xxl-paragraphsmall: 10px;




  --sco-font-size-headline1: var(--sco-font-size-xs-headline1);
  --sco-font-size-headline2: var(--sco-font-size-xs-headline2);
  --sco-font-size-headline3: var(--sco-font-size-xs-headline3);
  --sco-font-size-headline4: var(--sco-font-size-xs-headline4);
  --sco-font-size-headline5: var(--sco-font-size-xs-headline5);
  --sco-font-size-headline6: var(--sco-font-size-xs-headline6);
  --sco-font-size-paragraph: var(--sco-font-size-xs-paragraph);
  --sco-font-size-paragraphsmall: var(--sco-font-size-xs-paragraphsmall);


  @include screen-s {
    --sco-input-wrapper-height: 48px;

    --sco-font-size-headline1: var(--sco-font-size-s-headline1);
    --sco-font-size-headline2: var(--sco-font-size-s-headline2);
    --sco-font-size-headline3: var(--sco-font-size-s-headline3);
    --sco-font-size-headline4: var(--sco-font-size-s-headline4);
    --sco-font-size-headline5: var(--sco-font-size-s-headline5);
    --sco-font-size-headline6: var(--sco-font-size-s-headline6);
    --sco-font-size-paragraph: var(--sco-font-size-s-paragraph);
    --sco-font-size-paragraphsmall: var(--sco-font-size-s-paragraphsmall);
  }

  @include screen-m {
    --sco-input-wrapper-height: 48px;

    --sco-font-size-headline1: var(--sco-font-size-m-headline1);
    --sco-font-size-headline2: var(--sco-font-size-m-headline2);
    --sco-font-size-headline3: var(--sco-font-size-m-headline3);
    --sco-font-size-headline4: var(--sco-font-size-m-headline4);
    --sco-font-size-headline5: var(--sco-font-size-m-headline5);
    --sco-font-size-headline6: var(--sco-font-size-m-headline6);
    --sco-font-size-paragraph: var(--sco-font-size-m-paragraph);
    --sco-font-size-paragraphsmall: var(--sco-font-size-m-paragraphsmall);
  }

  @include screen-l {
    --sco-input-wrapper-height: 52px;

    --sco-font-size-headline1: var(--sco-font-size-l-headline1);
    --sco-font-size-headline2: var(--sco-font-size-l-headline2);
    --sco-font-size-headline3: var(--sco-font-size-l-headline3);
    --sco-font-size-headline4: var(--sco-font-size-l-headline4);
    --sco-font-size-headline5: var(--sco-font-size-l-headline5);
    --sco-font-size-headline6: var(--sco-font-size-l-headline6);
    --sco-font-size-paragraph: var(--sco-font-size-l-paragraph);
    --sco-font-size-paragraphsmall: var(--sco-font-size-l-paragraphsmall);
  }

  @include screen-xl {
    --sco-input-wrapper-height: 56px;

    --sco-font-size-headline1: var(--sco-font-size-xl-headline1);
    --sco-font-size-headline2: var(--sco-font-size-xl-headline2);
    --sco-font-size-headline3: var(--sco-font-size-xl-headline3);
    --sco-font-size-headline4: var(--sco-font-size-xl-headline4);
    --sco-font-size-headline5: var(--sco-font-size-xl-headline5);
    --sco-font-size-headline6: var(--sco-font-size-xl-headline6);
    --sco-font-size-paragraph: var(--sco-font-size-xl-paragraph);
    --sco-font-size-paragraphsmall: var(--sco-font-size-xl-paragraphsmall);
  }

  @include screen-xxl {
    --sco-input-wrapper-height: 60px;

    --sco-font-size-headline1: var(--sco-font-size-xxl-headline1);
    --sco-font-size-headline2: var(--sco-font-size-xxl-headline2);
    --sco-font-size-headline3: var(--sco-font-size-xxl-headline3);
    --sco-font-size-headline4: var(--sco-font-size-xxl-headline4);
    --sco-font-size-headline5: var(--sco-font-size-xxl-headline5);
    --sco-font-size-headline6: var(--sco-font-size-xxl-headline6);
    --sco-font-size-paragraph: var(--sco-font-size-xxl-paragraph);
    --sco-font-size-paragraphsmall: var(--sco-font-size-xxl-paragraphsmall);
  }


  & {
    --sco-color-primary: #78B928;
    --sco-color-primary-disabled: #D7EABF;
    --sco-color-primary-a80: #78B928CC;
    --sco-color-black: #000000;
    --sco-color-silver: #c3c3c3;
    --sco-color-white: #ffffff;
    --sco-color-white-a80: #FFFFFFCC;
    --sco-color-grey-020: #f9f9f9;
    --sco-color-grey-100: #f2f2f2;
    --sco-color-grey-200: #e6e6e6;
    --sco-color-grey-230: #dddddd;
    --sco-color-grey-300: #cccccc;
    --sco-color-grey-350: #bfbfbf;
    --sco-color-grey-400: #999999;
    --sco-color-grey-500: #787878;
    --sco-color-grey-600: #666666;
    --sco-color-grey-700: #444444;
    --sco-color-grey-700-a80: #444444CC;
    --sco-color-grey-800: #262626;
    --sco-color-grey-930: #111111;
    --sco-color-anthracite-100: #eaeff2;
    --sco-color-anthracite-200: #cfd9df;
    --sco-color-anthracite-300: #a2b3bc;
    --sco-color-anthracite-400: #778d98;
    --sco-color-anthracite-600: #445c67;
    --sco-color-anthracite-800: #253d47;

    --sco-color-green-50: #f2f9e7;
    --sco-color-green-100: #d0e7b5;
    --sco-color-green-200: #c6cc00;
    --sco-color-green-300: #b1e075;
    --sco-color-green-400: #78B928;
    --sco-color-green-500: #5f9621;
    --sco-color-green-600: #7caf41;
    --sco-color-green-700: #6b9b3a;
    --sco-color-green-800: #3e631c;
    --sco-color-green-900: #005f1e;
    --sco-color-green--A100: #c3ff88;
    --sco-color-green--A200: #aaff5a;
    --sco-color-green--A400: #76ff03;
    --sco-color-green--A700: #64dd17;


    --sco-color-blue-50: #e3f2fd;
    --sco-color-blue-100: #bbdefb;
    --sco-color-blue-200: #84cfe4;
    --sco-color-blue-300: #64b5f6;
    --sco-color-blue-400: #00a2d1;
    --sco-color-blue-500: #2196f3;
    --sco-color-blue-600: #06809f;
    --sco-color-blue-700: #0277bd;
    --sco-color-blue-800: #005681;
    --sco-color-blue-900: #003b5c;

    --sco-color-blue-A100: #82b1ff;
    --sco-color-blue-A200: #448aff;
    --sco-color-blue-A400: #2979ff;
    --sco-color-blue-A700: #2962ff;

    --sco-color-yellow-50: #fffde7;
    --sco-color-yellow-100: #fff9c4;
    --sco-color-yellow-200: #fff59d;
    --sco-color-yellow-300: #fff176;
    --sco-color-yellow-400: #ffee58;
    --sco-color-yellow-500: #ffeb3b;
    --sco-color-yellow-600: #fdd835;
    --sco-color-yellow-700: #fbc02d;
    --sco-color-yellow-800: #f9a825;
    --sco-color-yellow-900: #f57f17;
    --sco-color-yellow-a100: #ffff8d;
    --sco-color-yellow-a200: #ffff00;
    --sco-color-yellow-a400: #ffea00;
    --sco-color-yellow-a700: #ffd600;

    --sco-color-orange-50: #fff3e0;
    --sco-color-orange-100: #ffd500;
    --sco-color-orange-150: #ffc000;
    --sco-color-orange-200: #f18700;
    --sco-color-orange-300: #fb8c00;
    --sco-color-orange-400: #ff9800;
    --sco-color-orange-500: #e94c0a;
    --sco-color-orange-600: #ff5722;
    --sco-color-orange-700: #f4511e;
    --sco-color-orange-800: #e64a19;
    --sco-color-orange-900: #d84315;

    --sco-color-orange-A100: #ff9e80;
    --sco-color-orange-A200: #ff6e40;
    --sco-color-orange-A400: #ff3d00;
    --sco-color-orange-A700: #dd2c00;


    --sco-color-red-50: #ffebee;
    --sco-color-red-100: #ffcdd2;
    --sco-color-red-200: #fc6647;
    --sco-color-red-300: #e57373;
    --sco-color-red-400: #ef5350;
    --sco-color-red-500: #ca2424;
    --sco-color-red-600: #bc0000;
    --sco-color-red-700: #d32f2f;
    --sco-color-red-800: #960707;
    --sco-color-red-900: #b71c1c;

    --sco-color-red-A100: #ff8a80;
    --sco-color-red-A200: #ff5252;
    --sco-color-red-A400: #ff1744;
    --sco-color-red-A700: #d50000;


    --sco-color-violette-50: #f3e5f5;
    --sco-color-violette-100: #e1bee7;
    --sco-color-violette-200: #ce93d8;
    --sco-color-violette-300: #ba68c8;
    --sco-color-violette-400: #ab47bc;
    --sco-color-violette-500: #9c27b0;
    --sco-color-violette-600: #8e24aa;
    --sco-color-violette-700: #7b1fa2;
    --sco-color-violette-800: #6a1b9a;
    --sco-color-violette-900: #4a148c;

    --sco-color-violette-A100: #ea80fc;
    --sco-color-violette-A200: #e040fb;
    --sco-color-violette-A400: #d500f9;
    --sco-color-violette-A700: #aa00ff;

    --sco-color-brown-50: #f5e9dc;
    --sco-color-brown-100: #e5c9a7;
    --sco-color-brown-200: #d8b483;
    --sco-color-brown-300: #c3975c;
    --sco-color-brown-400: #a9793d;
    --sco-color-brown-500: #8a5a2e;
    --sco-color-brown-600: #733f23;
    --sco-color-brown-700: #5b301b;
    --sco-color-brown-800: #412617;
    --sco-color-brown-900: #21180d;

    --sco-color-brown-A100: #d7ccc8e6;
    --sco-color-brown-A200: #bcaaa4cc;
    --sco-color-brown-A400: #a1887f99;
    --sco-color-brown-A700: #8d6e63cc;

    --sco-color-kunde-text: var(--sco-color-grey-700);
    --sco-color-kunde-background: var(--sco-color-blue-300);

    --sco-color-planer-text: var(--sco-color-grey-700);
    --sco-color-planer-background: var(--sco-color-violette-300);

    --sco-color-projekt-text: var(--sco-color-grey-700);
    --sco-color-projekt-background: var(--sco-color-orange-100);

    --sco-color-aktivitaet-text: var(--sco-color-white);
    --sco-color-aktivitaet-background: var(--sco-color-anthracite-400);

    --sco-icon-fa-keyboard: "\f11c";
    --sco-icon-fa-slash: "\f715";

    --sco-icon-input: var(--sco-icon-fa-keyboard);
    --sco-icon-input--disabled: var(--sco-icon-fa-slash);
    --sco-color-input--disabled: var(--sco-color-grey-300);

    --sco-color--picker-selected: var(--sco-color-green-400);
    --sco-color--picker-hovered: var(--sco-color-green-100);
  }
}