.s-projekt-vorschlagsliste {
  .c-dropdown--wrapper .c-dropdown--container.style-header-button {
    background-color: transparent;
    height: var(--sco-spacing-32);
  }

  .s-projekt-vorschlagsliste-map--filters {
    .c-section--content {
      padding: 15px 10px;
    }

    .c-section--title-actions {
      #vtw-select_container .select2-selection,
      #vtw-select_container .select2-selection.select2-selection--single {
        border: none !important;
      }
    }margin-top: 5px;
    margin-bottom: 20px;

    &.c-section.c-section--folded > .c-section--heading {
      background: var(--sco-color-grey-400);
      color: var(--sco-color-white);

      &:hover {
        background: var(--sco-color-grey-600);
      }
    }
  }

  .s-projekt-vorschlagsliste--filters-search {
    position: absolute;
    right: 30px;
    bottom: 9px;

    @include mobile() {
      margin-top: 15px;
      position: relative;
      flex: 0 0 100%;
      display: block;
      right: auto;
      bottom: auto;

    }

  }
}


.s-project-vorschlagsliste-map--filters-headerlabel {
  font-weight: bold;
}

#searchradius-slider_label {
  font-weight: bold;
}

#dropdown_spezialisierung_label {
  font-weight: bold;
}

.s-project-vorschlagsliste-map--filters-checkboxrow {
  &.o-row {
    margin-top: 10px;
  }

  .o-col {
    margin-top: -20px;
  }

  .checkboxtext {
    position: relative;
    bottom: 4px;
    margin-left: 5px;
    margin-right: -5px;
  }
}

.s-projekt-vorschlagsliste-map--container {
  margin-top: 10px;
}

.s-projekt-vorschlagsliste--detail-address {

  .c-icon {
    min-width: 22px;
    text-align: center;
    margin-right: 15px;
    display: inline-block;
    position: relative;

    transform: scaleX(-1.2) scaleY(1.2);
  }
}

.s-projekt-vorschlagsliste--kundedetail-spezialisierung {
  margin-left: 10px;
  margin-bottom: 10px;
  line-height: 1;
}

//Kundendetails bleiben sticky rechts kleben beim Scrollen
.s-projekt-vorschlagsliste-list {
  @media (min-width: 1024px) {
    .c-master-container {
      overflow-x: unset;
      overflow-y: unset;
    }

    #vorschlagsliste-detail-column {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 10px;
      align-self: flex-start;
    }
  }
}


//Kundendetails / Projektdetails
.s-projekt-vorschlagsliste--detail {

  .s-projekt-vorschlagsliste--detail-header {
    float: left;
  }

  .s-projekt-vorschlagsliste--detail-header1 {
    font-size: var(--sco-font-size-headline4);
    float: left;
  }

  .s-projekt-vorschlagliste--detail-headericon {
    align-self: center;
    margin-right: 15px;
    margin-top: 7px;
    float: left;

    &.kundeIcon {
      color: $color-icon-kunde;
    }

    &.projektIcon {
      color: $color-icon-projekt;
    }
  }


  @media (max-width: 1024px) {
    .s-projekt-vorschlagliste--detail-headericon {
      display: none;
    }
  }

  .s-projekt-vorschlagsliste--detail-add-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .s-projekt-vorschlagsliste--kundedetail-kriterien {
    .c-section {
      margin-top: 5px;
    }

    .o-row {
      margin-left: 10px;

      .c-star-rating, .s-projekt-vorschlagsliste--kundedetail-kriterium {
        margin-right: 35px;
      }
    }
  }
}

//Projektdetails
.s-projekt-vorschlagsliste--projektetail-row {
  padding: 0 15px 0 15px;
}

.s-projekt-vorschlagsliste--infoWindow-header {
  font-weight: bold;
}

.s-projekt-vorschlagsliste--infoWindow-header-has-add-button {
  border-bottom: 1px solid black;
  margin-bottom: 5px;
  padding-bottom: 2px;
}

.s-projekt-vorschlagsliste--spezialisierung-container {
  .spezialisierung--header {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: var(--sco-font-size-headline5);

    .spezialisierung--header-content {
      text-decoration: underline;
    }
  }

  .spezialisierung--list {
    margin-bottom: 0;
  }
}

.s-projekt-vorschlagsliste--infoWindow-add-button {
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
}

.s-projekt-vorschlagsliste-list {
  #s-projekt-vorschlagsliste-list--add-to-firmenliste-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  #s-projekt-vorschlagsliste-list--list-container {
  }

  .s-projekt-vorschlagsliste-list--content-container {
    margin-top: 6px;
  }

  .s-projekt-vorschlagsliste-list--item {
    .list-item-card {
      &:hover,
      &.active {
        background-color: var(--sco-color-grey-100);
        cursor: pointer;
      }
    }

    .c-card {
      margin-bottom: 3px;
    }

    .row-vertical {
      margin-top: 14px;
    }

    .kundeIcon {
      color: $color-icon-kunde;
    }

    .kundeNr {
      font-size: 12px;
    }

    .kundeSegmentierung {
      font-size: 12px;
    }

    .kundeName {
      font-size: var(--sco-font-size-headline4);
    }

    .item-content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      $width-extra-small: 50px;
      $width-small: 70px;
      $width-middle: 100px;

      .kunde-selected-checkbox-col {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: $width-extra-small;
      }
      .kunde-icon-col {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: $width-extra-small;
      }
      .kunde-display-col {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - #{$width-middle} - #{$width-small} - #{$width-small} );
      }
      .kunde-rating-col {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: $width-middle;
      }
      .rating-stars {
        margin-top: 22px;
      }
      .distance-col {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: $width-small;
        font-size: 12px;
        margin: 15px 0 0 10px;
      }
      .planerbeziehung-icon {
        font-size: var(--sco-font-size-headline5);
        margin-left: 5px;
      }
      .projectdistance-icon {
        font-size: $fontsize-label-default;
      }
    }
  }
}