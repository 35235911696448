.s-projekt-produktgruppen-forecast {
    .s-projekt-produktgruppen-forecast--card {
        .c-card--header {
            padding: 4px 16px;
            min-height: auto;

            .c-card--header-wrapper-left {
                min-width: 0;
                flex-basis: unset;
            }
        }

        .c-section.design-storybook {
            .c-section--heading {
                background-color: $color-gray--130;
            }

            .c-section--content {
                margin-top: 0;
                border-left: 32px $color-gray--130 solid;
                border-color: $color-gray--130;
            }

            &:not(.c-section--folded) {
                .c-section--heading {
                    margin-bottom: 0;
                }
            }
        }

        .s-projekt-produktgruppen-forecast--forecast-table {
            table-layout: fixed;
            margin-bottom: 5px;

            .s-projekt-produktgruppen-forecast--toggle-col, .s-projekt-produktgruppen-forecast--action-col {
                width: 48px;
            }

            .simple-div-table--table-heading {
                .s-projekt-produktgruppen-forecast--flaeche-col, .s-projekt-produktgruppen-forecast--forecast-col, .s-projekt-produktgruppen-forecast--stueckzahl-col {
                    text-align: right;
                }
            }

            .simple-div-table--table-body {
                .c-input--text-wrapper {
                    height: unset;

                    .c-input--text {
                        height: unset;
                        padding: unset;
                        background-color: unset;
                    }
                }

                .simple-div-table--table-cell-even, .simple-div-table--table-cell-odd {
                    background-color: unset;
                }

                &.s-projekt-produktgruppen-forecast--produktgruppe-row-group {
                    &:not(:last-child)::after {
                        content: '';
                        height: 24px;
                        display: block;
                    }

                    .s-projekt-produktgruppen-forecast--produktgruppe-header-row, .s-projekt-produktgruppen-forecast--produktgruppe-footer-row {
                        background-color: $color-gray--152;
                        .s-projekt-produktgruppen-forecast--action-col {
                            background-color: $color-white;
                        }

                        .s-projekt-produktgruppen-forecast--system-col {
                            display: flex;
                            justify-content: space-between;

                            .s-projekt-produktgruppen-forecast--sum-symbol {
                                font-size: larger;
                                margin: auto 0;
                            }
                        }
                    }

                    .s-projekt-produktgruppen-forecast--toggle-col, .s-projekt-produktgruppen-forecast--produktgruppe-col {
                        background-color: $color-gray--152;
                    }

                    &.s-projekt-produktgruppen-forecast--produktgruppe-row-group-expanded {
                        .s-projekt-produktgruppen-forecast--produktgruppe-footer-row .s-projekt-produktgruppen-forecast--produktgruppe-col span {
                            display: none;
                        }
                    }
                }

                .s-projekt-produktgruppen-forecast--produktgruppe-col {
                    font-family: 'univers-bold';
                }

                .s-projekt-produktgruppen-forecast--validation-cell {
                    text-align: right;
                }

                .s-projekt-produktgruppen-forecast--produktgruppe-value-input-wrapper,
                .s-projekt-produktgruppen-forecast--system-value-input-wrapper {
                    .c-input--content-right {
                        background: none;
                        left: unset;
                    }
                }

                .s-projekt-produktgruppen-forecast--calculate-produktgruppe-info-icon, .s-projekt-produktgruppen-forecast--calculate-system-info-icon {
                    color: var(--sco-color-grey-400);

                    &:not(.forecast-calculate-info) {
                        display: none;
                    }

                    &.forecast-calculate-info {
                        &.forecast-calculate-info-deviation {
                            color: var(--sco-color-blue-400);
                        }
                    }
                }

                .c-input--content-right {
                    .s-projekt-produktgruppen-forecast--produktgruppe-reset-button, .s-projekt-produktgruppen-forecast--system-reset-button, .s-projekt-produktgruppen-forecast--warning-icon {
                        display: none;
                    }
                }

                .forecast-warning ~ .c-input--content-right, .forecast-info ~ .c-input--content-right, .flaeche-warning ~ .c-input--content-right, .stueckzahl-warning ~ .c-input--content-right {
                    .s-projekt-produktgruppen-forecast--produktgruppe-reset-button, .s-projekt-produktgruppen-forecast--system-reset-button {
                        display: unset;
                        cursor: pointer;
                    }
                }

                .forecast-warning ~ .c-input--content-right, .flaeche-warning ~ .c-input--content-right, .stueckzahl-warning ~ .c-input--content-right {
                    .s-projekt-produktgruppen-forecast--warning-icon {
                        display: unset;
                        color: var(--sco-color-orange-200);
                    }
                }

                .s-projekt-produktgruppen-forecast--produktgruppe-header-row, .s-projekt-produktgruppen-forecast--produktgruppe-sum-row {
                    font-family: var(--sco-font-family-lato-bold);

                    input {
                        font-family: var(--sco-font-family-lato-bold);
                    }

                    .s-projekt-produktgruppen-forecast--produktgruppe-forecast-input, .s-projekt-produktgruppen-forecast--produktgruppe-flaeche-input, .s-projekt-produktgruppen-forecast--produktgruppe-stueckzahl-input {
                        &::-webkit-input-placeholder { /* Edge */
                            color: var(--sco-color-grey-400);
                            font-family: var(--sco-font-family-lato-bold);
                        }

                        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                            color: var(--sco-color-grey-400);
                            font-family: var(--sco-font-family-lato-bold);
                        }

                        &::placeholder {
                            color: var(--sco-color-grey-400);
                            font-family: var(--sco-font-family-lato-bold);
                        }
                    }

                    .s-projekt-produktgruppen-forecast--produktgruppe-forecast-input.forecast-info:not(:is(:hover, :active)) {
                        border-bottom: 2px solid var(--sco-color-blue-400);
                    }

                    .s-projekt-produktgruppen-forecast--produktgruppe-forecast-input.forecast-warning:not(:is(:hover, :active)),
                    .s-projekt-produktgruppen-forecast--produktgruppe-flaeche-input.flaeche-warning:not(:is(:hover, :active)),
                    .s-projekt-produktgruppen-forecast--produktgruppe-stueckzahl-input.stueckzahl-warning:not(:is(:hover, :active)) {
                        border-bottom: 2px solid var(--sco-color-orange-200);
                    }
                }

                .s-projekt-produktgruppen-forecast--system-row:not(.s-projekt-produktgruppen-forecast--system-row-hidden) + .s-projekt-produktgruppen-forecast--produktgruppe-sum-row {
                    .s-projekt-produktgruppen-forecast--produktgruppe-sum-cell {
                        border-top: 0.5px solid var(--sco-color-grey-600);
                    }
                }

                .s-projekt-produktgruppen-forecast--system-row {
                    .s-projekt-produktgruppen-forecast--system-forecast-input.forecast-info:not(:is(:hover, :active)) {
                        border-bottom: 2px solid var(--sco-color-blue-400);
                    }

                    &.s-projekt-produktgruppen-forecast--system-row-hidden {
                        display: none;
                    }
                }

                .s-projekt-produktgruppen-forecast--expand-systeme-button-hidden, .s-projekt-produktgruppen-forecast--fold-systeme-button-hidden {
                    display: none;
                }

                /* Remove Arrows/Spinners */
                /* Chrome, Safari, Edge, Opera */
                input.c-input--text::-webkit-outer-spin-button,
                input.c-input--text::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                input.c-input--text[type=number] {
                    -moz-appearance: textfield;
                }

                .s-projekt-produktgruppen-forecast--flaeche-col, .s-projekt-produktgruppen-forecast--forecast-col, .s-projekt-produktgruppen-forecast--stueckzahl-col {
                    .c-input--text[type="number"] {
                        text-align: right;
                    }
                }
            }
        }

        #s-projekt-produktgruppen-forecast--schluesselfragen-dialog-button {
            &.schluesselfragenValid{
                color: var(--sco-color-primary);

                &:focus {
                    color: var(--sco-color-green-800);
                }

                &:hover {
                    color: var(--sco-color-green-800);
                }

                &:active {
                    color: var(--sco-color-green-800);
                }
            }

            &.schluesselfragenInvalid{
                color: var(--sco-color-orange-200);

                &:focus {
                    color: $color-orange--dark;
                }

                &:hover {
                    color: $color-orange--dark;
                }

                &:active {
                    color: $color-orange--dark;
                }
            }
        }

        .c-section.c-section--foldable.c-section--folded {
            width: 100%;
        }
    }

    .add-systeme-dialog {
        #produktgruppen-systeme-dialog--title-grid {
            display: grid;
            grid-gap: 8px;
            grid-template-columns: 1fr 2fr 1fr;

            #s-projekt-create--produktgruppen-systeme-dialog--save-button {
                float: right;
            }
        }

        #produktgruppen-systeme-dialog--grid {
            display: grid;
            grid-gap: 8px;

            #produktgruppen-list, #systeme-list {
                overflow-y: auto;
                height: 600px;

                .produktgruppen-systeme-link {
                    @include s-projekt-produktgruppen-systeme--produktgruppen-systeme-link;
                }
            }
        }
    }

    .s-projekt-schluesselfragen-dialog {
        #s-projekt-schluesselfragen-dialog--buttons {
            position: sticky;
            top: -10px;
            z-index: 1;
            background-color: var(--sco-color-white);

            .c-button {
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }

        #s-projekt-schluesselfragen-dialog--allgemeine-angaben {
            .c-section--content {
                margin-bottom: 5px;
                display: flex;
            }
        }

        .s-projekt-schluesselfragen-dialog--grid {
            // Variablen Teil
            --grid-gap: 24px; // default
            margin-top: var(--grid-gap);
            margin-bottom: var(--grid-gap);


            --single-values-per-col: 2;
            --grid-cols: 3;

            // Spaltenbreite = Breite * Anzahl der single-values per Spalte + Platz der Gaps dazwischen
            --grid-cols-width: calc(
                    1fr * var(--single-values-per-col) + (var(--single-values-per-col) - 1) * var(--grid-gap)
            );

            // Properties
            display: grid;
            grid-gap: var(--grid-gap);
            grid-template-columns: repeat(var(--grid-cols), 1fr) [end];


            @include screen-xs {
                --grid-cols: 1;
                --grid-cols-1-span: 1;
                --single-values-per-col: 1;
            }

            @include screen-s {
                --grid-cols: 1;
                --grid-gap: 8px;
                --single-values-per-col: 1;
            }

            @include screen-m {
                --grid-gap: 8px;
            }

            @include screen-l {
                --grid-gap: 16px;
            }

            @include screen-xl {
            }

            @include screen-xxl {
            }

            .c-checkbox {
                height: unset;

                label {
                    text-align: left;
                }
            }
        }

        .c-button {
            margin-right: 12px;
        }
    }

    .c-card--header-wrapper-left {
        min-width: 0;
    }

    .c-card--header-wrapper-right {
        flex-grow: 0;
    }

}