///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Standardise some UI treatments.
$global-radius: 3px !default;
$global-transition: all 200ms ease-in-out !default;

$content-padding: 20px;
$pannel--padding: 15px;
$pannel--action-spacing: 10px;

$navigation-width: 250px;

$input--height: 44px;

$tablet-width: 768px;
$desktop-width: 1113px;
$fhd-width: 1920px;
$f4k-width: 3840px;

$screen-s-min-width: 375px;
$screen-m-min-width: 768px;
$screen-l-min-width: 1024px;
$screen-xl-min-width: 1440px;
$screen-xxl-min-width: 1920px;

