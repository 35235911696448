$c-sidebar-color: var(--sco-color-primary);

.c-sidebar {
  position: fixed;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  z-index: 150;
  display: table;
}

.c-sidebar-item {
  background: var(--sco-color-grey-700);
  position: relative;
  display: table-row;
  cursor: pointer;
  height: 46px;
}

.c-sidebar-item-icon {
  display: table-cell;
  //width: 38px;
  width: 46px;
  vertical-align: middle;
  border-bottom: 1px solid var(--sco-color-grey-600);
  text-align: center;
  position: relative;
  &:before {
    position: absolute;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: var(--sco-color-white);
    font-size: var(--sco-font-size-headline4);
  }
}

.c-sidebar-item-title {
  display: table-cell;
  color: var(--sco-color-white);
  vertical-align: middle;
  border-bottom: 1px solid var(--sco-color-grey-600);
  max-width: 0px;
  transition: all 0.26s ease;
  white-space: nowrap;
  overflow: hidden;
  font-size: var(--sco-font-size-headline6);
  letter-spacing: 0;
  text-transform: uppercase;

  &:after {
    content: '';
    display: inline-block;
    width: 25px;
  }
}

.c-sidebar-item:last-child {
  .c-sidebar-item-title {
    border-bottom: 0px;
  }
  .c-sidebar-item-icon {
    border-bottom: 0px;
  }
}

.c-sidebar:hover .c-sidebar-item-title,
.c-sidebar--expanded .c-sidebar-item-title {
  max-width: 300px;
}

.c-sidebar-item:hover {
  background: var(--sco-color-green-500);
}

@media(max-height: 768px) {
  .c-sidebar-item {
    height: 36px;
  }
  .c-sidebar-item-icon {
    width: 36px;

    &:before {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}
