
/* cardSimpleInfoContent */


.c-card-simple-info-content {
  &.design-storybook {
    --sco-color-grey-100: #f2f2f2;
    --sco-color-grey-200: #bfbfbf;
    --sco-color-green-500: #5f9621;
    --sco-color-primary: #78b928;
    --sco-color-white: #ffffff;
    --sco-spacing-xs: 8px;
    --sco-spacing-s: 16px;
    --sco-font-size-m-paragraph: 14px;

    tr:nth-child(even) {
      background-color: #F7F7F7;
    }
    td {
      padding: var(--sco-spacing-xs) var(--sco-spacing-s);
      height: 50px;
    }
    tr:first-child td {
      border-top: 1px solid $color-border--normal;
    }
    td {
      vertical-align: middle;
      border-bottom: 1px solid $color-border--normal;
      text-align: left;
    }
  }

  width: 100%;
  display: table;
  background-color: var(--sco-color-white);
}

.c-card-simple-info-content--row {
  width: 100%;
  display: table-row;
}

.c-card-simple-info-content--row-title {
  font-family: var(--sco-font-family-lato-bold);
  display: table-cell;
  padding: 0;
}

.c-card-simple-info-content--row-content {
  display: table-cell;
  text-align: right;
  padding: 0;
}

.c-card-simple-info-content--row-content--contentinnewline {
  text-align: left;

}

/* cardSimpleList */
.c-card-simple-list {
  margin: -10px -15px;
}

.c-card-simple-list--item {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.c-card-simple-list--item-even {
  background: var(--sco-color-grey-100);
}

.c-card-simple-list--item-odd {
  background: var(--sco-color-white);
}

a.c-card-simple-list--item-even:hover {
  background: var(--sco-color-grey-020);
}

a.c-card-simple-list--item-odd:hover {
  background: $color-white-hover;
}

.c-card-simple-list--item-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
}

.c-card-simple-list--item-primary {

}

.c-card-simple-list--item-secondary {
  color: #999999;
  font-size: 0.9em;
}

.c-card-simple-list--item-with-icon {
  .c-card-simple-list--item-primary,
  .c-card-simple-list--item-secondary {
    padding-left: 30px;
  }
}

.c-card-simple-list--empty {
  padding: 10px 15px;
}