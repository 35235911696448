

.s-massnahmenplan-search {
  .c-checkbox {


    @include screen-s {
      height: 100px;
    }

    @include screen-m {
      height: 100px;
    }

    @include screen-l {
      height: 88px;
    }

    @include screen-xl {
      height: 80px;
    }
  }
}