.dataTables_infoDisplay {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.dataTables_paginate {
  .fg-button {
    margin: 0px 2px;
    border-radius: 1px;
    //background: var(--sco-color-grey-400);
    color: var(--sco-color-primary);
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;

    &.ui-state-disabled {
      background: $color-link--green;
      color: var(--sco-color-white);
    }

    &:hover {
      cursor: pointer;
      background: $color-link--green-hover;
      color: var(--sco-color-white);
    }

  }

  .next {
    @include faicon-before($fa-var-angle-right);
  }

  .previous {
    @include faicon-before($fa-var-angle-left);
  }

  .first {
    @include faicon-before($fa-var-angle-double-left);
  }

  .last {
    @include faicon-before($fa-var-angle-double-right);
  }

  .next,
  .previous,
  .first,
  .last {
    vertical-align: top;
    text-align: left;
    overflow: hidden;

    /* The before element will cover the complete size */
    &:before {
      position: relative;
      visibility: visible;
      height: 30px;
      width: 30px;
      line-height: 30px;
      vertical-align: middle;
      text-align: center;
    }

    &.ui-state-disabled {
      background: transparent;
      font-weight: 300;
      color: var(--sco-color-grey-400);

      &:before {
        font-weight: 300;
        color: var(--sco-color-grey-400);
      }
    }
  }
}

.dataTables_processing, .dataList_processing {
  position: absolute;
  z-index:9999;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
  background: url("../assets/images/loading_big.gif") no-repeat 50% 50% black;
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  opacity: 0.5;
  text-align: center;
}
