.c-colored-tile--container {
  margin: -10px;
  position: relative;
}

.c-colored-tile--teaser {
  padding: 15px;
  width: 300px;
  float: left;
  height: 300px;
  overflow: hidden;
  margin: 10px;
}

.c-colored-tile--title {
  font-size: var(--sco-font-size-headline4);
  text-transform: uppercase;
  color: white;
  min-height: 3em;
}

.c-colored-tile--teaser-link {
  padding: 10px 15px;
  background: rgba(255,255,255,0.4);
  margin: 10px;
  display: block;
  transition: all 0.4s;
  color: var(--sco-color-grey-700);

  &:hover {
    background: rgba(255,255,255,0.6);
    color: var(--sco-color-grey-700);
  }
}

.c-colored-tile--minimized {
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;

  .c-colored-tile--minimized-text {
    padding: 10px 10px;
    text-align: center;
    color: var(--sco-color-white);
    font-size: var(--sco-font-size-headline6);
    height: 100%;
    width: 100%;
    transition: all 0.4s;

    &:hover {
      background: rgba(255,255,255,0.25);
      color: var(--sco-color-white);
    }
  }
}

.c-colored-tile--maximized {
  width: 100%;
  padding: 20px;

}

