@import "../../node_modules/inuitcss/tools/tools.clearfix";

.o-clear, .clear {
  clear:both;
  line-height: 1px;
  height: 0px;
  content: ' ';
}

.clearfix {
  @include inuit-clearfix();
}