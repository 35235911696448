$acitvity-timeline-status-icons: (
  "OPEN": $fa-var-clock,
  "POSITIVE": $fa-var-check,
  "NEGATIVE": $fa-var-times,
);

.c-activity-timeline-item {
  display: flex;
  position: relative;
  width: 100%;
  text-align: left;
}
.c-activity-timeline-item__collapse {
  flex-grow:0;
  flex-shrink:0;
  flex-basis: 16px;
  @include faicon-before($fa-var-angle-right);

  cursor: pointer;
  visibility: hidden;

  &:hover {
    color: var(--sco-color-grey-400);
  }
}

.c-activity-timeline-item--expanded .c-activity-timeline-item__collapse {
  @include faicon-before($fa-var-angle-down);
}

.c-activity-timeline-item__icon {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20px;
  height: 20px;
  background: green;
  border-radius: 2px;

  &:after {
    content:'';
    display: block;
    height: calc(100% - 31px);
    position: absolute;
    top: 25px;
    left: 25px;
    width: 2px;
    background: green;
  }
}

.c-activity-timeline-item__icon-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  &:before {
    color: white;
  }
}

.c-activity-timeline-item__main {
  flex: 1;
  padding-left: 5px;
  margin-right: var(--sco-spacing-8);
  height: 7em;
}
.c-activity-timeline-item__main-subject {

}
.c-activity-timeline-item__main-subject-text {

}
.c-activity-timeline-item__main-subject-status {
  display: inline-block;
  @include faicon-before($fa-var-question);
  color: var(--sco-color-grey-400);
}

.c-activity-timeline-item__erfasser {
  display: inline-block;
  color: var(--sco-color-grey-400);
  float: right;
}

@each $name, $code in $acitvity-timeline-status-icons {
  .c-activity-timeline-item__main-subject-status--#{$name}:before {
    content: fa-content($code);
  }
}

.c-activity-timeline-item__main-person {
  color: var(--sco-color-grey-400);
  font-size: 0.9em;
}
.c-activity-timeline-item__main-content-abstract {
  display: block;
  overflow-wrap: anywhere;
}

.c-activity-timeline-item__main-content-full {
  display: none;
}

.c-activity-timeline-item--expanded .c-activity-timeline-item__main-content-abstract {
  display: none;
}

.c-activity-timeline-item--expanded .c-activity-timeline-item__main-content-full {
  display: block;
}

.c-activity-timeline-item__date {
  flex: 0;
  padding-bottom: 15px;
}


$activity-timeline-colors:
  "kunde" var(--sco-color-kunde-background) var(--sco-color-kunde-text),
  "planer" var(--sco-color-planer-background) var(--sco-color-planer-text),
  "projekt" var(--sco-color-projekt-background) var(--sco-color-projekt-text),
  "gray" $color-gray--90 var(--sco-color-white);


@each $name, $background-color, $color in $activity-timeline-colors {
  .c-activity-timeline-item--color-#{$name} {
    .c-activity-timeline-item__icon {
      background-color: $background-color;
      color: $color;
      &:after {
        background-color: $background-color;
      }

      .c-activity-timeline-item__icon-inner {
        &:before {
          color: $color;
        }
      }
    }
  }
}

.c-activity-timeline-item__status-edit {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 28px;
}

.c-activity-timeline-item__edit-delete {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 28px;
}

.c-activity-timeline-item__status-edit-icon {
  @include faicon-before($fa-var-question);

  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 22px;
  width: 22px;
  height: 22px;

  margin-right: 5px;
  &:last-child {
    margin-right: 0px;
  }


  color: var(--sco-color-grey-400);

  border: 2px solid var(--sco-color-grey-400);
  border-radius: 11px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.c-activity-timeline-item__status-edit-icon-active, &:hover {
    color: var(--sco-color-primary);
    border-color: var(--sco-color-primary);
  }
}

@each $name, $code in $acitvity-timeline-status-icons {
  .c-activity-timeline-item__status-edit-icon--#{$name}:before {
    content: fa-content($code);
  }
}

.c-activity-timeline-item__delete-btn,
.c-activity-timeline-item__edit-btn, {
  @include faicon-before($fa-var-question);

  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 22px;
  width: 22px;
  height: 22px;

  margin-right: 5px;
  &:last-child {
    margin-right: 0px;
  }


  color: $color-link--green;

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.c-activity-timeline-item__status-edit-icon-active, &:hover {

    color: $color-link--green-hover;
  }
}

.c-activity-timeline-item__edit-btn:before {
  content: fa-content($fa-var-pencil);
}

.c-activity-timeline-item__delete-btn:before {
  content: fa-content($fa-var-trash);
}

.swal2-content .c-activity-timeline {
  max-height: 70vH;
  overflow-y: auto;
  padding-right: 15px;
}

.c-activity-timeline__delete-confirm-template-content {
  padding: 7px;
}
.c-activity-timeline__delete-confirm-template-confirm-question {
  margin-bottom: 7px;
}