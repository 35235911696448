.s-home-mitarbeiter-calendar {
    #s-home-calendar--calendar-container {
        max-height: calc(100vh - 200px);
    }

    .c-button--iconbutton-green {
        .c-button--custom-iconcontent .custom-icon-content-bg {
            color: var(--sco-color-primary);
        }

        &:hover {
            .c-button--custom-iconcontent .custom-icon-content-bg {
                color: var(--sco-color-green-800);
            }
        }

        &:active {
            .c-button--custom-iconcontent .custom-icon-content-bg {
                color: var(--sco-color-green-500);
            }
        }
    }

    .c-button--iconbutton-anthracite {
        .c-button--custom-iconcontent .custom-icon-content-bg {
            color: var(--sco-color-anthracite-200);
        }

        &:focus {
            .c-button--custom-iconcontent .custom-icon-content-bg {
                color: var(--sco-color-anthracite-300);
            }
        }

        &:hover {
            .c-button--custom-iconcontent .custom-icon-content-bg {
                color: var(--sco-color-anthracite-300);
            }
        }

        &:active {
            .c-button--custom-iconcontent .custom-icon-content-bg {
                color: var(--sco-color-anthracite-300);
            }
        }
    }

    .c-button--iconbutton-anthracite:hover {
        .c-button--custom-iconcontent .custom-icon-content-bg {
            color: var(--sco-color-anthracite-200) !important;
        }
    }

}