@import "../../styles/tools/tools.breakpoints";

.c-dashboard--teaser-tile-grid-container {
  --grid-cols: 2;

  display: grid;
  grid-template-columns: repeat(var(--grid-cols), 1fr);

  width: 100%;

  @include screen-xs {
    --grid-cols: 1;
    grid-gap: 12px;
  }

  @include screen-s {
    --grid-cols: 1;
    grid-gap: 16px;
  }

  @include screen-m {
    --grid-cols: 1;
    grid-gap: 16px;
  }

  @include screen-l {
    --grid-cols: 1;
    grid-gap: 16px;
  }

  @include screen-xl {
    grid-gap: 24px;
  }

  @include screen-xxl {
    grid-gap: 24px;
  }

}

.c-dashboard--teaser-tile-grid-item {

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  text-align: left;
  background: var(--sco-color-grey-020);
  padding: 20px;

  @include screen-xs {
    flex-basis: 100%;
  }

  @include screen-s {
    flex-basis: 100%;
  }

  @include screen-m {
    flex-basis: 100%;
  }

  @include screen-l {
    flex-basis: 100%;
  }

  @include screen-xl {
    flex-basis: 100%;
  }

  .c-dashboard--teaser-tile-grid-item-impuls{
    font-size: var(--sco-font-size-headline2);

  }
  .c-dashboard--teaser-tile-grid-item-impuls-label{
    font-size: var(--sco-font-size-headline4);
  }
}

.c-dashboard--teaser-tile-grid-item-tooltip {
  text-align: left;

  .header {
    margin-left: var(--sco-spacing-8);
  }
  .details {
    margin-top: var(--sco-spacing-8);
  }
}
