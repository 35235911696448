.sco-number-highlight {
    @extend .text--bold;
    border-width: 2px 4px;
    border-style: solid;
    border-radius: 4px;

    &.sco-number-highlight--kunde {
        color: var(--sco-color-kunde-text);
        background-color: var(--sco-color-kunde-background);
        border-color: var(--sco-color-kunde-background);
    }

    &.sco-number-highlight--planer {
        color: var(--sco-color-planer-text);
        background-color: var(--sco-color-planer-background);
        border-color: var(--sco-color-planer-background);
    }

    &.sco-number-highlight--projekt {
        color: var(--sco-color-projekt-text);
        background-color: var(--sco-color-projekt-background);
        border-color: var(--sco-color-projekt-background);
    }
}

.sco-icon-highlight {
    padding-left: 4px;
    padding-right: 4px;

    &.sco-icon-highlight--kunde {
        background-color: var(--sco-color-kunde-background);
        border-color: var(--sco-color-kunde-background);
        color: var(--sco-color-kunde-text);

        .c-calendar-list--icon {
            color: var(--sco-color-kunde-text);
        }
    }

    &.sco-icon-highlight--planer {
        background-color: var(--sco-color-planer-background);
        border-color: var(--sco-color-planer-background);
        color: var(--sco-color-planer-text);

        .c-calendar-list--icon {
            color: var(--sco-color-planer-text);
        }
    }

    &.sco-icon-highlight--projekt {
        background-color: var(--sco-color-projekt-background);
        border-color: var(--sco-color-projekt-background);
        color: var(--sco-color-projekt-text);

        .c-calendar-list--icon {
            color: var(--sco-color-projekt-text);
        }
    }

    &.sco-icon-highlight--aktivitaet {
        background-color: var(--sco-color-aktivitaet-background);
        border-color: var(--sco-color-aktivitaet-background);
        color: var(--sco-color-aktivitaet-text);

        .c-calendar-list--icon {
            color: var(--sco-color-aktivitaet-text);
        }
    }
}