.c-kpmnetwork__node:hover {
  cursor: pointer;
}


.c-kpmnetwork__add-northdata-dialog {

  max-height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;

  .c-kpmnetwork__add-northdata-dialog--content {
    display: flex;
    flex-direction: row;

    &:hover {
      background-color: var(--sco-color-grey-020);
    }
  }

  .c-kpmnetwork__add-northdata-dialog--company-label {
    line-height: 32px;
    width: 80%;
    text-align: left;
  }

  .c-kpmnetwork__add-northdata-dialog--buttons {
    width: 20%;
    text-align: right;
  }
}