.s-activity--edit {
  .activity-section {
    margin-top: var(--sco-spacing-8);
  }

  .references-button-list {
    max-height: 70vh;
    overflow: auto;
  }

  .add-contact-person-container {
    max-height: 70vh;
    overflow: auto;
  }

  .c-section {
    .unsaved-changes-marker {
      //  visibility: hidden;
      background-image: repeating-linear-gradient(45deg, #0000 0%, #0000 4%, #78b92850 4%, #78b92850 8%, #0000 8%);
      padding: 0 10px 0 10px;
      margin-right: var(--sco-spacing-8);
    }
  }

  .c-section--foldable.c-section--folded {
    .unsaved-changes-marker {
      //  visibility: hidden;
      background-image: repeating-linear-gradient(45deg, #0000 0%, #0000 4%, #78b9283a 4%, #78b9283a 8%, #0000 8%);
      padding: 0 10px 0 10px;
      margin-right: var(--sco-spacing-8);
    }
  }
}