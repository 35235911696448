.picomplete {
  position: relative;
  display: inline-block;
}

.picomplete-items {
  position: absolute;
  border: 1px solid var(--sco-color-grey-230);
  border-bottom: none;
  border-top: none;
  z-index: 99;

  width: 100%;
  overflow-y: scroll;
  max-height: 185px;
  scrollbar-width: thin !important;
}

.picomplete-items div {
  padding: 3px 10px 3px 10px;
  cursor: pointer;
  background-color: var(--sco-color-white);
  border-bottom: 1px solid var(--sco-color-grey-230);
}

.picomplete-items div:hover {
  background-color: var(--sco-color-primary);
  color: var(--sco-color-white);
}

.picomplete-items::-webkit-scrollbar {
  width: 2px;
}

.picomplete-items::-webkit-scrollbar-track {
  background: var(--sco-color-grey-100);
}

.picomplete-items::-webkit-scrollbar-thumb {
  background: var(--sco-color-grey-500);
}