
.c-dialog--template {
  display: none;
}

.swal2-popup .swal2-header {
  min-height: 20px;
}
.swal2-popup .swal2-content {
  font-size: $fontsize-default !important;
}

.swal2-popup #swal2-title {
  max-width: 100%;
  top: -5px;

  font-size: 1.3em;
  text-transform: uppercase;
  font-family: var(--sco-font-family-lato-regular);
}

.swal2-popup .swal2-styled.swal2-confirm {
  background-color: $color-green--default !important;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

.swal2-close {
  top: 5px;
  right: 5px;
}
.swal2-close:focus {
  outline: none;
}



.c-dialog--heading {
  margin: 20px 0px 10px 0px;
  font-weight: bold;
}

.c-dialog--buttons {
  margin-top: var(--sco-spacing-8);
}