.c-table--wrapper {
  &.design-storybook {

    th {
      background-color: var(--sco-color-grey-100);
      font-family: var(--sco-font-family-lato-black);
      padding: var(--sco-spacing-4) var(--sco-spacing-4);
      height: var(--sco-spacing-20);
      white-space: nowrap;

      @include screen-m {
        padding: var(--sco-spacing-4) var(--sco-spacing-4);
        height: var(--sco-spacing-20);
      }
      @include screen-l {
        padding: var(--sco-spacing-4) var(--sco-spacing-8);
        height: var(--sco-spacing-24);
      }
      @include screen-xl {
        padding: var(--sco-spacing-4) var(--sco-spacing-8);
        height: var(--sco-spacing-24);
      }
      @include screen-xxl {
        padding: var(--sco-spacing-8) var(--sco-spacing-16);
        height: var(--sco-spacing-32);
      }

    }

    th.sorting div:first-child::after {
      font-family: "Font Awesome 6 Pro";
      color: var(--sco-color-grey-200);
      content: "\f0dc";
    }

    th.sorting.sorting_asc div:first-child::after {
      color: var(--sco-color-primary);
      content: "\f0d7";
    }

    th.sorting.sorting_desc div:first-child::after {
      color: var(--sco-color-primary);
      content: "\f0d8";
    }

    th.sorting:hover div:first-child::after {
      color: var(--sco-color-primary);
    }

    tr:nth-child(even) {
      background-color: #F7F7F7;
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--sco-color-grey-100);
      padding: var(--sco-spacing-16);

      > div {
        float: none;
        margin: 0;
      }

      .dataTables_length select {
        border: none;

        &:hover {
          color: var(--sco-color-primary);
        }
      }

      .dataTables_info {
        color: var(--sco-color-grey-400);
      }

      .paginate_button {
        font-size: var(--sco-font-size-paragraph);
        padding: .1875rem .625rem;
        width: auto;
        height: auto;
        line-height: normal;
        color: var(--sco-color-green-500);

        &.next, &.previous, &.first, &.last {
          color: #0a0a0a;
        }

        &::before {
          width: auto;
          height: auto;
          line-height: normal;
        }

        &.disabled {
          color: #cacaca;
        }

        &:hover {
          background: $color-black--10;
        }

        &.disabled:hover {
          background-color: var(--sco-color-grey-100);
        }

        &.current, &.current:hover {
          background-color: var(--sco-color-primary);
          color: var(--sco-color-white);
        }
      }
    }

    td {
      padding: var(--sco-spacing-4) var(--sco-spacing-4);
      height: var(--sco-spacing-20);

      @include screen-m {
        padding: var(--sco-spacing-4) var(--sco-spacing-4);
        height: var(--sco-spacing-20);
      }
      @include screen-l {
        padding: var(--sco-spacing-4) var(--sco-spacing-8);
        height: var(--sco-spacing-24);
      }
      @include screen-xl {
        padding: var(--sco-spacing-4) var(--sco-spacing-8);
        height: var(--sco-spacing-24);
      }
      @include screen-xxl {
        padding: var(--sco-spacing-8) var(--sco-spacing-16);
        height: var(--sco-spacing-32);
      }

    }
  }
}
