/* snackbar.css */
#snackbar-container {
  position: fixed;
  left: 20px;
  bottom: 0;
  z-index: 99999;
}
.snackbar {
  overflow: hidden;
  clear: both;
  min-width: 288px;
  max-width: 568px;
  cursor: pointer;
  opacity: 0;
}
.snackbar.snackbar-opened {
  height: auto;
  opacity: 1;
}
@media (max-width: 767px) {
  #snackbar-container {
    left: 0px !important;
    right: 0px;
    width: 100%;
  }
  #snackbar-container .snackbar {
    min-width: 100%;
  }
  #snackbar-container [class="snackbar snackbar-opened"] ~ .snackbar.toast {
    margin-top: 20px;
  }
  #snackbar-container [class="snackbar snackbar-opened"] {
    border-radius: 0;
    margin-bottom: 0;
  }
}

/* material.css */
.snackbar {
  background-color: #323232;
  color: #FFFFFF;
  font-size: var(--sco-font-size-headline5);
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: 0;
  -moz-transition: -moz-transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0s linear 0.2s, padding 0s linear 0.2s, height 0s linear 0.2s;
  -webkit-transition: -webkit-transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0s linear 0.2s, padding 0s linear 0.2s, height 0s linear 0.2s;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0s linear 0.2s, padding 0s linear 0.2s, height 0s linear 0.2s;
  -moz-transform: translateY(200%);
  -webkit-transform: translateY(200%);
  transform: translateY(200%);
}
.snackbar.snackbar-opened {
  padding: 14px 15px;
  margin-bottom: 20px;
  height: auto;
  -moz-transition: -moz-transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0s linear 0.2s;
  -webkit-transition: -webkit-transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0s linear 0.2s;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0s linear 0.2s, height 0s linear 0.2s;
  -moz-transform: none;
  -webkit-transform: none;
  transform: none;
}
.snackbar.toast {
  border-radius: 200px;
}

/* custom */

.snackbar {
  background-color: var(--sco-color-primary);
  color: var(--sco-color-white);
  text-align:left;
  display: block;
  margin: 0 auto;

  &:before {
    width:100%;
  }

  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

#snackbar-container {
  width: 100%;
  left: 0px;
  text-align: center;
}

.c-notification--info, .c-notification--info .span {
  background-color: var(--sco-color-primary);
  color: var(--sco-color-white);
}

.c-notification--error, .c-notification--error .span {
  background-color: var(--sco-color-red-600);
  color: var(--sco-color-white);
}

.c-notification--advice, .c-notification--advice .span {
  background-color: var(--sco-color-orange-200);
  color: $color-text--white !important;

  a {
    color: $color-text--white !important;
  }
}