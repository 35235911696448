.s-salestools {

  .c-colored-tile--maximized {
    min-height: calc(100vH - 210px);

    .c-colored-tile--teaser-link {
      position: relative;
    }

    .c-colored-tile--teaser-link-selected:before {
      display: block;
      content: '';
      position: absolute;
      height: 100%;
      width: 15px;
      background: rgba(255,255,255,0.4);
      right: -15px;
      top: 0px;
    }
  }

  .s-salestools--tile-maximized-content {
    display: flex;
  }

  .s-salestools--tile-maximized-subkategorie-menu {
    flex: 0 0 250px;
    padding-right: 5px;
    margin-top: 5px;

    &.s-salestools--tile-maximized-subkategorie-menu-collapsed {
      flex: 1 0 200px;
      max-width: 300px;
    }
  }

  .s-salestools--tile-maximized-items-menu {
    background: rgba(255,255,255,0.4);
    min-height: 100%;
    flex: 0 0 250px;
    max-width: 300px;
    display: none;

    &.s-salestools--tile-maximized-items-menu-active {
      display: block;
      flex: 1 0 200px;
      max-width: 300px;
    }
    &.s-salestools--tile-maximized-items-menu-collapsed {
      flex: 1 0 200px;
      max-width: 300px;
    }
  }

  .s-salestools--tile-maximized-item {
    // SalesTools Item in Menu

    display: block;
    background: rgba(255,255,255,0.4);
    transform: translateZ(0);
    padding: 10px 15px;
    margin: 10px 15px;

    &:first-child {
      margin-top: 15px;
    }

    &:last-child {
      margin-bottom: 15px;
    }

    &:hover {
      background: rgba(255,255,255,0.6);
      color: var(--sco-color-grey-700);
    }
  }

  .s-salestools--tile-maximized-item-selected {
    position: relative;
    margin-right: 0px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      height: 100%;
      width: 15px;
      background: rgba(255,255,255,0.64);
      right: -15px;
      top: 0px;
    }
  }

  .s-salestools--tile-maximized-item-detail {
    display: none;
    flex: 1 1 455px;
    margin-right: 10px;
    min-height: 300px;
    background: rgba(255,255,255,0.64);
    margin-left: 15px;
    position: relative;
    padding-bottom: 130px;
  }
  .s-salestools--tile-maximized-item-detail-selected {
    display: block;
    align-items: start;
  }

  .s-salestools-tile-maximized-item-detail-label {
    font-size: var(--sco-font-size-headline5);
    font-weight: bold;
    padding: 25px 30px 0px 30px;
  }

  .s-salestools-tile-maximized-item-detail-heading {
    font-weight: bold;
    font-size: var(--sco-font-size-paragraph);
    padding: 20px 40px 20px 45px;
  }

  .s-salestools-tile-maximized-item-detail-remark {
    font-size: 12px;
    padding: 0px 40px 0px 45px;
  }

  .s-salestools-tile-maximized-item-detail-heading {
    font-weight: bold;
    font-size: 12px;
    padding: 20px 40px 20px 45px;
  }

  .s-salestools-tile-maximized-item-detail-foldable {
    .c-section--content {
      padding: 15px 10px;
    }

    margin: 20px 30px 0px 30px;
    text-align: left;
    width: calc(100% - 60px);

    &.c-section.c-section--folded > .c-section--heading {
      background: rgba(255,255,255,0.64);
      color: var(--sco-color-grey-700);

      &:hover {
        background: rgba(255,255,255,0.64);
      }
    }
  }

  .s-salestools--tile-maximized-item-detail-date,
  .s-salestools--tile-maximized-item-detail-type,
  .s-salestools--tile-maximized-item-detail-video,
  .s-salestools--tile-maximized-item-detail-size {
    padding: 0px 40px 0px 45px;
    font-size: 12px;
  }

  .s-salestools--tile-maximized-item-detail-type {
    padding-top: 20px;
  }

  .s-salestools-tile-maximized-item-detail-btn-download,
  .s-salestools-tile-maximized-item-detail-btn-stream,
  .s-salestools-tile-maximized-item-detail-btn-link,
  .s-salestools-tile-maximized-item-detail-btn-fav {
    &:before {
      content: fa-content($fa-var-download);
      font-family: "Font Awesome 6 Pro";
      font-size: var(--sco-font-size-headline4);
      display: inline-block;
    }

    text-align: left;
    .c-button--content {
      font-family: var(--sco-font-family-lato-regular);
    }
    color: var(--sco-color-grey-700);
    display: block;
    padding: 10px 5px 10px 45px;
    margin: 0px 30px 0px 30px;
    margin-top: 20px;
    background: rgba(255,255,255,0.64);

    width: calc(100% - 60px);

    &:hover {
      color: var(--sco-color-primary);
    }
  }

  .s-salestools--search-results-buttons {
    display: block;
    margin-top: 20px;
    margin-right: -15px;
  }

  .s-salestools--search-results-btn-download,
  .s-salestools--search-results-btn-stream,
  .s-salestools--search-results-btn-link,
  .s-salestools--search-results-btn-fav {
    float: left;

    &:before {
      content: fa-content($fa-var-download);
      font-family: "Font Awesome 6 Pro";
      font-size: var(--sco-font-size-headline4);
      padding-right: 15px;
      padding-left: 15px;
      display: inline-block;
      padding-top: 2px;
      padding-bottom: 2px;
      position: relative;
      top: 1px;
    }
    text-align: left;

    .c-button--content {
      font-family: var(--sco-font-family-lato-regular);
    }
    color: var(--sco-color-grey-700);

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 20px 5px 5px;
    background: rgba(255,255,255,0.64);
    width: auto;
    height: 41px;
    margin-right: 15px;
    margin-bottom: 15px;

    &:hover {
      color: var(--sco-color-primary);
    }
  }

  .fav-add {
    bottom: 65px;

    &:before {
      content: fa-content($fa-var-star);
      font-weight: 400;
    }
    &[data-is-fav='1'] {
      display: none;
    }
  }

  .fav-del {
    bottom: 65px;

    &:before {
      content: fa-content($fa-var-star);
      font-weight: 900;
    }
    &[data-is-fav='0'] {
      display: none;
    }
  }

  .s-salestools-tile-maximized-item-detail-btn-stream,
  .s-salestools--search-results-btn-stream {
    &:before {
      content: fa-content($fa-var-play);
    }
  }

  .s-salestools-tile-maximized-item-detail-btn-link,
  .s-salestools--search-results-btn-link {
    &:before {
      content: fa-content($fa-var-external-link);
    }
  }

  .c-header--titlebuttons {
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 0px;

    .c-input--search {
      flex: 1;
      .c-input--text {
        background: transparent;
      }
    }
    .c-button {
      flex: 0;
      padding: 0px 13px;
      width: auto;

      &:nth-of-type(1) {
        margin-left: 15px;
      }
      &:nth-last-of-type(1) {
        margin-right: 15px;
      }
    }
  }

  .c-card {
    margin-bottom: 20px;
  }

  .c-card--header {
    color: var(--sco-color-white);
    padding: 10px 15px;
  }

  .c-card--content {
    padding-bottom: 10px;
    background: rgba(255, 255, 255, 1.0);
  }


  .s-salestools--search-results-remark {
    font-size: 12px;
    height: 72px;
  }

  .s-salestools--activities-item {

    &:hover {
      .c-card--content {
        background: var(--sco-color-green-800);
      }
      cursor: pointer;
    }

    &.s-salestools--activities-item-read * {
      font-weight: normal !important;
    }

    &.s-salestools--activities-item-unread * {
      font-weight: bold !important;
    }
  }


  .s-salestools--video {
    width: 100%;
  }

  /* Darker Modal Background for Videos */
  .swal2-popup{
    width: 100%
  }
  .swal2-content {
    height: 0%; // IE11 workaround
  }

  .swal2-container.swal2-shown {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.platform-ios {
  .s-salestools--tile-maximized-item:hover {
    background: inherit;
    color: inherit;
  }
}