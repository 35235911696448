.s-messe-nachbereitung-import {
  .messe-id-dd {
    max-width: 305px;
  }
  .c-upload--simple {
    padding-left: 10px;
  }

  #messeform-fields {
    padding-left: 10px;
    height: 60vh;
    overflow: auto;

    table {
      width: auto;

      .group-label {
        font-size: larger;
        background-color: $color-gray--152;
      }

      .label-field {
        min-width: 30ch;
      }

      .select-field {
        min-width: 60ch;
      }

      .option-label {
        padding-left: 5ch;
      }
    }
  }

  .import-buttons {
    justify-content: left;
    margin-bottom: 15px;
  }
  .auswahl {
    margin-bottom: 15px;
    justify-content: left;
    .delete-button-container {
      flex: 0 0 auto;
    }
  }
  .hinweis {
    margin-bottom: 5px;
  }
  .delete-button {
    background-color: $color-red--default;
  }

  #importsimulationresults {
    margin-top: 2em;
    margin-bottom: 2em;
    display: flex;
    gap: 5px;
    ul {
      margin-bottom: 0;
    }
    .title {
      text-align: center;
    }
    .items {
      padding: 5px;
    }
    .errors, .advices, .infos {
      flex: 1;
    }
    .errors {
      border: $color-red--default solid 1px;
      .title {
        background-color: $color-red--default;
        color: $color-text--white;
      }
    }
    .advices {
      border: $color-orange--default solid 1px;
      .title {
        background-color: $color-orange--default;
      }
    }
    .infos {
      border: $color-green--10 solid 1px;
      .title {
        background-color: $color-green--10;
      }
    }
  }
}