@mixin s-projekt-produktgruppen-systeme--highlight-item {
    border-color: var(--sco-color-primary);
    border-width: 5px;
    border-style: solid;
    padding: 5px;
    color: var(--sco-color-grey-700);

    &:hover {
        border-color: var(--sco-color-green-500);
        transition: border-color unset;
    }

    &.produktgruppe-hover {
        &.odd {
            background: var(--sco-color-grey-100);
        }
        &.even {
            background: var(--sco-color-white);
        }

        .c-checkbox--virtualbox-check {
            color: var(--sco-color-primary);
        }
    }
}

@mixin s-projekt-produktgruppen-systeme--item {
    padding: 10px;
    text-align: left;
    display: flex;
    transition: border-color 0.1s ease-in-out;
    color: var(--sco-color-grey-700);

    &.odd {
        background: var(--sco-color-grey-100);
        border-color: var(--sco-color-grey-100);
    }
    &.even {
        background: var(--sco-color-white);
        border-color: var(--sco-color-white);
    }

    &.produktgruppe-hover, &:hover {
        background: var(--sco-color-primary);
        border-color: var(--sco-color-primary);
        transition: all 0.1s ease-in-out;
        color: var(--sco-color-white);

        .produktgruppen-systeme-favorit-button {
            &.favorit {
                color: var(--sco-color-green-500);
            }

            &:hover {
                color: var(--sco-color-white);
            }
        }

        .c-checkbox--virtualbox-check {
            color: var(--sco-color-white);
        }
    }

    .o-col-inner {
        padding-top: 6px;
    }

    .c-checkbox {
        height: unset;
    }
}

@mixin s-projekt-produktgruppen-systeme--item-name {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
}

@mixin s-projekt-produktgruppen-systeme--produktgruppen-systeme-link {
    .produktgruppen-systeme-item {
        min-height: 20px;
        @include s-projekt-produktgruppen-systeme--item;

        .produktgruppen-systeme-name {
            @include s-projekt-produktgruppen-systeme--item-name;
        }
    }

    .produktgruppen-systeme-favorit-button {
        color: var(--sco-color-grey-230);
        transition: none;

        &:hover {
            color: var(--sco-color-primary);
        }

        &.favorit {
            color: var(--sco-color-primary);

            &:hover {
                color: var(--sco-color-grey-230);
            }
        }
    }

    &.hidden-by-search {
        display: none;
    }

    &.hidden-by-filter {
        display: none;
    }

    &.highlight-produktgruppe .produktgruppen-systeme-item, &.highlight-system .produktgruppen-systeme-item {
        @include s-projekt-produktgruppen-systeme--highlight-item;
    }
}

.s-projekt-create {
    .c-stepper .sco-stepper__list {

        @include screen-xs{
            margin-left: 0px;
        }
        @include screen-s{
            margin-left: 0px;
        }

        .sco-stepper__step-name{
            @include screen-xs{
                display:none;
            }
            @include screen-s{
                display:none;
            }
        }

        li.step-complete, li.step-warning, li.step-error {
            span.sco-stepper__step::after {
                font-family: "Font Awesome 6 Pro";
                font-weight: 900;
                font-size: 0.65em;

                color: var(--sco-color-white);

                width: 16px;
                height: 16px;
                line-height: 1;
                border-width: 3px;
                border-style: solid;
                border-radius: 8px;
                position: absolute;
                top: 24px;
                right: -8px;
            }
        }
        li.step-complete {
            span.sco-stepper__step::after {
                content: "\f00c";
                background-color: var(--sco-color-primary);
                border-color: var(--sco-color-primary);
            }
        }
        li.step-complete.current {
            span.sco-stepper__step::after {
                background-color: var(--sco-color-green-800);
                border-color: var(--sco-color-green-800);
            }
        }
        li.step-warning {
            span.sco-stepper__step::after {
                content: "\21";
                background-color: $color-orange--light;
                border-color: $color-orange--light;
            }
        }
        li.step-error {
            span.sco-stepper__step::after {
                content: "\21";
                background-color: var(--sco-color-red-600);
                border-color: var(--sco-color-red-600);
            }
        }

        #s-projekt-create--stepper-forecast-spinner {
            display: none;
        }
    }

    div.c-dropdown--container:has(select.o-hyperform--validated.o-hyperform--invalid) {
        span.select2-selection {
            border-bottom: $c-input--border-focus solid $color-border--invalid !important;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            padding-bottom:0;
        }
    }

    #s-projekt-create--next-button-spinner {
        padding: 3px;
        display: none;
    }

    .c-card--header-wrapper-left {
        min-width: 0;
    }

    .c-card--header-wrapper-right {
        flex-grow: 0;
    }
}

.s-projekt-create-list {
    .s-projekt-create--list-new-card {
        margin-bottom: 12px;
    }

    .s-projekt-create--list-grid-layout {
        justify-content: flex-start;

        .s-projekt-create--list-grid-tile {
            flex-shrink: 1;
            flex-grow: 0;
            @include number-of-dashboard-tiles-per-row(2);
            min-height: 0;
            margin: 5px;

            &.c-card--with-content-scrollable {
                min-height: 300px;
            }

            @include mobile {
                @include number-of-dashboard-tiles-per-row(1);
            }

            @include tabletPortrait {
                @include number-of-dashboard-tiles-per-row(1);
            }

            @include tabletLandscape {
                @include number-of-dashboard-tiles-per-row(2);
            }

            @include wider-than-desktop {
                @include number-of-dashboard-tiles-per-row(3);
            }

            .c-card--header {
                padding: 4px 16px;
                min-height: auto;

                .c-card--header-wrapper-left {
                    min-width: 0;
                    flex-basis: unset;
                }
            }

            .c-card-simple-info-content {
                margin-bottom: 8px;
            }

            .s-projekt-create-list--entscheider-info, .s-projekt-create-list--kunde-info {
                display: flex;
                background-color: var(--sco-color-grey-100);
                padding: 4px;

                .s-projekt-create-list--planer-kunde-info-icon {
                    font-size: var(--sco-font-size-headline3);
                    margin-left: 4px;
                    margin-right: 8px;

                    i {
                        vertical-align: middle;
                    }
                }

                .s-projekt-create-list--planer-kunde-info-text {
                }
            }

            .s-projekt-create-list--planer-kunde-row {
                margin-bottom: 8px;
            }

            .s-projekt-create-list--datum-row {
                margin-bottom: 8px;
            }
        }

        #s-projekt-create--list-grid-tile-create-new {
            .s-projekt-create-list--plus-icon {
                font-size: 100px;
                height: 100%;
                display: block;

                &:not(:hover) {
                    color: var(--sco-color-grey-230);
                }

                i {
                    width: 100%;
                    text-align: center;
                    vertical-align: middle;
                }
            }
        }
    }
}

.s-projekt-create-stammdaten {
    .c-projekt-create-stammdaten--projekt-city, .c-projekt-create-stammdaten--projekt-city-wahlfeld, .c-projekt-create-stammdaten--projekt-city-freitext-checkbox {
        &.uiHidden {
            display: none;
        }
    }

    .s-projekt-create--duplicates-grid-layout {
        justify-content: flex-start;

        .s-projekt-create--duplicates-grid-tile {
            flex-shrink: 1;
            flex-grow: 0;
            @include number-of-dashboard-tiles-per-row(2);
            min-height: 0;
            margin: 5px;

            &.c-card--with-content-scrollable {
                min-height: 300px;
            }

            @include mobile {
                @include number-of-dashboard-tiles-per-row(1);
            }

            @include tabletPortrait {
                @include number-of-dashboard-tiles-per-row(1);
            }

            @include tabletLandscape {
                @include number-of-dashboard-tiles-per-row(2);
            }

            @include wider-than-desktop {
                @include number-of-dashboard-tiles-per-row(3);
            }

            .c-card--header {
                padding: 4px 16px;
                min-height: auto;

                .c-card--header-wrapper-left {
                    min-width: 0;
                    flex-basis: unset;
                }
            }
        }
    }

    .s-projekt-create--no-duplicates-found-panel, .s-projekt-create--duplicates-card {
        margin-top: 10px;
        margin-bottom: 15px;

        .c-card--header-title {
            white-space: normal;
        }
    }

    .c-button.s-projekt-create--button-hidden {
        display: none;
    }
}

.s-projekt-create-planer {
    .s-projekt-create--planer-card {
        .s-projekt-create-planer--add-planer-buttons {
            margin-right: 12px;
            margin-top: 4px;
        }

        .s-projekt-create--planer-grid-layout {
            justify-content: flex-start;

            .s-projekt-create--planer-grid-tile {
                flex-shrink: 1;
                flex-grow: 0;
                @include number-of-dashboard-tiles-per-row(2);
                min-height: 0;
                margin: 5px;

                &.c-card--with-content-scrollable {
                    min-height: 300px;
                }

                @include mobile {
                    @include number-of-dashboard-tiles-per-row(1);
                }

                @include tabletPortrait {
                    @include number-of-dashboard-tiles-per-row(1);
                }

                @include tabletLandscape {
                    @include number-of-dashboard-tiles-per-row(2);
                }

                @include wider-than-desktop {
                    @include number-of-dashboard-tiles-per-row(3);
                }

                .c-card--header {
                    .c-card--header-wrapper-left {
                        min-width: 0;
                        flex-basis: unset;
                    }
                }
            }

            .c-card--header.s-projekt-create--planer-grid-tile-entscheider {
                background: var(--sco-color-primary);
                color: white;

                a.c-card--header-title-link {
                    color: inherit;
                }
            }

        }
    }

    .select2-container--default .select2-results>.select2-results__options {
        max-height: 50vh;
    }
}

.s-projekt-create-produktgruppen-systeme {

    .produktkategorien-tile-section--hidden {
        .c-section--heading {
            display: none;
        }
    }

    .s-projekt-create--produktgruppen-systeme-card-TILES {
        .s-projekt-create-produktgruppen-systeme--select-vtw-col {
            display: none;
        }
        .s-projekt-create-produktgruppen-systeme--produktkategorien-tile-container {
            --grid-cols: 6;

            @include screen-xs {
                font-size: var(--sco-font-size-xs-paragraph);
                --grid-cols: 1;
                --grid-gap: 12px;
                --grid-cols-1-span: 4;
                --single-values-per-col: 1;
            }

            @include screen-s {
                font-size: var(--sco-font-size-s-paragraph);
                --grid-cols: 1;
                --grid-gap: 16px;
                --grid-cols-1-span: 8;
                --single-values-per-col: 1;
            }

            @include screen-m {
                font-size: var(--sco-font-size-m-paragraph);
                --grid-cols: 2;
                --grid-gap: 16px;
                --grid-cols-1-span: 4;
                --single-values-per-col: 2;
            }

            @include screen-l {
                font-size: var(--sco-font-size-l-paragraph);
                --grid-cols: 3;
                grid-gap: 16px;
                --single-values-per-col: 3;
            }

            @include screen-xl {
                font-size: var(--sco-font-size-xl-paragraph);
                --grid-cols: 4;
                --grid-gap: 24px;
                --single-values-per-col: 4;
            }

            @include screen-xxl {
                font-size: var(--sco-font-size-xxl-paragraph);
                --grid-cols: 6;
                --grid-gap: 24px;
                --single-values-per-col: 6;
            }
        }
    }

    .produktgruppen-systeme-grid {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 1fr 1fr 1fr;

        #produktgruppen-suche-container, #systeme-suche-container {
            min-height: 55px;
        }

        .vtw-link {
            .vtw-item {
                min-height: 52px;
                @include s-projekt-produktgruppen-systeme--item;

                &.vtw-item {
                    background: var(--sco-color-grey-700);
                    border-color: var(--sco-color-grey-700);
                }

                .vtw-name {
                    @include s-projekt-produktgruppen-systeme--item-name;
                    color: var(--sco-color-white);
                }
            }
        }

        .produktkategorie-link {
            .produktkategorie-item {
                min-height: 52px;
                @include s-projekt-produktgruppen-systeme--item;

                .produktkategorie-name {
                    @include s-projekt-produktgruppen-systeme--item-name;
                }
            }

            &.highlight-produktkategorie .produktkategorie-item {
                @include s-projekt-produktgruppen-systeme--highlight-item;
            }
        }

        .produktgruppen-systeme-link {
            @include s-projekt-produktgruppen-systeme--produktgruppen-systeme-link;
        }
    }

    #favoriten-link {
        .produktkategorie-item {
            min-height: 52px;
            @include s-projekt-produktgruppen-systeme--item;

            color: var(--sco-color-white);
            background-color: var(--sco-color-primary);

            .produktkategorie-name {
                @include s-projekt-produktgruppen-systeme--item-name;
            }

            &:focus {
                background-color: var(--sco-color-primary);
                color: var(--sco-color-white);
            }

            &:hover {
                background-color: var(--sco-color-green-800);
                color: var(--sco-color-white);
            }

            &:active {
                background-color: var(--sco-color-green-500);
                color: var(--sco-color-white);
            }

            .produktkategorie-name i {
                margin-right: 8px;
            }
        }
    }

    .s-projekt-create--produktgruppen-systeme-card-TILES .produktkategorien-tile-section:first-of-type {
        margin-top: 12px;
    }

    .produktgruppen-systeme-dialog {
        #produktgruppen-systeme-dialog--title-grid {
            display: grid;
            grid-gap: 8px;
            grid-template-columns: 1fr 2fr 1fr;

            #s-projekt-create--produktgruppen-systeme-dialog--save-button {
                float: right;
            }
        }

        #produktgruppen-systeme-dialog--grid {
            display: grid;
            grid-gap: 8px;

            #produktgruppen-list, #systeme-list {
                overflow-y: auto;
                height: 600px;

                .produktgruppen-systeme-link {
                    @include s-projekt-produktgruppen-systeme--produktgruppen-systeme-link;
                }
            }
        }
    }

    #s-projekt-create-produktgruppen-systeme--produktgruppen-systeme-search-dialog--title-grid {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: auto min-content;
    }

    #s-projekt-create-produktgruppen-systeme--produktgruppen-systeme-search-dialog--produktgruppen-systeme-search-results {
        #produktgruppenSystemeSearchResults {
            td {
                height: unset;
            }
            tr.produktgruppe-system-vorhanden {
                background: var(--sco-color-primary);
            }
        }
    }

    #s-projekt-create-produktgruppen-systeme--produktgruppen-systeme-suche-col .o-col-inner {
        display: flex;

        :first-child {
            flex-grow: 1;
        }
    }
}

.s-projekt-create-forecast {
    .s-projekt-create--forecast-card {
        .c-section--heading {
            background-color: $color-gray--130;
        }

        .c-section.design-storybook {
            .c-section--content {
                margin-top: 0;
                border-left: 32px $color-gray--130 solid;
                border-color: $color-gray--130;
            }

            &:not(.c-section--folded) {
                .c-section--heading {
                    margin-bottom: 0;
                }
            }
        }

        .s-projekt-create-forecast--forecast-table {
            table-layout: fixed;
            margin-bottom: 5px;

            .s-projekt-create-forecast--action-col {
                width: 48px;
            }

            .simple-div-table--table-heading {
                .s-projekt-create-forecast--flaeche-col, .s-projekt-create-forecast--forecast-col, .s-projekt-create-forecast--stueckzahl-col {
                    text-align: right;
                }
            }

            .simple-div-table--table-body {
                .c-input--text-wrapper {
                    height: unset;

                    .c-input--text {
                        height: unset;
                        padding: unset;
                        background-color: unset;
                    }
                }

                .simple-div-table--table-cell-even, .simple-div-table--table-cell-odd {
                    background-color: unset;
                }

                &.s-projekt-create-forecast--produktgruppe-row-group {
                    &:not(:last-child)::after {
                        content: '';
                        height: 24px;
                        display: block;
                    }

                    .s-projekt-create-forecast--produktgruppe-header-row, .s-projekt-create-forecast--produktgruppe-footer-row {
                        background-color: $color-gray--152;
                        .s-projekt-produktgruppen-forecast--action-col {
                            background-color: $color-white;
                        }

                        .s-projekt-create-forecast--system-col {
                            .s-projekt-create-forecast--sum-symbol {
                                font-size: larger;
                                margin: auto 0;
                            }
                        }
                    }

                    .s-projekt-create-forecast--toggle-col, .s-projekt-create-forecast--produktgruppe-col {
                        background-color: $color-gray--152;
                    }

                    &.s-projekt-create-forecast--produktgruppe-row-group {
                        .s-projekt-create-forecast--produktgruppe-footer-row:not(:first-child) .s-projekt-create-forecast--produktgruppe-col span {
                            display: none;
                        }
                    }
                }

                .s-projekt-create-forecast--produktgruppe-col {
                    font-family: 'univers-bold';
                }

                .s-projekt-create-forecast--validation-cell {
                    text-align: right;
                }

                .s-projekt-create-forecast--produktgruppe-value-input-wrapper,
                .s-projekt-create-forecast--system-value-input-wrapper {
                    .c-input--content-right {
                        background: none;
                        left: unset;
                    }
                }

                .s-projekt-create-forecast--calculate-produktgruppe-info-icon, .s-projekt-create-forecast--calculate-system-info-icon  {
                    color: var(--sco-color-grey-400);

                    &:not(.forecast-calculate-info) {
                        display: none;
                    }

                    &.forecast-calculate-info {
                        &.forecast-calculate-info-deviation {
                            color: var(--sco-color-blue-400);
                        }
                    }
                }

                .c-input--content-right {
                    .s-projekt-create-forecast--produktgruppe-reset-button, .s-projekt-create-forecast--system-reset-button,
                    .s-projekt-create-forecast--warning-icon {
                        display: none;
                    }
                }

                .forecast-warning ~ .c-input--content-right, .forecast-info ~ .c-input--content-right, .flaeche-warning ~ .c-input--content-right, .stueckzahl-warning ~ .c-input--content-right {
                    .s-projekt-create-forecast--produktgruppe-reset-button, .s-projekt-create-forecast--system-reset-button {
                        display: unset;
                        cursor: pointer;
                    }
                }

                .forecast-warning ~ .c-input--content-right, .flaeche-warning ~ .c-input--content-right, .stueckzahl-warning ~ .c-input--content-right {
                    .s-projekt-create-forecast--warning-icon {
                        display: unset;
                        color: var(--sco-color-orange-200);
                    }
                }

                .s-projekt-create-forecast--produktgruppe-header-row, .s-projekt-create-forecast--produktgruppe-sum-row {
                    font-family: var(--sco-font-family-lato-bold);

                    input {
                        font-family: var(--sco-font-family-lato-bold);
                    }

                    .s-projekt-create-forecast--produktgruppe-forecast-input, .s-projekt-create-forecast--produktgruppe-flaeche-input, .s-projekt-create-forecast--produktgruppe-stueckzahl-input {
                        &::-webkit-input-placeholder { /* Edge */
                            color: var(--sco-color-grey-400);
                            font-family: var(--sco-font-family-lato-bold);
                        }

                        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                            color: var(--sco-color-grey-400);
                            font-family: var(--sco-font-family-lato-bold);
                        }

                        &::placeholder {
                            color: var(--sco-color-grey-400);
                            font-family: var(--sco-font-family-lato-bold);
                        }
                    }

                    .s-projekt-create-forecast--produktgruppe-forecast-input.forecast-info:not(:is(:hover, :active)) {
                        border-bottom: 2px solid var(--sco-color-blue-400);
                    }

                    .s-projekt-create-forecast--produktgruppe-forecast-input.forecast-warning:not(:is(:hover, :active)),
                    .s-projekt-create-forecast--produktgruppe-flaeche-input.flaeche-warning:not(:is(:hover, :active)),
                    .s-projekt-create-forecast--produktgruppe-stueckzahl-input.stueckzahl-warning:not(:is(:hover, :active)) {
                        border-bottom: 2px solid var(--sco-color-orange-200);
                    }
                }

                .s-projekt-create-forecast--system-row + .s-projekt-create-forecast--produktgruppe-sum-row {
                    .s-projekt-create-forecast--produktgruppe-sum-cell {
                        border-top: 0.5px solid var(--sco-color-grey-600);
                    }
                }

                .s-projekt-create-forecast--system-row {
                    .s-projekt-create-forecast--system-forecast-input.forecast-info:not(:is(:hover, :active)) {
                        border-bottom: 2px solid var(--sco-color-blue-400);
                    }
                }

                /* Remove Arrows/Spinners */
                /* Chrome, Safari, Edge, Opera */
                input.c-input--text::-webkit-outer-spin-button,
                input.c-input--text::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                input.c-input--text[type=number] {
                    -moz-appearance: textfield;
                }

                .s-projekt-create-forecast--flaeche-col, .s-projekt-create-forecast--forecast-col, .s-projekt-create-forecast--stueckzahl-col {
                    .c-input--text[type="number"] {
                        text-align: right;
                    }
                }
            }
        }

        .c-section.c-section--foldable.c-section--folded {
            width: 100%;
        }
    }
}

.s-projekt-create-zeitschiene {
    #projekt-create-zeitschiene-form {
        align-self: center;
    }
}

.s-projekt-create-schluesselfragen {
    .s-projekt-create-schluesselfragen--grid {
        // Variablen Teil
        --grid-gap: 24px; // default
        margin-top: var(--grid-gap);
        margin-bottom: var(--grid-gap);


        --single-values-per-col: 2;
        --grid-cols: 3;

        // Spaltenbreite = Breite * Anzahl der single-values per Spalte + Platz der Gaps dazwischen
        --grid-cols-width: calc(
                1fr * var(--single-values-per-col) + (var(--single-values-per-col) - 1) * var(--grid-gap)
        );


        // Properties
        display: grid;
        grid-gap: var(--grid-gap);
        grid-template-columns: repeat(var(--grid-cols), 1fr) [end];


        @include screen-xs {
            --grid-cols: 1;
            --grid-cols-1-span: 1;
            --single-values-per-col: 1;
        }

        @include screen-s {
            --grid-cols: 1;
            --grid-gap: 8px;
            --single-values-per-col: 1;
        }

        @include screen-m {
            --grid-gap: 8px;
        }

        @include screen-l {
            --grid-gap: 16px;
        }

        @include screen-xl {
        }

        @include screen-xxl {
        }

        .c-checkbox {
            height: unset;
        }
    }
}

.s-projekt-create-summary {
    #s-projekt-create-summary--dashboard {
        justify-content: flex-start;

        #s-projektanlage-summary--card-PLANER, #s-projektanlage-summary--card-KUNDE {
            @include desktop {
                order: 3;
            }

            @include wider-than-desktop {
                order: 2;
            }
        }

        #s-projektanlage-summary--card-VERTRIEBSBEREICHE {
            @include desktop {
                order: 2;
            }

            @include wider-than-desktop {
                order: 3;
            }

            flex-basis: calc(2*100%/3 - 10px);

            @include mobile {
                @include number-of-dashboard-tiles-per-row(1);
            }

            @include tabletPortrait {
                @include number-of-dashboard-tiles-per-row(1);
            }

            @include tabletLandscape {
                @include number-of-dashboard-tiles-per-row(1);
            }

            @include wider-than-desktop {
                flex-basis: calc(2*100%/4 - 10px);
            }
        }

        #s-projektanlage-summary--card-INFORMATIONEN {
            display: none;

            &.has-content {
                display: flex;
            }

            .c-infobox {
                margin-bottom: 8px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                .c-radio--input:not(:checked) + .c-radio--virtualradio {
                    color: var(--sco-color-grey-600);
                }
            }
        }

        #s-projektanlage-summary--card-DOKUMENTE {
            @include number-of-dashboard-tiles-per-row(1);

            @include wider-than-desktop {
                flex-basis: calc(2*100%/4 - 10px);
            }

            #dokumente_upload_fileupload button.c-button--icontextbutton {
                padding-left: 12px;
                padding-right: 12px;

                .c-button--content {
                    font-size: var(--sco-font-size-headline5);
                }
            }

            .tpl-container.dataTable td.folder .c-dropdown--wrapper .folder_dropdown {
                background-color: unset;
            }
        }

        &:has(#s-projektanlage-summary--card-INFORMATIONEN .c-infobox.c-infobox-orange), &:has(#s-projektanlage-summary--card-INFORMATIONEN .c-infobox.c-infobox-red) {
            #s-projektanlage-summary--card-INFORMATIONEN {
                order: 2;
            }

            #s-projektanlage-summary--card-PLANER, #s-projektanlage-summary--card-KUNDE {
                order: 3;

                @include wider-than-desktop {
                    order: 4;
                }
            }

            #s-projektanlage-summary--card-VERTRIEBSBEREICHE {
                order: 4;

                @include wider-than-desktop {
                    order: 3;
                }
            }
        }
    }
}
