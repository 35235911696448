@import "../../styles/tools/tools.breakpoints";

.c-dashboard--grid-layout {

    // Variablen Teil
    --grid-gap: 24px;         // default


    --single-values-per-col: 1;
    --grid-cols: 12;

    // Spaltenbreite = Breite * Anzahl der single-values per Spalte + Platz der Gaps dazwischen
    --grid-cols-width: calc(
            1fr * var(--single-values-per-col) + (var(--single-values-per-col) - 1) * var(--grid-gap)
    );

    // Properties
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: repeat(var(--grid-cols), 1fr) [end];

    @include screen-xs {
        font-size: var(--sco-font-size-xs-paragraph);
        --grid-cols: 4;
        --grid-gap: 12px;
        --grid-cols-1-span: 4;
        --single-values-per-col: 1;
    }

    @include screen-s {
        font-size: var(--sco-font-size-s-paragraph);
        --grid-cols: 8;
        --grid-gap: 16px;
        --grid-cols-1-span: 8;
        --single-values-per-col: 1;
    }

    @include screen-m {
        font-size: var(--sco-font-size-m-paragraph);
        --grid-cols: 8;
        --grid-gap: 16px;
        --grid-cols-1-span: 4;
        --single-values-per-col: 2;
    }

    @include screen-l {
        font-size: var(--sco-font-size-l-paragraph);
        --grid-cols: 12;
        grid-gap: 16px;
        --single-values-per-col: 3;
    }
    
    @include screen-xl {
        font-size: var(--sco-font-size-xl-paragraph);
        --grid-cols: 12;
        --grid-gap: 24px;
        --single-values-per-col: 4;
    }

    @include screen-xxl {
        font-size: var(--sco-font-size-xxl-paragraph);
        --grid-cols: 12;
        --grid-gap: 24px;
        --single-values-per-col: 6;
    }

    .c-dashboard--grid-tile {

    }

    .grid-subcontainer {
        display: grid;
        grid-gap: var(--grid-gap);
        grid-template-columns: repeat(auto-fit, 1fr);
    }

    .new-group {
        grid-column-start: 1;
    }

    .c-dashboard--grid-tile, .grid-subcontainer {
        &.span-x-1 {
            grid-column-end: span var(--grid-cols-1-span, 1);

            @include screen-s {
                grid-column-end: span var(--grid-cols-1-span, 4);
            }
        }
        &.span-x-2 {
            grid-column-end: span var(--grid-cols-1-span, 2);

            @include screen-s {
                grid-column-end: span var(--grid-cols-1-span, 4);
            }
        }
        &.span-x-3 {
            grid-column-end: span var(--grid-cols-1-span, 3);

            @include screen-s {
                grid-column-end: span var(--grid-cols-1-span, 4);
            }
        }
        &.span-x-4 {
            grid-column-end: span var(--grid-cols-1-span, 4);

            @include screen-s {
                grid-column-end: span var(--grid-cols-1-span, 4);
            }
        }
        &.span-x-5 {
            grid-column-end: span var(--grid-cols-1-span, 5);

            @include screen-s {
                grid-column-end: span var(--grid-cols-1-span, 4);
            }
        }
        &.span-x-6 {
            grid-column-end: span var(--grid-cols-1-span, 6);

            @include screen-s {
                grid-column-end: span var(--grid-cols-1-span, 4);
            }
        }
        &.span-x-7 {
            grid-column-end: span var(--grid-cols-1-span, 7);

            @include screen-s {
                grid-column-end: span var(--grid-cols-1-span, 4);
            }
        }
        &.span-x-8 {
            grid-column-end: span var(--grid-cols-1-span, 8);

            @include screen-s {
                grid-column-end: span var(--grid-cols-1-span, 4);
            }
        }
        &.span-x-12 {
            grid-column-end: span var(--grid-cols-1-span, 12);

            @include screen-s {
                grid-column-end: span var(--grid-cols-1-span, 4);
            }
        }
        &.span-y-2 {
            grid-row-end: span 2;
        }
        &.span-x-all {
            grid-column: 4 / end;
        }
    }

    .c-dashboard--grid-tile {
        &.grid-subcontainer-tile {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .grid-subcontainer-tile {

    }

}