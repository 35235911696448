$spinnerHeight: 66px;
$spinnerWidth: 66px;

.c-spinner {
  display: block;
  content:'';
  width: $spinnerWidth;
  height: $spinnerHeight;
  margin: 0 auto;
  transform: translateY(-50%);
  position: relative;

  &:before {
    content:'';
    display: block;
    width: $spinnerWidth;
    height: $spinnerHeight;
    background-image: url("../assets/images/loading_circle.gif");
    background-repeat: no-repeat;
    background-position: center center;
  }

  &.c-spinner--xxl:before {
    background-image: url("../assets/images/loading_xxl.gif");
  }

  &.c-spinner--content:before {
    background-image: url("../assets/images/loading_big.gif");
  }
}