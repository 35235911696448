.s-forecastwerte-backoffice-liste {
    #s-forecastwerte-backoffice-liste--upload-form {
        #s-forecastwerte-backoffice-liste--upload-input {
            max-width: unset;
        }
    }

    #s-forecastwerte-backoffice-liste--save-form {
        .simple-div-table--table .c-dropdown--wrapper .c-dropdown--container {
            background: none;

            .select2-container--default .select2-selection--single {
                background: none;
            }
        }
    }
}