/* Leadübersicht (Liste) = Partner Home Page */

.s-tml-partner-home--filters {
  .c-section--content {
    padding: 15px 30px;
  }
  margin-top: 5px;
  margin-bottom: 20px;

  //&.c-section.c-section--folded > .c-section--heading {
  //  background: var(--sco-color-grey-400);
  //  color: var(--sco-color-white);
  //
  //  &:hover {
  //    background: var(--sco-color-grey-600);
  //  }
  //}
}

.s-tml-partner-home--title-panel {
  margin-bottom: 10px;

  .c-button--badge {
    bottom: 25px;
  }

  .c-panel--title {

    display: flex;
    padding: 0px;

    > span {
      // title
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 250px;
      display: inline-block;
      padding: 15px;
    }

    > .c-panel--title-actions {
      // Buttons
      padding: 5px 0px;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 250px;
      min-height: 1px;
      min-width: 1px;
      position: relative;
      text-align: right;
      > * {
        transform: none;
        top: auto;
        position: relative;
      }
    }
  }
}

.s-tml-partner-home--title-cb-available {
  // "Ich bin anwesend" Checkbox

}

.s-tml-partner-home--title-available-from {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.s-tml-partner-home--process-one-button-layout {
  display: flex;

  >:nth-child(1) {
    flex: 1;
    margin-right: 7px;
  }

  .c-button--content {
    text-align: center;
    display: block;
  }
}

.s-tml-partner-home--process-two-button-layout {
  display: flex;

  >:nth-child(1) {
    flex: 1;
    margin-right: 7px;
  }
  >:nth-child(2) {
    flex: 1;
    margin-left: 7px;
  }

  .c-button--content {
    text-align: center;
    display: block;
  }
}

.s-tml-partner-home--process-three-button-layout {
  display: flex;

  >:nth-child(1) {
    flex: 1;
    margin-right: 7px;
  }
  >:nth-child(2) {
    flex: 1;
  }
  >:nth-child(3) {
    flex: 1;
    margin-left: 7px;
  }

  .c-button--content {
    text-align: center;
    display: block;
  }
}

.s-tml-partner-home--process-topline {
  margin-bottom: 16px;
  font-size: var(--sco-font-size-headline5);
}

.s-tml-partner-home--process-bottomline {
  padding-top: 16px;
  padding-bottom: 4px;
  font-size: var(--sco-font-size-headline5);
}

.s-tml-partner-home--cardheader {
  margin: -15px -15px 20px -15px;
  background: var(--sco-color-grey-100);
  position: relative;
}

.s-tml-partner-home--cardheader-name {
  font-size: $fontsize-heading-20;
  padding: 15px;
  float: left;
}
.s-tml-partner-home--cardheader-progress {
  padding-top: 5px;
  padding-right: 30px;
  padding-left: 40px;
  float: right;
  min-width: 405px;



    // Der Tracker ist etwa 400 Pixel breit und soll maximal die Hälfte Platz einnehmen. Äußerer Rand sind 70px.
    @media(max-width: 870px) {
      .c-progress-tracker {
        transform: scale(0.8);
        transform-origin: right

      }
    }

    @media(max-width: 720px) {
      float: none;
      clear: both;
      padding-top: 0px;
      padding-bottom: 10px;
      padding-right: 0px;
      margin: 0px -100px 0px 0px;
      .c-progress-tracker {
        transform: scale(0.8);
        transform-origin: left;
      }
    }

}
//Reparatur hat nur 3 Schritte
.s-tml-partner-home--cardheader-progres-reparatur {
  min-width: 315px;
}

.s-tml-partner-home--content {
  padding-left: 15px;
  padding-right: 15px;

  margin-bottom: -15px;
  > .o-col {
    margin-bottom: 15px;
  }

}

.s-tml-partner-home--datalist {
  margin-left: 20px;

  @include mobile() {
    margin-left: 0px;
  }
}

.s-tml-partner-home--lead-date {
  margin-bottom: 15px;
  font-size: var(--sco-font-size-headline5);
}

.s-tml-partner-home--lead-summary {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: var(--sco-font-size-headline5);
}

.s-tml-partner-home--lead-contact-heading {
  margin-bottom: 16px;
  font-size: var(--sco-font-size-headline5);
}

.s-tml-partner-home--verantwortlicher-heading {
  margin-bottom: 0;
  font-size: var(--sco-font-size-headline5);
}

.s-tml-partner-home--lead-phone {
  margin: 0 0;
  font-size: var(--sco-font-size-headline5);

  .textbold {
    font-weight: bold;
  }
}

.s-tml-partner-home--lead-email {
  margin: 0px 0px;
  font-size: var(--sco-font-size-headline5)x;

  .textbold {
    font-weight: bold;
  }
}

.s-tml-partner-home--verantwortlicher {
  margin-top: 25px;

  .s-tml-partner-home--verantwortlicher-button {
    transform: scaleX(0.7) scaleY(0.7);
    -webkit-transform: scaleX(0.7) scaleY(0.7);
  }
}

.s-tml-partner-home--lead-distance {
  margin: 0px 0px;
  font-size: var(--sco-font-size-headline5);
}

.s-tml-partner-home--lead-address {
  margin: 0px 0px;
  font-size: var(--sco-font-size-headline5);
}

.s-tml-partner-home--filters-search {
  position: absolute;
  right: 30px;
  bottom: 9px;

  @include mobile() {
    margin-top: 15px;
    position: relative;
    flex: 0 0 100%;
    display: block;
    right: auto;
    bottom: auto;

  }

}



/* Lead Detail Ansicht */
$s-tml--lead-title-size: 28px;

.s-tml-show {

}

.s-tml-lead-show--summary {
  padding-left: 30px;
}

.s-tml-lead-show--section {
  display: flex;
  margin-top: 15px;
}

.s-tml-lead-show--section-icon {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 30px;
  position: relative;

  .c-icon {
    position: absolute;
    top: 9px;

    &:before {
      font-size: var(--sco-font-size-headline4);
    }
  }
}

.s-tml-lead-show--section-section {
  flex-grow: 1;
  width: 100%;

  .c-section--heading {
    height: 35px;
  }

  &.c-section.c-section--folded > .c-section--heading {
    background: var(--sco-color-grey-400);
    color: var(--sco-color-white);

    &:hover {
      background: var(--sco-color-grey-600);
    }
  }

  > .c-section--content > .c-card {
    margin: 10px 0px 17px 0px;
  }
}

.s-tml-lead-show--attachments {
  .c-buttonpanel {
    margin: 15px 0px 15px 0px;
  }
  .c-buttonpanel--container button {
    margin-right: 5px;
  }
  .c-section--content {
    margin-left: 30px;
  }
}

.s-tml-lead-show--details .c-section--content {
  margin-left: 30px;
}

/* Prozess Einträge */
#s-tml-lead-show--history-partner { // Liste
  margin-top: 15px;
  margin-left: 30px;

  @include smaller-than-desktop {
    margin-left: 5px;
  }
}

.s-tml-lead-show--history-item {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
  padding-left: 30px;
}
.s-tml-lead-show--history-item-left {
  flex: 0;
  flex-basis: 55px;
  padding-right: 15px;
}
.s-tml-lead-show--history-item-right {
  flex: 1;
}
.s-tml-lead-show--history-item-head {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}
.s-tml-lead-show--history-item-head-title {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 200px;
  background: var(--sco-color-grey-400);
  color: var(--sco-color-white);
  line-height: 1;
  height: 35px;

  display: flex;
  align-items: center;

  > span {
    display: block;
    padding-left: 15px;
    flex: 1 0 300px;
  }
  .s-tml-lead-show--history-item-head-wiedervorlage {
    flex: 0;
    padding-right: 15px;
    white-space: nowrap;
  }
}

.s-tml-lead-show--history-item-head-action {
  flex: 0;
  flex-basis: 35px;
  padding-left: 15px;

  .c-button--iconbutton {
    height: 35px;
    width: 35px;
    min-width: 35px;
  }
  .c-button {
    height: 35px;
  }
}

.s-tml-lead-show--history-item-content {
  padding: 15px;
}

.s-tml-lead-show--history-partner-section {
  margin-bottom: 15px;

  .c-section--heading {
    position: relative;
    padding: 25px 45px 25px 15px;

    //Falls der Kunde im Titel verlinkt ist, dann optisch analog zu normaler Ueberschrift
    .c-section--title a {
      color: white;
    }

    @include mobile() {
      padding: 25px 45px 25px 15px;
    }

    .c-progress-tracker {
      position: absolute;
      right: 72px;
      top: calc(50% + 5px);
      transform: translateY(-50%);
      padding-bottom: 0px;


      @include mobile() {
        display: none;
      }
    }
    .c-progress-tracker-3-steps {
      right: 65px;
    }
  }

  //Falls der Kunde im Titel verlinkt ist, dann optisch analog zu normaler Ueberschrift
  //&.c-section--folded .c-section--heading  .c-section--title a {
  //  color: var(--sco-color-grey-700);
  //}
}

.s-tml-lead-show--comment-btn-with-comment {
  position: relative;
  &:after {
    width: 18px;
    height: 18px;
    content:'!';
    font-weight: bold;
    color: white;
    font-size: var(--sco-font-size-headline6);
    text-align: center;
    display: block;
    position: absolute;
    top:-8px;
    right:-8px;
    background: var(--sco-color-orange-200);
    border-radius: 9px;
    line-height: 18px;
    vertical-align: middle;
  }
}

.s-tml-partner-assign--aspdialog {
  width: 100%;
  th, td {
    padding-bottom: 5px;
    vertical-align: top;
    text-align: left;
  }
  .c-card--header-title {
    text-align: left;
  }
}

#s-tml-partner-home-dlg-settings-form {

  text-align: left;

  .tml-settings-button-panel {
    text-align: center;
  }

  .o-row.lastInSection {
    margin-bottom: 35px;
  }

  .c-dialog--heading.wenigerAbstand {
    margin-bottom: 0;
  }

  label.abstandLinks {
    padding-left: 10px;
  }
}

.tml-klaerfall-ticket-dialog {
  text-align: left;

  .tml-klaerfall-ticket-dialog--title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .o-row {
    margin-bottom: 15px;
  }

  form {
    margin-bottom: 20px;
  }
}


#s-tml-settings-verantwortlicher-spinner {
  padding-top: 20px;
  display: none;
}

@media print {
  * { overflow: visible !important; }

  button {
    visibility: hidden;
  }

  .c-button {
    visibility: hidden;
  }

  ::after {
    visibility: hidden;
  }

  .s-tml-lead-show--section {
    page-break-inside : avoid;
    border-right: 1px solid gray;
  }

  .c-progress-tracker {
    visibility: visible !important;
    transform: none;
    width: 100%;
    padding-bottom: 100%;
  }

}

.s-tml-partner-assign {

  #button-partner-bulk-add {
    margin-left: 35px;
  }

  .partnerZusatzinfo {
    color: #78b928;
  }

  td.buttonColumn{
    white-space: nowrap;

    .o-col {
      display: inline-block;

      .c-checkbox {
        height: 25px;
        top: 7px;
      }
    }
  }

}