.s-kunde-dashboard {
    .s-kunde-dashboard--grid-layout {
        justify-content: flex-start;
    }

    .c-dashboard-flex-tile {
        min-height: unset;

        @include number-of-dashboard-tiles-per-row(2);

        @include mobile {
            @include number-of-dashboard-tiles-per-row(1);
        }

        @include tabletPortrait {
            @include number-of-dashboard-tiles-per-row(1);
        }

        @include tabletLandscape {
            @include number-of-dashboard-tiles-per-row(2);
        }

        @include wider-than-desktop {
            @include number-of-dashboard-tiles-per-row(4);
        }
    }

    .c-card--header-compressed .c-card--header-title {
        padding: 0 16px 0 0;
    }

    .s-kunde-dashboard-settings-box-cards {
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 2px rgba(0, 0, 0, 0.12);
        border-radius: 2px;
        margin-bottom: 10px;

        .c-sortable-item {
            display: flex;
            background: var(--sco-color-white);
        }
    }
    .s-kunde-dashboard-settings-box-card-left {
        flex: 1;
    }
    .s-kunde-dashboard-settings-box-card-right {
        flex: 0;
        flex-basis: 30px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .s-kunde-dashboard--legend-text {
        font-size: var(--sco-font-size-paragraphsmall);
        font-family: var(--sco-font-family-lato-light);
        color: var(--sco-color-anthracite-200);
    }

    .s-kunde-dashboard--label-text {
        font-size: var(--sco-font-size-headline5);
        font-family: var(--sco-font-family-lato-light);
        text-align: center;
    }

    // #################################################################################################################
    // Styles der Kachel Vertriebsdetails
    // #################################################################################################################
    .s-kunde-dashboard-card-vertriebsdetails {
        margin-bottom: 16px;
    }

    .s-kunde-dashboard-card-vertriebsdetails--einkaufsvolumen-trend-icon i {
        cursor: pointer;
    }

    .s-kunde-dashboard-card-vertriebsdetails--einkaufsvolumen-box {
        display: inline;
        float: left;
        width: 50%;
        height: 30%;
    }

    .s-kunde-dashboard-card-vertriebsdetails--einkaufsvolumen {
        border-right: var(--sco-color-anthracite-300) solid 2px;
    }

    .s-kunde-dashboard-card-vertriebsdetails--einkaufsvolumen-label {
        @extend .s-kunde-dashboard--label-text;
        padding-top: 10px;
        padding-bottom: 50px;
        height: 40px;
    }

    .s-kunde-dashboard-card-vertriebsdetails--einkaufsvolumen-value {
        @extend .s-kunde-dashboard--label-text;
        font-weight: bold;
        color: var(--sco-color-primary);
    }

    .s-kunde-dashboard-card-vertriebsdetails--einkaufsvolumen-trend-icon {
        color: var(--sco-color-primary);
        margin-left: 48%;

        .trend-icon {
            font-size: var(--sco-font-size-headline2);
        }
    }

    .s-kunde-dashboard-card-vertriebsdetails--div-left {
        height: 100%;
        width:50%;
        border-right: 1px solid var(--sco-color-anthracite-400);
        float: left;
    }

    .s-kunde-dashboard-card-vertriebsdetails--div-inner {
        height: 50%;
    }

    .s-kunde-dashboard-card-vertriebsdetails--div-right {
        height: 100%;
        width:50%;
        float: left;
    }

    .s-kunde-dashboard-card-vertriebsdetails--label-text {
        @extend .s-kunde-dashboard--label-text;
        padding-top: 3em;
    }

    .s-kunde-dashboard-card-vertriebsdetails--value-text {
        @extend .s-kunde-dashboard--label-text;
        font-weight: bold;
        color: var(--sco-color-primary);
    }

    .s-kunde-dashboard-card-vertriebsdetails--klassifizierung-value-text {
        @extend .s-kunde-dashboard-card-vertriebsdetails--value-text;
        font-size: var(--sco-font-size-headline4);
        color: $color-blue--default;
    }


    // #################################################################################################################
    // Styles der Kachel Besuche
    // #################################################################################################################
    #s-kunde-kundedashboard--card-BESUCHE .c-card--content {
        padding: 0;
    }
    .s-kunde-dashboard-card-besuche--div-left {
        height: 100%;
        width: calc(50% - 1px);
        //border-right: 1px solid var(--sco-color-anthracite-400);
        float: left;
        padding: 10px 15px 10px 0;
    }

    .s-kunde-dashboard-card-besuche--div-mid {
        height: calc(100% - 20px);
        float: left;
        margin-top: 10px;
        width: 1px;
        background: var(--sco-color-anthracite-400);
    }

    .s-kunde-dashboard-card-besuche--div-right {
        height: 100%;
        width:50%;
        float: left;
        padding: 10px 0 10px 15px;

        display: flex;
        flex-direction: column;
        justify-content: stretch;

        &:hover {
            background: var(--sco-color-grey-020);
            cursor: pointer;
        }
    }
    .s-kunde-dashboard-card-besuche--div-right-t,
    .s-kunde-dashboard-card-besuche--div-right-b {
        flex: 1;
    }

    .s-kunde-dashboard-card-besuche--chart-besuche {
        stroke: var(--sco-color-orange-200);
        display: block;
        margin: 10px auto;
        max-width: 80%;
        max-height: 250px;
    }

    .s-kunde-dashboard-card-besuche--chart-besuche-circle-bg {
        fill: none;
        stroke: var(--sco-color-grey-100);
        stroke-width: 1px;
    }

    .s-kunde-dashboard-card-besuche--chart-besuche-circle {
        fill: none;
        stroke-width: 2px;
        stroke: $color-green--light;
        stroke-linecap: round;
        animation: s-kunde-dashboard-card-besuche--chart-besuche-progress 1s ease-out forwards;
    }

    @keyframes s-kunde-dashboard-card-besuche--chart-besuche-progress {
        0% {
            stroke-dasharray: 0 100;
        }
    }

    .s-kunde-dashboard-card-besuche--chart-besuche-percentage {
        fill: #666;
        font-family: "Font Awesome 6 Pro", Arial;
        font-size: 0.5em;
        text-anchor: middle;
        stroke: $color-black--100;
        transform: rotate(315deg);
    }

    .s-kunde-dashboard-card-besuche--anzahl-besuche {
        @extend .s-kunde-dashboard-card-vertriebsdetails--value-text;
        padding-top: 20px;
        font-size: var(--sco-font-size-headline2);
        color: $color-blue--default;
    }

    .s-kunde-dashboard-card-besuche--trend-icon {
        font-size: var(--sco-font-size-headline2);
    }

    .s-kunde-dashboard-card-besuche--label-text {
        @extend .s-kunde-dashboard--label-text;
        padding-top: 0.8em;
    }

    .s-kunde-dashboard-card-besuche--calendarIcon {
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .s-kunde-dashboard-card-besuche--nodata {
        text-align: center;
        padding-top: 20px;
    }
    .s-kunde-dashboard-card-besuche-lastBesuchOpen {
        text-align: center;
        color: var(--sco-color-red-600);
    }

    // #################################################################################################################
    // Styles der Kachel Entwicklung
    // #################################################################################################################

    #s-kunde-kundedashboard--card-ENTWICKLUNG .c-card--content {
        padding: 0
    }

    #s-kunde-kundedashboard--card-ENTWICKLUNG .s-kunde-dashboard--label-text{
        margin-bottom: 5px;
        //text-align: right;
        //font-size: 1em;
    }

    .s-kunde-dashboard-card-entwicklung--nodata {
        text-align: center;
        padding-top: 20px;
    }

    // #################################################################################################################
    // Styles der Kachel Sortiment
    // #################################################################################################################

    td.sortiment-entry {
        padding-left: 10px;
        border-right: 1px solid var(--sco-color-grey-600);
        vertical-align: top;
    }

    // #################################################################################################################
    // Styles der Kachel Vertriebskennzahlen
    // #################################################################################################################

    #s-kunde-kundedashboard--card-VERTRIEBSKENNZAHLEN {
        .c-card--content {
            padding-left: 16px;
        }

        .vertriebskennzahlen--content-top {
            display: grid;
            grid-template-columns: min-content 1fr;
            grid-template-rows: min-content auto min-content;
            row-gap: 16px;

/*
            +---+-------------+
            |   |             |
            |   |             |
            +-----------------+
            |   |             |
            |   |             |
            |   |             |
            |   |             |
            |   |             |
            |   |             |
            +-----------------+
            |   |             |
            +---+-------------+
*/

            table {
                margin-bottom: 0;
                flex: 2
            }

            td {
                padding: var(--sco-spacing-xs) var(--sco-spacing-s)
            }

            td:first-child {
                text-align: left;
                padding-left: var(--sco-spacing-l);

                @include screen-xs {
                    padding-left: var(--sco-spacing-xs);
                }

                @include screen-s {
                    padding-left: var(--sco-spacing-s);
                }

                @include screen-m {
                    padding-left: var(--sco-spacing-m);
                }
                @include screen-l {
                    padding-left: var(--sco-spacing-m);
                }
                @include screen-xl {
                    padding-left: var(--sco-spacing-l);
                }

            }

            .trend {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;

                .trend-indicator {
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    justify-content: center;
                    gap: 5px;
                    height: 100px;
                    width: 100px;
                    border-radius: 50px;
                    font-size: var(--sco-font-size-headline3);
                    line-height: initial;
                    border: solid 1px $color-green--default;
                    color: var(--sco-color-primary);
                    margin-bottom: 8px;
                }
                .fa-arrow-right {
                    color: var(--sco-color-primary);
                }
                &.down {
                    .trend-indicator {
                        border-color: var(--sco-color-red-600);
                        color: var(--sco-color-red-600);
                    }
                    .fa-arrow-right {
                        color: var(--sco-color-red-600);
                        transform: rotate(90deg);
                    }
                }
                &.up .fa-arrow-right {
                    transform: rotate(-90deg);
                }
            }


            .indicator-container {
                grid-area: 1 / 1 / 2 / 3;
                #indicator-digitaler-umsatz .trend-indicator {
                    cursor: pointer;
                }
            }

            // TODO: Erst nach dem GoLive Home Branch wird das Grid mehr Spalten haben, dann kann man nochmals genauer justieren

            .vertriebskennzahlen--ist-umsatz-und-auftragsbestand-container {
                grid-area: 2 / 1 / 3 / 2;

                @include screen-xs {grid-area: 2 / 1 / 3 / 3;}
                @include screen-s {grid-area: 2 / 1 / 3 / 3;}
                @include screen-m {grid-area: 2 / 1 / 3 / 3;}
                @include screen-l {grid-area: 2 / 1 / 3 / 3;}
                @include screen-xl {grid-area: 2 / 1 / 3 / 3;}
                @include screen-xl {grid-area: 2 / 1 / 3 / 3;}
                @include screen-xxl {grid-area: 2 / 1 / 3 / 3;}
            }

            .vertriebskennzahlen--allgemein {
                grid-area: 2 / 2 / 3 / 3;

                @include screen-xs {grid-area: 3 / 1 / 4 / 3;}
                @include screen-s {grid-area: 3 / 1 / 4 / 3;}
                @include screen-m {grid-area: 3 / 1 / 4 / 3;}
                @include screen-l {grid-area: 3 / 1 / 4 / 3;}
                @include screen-xl {grid-area: 3 / 1 / 4 / 3;}
                @include screen-xl {grid-area: 3 / 1 / 4 / 3;}
                @include screen-xxl {grid-area: 3 / 1 / 4 / 3;}

            }

            .umsatzvergleich {
                grid-area: 3 / 1 / 4 / 2;

                @include screen-xs {grid-area: 4 / 1 / 5 / 3;}
                @include screen-s {grid-area: 4 / 1 / 5 / 3;}
                @include screen-m {grid-area: 4 / 1 / 5 / 3;}
                @include screen-l {grid-area: 4 / 1 / 5 / 3;}
                @include screen-xl {grid-area: 4 / 1 / 5 / 3;}
                @include screen-xl {grid-area: 4 / 1 / 5 / 3;}
                @include screen-xxl {grid-area: 4 / 1 / 5 / 3;}

            }
        }

        .umsatzvergleich {
            border: 2px solid $color-green--default;
            border-radius: 8px;
            padding: var(--sco-spacing-s);
            margin-left: var(--sco-spacing-s);
        }

    }

    // #################################################################################################################
    // Styles der Kachel Konditionen
    // #################################################################################################################

    #s-kunde-kundedashboard--card-KONDITIONEN {
        @include number-of-dashboard-tiles-per-row(1);
        overflow: auto;

        table button.c-button--textbutton {
            padding: 0;
            min-width: 4ch;
        }
        .c-card--header-wrapper-right {
            align-items: center;
        }
        #projektuebersichtdashboard-kunde-card-dialog-button::before {
            font-size: var(--sco-font-size-headline3);
        }
        .legend-marker-auslaufende-kondition {
            display: inline-block;
            background-color: var(--sco-color-orange-200);
            height: 1.3em;
            width: 1.3em;
            vertical-align: top;
        }
        span.auslaufende-kondition {
            color: var(--sco-color-orange-200);
        }
    }

    // #################################################################################################################
    // Styles der Kachel Produktsortiment
    // #################################################################################################################

    #s-kunde-kundedashboard--card-PRODUKTSORTIMENT {
        .c-card--content {
            padding-left: 0;
            padding-right: 0;
        }
        .produktsortiment {
            display: flex;
            flex-direction: column;

            &--top {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                flex-grow: 1;

                & > div {
                    padding: 0 15px;

                    &:nth-child(odd) {
                        background-color: var(--sco-color-grey-100);
                    }
                }

                & > div:last-child {
                    background-color: var(--sco-color-white);
                    border-top: solid 1px var(--sco-color-grey-230);
                    padding-top: 5px;
                }

                h3 {
                    font-family: var(--sco-font-family-lato-bold);
                    font-size: var(--sco-font-size-paragraph);
                    text-align: left;
                    margin-bottom: 5px;
                    padding-left: 15px;
                }
            }
            &--bottom {
                display: flex;
                justify-content: space-evenly;

                & > div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                h3 {
                    font-family: var(--sco-font-family-lato-bold);
                    font-size: var(--sco-font-size-paragraph);
                    text-align: center;
                    margin-bottom: 5px;
                }
            }
            .sortiment-kategorie {
                display: flex;
                align-items: center;
                height: 5em;

                .c-icon {
                    font-size: var(--sco-font-size-headline3);
                }

                .c-trendIcon--icon {
                    font-size: var(--sco-font-size-headline4);
                    margin-right: 16px;
                }

                .warning {
                    color: var(--sco-color-red-600);
                }
            }

            a.maschinen.icon {
                background-image: url("../assets/cps-icons/src/kunde/dashboard/robot-arm.svg");
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                width: 60px;
                height: 60px;
            }
            .maschinenkonto-button {
                cursor: default;
                color: var(--sco-color-white);
                background-color: var(--sco-color-primary);
                width: 20px;
                min-width: 20px;
                height: 20px;
                border-radius: 10px;
                top: -20px;
                left: -5px;
                &:before {
                    font-size: var(--sco-font-size-headline6);
                }

                &:hover {
                    background-color: var(--sco-color-primary);
                    color: var(--sco-color-white);
                }
                &.maschinenchance-button-abgelaufene {
                    background-color: var(--sco-color-red-600);
                }

                &.maschinenchance-button-offeneVorhanden {
                    background-color: var(--sco-color-orange-200);
                }
            }

            a.software.icon {
                background-image: url("../assets/cps-icons/src/kunde/dashboard/software.svg");
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                width: 60px;
                height: 60px;
            }
            span.chancen-icon {
                color: var(--sco-color-white);
                background-color: var(--sco-color-primary);
                height: 1.5em;
                width: 1.2em;
                text-align: center;
                line-height: 1.6em;
                &.offene-chancen {
                    color: var(--sco-color-red-600);
                }
            }

            .fa-award {
                width: 48px;
                height: 48px;
                border-radius: 25px;
            }

            .fa-award:before {
                font-size: var(--sco-font-size-headline1);
            }
        }
    }

    // #################################################################################################################
    // Styles der Kachel Projektübersicht
    // #################################################################################################################

    #s-kunde-kundedashboard--card-PROJEKTUEBERSICHT {
        #show-details {
            background-color: var(--sco-color-white);
            color: var(--sco-color-primary);
            padding: 5px 15px;
            border-radius: 20px;
            height: 40px;
            width: fit-content;
            text-align: start;
            .fa-stack {
                padding: 0;
                margin: 0;
                font-size: var(--sco-font-size-headline5);
            }
        }
        .projektuebersicht {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            .forecast a {
                color: var(--sco-color-primary);

                &:before {
                    font-family: "Font Awesome 6 Pro";
                    font-size: var(--sco-font-size-headline4);
                    font-weight: 900;
                    content: "\f105 ";
                }
            }

            div.hit-rate {
                display: flex;
                flex-direction: column;
                text-align: center;
                flex-grow: 1;
                justify-content: center;

                div.hit-rate-lines {
                    display: flex;
                    flex-direction: column;
                    gap: 40px;

                    div.hit-rate-line {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 8px;

                        .c-icon {
                            color: var(--sco-color-primary);
                            font-size: var(--sco-font-size-headline1);
                        }

                        .fa-stack {
                            color: var(--sco-color-primary);
                            font-size: var(--sco-font-size-headline3);

                            .fa-person {
                                top: -10px;
                                left: -10px;
                            }

                            .fa-person-dress {
                                top: -23px;
                                left: 3px;
                            }
                        }

                        div.hit-rate-percent {
                            display: flex;
                            align-content: center;
                            justify-content: center;
                            flex-wrap: wrap;
                            font-size: var(--sco-font-size-headline2);
                            height: 80px;
                            width: 80px;
                            border-radius: 40px;
                            border: solid 1px $color-green--default;
                            color: var(--sco-color-primary);
                        }

                        div.hit-rate-text {
                            white-space: nowrap;
                        }

                        &.kunde {
                            div.hit-rate-percent {
                                border: solid 1px $color-text--gray-dark;
                                color: $color-text--gray-dark;
                            }
                        }
                    }
                }
            }
        }
        .quote_projektumsatz {
            width: 100%;
            table th, td:not(:first-child){
                text-align: center;
            }
        }
    }
}

// #################################################################################################################
// Styles fuer den Info Content
// #################################################################################################################
.s-kunde-dashboard--info-content {

    text-align: left;

    .s-kunde-dashboard--info-partner {
        min-height: 2em;
        font-size: var(--sco-font-size-headline5);
    }

    .s-kunde-dashboard--info-address {
        min-height: 2em;
        font-size: var(--sco-font-size-headline5);
    }

    .s-kunde-dashboard--info-phone {
        min-height: 2em;
        font-size: var(--sco-font-size-headline5);
    }

    .s-kunde-dashboard--info-email {
        min-height: 2em;
        font-size: var(--sco-font-size-headline5);
    }

    .s-kunde-dashboard--info-website {
        min-height: 2em;
        font-size: var(--sco-font-size-headline5);
    }

    .s-kunde-dashboard--info-position {
        min-height: 1.2em;
        font-size: var(--sco-font-size-headline5);
        color: $color-orange--light;
        font-weight: bold;
    }

    .s-kunde-dashboard--info-value {
        min-height: 1.2em;
        font-size: var(--sco-font-size-headline5);
    }

    .s-kunde-dashboard--info-property-name {
        font-size: var(--sco-font-size-headline5);
        font-weight: bold;
    }

    .s-kunde-dashboard--info-property-value {
        font-size: var(--sco-font-size-headline5);
    }
}

.s-kunde-dashboard-card-vertriebskennzahlen--dialog-kundevtwinfo {
    text-align: left;

    .s-kunde-dashboard-card-vertriebskennzahlen--kreditinfo {
        color: $color-text--gray;
    }

    table {
        tr.new-section {
            border-top: solid 1px var(--sco-color-grey-300);
            td {
                margin-top: var(--sco-spacing-xs);
            }
        }
        th {
            font-family: var(--sco-font-family-lato-bold);
            font-size: var(--sco-font-size-headline5);
            padding: var(--sco-spacing-xs) var(--sco-spacing-s);
            text-align: right;
        }
        th.delta {
            width: 8ch;
            color: var(--sco-color-primary);
            text-align: center;
        }
        td {
            text-align: left;
            padding: var(--sco-spacing-xs) var(--sco-spacing-s)
        }
        td:first-child {
            text-align: left;
            padding: var(--sco-spacing-xs) 0
        }
        td:not(:first-child) {
            text-align: right;
        }
        td.delta {
            width: 8ch;
            font-size: var(--sco-font-size-headline5);
            white-space: nowrap;
            &.positive-value {
                color: var(--sco-color-primary);
            }
            &.negative-value {
                color: var(--sco-color-red-600);
            }
            &.equal-value {
                color: var(--sco-color-orange-200);
            }
        }
        tr.mahnstufe td {
            position: relative;
            .c-info-tool-tip {
                right: var(--sco-spacing-s);
            }
        }
    }
    .trend-hinweis {
        font-size: 12px;
    }

}

.s-kunde-dashboard-card-projektuebersicht--dialog-projekt-details {
    display: flex;
    flex-direction: column;

    .details-top {
        display: flex;
        justify-content: space-around;
        border-bottom: solid 2px var(--sco-color-grey-100);
        padding: 30px 0;

        h3 {
            text-align: center;
            font-size: 1.3em;
            margin-bottom: 12px;
        }

        .value {
            color: var(--sco-color-primary);
            font-size: var(--sco-font-size-headline4);
            font-family: var(--sco-font-family-lato-bold);
        }
    }

    .details-bottom {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
    }

    .details-left {
        display: flex;
        flex-direction: column;
        border-right: solid 2px var(--sco-color-grey-100);

        .ausschreibungen {
            border-bottom: solid 2px var(--sco-color-grey-100);
        }
        .ausschreibungen, .wohnbau {
            padding: 15px 0;
        }
    }
    .gebaeudeklassen {
        display: flex;
        justify-content: center;
        flex-grow: 1;
        min-height: 300px;
    }

    @media screen and (max-width: 1170px) {
        .details-left {
            border-right: none;
            flex: 1;
        }
        .wohnbau {
            border-bottom: solid 2px var(--sco-color-grey-100);
        }
    }
}
// #################################################################################################################
// Styles fuer Popups der Kachel "Konditionen"
// #################################################################################################################
.konditionen--popup {
    text-align: left;
    strong {
        font-family: var(--sco-font-family-lato-bold);
    }
}

// #################################################################################################################
// Styles der Kachel Tickets
// #################################################################################################################

#s-kunde-kundedashboard--card-TICKETS {
    @include number-of-dashboard-tiles-per-row(1);
    overflow: auto;
    min-height: 290px;

    table button.c-button--textbutton {
        padding: 0;
        min-width: 4ch;
    }
    .c-card--header-wrapper-right {
        align-items: center;
    }
}