.s-projekt-produktgruppen-systeme {

    .s-projekt-produktgruppen-systeme--title-panel {
        margin-bottom: 10px;
    }

    .produktkategorien-tile-section--hidden {
        .c-section--heading {
            display: none;
        }
    }

    .s-projekt-produktgruppen-systeme-card-TILES {
        .s-projekt-produktgruppen-systeme--select-vtw-col {
            display: none;
        }

        .s-projekt-create-produktgruppen-systeme--produktkategorien-tile-container {
            --grid-cols: 6;

            @include screen-xs {
                font-size: var(--sco-font-size-xs-paragraph);
                --grid-cols: 1;
                --grid-gap: 12px;
                --grid-cols-1-span: 4;
                --single-values-per-col: 1;
            }

            @include screen-s {
                font-size: var(--sco-font-size-s-paragraph);
                --grid-cols: 1;
                --grid-gap: 16px;
                --grid-cols-1-span: 8;
                --single-values-per-col: 1;
            }

            @include screen-m {
                font-size: var(--sco-font-size-m-paragraph);
                --grid-cols: 2;
                --grid-gap: 16px;
                --grid-cols-1-span: 4;
                --single-values-per-col: 2;
            }

            @include screen-l {
                font-size: var(--sco-font-size-l-paragraph);
                --grid-cols: 3;
                grid-gap: 16px;
                --single-values-per-col: 3;
            }

            @include screen-xl {
                font-size: var(--sco-font-size-xl-paragraph);
                --grid-cols: 4;
                --grid-gap: 24px;
                --single-values-per-col: 4;
            }

            @include screen-xxl {
                font-size: var(--sco-font-size-xxl-paragraph);
                --grid-cols: 6;
                --grid-gap: 24px;
                --single-values-per-col: 6;
            }
        }
    }



    .produktgruppen-systeme-grid {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 1fr 1fr 1fr;

        #produktgruppen-suche-container, #systeme-suche-container {
            min-height: 55px;
        }

        .vtw-link {
            .vtw-item {
                min-height: 52px;
                @include s-projekt-produktgruppen-systeme--item;

                &.vtw-item {
                    background: var(--sco-color-grey-700);
                    border-color: var(--sco-color-grey-700);
                }

                .vtw-name {
                    @include s-projekt-produktgruppen-systeme--item-name;
                    color: var(--sco-color-white);
                }
            }
        }

        .produktkategorie-link {
            .produktkategorie-item {
                min-height: 52px;
                @include s-projekt-produktgruppen-systeme--item;

                .produktkategorie-name {
                    @include s-projekt-produktgruppen-systeme--item-name;
                }
            }

            &.highlight-produktkategorie .produktkategorie-item {
                @include s-projekt-produktgruppen-systeme--highlight-item;
            }
        }

        .produktgruppen-systeme-link {
            @include s-projekt-produktgruppen-systeme--produktgruppen-systeme-link;
        }
    }

    #favoriten-link {
        .produktkategorie-item {
            min-height: 52px;
            @include s-projekt-produktgruppen-systeme--item;

            color: var(--sco-color-white);
            background-color: var(--sco-color-primary);

            .produktkategorie-name {
                @include s-projekt-produktgruppen-systeme--item-name;
            }

            &:focus {
                background-color: var(--sco-color-primary);
                color: var(--sco-color-white);
            }

            &:hover {
                background-color: var(--sco-color-green-800);
                color: var(--sco-color-white);
            }

            &:active {
                background-color: var(--sco-color-green-500);
                color: var(--sco-color-white);
            }

            .produktkategorie-name i {
                margin-right: 8px;
            }
        }
    }

    .s-projekt-produktgruppen-systeme-card-TILES .produktkategorien-tile-section:first-of-type {
        margin-top: 12px;
    }

    .produktgruppen-systeme-dialog {
        #produktgruppen-systeme-dialog--title-grid {
            display: grid;
            grid-gap: 8px;
            grid-template-columns: 1fr 2fr 1fr;

            #s-projekt-create--produktgruppen-systeme-dialog--save-button {
                float: right;
            }
        }

        #produktgruppen-systeme-dialog--grid {
            display: grid;
            grid-gap: 8px;

            #produktgruppen-list, #systeme-list {
                overflow-y: auto;
                height: 600px;

                .produktgruppen-systeme-link {
                    @include s-projekt-produktgruppen-systeme--produktgruppen-systeme-link;
                }
            }
        }
    }

    #s-projekt-produktgruppen-systeme--produktgruppen-systeme-search-dialog--title-grid {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: auto min-content;
    }

    #s-projekt-produktgruppen-systeme--produktgruppen-systeme-search-dialog--produktgruppen-systeme-search-results {
        #produktgruppenSystemeSearchResults {
            td {
                height: unset;
            }
            tr.produktgruppe-system-vorhanden {
                background: var(--sco-color-primary);
            }
        }
    }

    #s-projekt-produktgruppen-systeme--produktgruppen-systeme-suche-col .o-col-inner {
        display: flex;

        :first-child {
            flex-grow: 1;
        }
    }

    #zeitschiene-table {
        input.c-input--text.highlight-automatic:not(.invalid), input.MuiInputBase-input.highlight-automatic:not(.invalid) {
            background-color: #e2f8ff;
        }
    }

    .c-card--header-wrapper-left {
        min-width: 0;
    }

    .c-card--header-wrapper-right {
        flex-grow: 0;
    }
}