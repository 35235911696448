.s-projekt-aftersales-dashboard {
    .s-projekt-aftersales--grid-tile {
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: calc(50% - 10px);
        min-height: 0;

        &.c-card--with-content-scrollable {
            min-height: 300px;
        }

        @include mobile {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: 100%;
        }

        @include tabletPortrait {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: calc(50% - 10px);
        }

        @include tabletLandscape {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: calc(50% - 10px);
        }

        @include wider-than-desktop {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: calc(50% - 10px);
        }
    }
}

.s-projekt-aftersales {

    .s-projekt-aftersales--button-tile {
        flex-grow: 0;
        height: 100px;

        @include mobile {
            flex-basis: 100%;
        }

        @include tabletPortrait {
            flex-basis: calc(50% - 10px);
        }

        @include tabletLandscape {
            flex-basis: calc(33% - 10px);
        }

        @include wider-than-desktop {
            flex-basis: calc(25% - 10px);
        }
    }

    .s-projekt-aftersales--project-tile {

        @include mobile {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: 100%;
        }

        @include tabletPortrait {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: calc(50% - 10px);
            height: 440px;
        }

        @include tabletLandscape {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: calc(33% - 10px);
            height: 440px;
        }

        @include wider-than-desktop {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: calc(25% - 10px);
        }

    }

    .c-card--header {
        min-height: 45px;
    }

    .s-projekt-aftersales-card-background-no-project {
        background: var(--sco-color-grey-100);
        font-size: var(--sco-font-size-headline2);
        width: 100%;
        height: 100%;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 330px;
    }

    .s-projekt-aftersales--grid-layout-table {
        justify-content: flex-start;

        .simple-div-table--table {
            display: table;
            width: 100%;
            border: 0;
        }

        .simple-div-table--table-body {
            display: table-row-group;
        }

        .simple-div-table--table-row:nth-child(even){
            display: table-row;
            height: auto;
            background: white;
        }

        .simple-div-table--table-row:nth-child(odd){
            display: table-row;
            height: auto;
            background: var(--sco-color-grey-100);
        }

        .simple-div-table--table-heading {
            display: table-header-group;
            font-weight: bold;
        }

        .simple-div-table--table-head {
            //border-right: 0.5px solid : var(--sco-color-grey-100);;
            display: table-cell;
            padding: 4px 16px;
            vertical-align: bottom;
            font-weight: bold;
        }

        .simple-div-table--table-cell-style {
            //border-right: 0.5px solid var(--sco-color-grey-300);
            display: table-cell;
            padding: 0 2px 0 2px;
            vertical-align: top;
            //padding-top: 8px;
            //padding-bottom: 8px;
        }
    }
}

.s-projekt-aftersales-dashboard-choose-asb--sections {
    margin-top: 20px;
}

.s-projekt-aftersales-dashboard-choose-asb--section > .c-section--content {
    margin-right: -10px;
}

.s-projekt-aftersales-dashboard-choose-asb--item {
    display: flex;
    height: 70px;
    min-width: 210px;
    align-items: center;
    justify-content: center;
    padding: 30px;
    text-align: center;
    background: var(--sco-color-grey-230);
    color: var(--sco-color-grey-700);
    border-radius: 0 !important;

    width: calc(20% - 10px);
    float: left;

    margin-right: 10px;
    margin-bottom: 10px;

    &:hover {
        background: var(--sco-color-grey-300);
        color: var(--sco-color-grey-700);
    }
}

.s-projekt-aftersales-dashboard-choose-asb--item-existing {
    background: $color-green--10;

    &:hover {
        background: var(--sco-color-primary);
    }
}

.s-projekt-aftersales-dashboard-create-asb--servicepartners th {
    text-align: left;
}

.s-projekt-aftersales-dashboard-systems-template {
    display: none;
}
.s-projekt-aftersales-dashboard-systems-item {
    margin-bottom: 20px;
}

.s-projekt-aftersales-dashboard-systems-head,
.s-projekt-aftersales-dashboard-servicepartners-head {
    font-weight: bold;
    margin-bottom: 5px;
}

.s-projekt-aftersales-dashboard-systems-delete-col {
    flex-basis: 52px !important;
    width: 52px !important;
    min-width: 52px !important;
    flex-shrink: 0 !important;
    flex-grow: 0 !important;
}

.s-projekt-aftersales-create {
    .s-projekt-aftersales-create-choose-projekt--grid-layout {
        justify-content: left;

        .s-projekt-aftersales-create-choose-projekt--projekt-tile {
            flex-shrink: 1;
            flex-grow: 0;
            flex-basis: calc(50% - 10px);
            min-height: 0;

            &.c-card--with-content-scrollable {
                min-height: 300px;
            }

            @include mobile {
                flex-basis: 100%;
            }

            @include tabletPortrait {
                @include number-of-dashboard-tiles-per-row(2);
            }

            @include tabletLandscape {
                @include number-of-dashboard-tiles-per-row(2);
            }

            @include wider-than-desktop {
                @include number-of-dashboard-tiles-per-row(4);
            }

            .c-card--header-wrapper-left {
                width: calc(100% - 80px)
            }
        }
    }
}