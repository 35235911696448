.s-mitarbeiter-show {
    .s-mitarbeiter-show--forecast-berechnung-section {
        margin-bottom: 10px;
    }
}

.s-mitarbeiter-create {
    .s-mitarbeiter-create--forecast-berechnung-section {
        margin-bottom: 10px;
    }
}

.s-mitarbeiter-edit {
    .s-mitarbeiter-edit--forecast-berechnung-section {
        margin-bottom: 10px;
    }
}
