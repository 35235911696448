.s-kunde-kitfirmenliste {

  .table-row-summe-label {
    font-weight: bolder;
    border-top: 2px solid var(--sco-color-anthracite-200);
  }

  .table-row-summe-value {
    font-weight: bolder;
    border-top: 2px solid var(--sco-color-anthracite-200);
  }

  .s-kunde-kitfirmenliste-choose-projekt--item {
    display: flex;
    height: 120px;
    min-width: 300px;
    align-items: center;
    justify-content: left;
    padding: 10px;
    text-align: left;
    background: var(--sco-color-grey-230);
    color: var(--sco-color-grey-700);
    border-radius: 0 !important;

    width: calc(20% - 10px);
    float: left;

    margin-right: 10px;
    margin-bottom: 10px;

    &:hover {
      background: var(--sco-color-grey-300);
      color: var(--sco-color-grey-700);
    }
  }
}