.c-strategy-section-edit-form, .c-chances-edit-form, .c-risks-edit-form {
  display: flex;
  flex-direction: column;
  border: solid 2px $color-black;
  padding: 10px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.c-strategy-section-edit-form .c-strategy-section-header.c-toggle-button-clicktarget {
  cursor: grab;
}
.c-strategy-section-edit-form .add-custom-section-btn {
  align-self: end;
  margin-top: 10px;
  background: var(--sco-color-primary);
  color: var(--sco-color-white);
}
.c-strategy-section-header {
  display: flex;
  justify-content: space-between;
  background: var(--sco-color-grey-100);
  font-size: var(--sco-font-size-headline5);
  margin-top: 8px;
}
.c-strategy-section-header.highlighted {
  background: var(--sco-color-primary);
  color: var(--sco-color-white);
}
.c-strategy-section-header.highlighted .c-button--iconbutton {
  color: var(--sco-color-white);
}
.c-strategy-section-header.highlighted .c-button--iconbutton:hover {
  color: $color-green--10;
}
.c-strategy-section-header.c-toggle-button-clicktarget {
  cursor: pointer;
}
.c-strategy-section-header-left .c-checkbox {
  height: 20px;
  margin-top: 5px;
}
.c-strategy-section-header-left {
  background: var(--sco-color-grey-100);
}
.c-strategy-section-drag-placeholder {
  min-height: 25px;
  background: $color-orange--10;
  outline: dotted 3px $color-orange--default;
}
.c-strategy-section-header-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
}
.c-strategy-section-header .c-strategy-section-header-title .o-hyperform--warning {
  position: relative;
}
.c-strategy-section-header .c-strategy-section-header-title input {
  background: var(--sco-color-grey-100);
  border: solid var(--sco-color-grey-230) 2px;
  caret-color: var(--sco-color-grey-600);
}
.c-strategy-section-header.highlighted .c-strategy-section-header-title input {
  background: $color-green--10;
  border: solid $color-green--middle 2px;
  caret-color: var(--sco-color-primary);
}
.c-strategy-section-content {
  width: 100%;
  display: none;
}
.main-section .c-strategy-section-header {
  display: none;
}
.main-section .c-strategy-section-content {
  display: block;
}
