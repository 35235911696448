@import "./components.datetimepicker.lib";

.dtp {
    .hidden {
        display: none;
    }

   .dtp-calendarweek {
  		color: $color-text--gray-dark;
  		border-right: 1px solid $color-border--light2 !important;
  		padding-right: 10px !important;
	}

    &.dtp-week {
        .dtp-picker-days tr:hover td {
            background: #8BC34A;
            color: #fff;

            > a.selected {
                background: #8BC34A;
            }
        }
        tr.selected .dtp-calendarweek {
            color: white;
            font-weight: bold;
        }

        .dtp-picker-days tr.selected {
            background: #689F38;
            color: #fff;
        }
    }



    &.dtp-range {
        table.dtp-picker-days tr > td > a.selected {
            background: inherit;
            color: #212121;
            font-weight: 700;
        }

        table.dtp-picker-days tr > td.dtp-in-range a,
        table.dtp-picker-days tr > td.dtp-in-range > a.selected {
            background: #689F38;
            color: #fff;
        }

        .dtp-actual-num {
            display: none;
        }

        .dtp-picker-month {
            display: none;
        }
    }
}


.c-datetimepicker--chevron-right {
    @include fontawesome-icon();

    &:before {
        content: fa-content($fa-var-chevron-right);
    }
}

.c-datetimepicker--chevron-left {
    @include fontawesome-icon();

    &:before {
        content: fa-content($fa-var-chevron-left);
    }
}

.c-datetimepicker--chevron-up {
    @include fontawesome-icon();

    &:before {
        content: fa-content($fa-var-chevron-up);
    }
}

.c-datetimepicker--chevron-down {
    @include fontawesome-icon();

    &:before {
        content: fa-content($fa-var-chevron-down);
    }
}

.c-datetimepicker--arrow-close {
    @include fontawesome-icon();

    &:before {
        content: fa-content($fa-var-times);
    }
}



.c-datetimepicker--clearx {
    &:after {
        padding-left: 10px;
        padding-right: 5px;
        content: fa-content($fa-var-times);
        display: block;
        position: absolute;
        right: 5px;
        bottom: 14px;
        font-size: var(--sco-font-size-headline5);
        font-family: "Font Awesome 6 Pro";
        cursor: pointer;
    }

    color: $color-text--gray;
    &:hover {
        color: var(--sco-color-grey-700);
    }
}
