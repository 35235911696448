.c-button-collapsible {
    width: 100%;
    position: relative;
}

.c-button-collapsible--btn {
    @include faicon-before($fa-var-ellipsis-h);

    background: var(--sco-color-primary);
    color: var(--sco-color-white);
    height: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background: var(--sco-color-green-800);
    }
}

.c-button-collapsible--expanded .c-button-collapsible--btn {
    display: none;
}

.c-button-collapsible--content {
    max-height: 0;
    overflow: hidden;
    transition: height 1s ease-in-out;
}

.c-button-collapsible--expanded .c-button-collapsible--content {
    max-height: 500px;
}
