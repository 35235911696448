.c-tooltip {
    position: relative;
    top: 50%;

    &:after {
        content: fa-content($fa-var-info-circle);
        font-size: var(--sco-font-size-headline4);
        font-family: "Font Awesome 6 Pro";
        cursor: pointer;
        padding-left: 5px;
        color: $color-text--gray-dark;
    }
}

.c-tooltip--template {
    display: none;
}