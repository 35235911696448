
body {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: $color-text--input;
        -webkit-box-shadow: 0 0 0px 1000px white inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}

.text--bold {
    font-family: var(--sco-font-family-lato-black);
}

.text--transform-capitalize {
    text-transform: capitalize;
}
.text--transform-uppercase {
    text-transform: uppercase;
}
.text--transform-lowercase {
    text-transform: lowercase;
}

.text--underline {
    text-decoration: underline;
}

.text--align-left {
    text-align: left;
}

.text--align-right {
    text-align: right;
}

.text--align-center {
    text-align: center;
}

@for $i from 6 through 48 {
    .text--size-#{$i} {
        font-size: #{$i}px;
    }
}

.global--height-100 {
    height: 100%;
}
.global--min-height-100 {
    min-height: 100%;
}
.global--height-50 {
    height: 50%;
}
.global--min-height-50 {
    min-height: 50%;
}
.global--height-25 {
    height: 25%;
}
.global--min-height-25 {
    min-height: 25%;
}
.global--height-75 {
    height: 75%;
}
.global--min-height-75 {
    min-height: 75%;
}
.global--width-100 {
    width: 100%;
}
.global--width-50 {
    width: 50%;
}
.global--width-one-half {
    width: calc(100% / 2);
}
.global--width-one-third {
    width: calc(100% / 3);
}
.global--width-two-thirds {
    width: calc(200% / 3 );
}
.global--min-width-100 {
    min-width: 100%;
}
.global--min-width-50 {
    min-width: 50%;
}

@for $i from 1 through 750 {
    .global--width-#{$i}px {
        width: #{$i}px;
    }
    .global--min-width-#{$i}px {
        min-width: #{$i}px;
    }
    .global--max-width-#{$i}px {
        max-width: #{$i}px;
    }
    .global--height-#{$i}px {
        height: #{$i}px;
    }
    .global--min-height-#{$i}px {
        min-height: #{$i}px;
    }
    .global--max-height-#{$i}px {
        max-height: #{$i}px;
    }
}

@for $i from 1 through 40 {
    .global--order-#{$i} {
        order: $i
    }
}

@each $float in left,right,none {
    .global--float-#{$float} {
        float: #{$float};
    }
}

@for $i from 0 through 40 {
    @each $direction in top,bottom,left,right {
        .global--margin-#{$direction}-#{$i} {
            margin-#{$direction}: #{$i}px;
        }
        .global--padding-#{$direction}-#{$i} {
            padding-#{$direction}: #{$i}px;
        }
    }

    .global--margin-topbottom-#{$i} {
        margin-top: #{$i}px;
        margin-bottom: #{$i}px;
    }
    .global--padding-topbottom-#{$i} {
        padding-top: #{$i}px;
        padding-bottom: #{$i}px;
    }

    .global--margin-leftright-#{$i} {
        margin-left: #{$i}px;
        margin-right: #{$i}px;
    }
    .global--padding-leftright-#{$i} {
        padding-left: #{$i}px;
        padding-right: #{$i}px;
    }

    .global--margin-#{$i} {
        margin: #{$i}px
    }
    .global--padding-#{$i} {
        padding: #{$i}px
    }
}

@for $i from 1 through 360 {
    .global--rotate-#{$i} {
        -webkit-transform: rotate(#{$i}deg);
        transform: rotate(#{$i}deg);
    }
}

@each $cursor in cursor,alias,all-scroll,auto,cell,col-resize,context-menu,copy,crosshair,default,e-resize,ew-resize,grabbing,help,inherit,move,n-resize,ne-resize,nesw-resize,no-drop,none,not-allowed,ns-resize,nw-resize,nwse-resize,pointer,progress,row-resize,s-resize,se-resize,sw-resize,text,url,vertical-text,w-resize,wait,zoom-in,zoom-out {
    .global-cursor--#{$cursor} {
        cursor: #{$cursor};
    }
}

