$radio-size: 20px;

.c-radio {
  display: inline-block;
  height: $radio-size;
  width: $radio-size;
  line-height: $radio-size;
}

.c-radio--input {
  display: none;
}

.c-radio--virtualradio {
  cursor: pointer;
  transition: all 0.18s;
  display: inline-block;
  width: $radio-size;
  height: $radio-size;
  font-size: $radio-size;
  @include faicon-before($fa-var-circle);
}

.c-radio--virtualradio::before {
  font-weight: 400;
}

.c-radio--input:checked + .c-radio--virtualradio {
  @include faicon-before($fa-var-dot-circle);
  font-weight: 400;
  color: var(--sco-color-primary);
}

.c-radio--input:disabled + .c-radio--virtualradio {
  cursor: unset;
}