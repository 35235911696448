$c-navigation--width: 270px;
$c-navigation--background: #ffffff;
$c-navigation--text-color: $color-black;
$c-navigation--modal-color: #000000;
$c-navigation--link-color: #262626;
$c-navigation--spacer-color: $color-border--light;
$c-navigation--action-size: 16px;
	
.c-navigation--wrapper {
    width: 100%;
    height: 100%;
	position: fixed;
	top:0;
	left:0;

	@media print {
		display: none !important;
	}
}

.c-navigation--enabled {
    .c-master-container {
    	left: #{$c-navigation--width};
    	width: calc(100% - #{$c-navigation--width});
    	position: absolute;
    }
}

.c-navigation--modal { /* No modal on desktop, mobile see below*/
	display: none;
}

.c-navigation--bar {
    background: $c-navigation--background;
    color: $c-navigation--text-color;
    width: $c-navigation--width;
    height: 100%;
    overflow-y: auto;
    position: absolute;
    z-index: 1001;
	display: flex;
    flex-direction: column;

    a {
    	text-decoration: none;
	}

	.c-header--logo {
		flex: 0;
	}

	.c-navigation--info-action {
		flex: 0;
	}
	.c-navigation--toplist {
		flex: 1;
	}

	.c-navigation--info-wrapper {
		flex: 0;
	}
}

.c-navigation--toplist {
	margin-top: 20px;
	list-style-type: none;
	padding: 0px;
	margin: 0px;
	
	> li {
		padding: 0 10px 0 0;
		margin: 0;
		list-style-type: none;
	}

	a {
		.nav-item-icon{
			font-family: "Font Awesome 6 Pro" !important;
			font-weight: 900;
			display: inline-block;
			text-decoration: inherit;
			line-height: 1;
			width: 28px;
			text-align: center;
		}
		> span {
			font-family: var(--sco-font-family-lato-black);
			font-size: var(--sco-font-size-headline5);
			line-height: 30px;
			color: $c-navigation--link-color;
			text-align: center;
			padding-right: var(--sco-spacing-8);
		}
	
		padding: 5px 20px;
		//border-bottom: 2px solid $color-black--10;
		transition: .3s;
		background: $c-navigation--background;
		display: block;
		
		&:hover {
			background: #e6e6e6;
			border-radius: 8px;
		}
	}
}
.c-navigation--toplist li.active{
	//border-left: 10px solid #8BC34A !important;
}

.c-navigation--collapsible {
	float: right;
	padding: 0px 20px 0px 10px;
	position: relative;
	left: 20px;
}

.c-navigation--expand {
	@include faicon-before($fa-var-angle-right);
}

.c-navigation--collapse {
	@include faicon-before($fa-var-angle-down);
}

//TODO: get list-style: circle to work
.c-navigation--sublist {
	padding: 0px;
	margin: 0px;
	max-height: 0px;
	overflow: hidden;
	transition: max-height .3s cubic-bezier(0, 1, 0, 1) -.1s;
	
	li {
		list-style-type: none;

		a {
			font-family: var(--sco-font-family-lato-black);
			font-size: var(--sco-font-size-headline5);
			line-height: 30px;
			color: $c-navigation--link-color;
			padding-left: 50px;
		}
	}
}

.c-navigation--sublist-active {
	max-height: 4000px;
	overflow: hidden;
	transition-timing-function: cubic-bezier(0.5, 0, 1, 0); 
    transition-delay: 0s;
}

.c-navigation--info-user {
	padding: 0 20px 0 20px;
	font-size: var(--sco-font-size-headline6);
	display: flex;
	align-items: center;
	margin: 10px 0 10px 0;
	font-family: var(--sco-font-family-lato-regular);

	.c-navigation--info-userpicture {
		flex-basis: 50px;
		height: 40px;
		flex-shrink: 0;
		flex-grow: 0;

		img {
			width: 40px;
			height: 40px;
			border-radius: 20px;
			border: 2px solid #ffffff;
		}
	}
}


//
//.c-navigation--info-version {
//	padding: 20px 20px 0px 20px;
//}

.c-navigation--info-action {
	padding: 10px 20px 20px 10px;
	
	> a {
		color: var(--sco-color-white);
		padding: 7px 10px;
		//border-left: 1px solid $c-navigation--spacer-color;
		font-size: $c-navigation--action-size;
		/*&:last-child {
			border-right: 1px solid $c-navigation--spacer-color;			
		}*/
	
		transition: color .2s, background-color .5s;
		&:hover {
			background: #e6e6e6;
		}
	}
}

.c-header--logo{

	img {
		margin-top: 7px;
		margin-left: 20px;
		height: 40px !important;
	}
}

.c-navigation--btn-settings {
	@include faicon-before($fa-var-cog);
	color: #000000;
}

.c-navigation--btn-logout {
	@include faicon-before($fa-var-sign-out);
	color: #000000;
}

.c-navigation--btn-question {
	@include faicon-before($fa-var-question);
	color: #000000;
}

.c-navigation--btn-map {
	@include faicon-before($fa-var-map);
	color: #000000;
}

.c-navigation--btn-docucenter {
	@include faicon-before($fa-var-books);
	color: #000000;
}

@keyframes c-navigation--show-scroll {
    0% {
		position: fixed;
		width: 100%;
	    height: 100%;
	}
    100% {
		position: block;
		width: 100%;
	    height: 100%;
	}
}

.c-navigation--burger-btn {
	display: inline-block;
	font-size: var(--sco-font-size-headline3);
	padding: 5px 20px 5px 20px;
	margin-left: -20px;
	font-weight: 300;
	cursor: pointer;

	position: relative;
	flex: 0 0 60px;

	@include faicon-before($fa-var-bars);
}

.c-navigation--bar {
	left: -#{$c-navigation--width};
	transition: left .2s;
}



.c-navigation--inactive {
	animation-name: c-navigation--show-scroll;
	animation-duration: 1s;

	.c-master-container {
		left: 0px;
		width: 100%;
		transition: left .2s;
	}
}

.c-navigation--initiating.c-navigation--unset,
.c-navigation--enabled.c-navigation--active {
	width: 100%;
	height: 100%;

	.c-navigation--bar {
		left: 0px;
	}

	.c-master-container {
		left: #{$c-navigation--width};
		transition: left .2s;
	}
}

/* For IPad / Mobile */
@include smaller-than-desktop {

	.c-navigation--initiating.c-navigation--unset {
		.c-navigation--bar {
			left: -#{$c-navigation--width};
		}
		.c-master-container {
			left: 0px;
		}
	}

	.c-navigation--modal {
		width: 100%;
		background: $c-navigation--modal-color;
		height: 100%;
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 1000;
		display: none;
		opacity: 0.0;
		transition: opacity .2s;
	}

	.c-navigation--enabled {
		.c-master-container {
			left: 0px;
			transition: left .2s;
			width: 100%;
		}
	}

	.c-navigation--active {
		position: fixed;

		&.c-navigation--mobile {
			.c-navigation--wrapper {
				z-index: 1000;
			}

			.c-navigation--modal {
				display: block;
				opacity: 0.5;
			}
		}
	}
}

.c-navigation--noanimation,
.c-navigation--noanimation * {
	transition: none !important;
	animation: none !important;
}