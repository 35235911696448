.s-kpm-offers {

  .c-card--header-title {
    max-width: 550px;
  }


  #s-kpm-offers--layout {
    justify-content: flex-start;

    .c-dashboard-flex-tile {
      @include number-of-dashboard-tiles-per-row(2);

      @include mobile {
        @include number-of-dashboard-tiles-per-row(1);
      }

      @include tabletPortrait {
        @include number-of-dashboard-tiles-per-row(1);
      }

      @include tabletLandscape {
        @include number-of-dashboard-tiles-per-row(2);
      }

      @include wider-than-desktop {
        @include number-of-dashboard-tiles-per-row(2);
      }
    }
  }

  .s-kpm--offer-tile-content {
    display: flex;
    justify-content: stretch;
    min-height: 100%;
    flex-direction: column;
  }

  .s-kpm--offer-tile-content-row {
    display: flex;
    justify-content: space-between;
  }

  .s-kpm--offer-tile-content-row-top,
  .s-kpm--offer-tile-content-row-mid {
    flex-grow: 0;
  }
  .s-kpm--offer-tile-content-row-bottom {
    flex-grow: 1;
  }

  .s-kpm--offer-tile-content-row-top > .s-kpm--offer-tile-content-left {
    padding-bottom: 15px;
  }

  .s-kpm--offer-tile-content-left {
    flex: 0;
    flex-basis: calc(50% - 15px);

    .c-checkbox {
      justify-content: space-between;
      height: 43px;
      border-bottom: 1px solid $color-border--normal;
    }
  }

  .s-kpm--offer-tile-content-sep {
    flex:0;
    flex-basis: 1px;
    background: $color-border--light;
  }

  .s-kpm--offer-tile-content-right {
    flex: 0;
    flex-basis: calc(50% - 15px);
    max-width: calc(50% - 15px);


    /* Reduce Dropdown Height */
    .c-dropdown--wrapper .c-dropdown--container {
      margin-bottom: 5px;
    }

    /* Dropdown Content-Right Buttons */
    .select2-selection__choice {
      max-width: calc(100% - 80px);
    }

    .c-dropdown--content-right {
      right: 0;
      padding-right: 0;

      .c-button {
        padding-left: 0;
        padding-right: 0;
        width: 20px;
        min-width: 20px;
        height: 20px;
      }
    }

    /* Multi-Line multi select */
    .select2-container--default .select2-selection--multiple {
      height: auto;
      margin-bottom: 1px;
    }
    .select2-selection__choice {
      white-space: nowrap;
    }
    .c-dropdown--wrapper {
      height: auto;
    }
    .c-dropdown--wrapper .c-dropdown--container {
      height: auto;
    }
    .select2-selection__rendered {
      white-space: normal;
      text-overflow: unset;
      overflow: visible;
    }
    .select2-selection__rendered {
      padding-bottom: 3px !important;
    }
    .select2-selection__choice {
      bottom: 0px;
      .c-dropdown--selection-div {
        max-width: calc(100% - 10px);
        vertical-align: bottom;
        margin-bottom: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

  }

  .s-kpm--offer-tile-content-left-title,
  .s-kpm--offer-tile-content-right-title {
    font-weight: bold;
    font-size: var(--sco-font-size-headline5);
  }
}
