.c-star-rating {
  position: relative;
  display: inline-block;

  &:before {
    display:block;
    top:0;
    left:0;
    font-family: 'Font Awesome 6 Pro';
    font-weight: 300;
    color: grey;
  }

  span {
    position:absolute;
    top:0;
    left:0;
    height:100%;
    display:block;
    overflow:hidden;
    white-space: nowrap;
    &:before {
      font-family: 'Font Awesome 6 Pro';
      font-weight: 900;
      color: #EEBD01;
      position: absolute;
      left: 0;
      top:0;
      display:block;
    }
  }

  &.c-star-rating--checkboxlabel {
    top: -5px;
    margin-left: 5px;
  }

  &[data-totalstars='1']:before {
    content: "\f005";
  }

  &[data-totalstars='2']:before {
    content: "\f005\f005";
  }

  &[data-totalstars='3']:before {
    content: "\f005\f005\f005";
  }

  &[data-totalstars='4']:before {
    content: "\f005\f005\f005\f005";
  }

  &[data-totalstars='5']:before {
    content: "\f005\f005\f005\f005\f005";
  }

  &[data-totalstars='1'] {
    span {
      &:before {
        content: "\f005";
      }
    }
  }

  &[data-totalstars='2'] {
    span {
      &:before {
        content: "\f005\f005";
      }
    }
  }

  &[data-totalstars='3'] {
    span {
      &:before {
        content: "\f005\f005\f005";
      }
    }
  }

  &[data-totalstars='4'] {
    span {
      &:before {
        content: "\f005\f005\f005\f005";
      }
    }
  }

  &[data-totalstars='5'] {
    span {
      &:before {
        content: "\f005\f005\f005\f005\f005";
      }
    }
  }
}

.c-star-rating-input {
  .c-star-rating {
    cursor: pointer;
  }
}