.s-kunde-projektvorschlagliste {
  .c-filtersearch__filters {

    .c-filtersearch__numberrange-filter--hint {
      color: $color_gray--120;
    }

    .c-card--content {
      padding: 0;

      .c-filtersearch__filter-ajax-wrapper {
        padding: 10px 15px;
      }
    }

    .c-card--without-content-scrollable {
      .c-filtersearch__filter-ajax-wrapper:has(.c-filtersearch__active-filters) {
        max-height: fit-content;
      }
    }
  }

  .c-header--titlebuttons {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 15px;

    .c-dropdown--wrapper {
      min-width: 15ch;
      max-width: 20ch;
    }
  }

  #s-kunde-projektvorschlagliste__resultlist {
    .c-spinner {
      margin-top: 50px;
    }

    .c-card {
      width: calc(100% - 6px);
      left: 3px;

      .c-card--header-wrapper-left {
        flex-grow: 1;
        overflow: auto;

        .c-card--header-title-link {
          color: $color-green--default;

          .c-card--header-title {

          }
        }

      }

      &.NICHT_INTERESSIERT {

        .c-card--header-title-link {
          color: $color-black--85;
        }

        .c-button.interessiert {
          color: $color-gray--78;
        }
        .c-button.nicht-interessiert {
          color: $color-green--default;
        }
      }

      .c-card--header-wrapper-right {
        flex: none;
      }

      .result--distance {
        display: flex;
        flex-direction: column;
        align-items: center;

        .result--distance-icons {
          display: flex;
        }
      }

      .c-card--content {
        display: flex;
        gap: 5px;
        justify-content: start;
        flex-wrap: wrap;
        overflow: auto;

        .result--projektinfo {
          display: flex;
          flex: 1;
          gap: 5px;
          flex-wrap: wrap;
          //folgende Zeile korrigiert das Überlappen von Inhalten, weil die Trennlinie zwischen den Projektinfos und den Vbr-Infos beim flex-wrap in den nicht sichtbaren Bereich verschoben wurde - siehe class-selector "result--vbrinfo"
          padding-right: 15px;
          align-content: start;

          .result--projektfoto {
            display: flex;
            width: 250px;
            align-items: flex-start;

            img {
              max-width: 100%; /* Ensures image does not exceed its container */
              height: auto; /* Maintains aspect ratio */
              object-fit: contain; /* Scales the image to maintain its aspect ratio while fitting within the element's content box */
            }
          }

          .result--projektdata {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(180px, 230px));
            gap: 5px;
            align-content: start;
            flex: 1;

            .label_data {
              display: grid;
              grid-template-columns: subgrid;
              align-content: start;
            }

            .label {
              white-space: nowrap;
              font-weight: bold;
              font-family: "univers-bold", Arial, sans-serif;
            }

            .data {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .result--vbrinfo {
          flex: 1;
          border-left: solid 1px $color-anthracite--85;
          //folgende 2 Zeilen verschieben die Trennlinie (links - bzw. zwischen den Projektinfos und den VBR-Infos) in den nicht sichtbaren Bereich (vor das Elternelement)
          margin-left: -16px;
          padding-left: 15px;
          min-width: calc(16% + 100ch);

          td {
            .text--align-right {
              float: right;
            }

            &.no-wrap {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}