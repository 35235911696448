.s-projekt-vertriebsbereiche-bulk-edit {

    #s-projekt-vertriebsbereiche-bulk-edit--table {
        th {
            white-space: normal;
        }

        th, td {
            @include screen-xl {
                padding-left: 4px;
                padding-right: 4px;
            }

            @include screen-l {
                padding-left: 2px;
                padding-right: 2px;
            }

            @include screen-m {
                padding-left: 2px;
                padding-right: 2px;
            }

            @include screen-s {
                padding-left: 2px;
                padding-right: 2px;
            }

            @include screen-xs {
                padding-left: 2px;
                padding-right: 2px;
            }
        }

        td .spinner-container .c-spinner {
            transform: none;
        }
    }

    .c-input--text-wrapper.s-projekt-vertriebsbereiche-bulk-edit--editable input {
        background: none;
    }

    .s-projekt-vertriebsbereiche-bulk-edit--editable .c-dropdown--wrapper .c-dropdown--container {
        background: none;

        .select2-container--default .select2-selection--single {
            background: none;
        }
    }
}