@import "../../styles/tools/tools.breakpoints";

$card-text-padding: 10px 15px;

.c-card {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--sco-color-white);

    > .c-spinner {
        position: absolute;
        top: calc(50% + 55px);
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    @media print {
        margin-top: 5px;
    }
}

:nth-last-child(1 of .c-card) {
    margin-bottom: 8px;
}

.c-card--with-bottom-margin {
    margin-bottom: 15px;
}

.c-card--content {
    padding: $card-text-padding;
    line-height: 1.4;
    min-height: calc(100% - 50px);
    flex-grow: 1;
    flex-shrink: 1;
}

.c-card--header ~ .c-card--content {
    border-top: 2px solid var(--sco-color-grey-400);
}

.c-card--content-scrollable {
    /* Do not let the content expand the card size */
    height: 0;
    overflow: auto;
    flex-grow: 0;
    flex-shrink: 1;

    .c-card--with-header-size-1 & {
        min-height: calc(100% - 34px);
    }

    .c-card--with-header-size-2 & {
        min-height: calc(100% - 45px);
    }
}

.c-card--content-optionally-scrollable {
    flex-grow: 1;
    min-height: unset;
    overflow: hidden;
}

.c-card--show-more {
    height: 44px;

    @include screen-l {
        height: 41px;
    }
    @include screen-m {
        height: 41px;
    }
    @include screen-s {
        height: 38px;
    }
    @include screen-xs {
        height: 38px;
    }

    & {
        padding: 10px 15px;
        text-align: center;
    }

    .c-card--show-more-gradient {
        height: 20px;
        position: relative;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        transform: translate(0, -54px);

        @include screen-l {
            transform: translate(0, -51px);
        }
        @include screen-m {
            transform: translate(0, -51px);
        }
        @include screen-s {
            transform: translate(0, -48px);
        }
        @include screen-xs {
            transform: translate(0, -48px);
        }

        pointer-events: none;
    }
}

.c-card--header {
    padding: var(--sco-spacing-8) var(--sco-spacing-16);
    flex:0;
    display: flex;
    background: var(--sco-color-white);
    min-height: var(--sco-spacing-48);
}

.c-card--header-compressed {
    padding: 0 16px 0 16px;
    flex:0;
    background-color: var(--sco-color-white);
}

.c-card--fullwidth {
    width: 100%;
}

.o-col-inner > .c-card {
    margin-bottom: 20px;
}

.c-card-section-header {
    font-family: var(--sco-font-family-lato-black);
    font-size: var(--sco-font-size-headline5);
    line-height: 30px;
    padding-bottom: var(--sco-spacing-8);
    padding-top: var(--sco-spacing-16);

    @include screen-m {
        padding-bottom: var(--sco-spacing-12);
        padding-top: var(--sco-spacing-24);
    }

    @include screen-l {
        padding-bottom: var(--sco-spacing-12);
        padding-top: var(--sco-spacing-24);
    }

    @include screen-xl {
        padding-bottom: var(--sco-spacing-16);
        padding-top: var(--sco-spacing-32);
    }

    @include screen-xxl {
        padding-bottom: var(--sco-spacing-20);
        padding-top: var(--sco-spacing-40);
    }

}

.c-card--header-icon {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 30px;
    align-self: center;
    font-size: 26px;
    margin-right: 10px;
    min-width: 32px;

    &.c-card--icon-kunde {
        color: $color-icon-kunde;
    }

    &.c-card--icon-planer {
        color: $color-icon-planer;
    }

    &.c-card--icon-projekt {
        color: $color-icon-projekt;
    }

    &.c-card--icon-kpm {
        color: $color-icon-kpm;
    }
}

.c-card--header-wrapper-left {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
}

.c-card--header-wrapper-center {
    display: flex;
    flex-basis: auto;
    flex-grow: 0;
    min-width: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.c-card--header-wrapper-right {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
    flex-shrink: 0;
    align-items: center;

    & .c-button--textbutton {
        padding: 4px 16px; //sonst sind iconbuttons und textbuttons nicht gleich groß
        height: 32px; //sonst sind iconbuttons und textbuttons nicht gleich groß
    }

    & .c-button--icontextbutton {
        flex: auto; // sonst passen die Texte nicht in den Button
        padding: 4px 16px; //sonst sind iconbuttons und icontextbuttons nicht gleich groß
        height: 32px; //sonst sind iconbuttons und icontextbuttons nicht gleich groß
    }

}

.c-card--header-icon-link {
    padding-top: 7px;
    padding-bottom: 7px;
}

.c-card--header-left {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    min-width: 0;

}

.c-card--header-title {
    font-family: var(--sco-font-family-lato-black);
    font-size: var(--sco-font-size-headline5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.c-card--header-wrapper-center {
    .c-card--header-title {
        text-align: center;
    }
    .c-card--header-label {
        text-align: center;
    }
}

.c-card--header-label {
    font-size: var(--sco-font-size-paragraph);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.c-card--header-white {
    background-color: var(--sco-color-white);
}

.c-card--with-fullsize-button {

    & .c-button--textbutton {
        width: 100%;
        height: 100%;
    }

    & .c-card--content {
        height: 100%;
    }
}

.c-card--header-right {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: auto;
    align-self: center;
    display: flex;
    margin-left: 15px;

    > * {
        flex: 0;
        margin-left: 15px;
        align-self: center;

        &:first-child {
            margin-left: 0;
        }
    }

    > span {
        white-space: nowrap;
    }

    > .c-button--textbutton {
        padding: 4px 16px; //sonst sind iconbuttons und textbuttons nicht gleich groß
        height: 32px; //sonst sind iconbuttons und textbuttons nicht gleich groß
    }
    > .c-button--icontextbutton {
        flex: auto; // sonst passen die Texte nicht in den Button
        padding: 4px 16px; //sonst sind iconbuttons und icontextbuttons nicht gleich groß
        height: 32px; //sonst sind iconbuttons und icontextbuttons nicht gleich groß
    }
}

.c-card-ajax-variant-chooser {
    min-width: 200px;
}