
.c-chart-donut--container {

    height: 160px;
    width: 160px;

    & .c-chart-donut--segment {
        stroke-width: 30px;
        fill: transparent;
    }

}