.s-ticket-vertretung-uebersicht {

  .vertreter-table-header {
    font-weight: bold;
    padding-left: 8px;
    margin-bottom: 3px;
  }

  .vertretung-active > * {
      background: var(--sco-color-blue-200);
    }
}

.s-ticket-vertretung-create {
  #mitarbeiter-list {
    margin-left: 10px;
    border: none;
  }
}