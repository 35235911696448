$c-input--border: 1px;
$c-input--border-focus: 2px;
$c-textarea--lineheight: 17px;
$c-textarea--otherinputheight: 55px; // die textarea soll immer ein vielfaches hiervon sein, damit die höhe zu den anderen form elementen passt
$c-textarea--top-padding-height: 19px;
$c-textarea--bottomborder-margin-top: 6px;
$c-textarea--bottomborder-margin-bottom: 11px;
$c-textarea--bottomborder-height: 2px;

.c-textarea--text {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    padding: 0px 0px 0px 0px;
    min-height: 17px;
    height: 17px;
    line-height: $c-textarea--lineheight;
    
    outline: none;
    box-shadow: none;
    
    color: $color-text--input;
    border-bottom: 0px;
    
    //transition: all 0.2s;

    display: block;
    
    caret-color: $color-border--focus;
    
    &[disabled] {
        background: none;        
        color: $color-text--gray-dark;
        -webkit-opacity: 1;
        -webkit-text-fill-color: $color-text--gray-dark;

        ~ .c-textarea--bottomborder {
            border-bottom: $c-input--border solid $color-border--normal;
        }
    }
    
    &:hover:not([disabled]):not(:focus):not(.o-hyperform--invalid) {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        padding-bottom:0px;

        ~ .c-textarea--bottomborder {
            border-bottom: $c-input--border-focus solid $color-border--hover;
        }
    }
    
    &:focus {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        padding-bottom:0px;

        ~ .c-textarea--bottomborder {
            border-bottom: $c-input--border-focus solid $color-border--focus;
        }
    } 

    &.o-hyperform--invalid {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        padding-bottom:0px;

        ~ .c-textarea--bottomborder {
            border-bottom: $c-input--border-focus solid $color-border--invalid;
        }
    }

    &::placeholder {
        color: $color-text--gray;
        font-family: var(--sco-font-family-lato-light);
    }
}

.c-textarea--text-wrapper:has(textarea[disabled]):after {
    content: var(--sco-icon-input);
    display: block;
    position: absolute;
    right: 10px;
    top: 0;
    line-height: 44px;
    font-size: 16px;
    color: var(--sco-color-input--disabled);
    font-family: "Font Awesome 6 Pro";
    font-weight: 100;
}
.c-textarea--text-wrapper:has(textarea[disabled]):before {
    content: var(--sco-icon-input--disabled);
    display: block;
    position: absolute;
    right: 7px;
    top: 0;
    line-height: 44px;
    font-size: 18px;
    color: var(--sco-color-input--disabled);
    font-family: "Font Awesome 6 Pro";
    font-weight: 100;
}

.c-textarea--bottomborder {
    margin-top: $c-textarea--bottomborder-margin-top;
    margin-bottom: $c-textarea--bottomborder-margin-bottom;
    height: $c-textarea--bottomborder-height;
    border-bottom: 1px solid $color-border--normal;
}

.c-textarea--text-wrapper {
    display: block;
    position: relative;
    min-height: 43px;

    textarea {
        display: block;
        position: relative;
        width: 100%;
    }

    label {
        display: block;
        position: absolute;
        left: 0px;
        top: 16px;
        z-index: 10;
        pointer-events: none;
        cursor: text;
        transition: all 0.2s;
        color: $color-text--label;
        font-family: var(--sco-font-family-lato-light);

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
    }

    textarea:focus ~ label,
    textarea:not(.c-textarea--empty) ~ label,
    textarea[placeholder] ~ label {
        font-size: 9px;
        top: 0px;
    }

    textarea:focus ~ label
    {
        color: $color-border--focus;
    }

    margin-bottom: 0px;
}


.c-textarea--top-padding {
    height: $c-textarea--top-padding-height;
}

.c-textarea--text-wrapper-noautosize {
    height: auto;
    textarea, .c-textarea--text  {
        height: auto;
    }
}

@for $rows from 1 through 30 {
    .c-textarea--text[rows="#{$rows}"] {
        $xtimesotherinputheight: ceil((($c-textarea--lineheight * $rows)+($c-textarea--top-padding-height + $c-textarea--bottomborder-margin-top + $c-textarea--bottomborder-margin-bottom + $c-textarea--bottomborder-height)) / $c-textarea--otherinputheight);
        $overallheight: $xtimesotherinputheight * $c-textarea--otherinputheight;
        $textareaheight: $overallheight - $c-textarea--top-padding-height - $c-textarea--bottomborder-margin-top - $c-textarea--bottomborder-margin-bottom - $c-textarea--bottomborder-height;
        height: $textareaheight;
    }
}