.c-percentCircle--container {
  width: 60px;
  height: 60px;

  .c-percentCircle--circle {
    stroke-width: 2px;
    fill: none;
  }

  .c-percentCircle--full-circle {
  }

  .c-percentCircle--percent-circle {
    animation: c-percentCircle--percent-circle-progress 1s ease-out forwards;
  }

  @keyframes c-percentCircle--percent-circle-progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .c-percentCircle--text {
    stroke-width: 0.5px;
    font-weight: normal;
    font-size: 10px;
    text-anchor: middle;
  }
}

