/* Import font awesome */
$fa-font-path : '../assets/fonts/fontawesome-pro-6.5.1/webfonts';

@import "../assets/fonts/fontawesome-pro-6.5.1/scss/fontawesome";
@import "../assets/fonts/fontawesome-pro-6.5.1/scss/light";
@import "../assets/fonts/fontawesome-pro-6.5.1/scss/solid";
@import "../assets/fonts/fontawesome-pro-6.5.1/scss/regular";
@import "../assets/fonts/fontawesome-pro-6.5.1/scss/duotone";
@import "../assets/fonts/fontawesome-pro-6.5.1/scss/_duotone-icons";
@import "../assets/fonts/fontawesome-pro-6.5.1/scss/_variables";
@import "../assets/fonts/fontawesome-pro-6.5.1/scss/_mixins";

$cps-icons-font-path : '../assets/cps-icons/dist';
@import "../assets/cps-icons/dist/cps-icons";
@import "../tools/tools.breakpoints";

/* define fonts */

@font-face{
  font-family: 'univers-bold';
  src:url("../assets/fonts/univers-bold.eot?#iefix");
  src:url("../assets/fonts/univers-bold.eot?#iefix") format("eot"),url("../assets/fonts/univers-bold.woff") format("woff"),url("../assets/fonts/univers-bold.ttf") format("truetype"),url("../assets/fonts/univers-bold.svg#2995ad8b-a41a-47a5-8ff9-4ba180cf7131") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face{
  font-family: 'univers-light';
  src:url("../assets/fonts/univers-light.eot?#iefix");
  src:url("../assets/fonts/univers-light.eot?#iefix") format("eot"),url("../assets/fonts/univers-light.woff") format("woff"),url("../assets/fonts/univers-light.ttf") format("truetype"),url("../assets/fonts/univers-light.svg#5eda4e27-5666-4d4c-9529-9c09c9667099") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: 'univers';
  src:url("../assets/fonts/univers.eot?#iefix");
  src:url("../assets/fonts/univers.eot?#iefix") format("eot"),url("../assets/fonts/univers.woff") format("woff"),url("../assets/fonts/univers.ttf") format("truetype"),url("../assets/fonts/univers.svg#7784506a-b342-4ea9-9379-bdab1c51eb72") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Regular';
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/lato/Lato-Regular.woff2") format('woff2');
}

@font-face {
  font-family: 'Lato-Black';
  font-style: normal;
  src: url("../assets/fonts/lato/Lato-Black.woff2") format('woff2');
}

@font-face {
  font-family: 'Lato-Bold';
  font-style: normal;
  src: url("../assets/fonts/lato/Lato-Bold.woff2") format('woff2');
}

@font-face {
  font-family: 'Lato-Light';
  font-style: normal;
  src: url("../assets/fonts/lato/Lato-Light.woff2") format('woff2');
}

@font-face {
  font-family: 'Lato-Italic';
  font-style: normal;
  src: url("../assets/fonts/lato/Lato-Italic.woff2") format('woff2');
}

@font-face {
  font-family: 'Lato-BlackItalic';
  font-style: normal;
  src: url("../assets/fonts/lato/Lato-BlackItalic.woff2") format('woff2');
}



/* define font-sizes */

$fontsize-default: 14px;
$fontsize-label-default: 10px;
$fontsize-heading-20: 20px;

$generics-fonts-define-rules: true !default;

@if($generics-fonts-define-rules) {
  /* set default font */
  body {
    font-family: var(--sco-font-family-lato-regular);
    font-size: var(--sco-font-size-paragraph);
    color: var(--sco-color-grey-700);
  }

  b, strong {
    font-weight: 700;
  }

  table, p, label, span {
    font-family: var(--sco-font-family-lato-regular);
    font-size: var(--sco-font-size-paragraph);
    color: var(--sco-color-grey-700);
  }

  input, textarea {
    font-family: var(--sco-font-family-lato-regular);
    font-size: var(--sco-font-size-paragraph);
    color: var(--sco-color-grey-700);
  }

  button,
  optgroup,
  select {
    font-family: var(--sco-font-family-lato-regular);
    font-size: var(--sco-font-size-paragraph);
    color: var(--sco-color-grey-700);
  }
}

.sco-font-size-headline1 {
  font-size: var(--sco-font-size-headline1);
}

.sco-font-size-headline2 {
  font-size: var(--sco-font-size-headline2);
}

.sco-font-size-headline3 {
  font-size: var(--sco-font-size-headline3);
}

.sco-font-size-headline4 {
  font-size: var(--sco-font-size-headline4);
}

.sco-font-size-headline5 {
  font-size: var(--sco-font-size-headline5);
}

.sco-font-size-headline6 {
  font-size: var(--sco-font-size-headline6);
}

.sco-font-size-paragraph {
  font-size: var(--sco-font-size-paragraph);
}

.sco-font-size-paragraph-small {
  font-size: var(--sco-font-size-paragraphsmall);
}