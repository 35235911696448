.s-projekt-nachhaltigkeit {
    .s-projekt-nachhaltigkeit--pre-post-consumer-anteil-info {
        padding: 4px 16px;
        vertical-align: bottom;
        font-weight: bold;
    }
    .s-projekt-nachhaltigkeit--info-panel {
        margin-top: 10px;
        margin-bottom: 5px;

        .c-card--header-title {
            white-space: normal;
            text-align: center;

            #s-projekt-nachhaltigkeit--info-panel--anfrage-info {
                margin-top: 12px;
                margin-bottom: 8px;
                color: var(--sco-color-orange-200);
            }
        }

        .c-card--header-wrapper-right {
            display: none;
        }
    }

    .s-projekt-nachhaltigkeit--grid-tile {
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: calc(50% - 10px);
        min-height: 0;

        &.c-card--with-content-scrollable {
            min-height: 300px;
        }

        @include mobile {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: 100%;
        }

        @include tabletPortrait {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: calc(50% - 10px);
        }

        @include tabletLandscape {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: calc(50% - 10px);
        }

        @include wider-than-desktop {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: calc(33% - 10px);
        }
    }

    .c-card--header {
        min-height: 45px;
    }

    #s-projekt-nachhaltigkeit--card-NH_LOW_CARBON_ALUMINIUM {
        .s-projekt-nachhaltigkeit-low-carbon-aluminium-hinweis {
            font-weight: bold;
            color: var(--sco-color-orange-200);
        }
    }

    #s-projekt-nachhaltigkeit--card-NH_RECYCLETES_ALUMINIUM {
        .s-projekt-nachhaltigkeit-recyceltes-aluminium-hinweis {
            font-weight: bold;
            color: var(--sco-color-orange-200);
        }
    }

    #s-projekt-nachhaltigkeit--card-NH_ASI_ZERTIFIZIERTES_ALUMINIUM {
        .s-projekt-nachhaltigkeit-asi-zertifiziertes-aluminium-hinweis {
            font-weight: bold;
            color: var(--sco-color-orange-200);
        }
    }

    #s-projekt-nachhaltigkeit--card-NH_KOMMENTARE {
        .o-col.o-col-2 {
            height: 100%;
        }

        .s-projekt-nachhaltigkeit-kommentar-edit-editorWrapper {
            height: 100%;
            display: flex;
            flex-direction: column;

            .c-htmlwysiwygeditor {
                flex: 1;
            }

            .c-htmlwysiwygeditor-readonly {
                border: none;
                padding: 0;

                .ql-container {
                    border: none;

                    p {
                        margin: 0;
                    }
                }
            }
        }
    }

    #s-projekt-nachhaltigkeit--card-NH_HISTORY {
        flex-basis: calc(100% - 10px);
        .c-mitarbeiterpopup__img--visible {
            height: 1.5em;
            width: 1.5em;
            border-radius: 0.75em;
        }

        div.verteilung {
            display: none;
        }
        .verteilung-trigger {
            cursor: pointer;
        }
        td {
            vertical-align: top;
        }
    }

    .s-projekt-nachhaltigkeit--info-text {
        text-align:left;

        span.s-projekt-nachhaltigkeit--info-text-highlight {
            color: var(--sco-color-primary);
        }
    }

    &--anfrage-dialog {
        text-align: left;
        strong {
            font-family: var(--sco-font-family-lato-bold);
        }

        .c-section {
            .c-section--heading:after {
                content: ""
            }
            .c-section--content {
                margin-left: 5px;
            }
        }

        .info-box {
            margin-bottom: 15px;
        }

        #gueten-section table {
            border-bottom: solid 1px $color-border--normal;
            margin-left: -5px;
            width: calc(100% + 5px);
            th {
                width: 33%;
                > div {
                    padding-left: 3px;
                    div {
                        padding: 0;
                    }
                }
                .c-radio, label {
                    vertical-align: middle;
                }
                &.selected {
                    background-color: $color-green--10;
                }
            }
            td {
                background-color: white;
                .c-input--text {
                    padding-left: 10px;
                    width: 10ch;
                }
                &.LC, &.ULC {
                    .c-input--text {
                        background-color: $color-green--10;
                        &:not(:hover) {
                            border-bottom: 1px solid $color-black;
                        }
                    }
                }
            }
            //th.LC, th.ULC, td.LC, td.ULC {
            td.LC, td.ULC {
                color: $color-text--gray-dark;
                &.selected {
                    color: var(--sco-color-grey-700);
                    background-color: $color-green--10;
                }
            }
            tr:nth-child(1), tr:nth-child(2) {
                td:nth-child(1) {
                    font-family: var(--sco-font-family-lato-bold);
                }
            }
            tr.nachhaltigkeit_beantragen td:not(:first-child) {
                text-align: center;
            }
        }
        #zeiten-section {
            h3 {
                font-family: var(--sco-font-family-lato-bold);
                font-size: var(--sco-font-size-headline5);
                position: relative;
                padding-left: 10px;
            }
            input.c-input--text.highlight-automatic:not(.invalid) {
                background-color: #e2f8ff;
            }
            #ausfuehrung-picker-wrapper {
                .o-hyperform--warning {
                    z-index: 1;
                    background-color: var(--sco-color-white);
                    height: auto;
                    padding-left: 5px;
                    bottom: inherit;
                }
            }
        }
        .buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            margin-top: 15px;

            .nachhaltigkeit-anfrage-zugesagte-menge-hint {
                display: none;
                .hint-content {
                    display: flex;
                    align-items: center;
                    color: var(--sco-color-orange-200);
                    border: solid 1px $color-orange--default;
                    padding: 15px;
                    .c-icon {
                        font-size: 2em;
                        margin-right: 15px;
                    }
                    .c-checkbox {
                        justify-content: center;
                    }
                }
            }
        }
    }
    &--anfrage-storno-dialog {
        .info {
            margin: 15px 0;
        }

        .buttons {
            display: flex;
            justify-content: space-around;
            margin-top: 15px;
        }
    }
}

