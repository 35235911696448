// #################################################################################################################
// Styles der Simple Div Based Tabelle
// #################################################################################################################

.simple-div-table--table {
    display: table;
    width: 100%;
    border: 0;
}

.simple-div-table--table-body {
    display: table-row-group;
}

.simple-div-table--table-row {
    display: table-row;
    height: 52px;
}

.simple-div-table--table-heading {
    display: table-header-group;
    font-weight: bold;
}

.simple-div-table--table-head {
    display: table-cell;
    padding: 4px 16px;
    vertical-align: bottom;
    font-weight: bold;
}
.simple-div-table--table-head:not(:last-child) {
    border-right: 0.5px solid var(--sco-color-grey-100);
}

.simple-div-table--table-cell-style {
    display: table-cell;
    padding: 4px 8px;
    vertical-align: middle;
    padding-top: 8px;
    padding-bottom: 8px;
}
.simple-div-table--table-cell-style:not(:last-child) {
    border-right: 0.5px solid var(--sco-color-grey-300);
}

.simple-div-table--table-head {

}

.simple-div-table--table.with-parity .simple-div-table--table-row:nth-child(even) .simple-div-table--table-cell {
    @extend .simple-div-table--table-cell-style;
    background: var(--sco-color-grey-100);
}

.simple-div-table--table.with-parity .simple-div-table--table-row:nth-child(odd) .simple-div-table--table-cell {
    @extend .simple-div-table--table-cell-style;
    background: var(--sco-color-white);
}

.simple-div-table--table.with-parity.reversed-parity .simple-div-table--table-row:nth-child(even) .simple-div-table--table-cell {
    @extend .simple-div-table--table-cell-style;
    background: $color-white;
}

.simple-div-table--table.with-parity.reversed-parity .simple-div-table--table-row:nth-child(odd) .simple-div-table--table-cell {
    @extend .simple-div-table--table-cell-style;
    background: $color-gray--152;
}

.simple-div-table--table-cell-even {
    @extend .simple-div-table--table-cell-style;
    background: #f7f7f7;
}

.simple-div-table--table-cell-odd {
    @extend .simple-div-table--table-cell-style;
    background: var(--sco-color-white);
}

.simple-div-table--table-foot {
    display: table-footer-group;
    font-weight: bold;
}