/* General styles for PRINT rendering */

@media print {
  * {
    -webkit-animation-duration: 0s !important;
    -moz-animation-duration: 0s !important;
    -o-animation-duration: 0s !important;
    animation-duration: 0s !important;

    transition: none !important;
  }
}