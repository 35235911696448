.c-header {
  position: sticky;
  top: 0;
  z-index: 100;
  white-space: nowrap;
  padding: 5px $content-padding 0px $content-padding;
}

.c-content--main {
  min-height: 100vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none; // Für WebKit-Browser (Chrome, Safari)
  }
  -ms-overflow-style: none; // Für Internet Explorer und Edge
  scrollbar-width: none; // Für Firefox
}

.c-header--logo {
    flex-grow: 0;
}

.c-header--line1 {
  height: 46px;
  border-bottom: 1px solid $color-border--light2;
  display: flex;
  align-items: center;
}

.c-header--line2 {
  text-align: right;
  height: calc(64px - 13px);
}

.c-header--border-bottom {
  padding-bottom: 48px;
  //border-bottom: 2px solid $color-black--10;
}

.c-header--heading {
  float: left;
  display: flex;
  align-items: center;
  height: calc(64px - 13px);
  font-family: var(--sco-font-family-lato-light);
  font-size: var(--sco-font-size-headline3);
  color: var(--sco-color-grey-700);
  letter-spacing: 0.15px;
}

.c-header--line1-inline {
  text-align: right;
}

.c-header--titlebuttons {
  height: calc(64px - 13px);
  vertical-align: middle;
  line-height: calc(64px - 13px);
  float: right;
  flex-grow: 1;
  text-align: right;

  //sonst sind iconbuttons und icontextbuttons nicht gleich groß
  & > .c-button--icontextbutton {
    padding: 4px 16px;
    height: 32px;
  }
}

.c-header--global-spinner {
  height: calc(64px - 13px);
  width: calc(64px - 13px);
  vertical-align: middle;
  line-height: calc(64px - 13px);
  float: right;
  //flex-grow: 1;
  text-align: right;
  display: none;
}