.s-massnahmenplan-show {

  #s-massnahmenplan-show--edit-bearbeiter {
    height: 22px;
  }

  .massnahme-overdue {
    background-color: $color-red--10;
  }

}