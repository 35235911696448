.c-aufgaben {

  .c-aufgaben--date-navigator {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .c-aufgaben--date-prev {

  }

  .c-aufgaben--date-next {

  }

  .c-aufgaben--jobtable-date-header {
    margin-top: 2px;
  }

  .c-calendar--day-header-content-dayname {
    font-family: var(--sco-font-family-lato-regular);
    font-size: var(--sco-font-size-headline6);
  }

  .c-calendar--day-header-content-day-of-month {
    font-family: var(--sco-font-family-lato-bold);
    font-size: var(--sco-font-size-headline5);
  }


  .c-aufgaben--job-grid {
    --tablehead-size: calc(var(--sco-font-size-headline5) + var(--sco-font-size-headline5) + var(--sco-spacing-40));
    --tablerow-size: 42px;
    --tablerow-amount: 8;

    max-height: calc(var(--tablerow-amount) * var(--tablerow-size) + var(--tablehead-size) + 1.6px);
    overflow-y: scroll;

    .c-aufgaben--jobtable-tablehead tr:first-child th {
      position: sticky;
      top: 0;
      z-index: 1;
    }
    .c-aufgaben--jobtable-tablehead tr:nth-child(2) th {
      position: sticky;
      top: 33px;
      z-index: 1;
    }
  }

  .c-aufgaben--date-output {
    font-size: var(--sco-font-size-headline5);
  }

  .c-aufgaben--button-date {
    &:not(:disabled) {
      cursor: pointer;
    }

    font-size: 1.5em;
    line-height: 1.5em;
    border: none;
    background: none;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: var(--sco-color-primary);
    border-radius: 0.25em;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    height: 2em;

    &:hover {
      background-color: var(--fc-button-hover-bg-color);
      border-color: var(--fc-button-hover-border-color);
      color: var(--fc-button-text-color);
    }
  }

  .c-aufgaben--date-navigator {
    height: var(--sco-spacing-40);
  }

  .c-aufgaben--jobtable {

    --sco-aufgaben-border: 1px solid var(--sco-color-grey-200);
    --sco-aufgaben-border-today: 1px solid var(--sco-color-primary);
    --sco-create-aufgabe-button: var(--sco-color-grey-200);
    --sco-create-aufgabe-button-hover: var(--sco-color-grey-300);

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */

    width: 100%;
    table-layout: fixed;

    font-size: var(--sco-font-size-headline6);
    line-height: 14px;
    margin-bottom: 0;
    border-collapse: separate;

    td {
      height: 36px;

      .c-aufgaben--job-title {
        margin-bottom: 2px;
      }

      .c-aufgaben--job-kunde {
        .c-aufgaben--job-number-highlight {
          background-color: var(--sco-color-kunde-background);
          color: var(--sco-color-kunde-text);
          border-radius: 4px;
          padding: 2px;
        }
      }

      .c-aufgaben--job-projekt {
        .c-aufgaben--job-number-highlight {
          background-color: var(--sco-color-projekt-background);
          color: var(--sco-color-projekt-text);
          border-radius: 4px;
          padding: 2px;
        }
      }

      .c-aufgaben--job-planer {
        .c-aufgaben--job-number-highlight {
          background-color: var(--sco-color-planer-background);
          color: var(--sco-color-planer-text);
          border-radius: 4px;
          padding: 2px;
        }
      }

      .c-aufgaben--job-kunde + .c-aufgaben--job-content {
        @extend .c-aufgaben--job-kunde
      }

      .c-aufgaben--job-projekte + .c-aufgaben--job-content {
        @extend .c-aufgaben--job-projekte
      }

      .c-aufgaben--job-planer + .c-aufgaben--job-content {
        @extend .c-aufgaben--job-planer
      }
    }

    tr:first-child td {
      border-top: var(--sco-aufgaben-border);
    }

    tr:last-child td {
      border-bottom: var(--sco-aufgaben-border);
    }

    .c-aufgaben--jobtable-index-headercolumn {
      width: 61px;
    }

    .c-aufgaben--jobtable-indexcolumn {
      width: 61px;
      text-align: center;
      vertical-align: center;
      color: var(--sco-color-black);
    }

    .c-aufgaben--job-slot-highlighted {
      background: var(--sco-color-grey-300);
    }

    .c-aufgaben--jobtable-date-headercolumn {
      text-align: center;
      padding: 0;
      font-size: var(--sco-font-size-headline6);
      color: var(--sco-color-grey-700);
      font-family: var(--sco-font-family-lato-black);

      border: 1px solid var(--sco-color-grey-200);

      /*      @include screen-m {
              padding: var(--sco-spacing-8) var(--sco-spacing-8);
            }
            @include screen-l {
              padding: var(--sco-spacing-8) var(--sco-spacing-8);
            }
            @include screen-xl {
              padding: var(--sco-spacing-12) var(--sco-spacing-12);
            }
            @include screen-xxl {
              padding: var(--sco-spacing-12) var(--sco-spacing-12);
            }*/

      &.c-aufgaben--jobtable-date-headercolumn-past {
        background-color: var(--sco-color-grey-100);

        &:has(+.c-aufgaben--jobtable-date-headercolumn-today) {
          border-right: var(--sco-aufgaben-border-today);
        }
      }

      &.c-aufgaben--jobtable-date-headercolumn-today {
        border-left: var(--sco-aufgaben-border-today);
        border-right: var(--sco-aufgaben-border-today);
        border-top: var(--sco-aufgaben-border-today);
        background-color: color-mix(in srgb, var(--sco-color-white), var(--sco-color-primary) 3%);
      }

      &.c-aufgaben--jobtable-date-headercolumn-future {
        background-color: var(--sco-color-white);
      }
    }

    .c-aufgaben--jobtable-create-column {
      border: 1px solid var(--sco-color-grey-200);
      height: var(--sco-spacing-40);
      --job-create-button-size: 16px;

      @include screen-xs {
        --job-create-button-size: 12px;
      }
      @include screen-s {
        --job-create-button-size: 12px;
      }

      &.c-aufgaben--jobtable-create-column-past {
        background-color: var(--sco-color-grey-100);

        &:has(+.c-aufgaben--jobtable-create-column-today) {
          border-right: var(--sco-aufgaben-border-today);
        }

        .c-aufgaben--jobtable-create-job-button {
          padding: var(--job-create-button-size);
          background-color: var(--sco-create-aufgabe-button);

          &:hover {
            background-color: var(--sco-create-aufgabe-button-hover);
          }
        }
      }

      &.c-aufgaben--jobtable-create-column-today {
        border-left: var(--sco-aufgaben-border-today);
        border-right: var(--sco-aufgaben-border-today);
        background-color: color-mix(in srgb, var(--sco-color-white), var(--sco-color-primary) 3%);

        .c-aufgaben--jobtable-create-job-button {
          padding: var(--job-create-button-size);
          background-color: var(--sco-create-aufgabe-button);
          &:hover {
            background-color: var(--sco-create-aufgabe-button-hover);
          }
        }
      }

      &.c-aufgaben--jobtable-create-column-future {
        background-color: var(--sco-color-white);

        .c-aufgaben--jobtable-create-job-button {
          padding: var(--job-create-button-size);
          background-color: var(--sco-create-aufgabe-button);

          &:hover {
            background-color: var(--sco-create-aufgabe-button-hover);
          }
        }
      }
    }

    .c-aufgaben--jobtable-tablebody {
      .c-aufgaben--jobtable-column {
        border-left: var(--sco-aufgaben-border);
        border-right: var(--sco-aufgaben-border);

        &.c-aufgaben--jobtable-column-past {
          background-color: var(--sco-color-grey-100);

          &:has(+.c-aufgaben--jobtable-column-today) {
            border-right: var(--sco-aufgaben-border-today);
          }
        }

        &.c-aufgaben--jobtable-column-today {
          border-left: var(--sco-aufgaben-border-today);
          border-right: var(--sco-aufgaben-border-today);
          background-color: color-mix(in srgb, var(--sco-color-white), var(--sco-color-primary) 3%);
        }

        &.c-aufgaben--jobtable-column-future {
          background-color: var(--sco-color-white);
        }

        &.c-aufgaben--jobtable-column-dragover {
          background-color: var( --sco-color-blue-100);
        }
      }

      tr:last-of-type .c-aufgaben--jobtable-column-today {
        border-bottom: var(--sco-aufgaben-border-today);
      }
    }

  }

  .c-aufgaben--jobtable-item-container {
    position: relative;
    border: 1px solid var(--sco-color-grey-400);
    background: var(--sco-color-white);
    border-radius: 8px;
    overflow: hidden;

    .c-aufgaben--job-wrapper {
      border-radius: 7px;
      overflow: hidden;
    }

    .c-aufgaben--job {
      display: grid;
      height: 100%;
      grid-template-columns: [first-line] 20px [line2] auto [last-line];
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
          "icon-first  content-first"
          "icon-second content-second"
          "icon-third  content-third";

      cursor: pointer;
      font-size: var(--sco-font-size-headline6);
      line-height: 14px;
      position: relative;

      /* Area Zuordnungen */
      .c-aufgaben--job-icon-first {
        grid-area: icon-first;
      }

      .c-aufgaben--job-icon-second {
        grid-area: icon-second;
      }

      .c-aufgaben--job-icon-third {
        grid-area: icon-third;
      }

      .c-aufgaben--job-content-first {
        grid-area: content-first;
      }

      .c-aufgaben--job-content-second {
        grid-area: content-second;
      }

      .c-aufgaben--job-content-third {
        grid-area: content-third;
        max-height: 1lh;
      }

      .c-aufgaben--job-icon {
        padding-top: var(--sco-spacing-4);
      }

      .c-aufgaben--job-content {
        padding-top: var(--sco-spacing-4);
      }

      .c-aufgaben--job-icon {
        color: var(--sco-color-white);
        text-align: center;
      }

      .c-aufgaben--job-content {
        margin-left: var(--sco-spacing-4);
        text-overflow: ellipsis;
        font-family: var(--sco-font-family-lato-regular);
        font-weight: normal;
        overflow: hidden;
        white-space: nowrap;
      }

      &.c-aufgaben--job-type-kunde {
        .c-aufgaben--job-icon {
          color: var(--sco-color-kunde-text);
          background-color: var(--sco-color-kunde-background);
        }
      }

      &.c-aufgaben--job-type-planer {
        .c-aufgaben--job-icon {
          color: var(--sco-color-planer-text);
          background-color: var(--sco-color-planer-background);
        }
      }

      &.c-aufgaben--job-type-projekt {
        .c-aufgaben--job-icon {
          color: var(--sco-color-projekt-text);
          background-color: var(--sco-color-projekt-background);
        }
      }

      &.c-aufgaben--job-type-neutral {
        .c-aufgaben--job-icon {
          color: var(--sco-color-white);
          background-color: var(--sco-color-grey-600);
        }
      }

      &.c-aufgaben--job-negative {
        * {
          text-decoration: line-through;
        }
      }

      .c-aufgaben--job-loading {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: white;
        text-align: center;
      }

      .c-aufgaben--job-loading-spinner {
        position: relative;
        text-decoration: none;
        top: 10px;
      }
    }

    .c-aufgaben--job-status-corner {
      position: absolute;
      top: 0;
      right: 0;
      border-style: solid;
      border-width: 24px 24px 0 0;
      transform: rotate(-90deg);

      &.c-aufgaben--job-due {
        border-color: transparent var(--sco-color-red-600) transparent transparent;
      }

      &.c-aufgaben--job-positive {
        border-color: transparent var(--sco-color-primary) transparent transparent;
      }

      &.c-aufgaben--job-open {
        border-color: transparent var(--sco-color-grey-800) transparent transparent;
      }

      &.c-aufgaben--job-negative {
        border-color: transparent var(--sco-color-red-600) transparent transparent;
      }
    }
  }
}