@keyframes o-hyperform--fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* hyperform validation message */

.o-hyperform--warning {
    font-family: var(--sco-font-family-lato-regular);
    font-size: var(--sco-font-size-paragraphsmall);
    color: $color-border--invalid;
    animation: o-hyperform--fadein 0.2s;
    height: var(--sco-spacing-12);
    position: absolute;
    left: 0px;
    bottom: var(--sco-spacing-4);
    max-width: 100%;
}

.c-validation-message {
    color: $color-border--invalid;
}