$c-sidebar-color: var(--sco-color-primary);

.c-back-to-top-button {
  position: fixed;
  bottom: 10px;
  right: 0;
  z-index: 150;
  display: table;

  transition: bottom 0.2s ease;

  &.c-back-to-top-button--hidden {
    bottom: -48px;
  }
}
