.s-benutzerantrag-uebersicht {

    .s-benutzerantrag-uebersicht--grid-layout {

    }

    .s-benutzerantrag-uebersicht-auswahl--item {
        display: flex;
        min-height: 260px;
        min-width: 400px;
        align-items: center;
        justify-content: left;
        padding: 10px;
        text-align: left;
        background: var(--sco-color-primary);
        color: var(--sco-color-grey-700);
        border-radius: 0 !important;

        width: calc(20% - 10px);
        float: left;

        margin-right: 10px;
        margin-bottom: 10px;

        &:hover {
            background: var(--sco-color-green-500);
            color: var(--sco-color-grey-700);
        }

        .s-benutzerantrag-uebersicht-auswahl--item-content {
            margin-bottom: auto;
        }
    }
}

.benutzerantrag-section {
    margin-bottom: 10px;
}

.s-benutzerantrag-show {
    #s-benutzerantrag-show--send-mail-dialog--address-template {
        display: none;
    }

    .s-benutzerantrag-show--send-mail-dialog--additional-address-row :first-child {
      text-align: left;
    }
}