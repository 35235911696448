.c-percentBar--container {
  width: 100%;
}

.c-percentBar--outerRect {
  stroke-width: 0;
  left:0;
}

.c-percentBar--innerRect {
  stroke-width: 0;
  left:0;
}

.c-percentVar--innerColor {
  @extend .svg--fill-greenDefault;
}

.c-percentVar--outerColor {
  @extend .svg--fill-green10;
}