@import "components.datetimes-commons";

.c-input--time {
  @extend .datetimes-commons;
}

.c-input--time-picker--clockSectionItem {
  &.Mui-selected {
    background-color: var(--sco-color--picker-selected);
  }
  &.Mui-selected:focus {
    background-color: var(--sco-color--picker-selected);
  }

  &:hover {
    background-color: var(--sco-color--picker-hovered);
  }
}

/**
 Der Popper wird in der Desktop Variante geöffnet als 'digitale' Uhr
 */
.c-input--time-picker-popper {
  .MuiMultiSectionDigitalClock-root {
    .MuiMultiSectionDigitalClockSection-root {
      width: 65px;
      max-height: 200px;
    }
  }

  .MuiDialogActions-root.MuiPickersLayout-actionBar {
    justify-content: center;
  }

  .MuiMultiSectionDigitalClockSection-item {
    @extend .c-input--time-picker--clockSectionItem
  }
}


/**
 Der Dialog wird in der Mobile Variante geöffnet als 'analoge' Uhr
 */
.c-input--time-picker-dialog {
  .MuiPickersLayout-toolbar {
    align-items: center;

    .MuiPickersToolbar-content {
      justify-content: center;
    }
  }
  .MuiPickersLayout-actionBar {
    justify-content: center;
  }
}

