.s-wahlfeld-compare {
    .has-difference {
        color: $color-red--default
    }

    .s-wahlfeld-compare--comparison-row {
        &.show-differences {
            .s-wahlfeld-compare--show-differences-button {
                display: none;
            }

            .hide-difference {
                display: none;
            }
        }

        &.show-everything {
            .s-wahlfeld-compare--show-everything-button {
                display: none;
            }
        }

        .c-card--header .c-checkbox {
            height: unset;

            label {
                font-family: var(--sco-font-family-lato-regular);
            }
        }
    }
}