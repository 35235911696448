/*
 * Flex-Container Classes
 * Mit denen kann man ein Parent Element definieren, wie es sich als Flexbox-Container verhalten soll
 */
.flexbox {
  display: flex;
}

.flex--row {
  @extend .flexbox;
  flex-direction: row;
}

.flex--column {
  @extend .flexbox;
  flex-direction: column;
}

@each $wrap in wrap,wrap-reverse,nowrap {
  .flex--wrap-#{$wrap} {
    flex-wrap: #{$wrap};
  }
}

@each $justify in flex-start,flex-end,center,space-between,space-around,space-evenly,start,end,left,right {
  .flex--justify-content-#{$justify} {
    justify-content: #{$justify};
  }
}

@each $align in stretch,flex-start,flex-end,center,baseline {
  .flex--align-items-#{$align} {
    align-items: #{$align};
  }
}

@each $align in stretch,flex-start,flex-end,center,space-between,space-around {
  .flex--align-content-#{$align} {
    align-content: #{$align};
  }
}

@for $i from 1 through 50 {
  .flex--gap-#{$i} {
    gap: #{$i}px #{$i}px;
  }
  .flex--row-gap-#{$i} {
    row-gap: #{$i}px;
  }
  .flex--column-gap-#{$i} {
    column-gap: #{$i}px;
  }
}

/*
 * Flex-Item Classes
 * Mit denen kann man ein Kind-Elemente eines Flex-Container definieren, wie diese sich als Flexbox-Items verhalten sollen
 */

@for $i from 1 through 40 {
  .flex--order-#{$i} {
    order: #{$i}
  }
}

@for $i from 1 through 20 {
  .flex--grow-#{$i} {
    flex-grow: #{$i}
  }
}

@for $i from 1 through 20 {
  .flex--shrink-#{$i} {
    flex-shrink: #{$i}
  }
}

@for $i from 1 through 100 {
  .flex--basis-#{$i} {
    // % Zeichen muss escaped werden:
    flex-basis: #{$i}#{'%'};
  }
}
.flex--basis-auto {
  flex-basis: auto;
}

@each $align in auto,flex-start,flex-end,center,baseline,stretch {
  .flex--align-self-#{$align} {
    align-self: #{$align};
  }
}
