.s-abgleichtoolwebkontakte--filters {
  .c-section--content {
    padding: 15px 30px;
  }
  margin-top: 5px;
  margin-bottom: 20px;

  &.c-section.c-section--folded > .c-section--heading {
    background: var(--sco-color-grey-400);
    color: var(--sco-color-white);

    &:hover {
      background: var(--sco-color-grey-600);
    }
  }
}

.c-button--badge {
  background-color: var(--sco-color-primary);
  bottom: 25px;
}