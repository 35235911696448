@import "~nouislider/distribute/nouislider.css";

.c-slider {
  padding-top: 20px;
  padding-bottom: 12px;
  position: relative;

  &:before {
    content:'';
    display:block;
    position: absolute;
    height: 1px;
    width: 100%;
    background: black;
    top: 26px;
  }
  
  .noUi-target {
    height: 12px;
    width: calc(100% - 12px);
    left: 6px;
    border-radius: 0px;
    border: 0px;
    box-shadow: unset;
    background: transparent;

    &:before {
      content:'';
      display:block;
      position: absolute;
      height: 1px;
      width: 100%;
      background: black;
      top: 6px;
    }
  }

  .noUi-horizontal .noUi-handle {

    cursor: grab;

    &.noUi-active {
      cursor: grabbing;
    }

    right: -6px !important;
    top: 0px;
    box-shadow: unset;
    height: 12px;
    width: 12px;
    border-radius: 5px;
    border: 1px solid black;

    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }

  .noUi-connect {
    height: 1px;
    top: 6px;
  }

  label {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .c-slider--value {
    position: absolute;
    top: 0px;
    right: 0px;
  }

}