.c-image-upload-with-preview {
  position: relative;
  display: inline-block;

  .c-image-upload-with-preview--preview-container {
    position: relative;
    margin-bottom: 15px;
    width: 550px;
    padding-top: 56.25%; // 9 / 16
  }

  .c-image-upload-with-preview--preview {
    position: absolute;
    left:0;
    top:0;
    height: 100%;
    width: 100%;
    object-fit: contain;
    border: 1px solid $color-border--normal;

    opacity: 0;
  }

  .c-image-upload-with-preview--label {

  }

  .c-image-upload-with-preview--spinner {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: var(--sco-color-grey-400);
    opacity: 0.8;
  }

  .c-image-upload-with-preview--input {
    display: none;
  }

  .c-image-upload-with-preview--error {
    display: none;
    color: var(--sco-color-red-600);
    margin: 10px 0;
  }
}