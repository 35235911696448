#s-nachhaltigkeit-planungsbuch {
  &--year-tabs {
    .c-chart--echarts {
      min-width: 950px;
    }
    .c-card.s-nachhaltigkeit-planungsbuch--year-tab {
      margin-top: 4px;
    }
    .overview-table .edit-overview-button {
      color: $color-link--green;
      &:hover {
        color: $color-link--green-hover;
      }
    }
    .anfragen-buttons {
      display: flex;
      justify-content: right;
      margin-bottom: 10px;
    }
    .c-card {
      margin: 15px 0;
    }
    .projektanfragen-table {

      .verteilung {
        display: none;
      }
      .verteilung-trigger {
        cursor: pointer;
      }

      .s-nachhaltigkeit-planungsbuch--export-anfragen {
        color: var(--sco-color-white);
      }

      .c-section--content {
        overflow: auto;
      }

      &.c-section {
        margin-bottom: 15px;
      }

      table {
        tr:not(.parentrow) td {
          color: $color-text--gray;
        }
        th, td {
          vertical-align: top;
        }
        td.icon-col, div.imLvAusgeschrieben {
          text-align: center;
        }
        td.fold-icon-col {
          margin: 0;
          padding: 0;
          button {
            margin: 0;
            padding: 0;
          }
        }
        tr.parentrow.history-visible, tr:not(.parentrow) {
          td .changedPerHistory, tr td .changedPerHistory p {
            color: var(--sco-color-red-600);
            font-size: var(--sco-font-size-headline5);
          }

          td .changedPerHistory div.verteilung-changed {
            display: block;
          }
        }
        tr.parentrow td div.verteilung-changed {
          display: none;
        }
      }
    }
    .c-mitarbeiterpopup__img--visible {
      // Die Tabellenzeilen haben mind. 2 Textzeilen, da können wir das Icon entsprechend größer darstellen
      height: 2em;
      width: 2em;
      border-radius: 1em;
    }
  }
}

.s-nachhaltigkeit-planungsbuch .c-mitarbeiterdetails, .s-projekt-nachhaltigkeit .c-mitarbeiterdetails {
  td {
    text-align: left;
  }
}

.s-nachhaltigkeit-planungsbuch {
  .overview--edit {
    display: flex;
    flex-direction: column;
    text-align: left;

    h3 {
      font-size: 1.5rem;
      line-height: 1.25;
      margin-bottom: 10px;
    }

    td.icon-col {
      text-align: right;
    }

    .c-table--wrapper {
      margin-top: 10px;
    }

    .add-button, .history-button {
      align-self: end;
      margin-right: var(--sco-spacing-s);
    }

    .geschaetzte-menge-table tr:not(:first-child) {
      color: var(--sco-color-grey-300);
    }
  }
}

