.s-ticket-objektbeschlaege {
  .c-panel--title, .c-card--header, .c-card--header-title, .c-card--header-wrapper-left {
    background: #f2f2f2;
  }

  .c-tabs .ui-tabs-nav > li {
    background: #FFFFFF;
  }

}

.s-ticket-objektbeschlaege--form-wrapper {
  .c-card {
    @media screen {
      min-height: 290px;
    }
  }

  #anfrageFormFocusInfoCol {
    .c-card {
      min-height: unset;
    }
  }
}

.s-ticket-objektbeschlaege--card-container {

  display: flex;
  flex-wrap: wrap;
  margin: 0 - $gutter;

  > .c-card {
    min-width: 300px;
    flex: 1 0 300px;
    width: auto;
    margin: $gutter;
  }

}

.s-ticket-objektbeschlaege--card-content .c-focus-info-target {

  position: relative;

  padding-left: 45px;

  @include faicon-before($fa-var-exclamation-triangle);

  &:before {
    position: absolute;
    left: 0px;
    top: 5px;
    font-size: var(--sco-font-size-headline2);
    color: $color-text--gray;
  }

}

@keyframes objektbeschlaegeInfoTargetPulse {
  0% {  color: rgba(241, 135, 0, 0.3); transform: scale(1.0) }
  50% {  color: rgba(241, 135, 0, 1.0); transform: scale(1.1) }
  100% {  color: rgba(241, 135, 0, 0.3); transform: scale(1.0) }
}

.s-ticket-objektbeschlaege--card-content .c-focus-info-target--active {
  &:before {
    animation-name: objektbeschlaegeInfoTargetPulse;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
}

@include mobile {
  #anfrageFormFocusInfoCol {
    display: none;
  }
}
@include tabletPortrait {
  #anfrageFormFocusInfoCol {
    display: none;
  }
}

#s-ticket-objektbeschlaege--documents {
  table {
    font-size: 12px;
  }
  button {
    margin: 5px 0px;
  }
}

.s-ticket-objektbeschlaege--danglingfiles {
  margin-bottom: 10px;
}
.s-ticket-objektbeschlaege--danglingfiles-list {
  font-weight: bold;
}
.s-ticket-objektbeschlaege--danglingfiles-remove {
  padding: 0px 10px 0px 5px;
  display: inline-block;
  @include faicon-before($fa-var-times-circle);
  &:hover:before {
    color: var(--sco-color-red-600);
  }
}

@media print {
  .s-ticket-objektbeschlaege .o-row,
  .s-ticket-objektbeschlaege .o-col {
    display: block !important;
  }

  .s-ticket-objektbeschlaege {
    .c-content--main .c-panel {
      box-shadow: none;
    }

    .c-panel--content {
      padding: 15px 0px;
    }

  }
}

@page {

    padding: 20px;

}
