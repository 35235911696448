.c-radio-button-group {

  width: 100%;

  .c-radio {
    margin: 0 5px 0 0;

    .c-radio--virtualradio {
      &:before {
        content: none;
      }
    }

    height: 32px;
  }

  label, input {
    display: block;
    position: absolute;
    bottom: 5px;
  }

  input[type="radio"] {
    opacity: 0.011;
    z-index: 100;
  }

  label {
    padding-bottom: 4px;
    z-index: 90;
  }

  .c-radio--parent {
    width: 100%;
    text-align: center;

  }

  .c-radio--parent:has(input[type="radio"]:checked) {
    background: var(--sco-color-primary);
    color: var(--sco-color-white);
  }

  .c-radio--parent:not(:has(input[type="radio"]:checked)) {
    background: $color-black--40;
    color: var(--sco-color-white);
  }

}

/*.c-radio--label + div.c-radio:has(input[type="radio"]:checked) {
    background: var(--sco-color-primary);

}

.c-radio--label + div.c-radio:not(:has(input[type="radio"]:checked)) {
    background: $color-black--40;

}*/




