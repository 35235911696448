.c-kpmexternnetwork__node-company-info-badge:hover, .c-kpmexternnetwork__node-company-link-badge {
  cursor: pointer;
}

.c-kpmexternnetwork--info-dialog {
  .c-card {
    min-height: auto;
  }
  &.info-dialog-header {
    flex-wrap: nowrap;
    .c-icon.far.fa-arrows-left-right {
      align-self: center;
    }
    .c-card {
      flex-grow: 1;
      flex-shrink: 1;
    }
    .c-card--header-title {
      text-align: left;
    }
    .details {
      text-align: left;
      font-weight: normal;
    }
  }
  &.info-dialog-content {
    justify-content: left;
    .c-card--header-title {
      text-align: left;
    }
    .c-card.c-card--fullwidth {
      flex-grow: 1;
    }
    .c-card.no-content .c-card--content {
      padding: 0;
    }
    .c-card.table-container .c-card--content {
      padding: 0;
      td:last-child
      , th:last-child{
        padding-right: 15px;
      }
      td:first-child
      , th:first-child{
        padding-left: 15px;
      }
    }
    hr {
      margin: 24px 0 24px 0;
      background-color: var(--sco-color-primary);
      border: none;
      height: 4px;
    }
    th {
      background-color: var(--sco-color-grey-100);
      text-align: center;
      &.align-left {
        text-align: left;
      }
      &.align-right {
        text-align: right;
      }
    }
    td {
      text-align: left;
      &.number {
        text-align: right;
      }
    }
    .overview-table {
      td {
        &.detailslink{
          cursor: pointer;
          &:hover {
            color: var(--sco-color-primary);
          }
        }
      }
      tfoot td, strong {
        font-weight: 900;
        //Die nächsten 2 Zeilen sind ein Workaround für Chrome - sonst gibt es kein Fettdruck - auch font-weight: bold geht nicht
        -webkit-font-smoothing: antialiased;
        //font-family: var(--sco-font-family-lato-regular);
      }
      &.variante-1 td {
        --start-l: 50%;
        --step-l: 10%;
        --step: 1;
        &:nth-child(5)
        ,&:nth-child(6)
        ,&:nth-child(10){
        --step: 2;
        }
        &:nth-child(7)
        ,&:nth-child(8)
        ,&:nth-child(11) {
          --step: 3;
        }
        &.detailslink.anzahl:hover {
          --step: 4;
        }
        &.anzahl {
          background-color: hsl(100, 33%, calc(var(--start-l) + var(--step) * var(--step-l)));
        }
        &.forecast {
          background-color: hsl(80, 33%, calc(var(--start-l) + var(--step) * var(--step-l)));
        }
        &.entscheider {
          background-color: hsl(60, 33%, 80%);
        }
      }
      &.variante-2 {
        .anzahl {
          border-left: solid gray 1px;
        }
        .anzahl ~ .anzahl {
          border-left: none;
        }
        .forecast {
          border-left: solid gray 1px;
        }
        .forecast ~ .forecast {
          border-left: none;
        }
        .entscheider {
          border-left: solid gray 1px;
        }
        .entscheider ~ .entscheider {
          border-left: none;
        }
      }
    }
    #externnetwork-info-dialog-details {
      th {
        text-align: left;
        &.number {
          text-align: right;
        }
      }
    }
  }
}
