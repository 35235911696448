@mixin number-of-dashboard-tiles-per-row($nr) {
    flex-basis: calc(#{100%/$nr} - 10px);
}

.c-dashboard-flex-layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -5px;
}

.c-dashboard-flex-tile {
    flex-shrink: 0;
    flex-grow: 0;
    min-height: 400px;
    margin: 5px;

    @include number-of-dashboard-tiles-per-row(3);

    @include mobile {
        flex-shrink: 0;
        flex-grow: 0;
        @include number-of-dashboard-tiles-per-row(1);
    }

    @include tabletPortrait {
        flex-shrink: 0;
        flex-grow: 0;
        @include number-of-dashboard-tiles-per-row(1);
    }

    @include tabletLandscape {
        flex-shrink: 0;
        flex-grow: 0;
        @include number-of-dashboard-tiles-per-row(2);
        min-height: 300px;
    }

    @include wider-than-desktop {
        flex-grow: 0;
        flex-shrink: 0;
        @include number-of-dashboard-tiles-per-row(4);
    }
}