
body { // Prefix with body to get a higher priority than the generated inline styles

  .MuiPickersToolbar-toolbar {
    background-color: var(--sco-color-grey-200);
  }

  .MuiTypography-colorPrimary {
    color: var(--sco-color-primary);
  }

  .MuiButton-textPrimary {
    color: var(--sco-color-white);
    border-radius: 0;
    text-transform: none;
    background-color: var(--sco-color-primary);

    &:hover,
    &:active {
      background-color: var(--sco-color-green-800);
      color: var(--sco-color-white);
    }
  }

  .sco-month-picker {
    .MuiButton-textPrimary {
      &:nth-last-of-type(2) {
        background-color: var(--sco-color-red-600);
        &:hover {
          background-color: $color-red--light;
        }
      }
    }
  }

  .MuiPickersMonth-monthSelected {
    color: $color-green--default !important;
  }

  .MuiTextField-root {
    display: flex;
    width: 100%;

    input {
      @extend .c-input--text;

      box-sizing: border-box;
    }
  }

  .MuiInput-underline:after {
    border-bottom-color: var(--sco-color-primary);
  }

  .MuiInputBase-input {
    font-family: var(--sco-font-family-lato-light);
    font-size: var(--sco-font-size-headline5);
    color: var(--sco-color-grey-700);
  }

  .MuiButtonBase-root {
    .MuiButton-label {
      .MuiTypography-subtitle1 {
        color: var(--sco-color-primary);
      }
    }
  }

  .MuiPickersToolbarText-toolbarBtnSelected {
    color: var(--sco-color-grey-600);
  }

}


//                 border: 'unset',
//                 width: 'unset',
//                 height: 'unset',
//                 margin: 'unset',
//                 minWidth: 'unset',
//                 boxSizing: 'unset',