.s-login {
  $loginContentWidth: 450px;
  $loginContainerHeight: 400px;

  .c-master-container {
    min-height: 100%;
  }

  &.c-navigation--initiating.c-navigation--unset,
  &.c-navigation--enabled.c-navigation--active {
    .c-master-container {
      left: 0;
    }
  }

  .c-content--main {
    justify-content: center;
  }

  .c-header {
    @include mobileLandscape {
      display: none;
    }
  }

  .login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 480px;

    .c-card--header {
      @include mobileLandscape {
        //display: none;
        padding: 0 15px;
      }
    }
  }

  .login-container {
    display: flex;
    flex-direction: row;
    height: $loginContainerHeight;

    @include mobileLandscape {
      height: auto;
    }

    .login-image-container {
      @include mobile {
        display: none;
      }
    }

  }

  .login-image {
    height: $loginContainerHeight;
  }

  .login-content {
    padding-left: 20px;
    width: $loginContentWidth;
    display: flex;
    align-items: center;
  }

  .login-form {
    width: 100%;
  }

  .login-credentials-message {
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .login-terms-and-conditions {
    margin-left: 10px;

    @include mobileLandscape {
      margin-bottom: 0;
    }
  }

  .login-form-submit {
    margin-right: 10px;
    text-align: right;
  }

  .login-service-and-support {
    margin-top: 20px;
  }

  .login-user-messages {
    margin-top: 10px;
    margin-bottom: 20px;

    .errorMsg {
      color: var(--sco-color-red-600);
    }

    @include mobileLandscape {
      margin: 0;
      font-size: var(--sco-font-size-paragraph);
    }
  }
}