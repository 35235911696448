.c-calendar {

  .c-calendar--day-header-content-dayname {
    font-family: var(--sco-font-family-lato-regular);
    font-size: var(--sco-font-size-headline6);
  }

  .c-calendar--day-header-content-day-of-month {
    font-family: var(--sco-font-family-lato-bold);
    font-size: var(--sco-font-size-headline5);
  }

}