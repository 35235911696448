.s-messe-nachbereitung {
  #s-messe-nachbereitung--dashboard {
    flex-flow: column;

    #kontaktliste-header {
      .list--title {
        .c-card--header-wrapper-left {
          max-width: unset;
        }
        .c-card--header-title {
          display: flex;
          .divider {
            background-color: $color-gray--120;
            width: 2px;
            margin: 0 5px;
          }
          span {
            color: $color-green--default;
            font-size: large;
          }
          a {
            color: $color-gray--90;
            font-size: large;
            &:hover {
              color: $color-black;
            }
          }
        }
      }
      .c-card--content {
        padding: 0 15px;
      }
    }

    .c-dashboard-flex-tile {
      min-height: auto;
    }

    .c-card--header:not(.list--title) {
      overflow: auto;
      .c-card--header-wrapper-left {
        //min-width: 30vw;
        max-width: 30vw;

        a#link--show-zugeordnete-kontakte {
          color: $color-green--default;
        }
      }
      .c-card--header-wrapper-center {
        padding-left: 15px;
        a#link--show-nicht-zugeordnete-kontakte {
          color: $color-green--default;
          font-size: larger;
        }
        span#link--show-nicht-zugeordnete-kontakte {
          font-size: larger;
        }
        flex-grow: 1;
        justify-content: space-around;

        .mitarbeiter-info {
          display: flex;
          align-items: center;
        }
      }

      .c-card--header-icon, .c-icon {
        color: var(--sco-color-grey-400);
      }

      .c-icon {
        margin-left: 16px;
        font-size: var(--sco-font-size-headline3);
        margin-right: 10px;

        &.kontaktinfo--icon {
          margin-left: 0;
        }
      }

      #back-button.c-button {
        color: var(--sco-color-white);
      }

      .c-button {
        color: var(--sco-color-primary);
        &:hover {
          color: var(--sco-color-green-800);
        }

        &.button-show {
          color: $color-green--default;
          &:hover {
            color: $color-green--dark;
          }
        }
        &.button-create {
          color: $color-gray--90;
          &:hover {
            color: $color-gray--60;
          }
        }
      }

      .c-progress-tracker {
        display: flex;
        flex-wrap: nowrap;
        padding: 20px 40px 0 30px;

        .c-progress-tracker--step-label {
          white-space: nowrap;
        }
      }

      .c-card--header-right {
        .c-button--disabled {
          color: var(--sco-color-grey-300);
        }
        .c-button--disabled.anlage-projekt-anderer-benutzer {
          color: $color-green--default;
          &:hover {
            color: $color-green--dark;
          }
        }
        .c-button--disabled.anlage-projekt-anderer-benutzer {
          color: $color-green--default;
          &:hover {
            color: $color-green--dark;
          }
        }
      }
    }

    .c-section--foldable.c-section--folded .c-section--heading {
      background-color: var(--sco-color-grey-400);
      color: var(--sco-color-white);
    }
  }
  #undelete-table {
    .c-checkbox {
      justify-content: center;
    }
  }

  .leadbogen--groups {
    display: flex;
    flex-wrap: wrap;

    .leadbogen--group {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      min-width: 25ch;
      * {
        padding: 0;
      }

      .leadbogen--group-label {
        font-size: larger;
      }
      .c-checkbox, .c-radio {
        height: 1.8em;
      }
      .leadbogen--field {
        margin-bottom: 15px;
      }
      .leadbogen--field-CHECKBOX {
        margin-bottom: 0;
      }
      .leadbogen--field-CHECKBOX + :not(.leadbogen--field-CHECKBOX) {
        margin-top: 15px;
      }
      .c-radio--group-label {
        display: block;
        margin-bottom: 5px;
      }
      .c-radio--parent {
        display: flex;
        gap: 3px;
      }
      .c-radio--label {
        color: $color-gray--40;
      }
    }
  }

  .contact-matching {
    .c-button--stacked-add-button {
      background-color:$color-green--default;
      border:none;

      .c-button--item-type {
        color:white;
        font-size: 1.5em;
        padding: 5px 10px 11px 0;
      }

      .c-button--add {
        color:white;
        position: absolute;
        right: 3px;
        bottom: 3px;
      }
    }
  }
}