@font-face {
  font-family: "CPSIcons";
  src: url('#{$cps-icons-font-path}/cps-icons.eot');
  src: url('#{$cps-icons-font-path}/cps-icons.eot') format('embedded-opentype'),
  url('#{$cps-icons-font-path}/cps-icons.woff') format('woff'),
  url('#{$cps-icons-font-path}/cps-icons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.cps-ic {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: normal;
}


.cps-ic-software:before {
  content: '\EA01';
  font-family: "CPSIcons";
}

.cps-ic-commercial:before {
  content: '\EA02';
  font-family: "CPSIcons";
}

.cps-ic-logistic:before {
  content: '\EA03';
  font-family: "CPSIcons";
}

.cps-ic-technical:before {
  content: '\EA04';
  font-family: "CPSIcons";
}

.cps-ic-service:before {
  content: '\EA05';
  font-family: "CPSIcons";
}

.cps-ic-group:before {
  content: '\EA06';
  font-family: "CPSIcons";
}

.cps-ic-hands-diamond:before {
  content: '\EA07';
  font-family: "CPSIcons";
}

.cps-ic-career:before {
  content: '\EA08';
  font-family: "CPSIcons";
}
