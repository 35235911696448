
.c-panel {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-bottom: 30px;
  background: var(--sco-color-white);
}

.c-panel--content {
  padding: $pannel--padding;
}

.c-panel--divider {
  height: 17px; //$pannel--padding;
  margin-bottom: $pannel--padding;
  display: flex;

  .c-panel--divider-icon {
    flex: 0;
    &:before {
      font-size: var(--sco-font-size-headline4);
      top: 2px;
      position: relative;
    }
  }
  .c-panel--divider-title {
    flex: 0;
    padding: 0 5px 0 10px;
    color: var(--sco-color-grey-700);
    font-size: var(--sco-font-size-headline5);
    white-space: nowrap;
  }

  &:after {
    flex: 1;
    border-bottom: 2px solid $color-border--normal;
    content: ' ';
  }
}

.c-panel--title {
   font-size: var(--sco-font-size-headline4);
   color: var(--sco-color-grey-700);
   padding: $pannel--padding;
   position: relative;
 }

.c-panel--sub-title {
  font-size: var(--sco-font-size-headline5);
  color: var(--sco-color-grey-700);
  padding-left: $pannel--padding;
  padding-right: $pannel--padding;
  padding-bottom: $pannel--padding;
  position: relative;
}

.c-panel--title-actions {
  position: absolute;
  right: $pannel--padding;
  top: 0;
  height: 100%;

  > * {
    margin-left: $pannel--action-spacing;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  @media print {
    display: none;
  }
}