/*
  Wir erstellen ein Layout-System, das jeder Spalte eine Mindestbreite von 80px bereitstellt (Bootstrap-Standard).

  - Das Layout ist so aufgebaut, dass auf einem Full-HD Display in jedem Fall alle Spalten einer Row in einer Zeile dargestellt werden können.
  - Wir haben 1920 - 250(Navigation) - 2*20(Content Padding) = 1630px Platz
  - Bei einer Spaltenbreite von mind. 80px kommen wir auf ein 20-spaltiges Layout bei dieser Größe.

  - Sollte der verfügbare Platz größer sein, haben alle Spalten mehr platz und der Inhalt wird gestretcht.
  - Sollte der verfügbare Platz kleiner sein, erzeugt das Layout in der Mitte jeder Zeile einen Umbruch - und der Platz wird entsprechend auf die Elemente aufgeteilt.

  - Alternativ zum Umbruch kann man auch optionale Spalten auf kleinen Displays automatisch ausblenden lassen
 */

@import "~cq-prolyfill/mixins.scss";

@function strip-units($number) {
  @if type-of(#{$number}) == "number" or not unitless($number) {
    $num-length  : str-length(#{$number});
    $unit-length : str-length(unit($number));
    @return str-slice(#{$number}, 1,  $num-length - $unit-length);
  } @else {
    @warn "This isn't a number, or it doesn't have any units anyway.";
    @return $number;
  }
}

$width-fhd: 1920px;
$min-column-width: 80px; // Das Padding (Gutter) liegt innerhalb der Columns
$spacing: 80px;

$width-fhd-content: $width-fhd - $navigation-width - ($content-padding*2) - ($pannel--padding*2) - $spacing; // 2019-05-06: 1520px
$number-of-columns-fhd-content: floor($width-fhd-content / $min-column-width); // 2019-05-06: 19 columns

/* Es gibt eine Variante, die den horizontalen Platz (von FHD ausgehend) in zwei Teile teilt: Einen für Formular-Content, einen für Buttons.
 * Dazu werden die Rows mit den größen form-with-btns-content und form-with-btns-btns definiert
 */
$width-form-with-btns-content: $width-fhd-content - ($min-column-width * 4);  // 2019-05-06: 1200px
$number-of-columns-form-with-btns-content: floor($width-form-with-btns-content / $min-column-width);  // 2019-05-06: 15 columns

$width-form-with-btns-btns: $min-column-width * 4; // 2019-05-06: 320px
$number-of-columns-form-with-btns-btns: floor($width-form-with-btns-btns / $min-column-width); // 2019-05-06: 4 columns

// Hier könnten das Layout für jede beliebige Anzahl von Spalten optimieren
// Aus Performance-Gründen (cq-prolyfill) sollten wir aber nur die CSS-Klassen generieren, die wir benötigen.
$layout-map: (
    "fhd": $number-of-columns-fhd-content,
    "form-with-btns-content": $number-of-columns-form-with-btns-content,
    "form-with-btns-btns": $number-of-columns-form-with-btns-btns,
    "12": 12, // 80*12 = 960 (Passt bei iPad 1024 noch drauf, wenn die volle Breite vorhanden ist; in einer Card allerdings nicht mehr
    "9": 9  // Für Darstellung innerhalb von Cards. In voller Breite anzeigbar auf iPads und auf kleinstem Desktop (inkl Seitenmenü, d.h. 762px card content)
);

// Platz zwischen Spalten (Padding, d.h. der Content einer Spalte hat $gutter*2 weniger Platz
$gutter: 10px;

// Die Row
.o-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100% + (#{2*$gutter}));
  position: relative;
  margin: 0px #{-$gutter};
  min-height: 0; // fixes overflow/scrolling issues in FF/Edge/Safari: https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts/
}

// Spalten
.o-col {
  position: relative;
  //overflow-x: hidden;
}

.o-col-inner {
  padding: 0px #{$gutter};
  height: 100%;
}


@each $name, $columns in $layout-map {
  // Folgende Definition ist für ein Layout mit $columns Spalten
  $all-columns-in-single-row-width: $columns * $min-column-width;

  .o-row-#{$name} {
    @for $ncolswidth from 1 through $columns {
      > .o-col-#{$ncolswidth} {
        $percentage-size: min(100%, ((100% / ($columns / 1)) * $ncolswidth));
        $growingratio: strip-units($percentage-size*1000);
        flex: $growingratio 0 $percentage-size; //$ncolswidth*$min-column-width;
        min-width: $ncolswidth*$min-column-width;
        max-width: 100%;
        &.o-col-fixed {
          flex-basis: $ncolswidth*$min-column-width;
        }
      }
      > .o-col-#{$ncolswidth}-half {
        $percentage-size: min(100%, ((100% / ($columns / 1)) * $ncolswidth));
        $growingratio: strip-units($percentage-size*1000);
        flex: $growingratio 0 $percentage-size; //$ncolswidth*$min-column-width;
        min-width: $ncolswidth*$min-column-width + $min-column-width/2;
        max-width: 100%;
        &.o-col-fixed {
          flex-basis: $ncolswidth*$min-column-width + $min-column-width/2;
        }
      }
    }
    > .o-col-half {
      $percentage-size: 2%;
      $growingratio: strip-units($percentage-size*1000);
      flex: $growingratio 0 $percentage-size; //$ncolswidth*$min-column-width;
      min-width: $min-column-width/2;
      max-width: 100%;
      &.o-col-fixed {
        flex-basis: $min-column-width/2;
      }
    }
  }

  @each $part in 1, 2, 4, 8 {
    .o-row-#{$name} {
      @for $ncolswidth from 1 through $columns {
        > .o-col-#{$ncolswidth} {
          // Das ist ein container-query cq-prolyfill ( https://github.com/ausi/cq-prolyfill )
          //
          @include cq-prolyfill("width <= #{($all-columns-in-single-row-width / $part) - 1px}") {
            $percentage-size: min(100%, ((100% / ($columns / ($part*2))) * $ncolswidth));
            $growingratio: strip-units($percentage-size*1000);
            flex: $growingratio 0 $percentage-size; //$ncolswidth*$min-column-width;
            min-width: $ncolswidth*$min-column-width;
            max-width: 100%;
          }
          &.o-col-fixed {
            @include cq-prolyfill("width <= #{($all-columns-in-single-row-width / $part) - 1px}") {
              flex-basis: $ncolswidth*$min-column-width;
            }
          }
        }
      }
    }
  }

  .o-col-fixed {
    flex-grow: 1 !important;
  }
}

.o-row--alignleft {
  justify-content: flex-start !important;

  > .o-col {
    flex-grow: 0 !important;
  }
}

.o-col-vcenter > .o-col-inner{
  display: flex;
  align-items: center;
}