@import "components.datetimes-commons";

.c-input--week {
  @extend .datetimes-commons;
}

.calender-view {
  .MuiDayCalendar-monthContainer {
    .day {
      border-radius: 0;
    }
    .day-selected {
      background-color: var(--sco-color--picker-selected);
    }
    .day-hovered {
      background-color: var(--sco-color--picker-hovered);
    }
    .start-of-week {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
    .end-of-week {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }
}

/**
 Der Popper wird in der Desktop Variante geöffnet als 'digitale' Uhr
 */
.c-input--week-picker-popper {
  @extend .calender-view;
  //.MuiMultiSectionDigitalClock-root {
  //  .MuiMultiSectionDigitalClockSection-root {
  //    width: 65px;
  //    max-height: 200px;
  //  }
  //}
  //
  //.MuiDialogActions-root.MuiPickersLayout-actionBar {
  //  justify-content: center;
  //}
}


/**
 Der Dialog wird in der Mobile Variante geöffnet als 'analoge' Uhr
 */
.c-input--week-picker-dialog {
  @extend .calender-view;
  //.MuiPickersLayout-toolbar {
  //  align-items: center;
  //
  //  .MuiPickersToolbar-content {
  //    justify-content: center;
  //  }
  //}
  //.MuiPickersLayout-actionBar {
  //  justify-content: center;
  //}
}

