@import '../../node_modules/select2/src/scss/core.scss';

$c-dropdown--border: 1px;
$c-dropdown--border-focus: 2px;

.c-dropdown--container {
  text-align: left;

  select {
    width: 100%;
    visibility: hidden;
  }
}

// Select 2 creates a separate overlay in the html body
.c-dropdown--select2-dropdown {

  border: 0px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);

  z-index: 1100; // must be greater than the z-index of dialogs

  .select2-results__options[aria-multiselectable="true"] {
    .select2-results__option {
      white-space: nowrap;
      vertical-align: middle;

      &:before {
        vertical-align: middle;
        content: '';
        display: inline-block;
        margin-right: 5px;
        border: 2px solid $color-border--dark;
        width: 20px;
        height: 20px;
      }
    }

    .select2-results__option[aria-selected=true] {
      @include faicon-before($fa-var-check);
    }
  }

  .select2-results__option {
    min-height: 32px; // height + padding
    padding: 6px;
  }

}

.select2-dropdown {
  z-index: 1100;
  font-size: var(--sco-font-size-headline5);
}

.select2-selection {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: $c-input--border solid $color-border--normal !important;
}

.select2-selection:hover {
  border-bottom: $c-dropdown--border-focus solid $color-border--hover !important;
}

.select2-container--disabled .select2-selection:hover {
  border-bottom: $c-input--border solid $color-border--normal !important;
}

.select2-selection:focus {
  border-bottom: $c-dropdown--border-focus solid $color-border--focus !important;
}

.select2-container--disabled .select2-selection:focus {
  border-bottom: $c-input--border solid $color-border--normal !important;
}

.select2-selection__rendered {
  padding-top: 3px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 30px !important;
  text-overflow: ellipsis;
  overflow: visible;
  max-height: 100%;
  white-space: nowrap;
  font-size: var(--sco-font-size-headline5);

  &[data-ddstyle="autocomplete"] {
    padding-right: 0px !important;
  }

  > span {
    display: inline-block !important;
    float: none !important;
  }

  > span.select2-selection__clear {
    display: inline-block !important;
    float: right !important;
    padding: 5px;
    margin: 0px 0px 0px 10px !important;
  }

  > .c-dropdown--selection-div {
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
    white-space: nowrap;
  }
}

.c-dropdown--container .select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding-right: 5px !important;
}

.select2-container--default .select2-selection--single {
  border-radius: 0px;
  height: 32px;

  .select2-selection__rendered {
    line-height: 36px;
  }
}

.select2-container--default .select2-selection--multiple {
  border-radius: 0px;
  height: 32px;
}

.c-dropdown--wrapper {
  display: inline-block;
  position: relative;
  height: var(--sco-input-wrapper-height);
  width: 100%;
  margin-bottom: 4px;

  // This is the select2 element
  .c-dropdown--container {
    display: block;
    position: relative;
    padding: 12px 0px 1px 0px;
    height: $input--height;
    line-height: 17px;
    margin-bottom: 10px;
    background: var(--sco-color-white);

    // #################################################################################################################
    // Styles fuer eine Dropdown, um sie als Button darzustellen: Einfach class="style-header-button" anfügen
    // #################################################################################################################
    &.style-header-button {
      background-color: unset;
      min-width: 100px;

      & span.select2-selection {
        background: var(--sco-color-primary);
      }

      & .c-dropdown--selection-div {
        color: var(--sco-color-white);
        padding-left: 5px;
      }

      & .select2-selection__arrow b {
        border-color: $color-white transparent transparent transparent;
      }

      & .select2-selection:hover {
        border-bottom: none !important;
      }

      .select2-container--default .select2-selection--single .select2-selection__rendered {
        line-height: 28px;
        margin-left: 8px
      }

    }
  }

  // The label
  label {
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 10;
    pointer-events: none;
    cursor: text;
    transition: all 0.2s;
    color: $color-text--label;
    font-family: var(--sco-font-family-lato-regular);
    font-size: var(--sco-font-size-headline6);

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100% - 17px);
  }


  .c-dropdown--container-focus ~ label {
    font-family: var(--sco-font-family-lato-black);
    font-size: var(--sco-font-size-headline6);
    //top: 0;
    color: $color-border--focus;
  }

  .c-dropdown--output ~ label,
  .c-dropdown--container-not-empty ~ label {
    font-family: var(--sco-font-family-lato-regular);
    font-size: var(--sco-font-size-headline6);
    //top: 0px;
  }

  .select2-container--default.select2-container--disabled .select2-selection--single {
    background: transparent;
    color: $color-text--input-disabled;
  }

  .o-hyperform--warning {
    z-index: 1;
  }

}

.c-dropdown--wrapper:has(.c-dropdown--output):after {
  content: var(--sco-icon-input);
  display: block;
  position: absolute;
  right: 10px;
  top: 12px;
  line-height: 44px;
  font-size: 16px;
  color: var(--sco-color-input--disabled);
  font-family: "Font Awesome 6 Pro";
  font-weight: 100;
}
.c-dropdown--wrapper:has(.c-dropdown--output):before {
  content: var(--sco-icon-input--disabled);
  display: block;
  position: absolute;
  right: 7px;
  top: 12px;
  line-height: 44px;
  font-size: 18px;
  color: var(--sco-color-input--disabled);
  font-family: "Font Awesome 6 Pro";
  font-weight: 100;
}

$c-dropdown--actionbar-height: 30px;

.c-dropdown--action-bar {
  height: $c-dropdown--actionbar-height;

  > div {
    display: inline-block;
    width: calc(100% / 3);
    height: $c-dropdown--actionbar-height;
    line-height: $c-dropdown--actionbar-height;
    vertical-align: middle;
    font-size: var(--sco-font-size-headline5);
    text-align: center;

    &:hover {
      color: var(--sco-color-primary);
      cursor: pointer;
    }
  }
}

.c-dropdown--action-bar-all {
  @include faicon-before($fa-var-check-circle);
  font-weight: 400;
}

.c-dropdown--action-bar-none {
  @include faicon-before($fa-var-times);
  font-weight: 400;
}

.c-dropdown--action-bar-reverse {
  @include faicon-before($fa-var-sync);
  font-weight: 300;
}


/* For Autocomplete, we are using the MultipleSelection template. We need to overwrite the "Tag"-like Style */
.c-dropdown--container[data-ddstyle="autocomplete"] {
  cursor: text;

  .select2-selection__choice {
    border: 0px !important;
    background: none !important;
    padding: 0px !important;
    position: absolute;
    left: 0px;
    bottom: 5px;
    font-family: var(--sco-font-family-lato-regular) !important;

    cursor: text;

    width: calc(100% - 33px);

    .c-dropdown--selection-div {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

}

.c-dropdown--output {
  padding: 12px 0px 1px 0px;
  height: $input--height;
  line-height: $input--height;
  vertical-align: middle;
  display: inline-block;
  color: $color-text--input-disabled;
  border-bottom: 1px solid #dddddd;
  width: 100%;
  background: var(--sco-color-white);
  text-align: left;
}

/* Color of the Dropdown Highlighting */
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: var(--sco-color-primary);
}

/* Multiple Selection Item Styling */
.c-dropdown--selection-div {
  display: inline-block;
}

.select2-selection__choice__remove {
  //transform: translateY(-1px);
  margin-bottom: 2px;
}

.no-search-box .select2-search--dropdown {
  display: none;
}

/* geoeffnete Dropdown Inhalte auch in Dialogen sichtbar */
.swal2-content .select2-container--open {
  z-index: 2000;
}

.swal2-content .c-dropdown--content-right {
  z-index: 2000;
}

.select2-container--open {
  .select2-results__option[aria-selected] {
    min-height: 2em;
  }
}

.c-dropdown--content-right {
  position: absolute;
  right: var(--sco-spacing-16);
  top: calc(50% - 2px);
  transform: translateY(-50%);
  background: var(--sco-color-white);
  padding: 0 5px 0 10px;

  .c-button--iconbutton {
    height: 26px;
    width: 26px;
    min-width: 26px;

    &:before {
      font-size: var(--sco-font-size-headline5);
    }
  }
}

/* Varianten */

/* Kein-Vertikaler Abstand */
.c-dropdown--wrapper-v-small {
  &.c-dropdown--wrapper {
    height: 45px;
  }

  &.c-dropdown--wrapper .c-dropdown--container {
    margin-bottom: 0px;
  }
}

/* Transparent statt weißer Hintergrund */
.c-dropdown--wrapper-transparent {
  &.c-dropdown--wrapper .c-dropdown--container {
    background: transparent;
  }

  .select2-container--default .select2-selection--single {
    background: transparent;
  }

  .select2-container--default .select2-selection--multiple {
    background: transparent;
  }
}

// Pfeil nach unten auch bei Multiselects anzeigen
.c-dropdown--wrapper {
  .c-dropdown--container:not([data-ddstyle=autocomplete]) {
    .select2-selection--multiple:before {
      content: "";
      position: absolute;
      right: 7px;
      top: 42%;
      border-top: 5px solid #888;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }
  }
}