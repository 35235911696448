.s-planer-ibau {
    .s-planer-ibau--flex-tile {
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: calc(100%/2 - 10px);
        min-height: 0;

        &.c-card--with-content-scrollable {
            min-height: 300px;
        }

        @include mobile {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: 100%;
        }

        @include tabletPortrait {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: calc(100%/2 - 10px);
        }

        @include tabletLandscape {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: calc(100%/2 - 10px);
        }

        @include wider-than-desktop {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: calc(100%/3 - 10px);
        }

        &#s-planer-ibau--card-PROJEKTLISTE {
            flex-basis: calc(100% - 10px);

            @include tabletPortrait {
                flex-basis: calc(100% - 10px);
            }

            @include tabletLandscape {
                flex-basis: calc(100% - 10px);
            }

            @include wider-than-desktop {
                flex-basis: calc(2*100%/3 - 10px);
            }
        }

        &#s-planer-ibau--card-BAUARTEN {
            flex-grow: 0;
        }

        &#s-planer-ibau--card-PROJEKTARTEN {
            flex-grow: 0;
        }

        &#s-planer-ibau--card-BAUROLLEN {
            flex-grow: 0;
        }
    }

    .c-card--header {
        min-height: 45px;
    }

    .s-planer-ibau--projektliste-dialog {
        width: 100%;
        max-width: 1600px;
    }

    .s-planer-ibau--projektliste-status-column, .s-planer-ibau--projektliste-ausschreibung-column {
        word-break: break-word;
        min-width: 140px;
    }

}
