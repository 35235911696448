@import 'quill/dist/quill.core';
@import 'quill/dist/quill.snow';

.c-htmlwysiwygeditor {

  padding: 10px;
  border: 1px solid #2b2b2b;
  background: #ffffff;


  .ql-editor {
    padding: 0px;
    //background: #fcffd2;
    background: #ffffff;
    overflow-x: hidden;
  }

  .ql-tooltip {
    z-index: 10;
  }

  .ql-container.ql-snow {
    border: 0px;
  }
}

.c-htmlwysiwygeditor--toolbar {
  background: #ffffff;
}

.c-htmlwysiwygeditor--toolbar .ql-toolbar {
  border: 1px solid black;
  border-bottom: 0px;
}


.c-htmlwysiwygeditor--wrapper {

  position: relative;

  .c-validation-message {
    display: none;
  }

  &.o-hyperform--invalid,
  &.invalid {

    .c-htmlwysiwygeditor {
      padding-bottom: 35px;
    }

    .c-validation-message {
      display: block;
      color: $color-border--invalid;
      position: absolute;
      bottom: 10px;
      left: 12px;
    }
  }
}

/* readonly */
.c-htmlwysiwygeditor-readonly {
  p, ol, ul, pre, blockquote, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

  ul {
    list-style-position: inside;
  }
}

/* v1 layout extras */
#masterContainer .c-htmlwysiwygeditor--wrapper {
  max-width: 810px;
}
