.s-kundensuche-map {
  .c-content--main {
    flex-direction: row;
    gap: 20px;
    margin-top: 15px;

    &:before {
      display: none;
    }

    #map {
      flex-grow: 2;
    }

    #params {
      flex-grow: 1;
    }
  }
}

.s-kundensuche-map--infoWindow-header {
  .gridPanelTable {
    margin-bottom: 3px;

    .titleRow {
      background-color: var(--sco-color-grey-230);
      height: 2em;
      td {
        color: var(--sco-color-grey-700);
        padding: 5px;
        border-radius: 3px;
      }
    }
    td {
      padding-left: 5px;
      padding-right: 10px;
      color: var(--sco-color-grey-400);
      a {
        color: var(--sco-color-grey-400);
        &:hover {
          color: var(--sco-color-primary);
        }
      }
    }
  }
  hr {
    background-color: #48802c;
    height: 1px;
    margin-bottom: 3px;
  }
}