:root {
  --sco-spacing-xs: 8px;
  --sco-spacing-s: 16px;
}

.sco-stepper__list {
//  background-color: yellow;
  display: flex;
  flex-direction: row;
  list-style-type: none;

  &.sco-stepper__list-stretch {
    justify-content: space-between;

    .sco-stepper__list-element {
      flex: 1;
    }
  }
}

.sco-stepper__list-element {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  margin-bottom: 0;
  font-size: var(--sco-font-size-headline4);
  &.first {
    padding-right: var(--sco-spacing-xs);
  }
  &.last {
    padding-left: var(--sco-spacing-xs);
  }

  // line connecting steps
  &.current::after, &.last.current::after, &.first:not(.current)::after {
    left: 50%;
  }
  &.current::before, &.first.current::before, &.current ~ &.last::after, &.last:not(.current)::after {
    right: 50%;
  }
  &.current::before, &.first.current::before, &.current ~ &.last::after, &.last:not(.current)::after, &.current::after, &.last.current::after, &.first:not(.current)::after {
    width: 50%;
    height: 2px;
    top: var(--sco-spacing-s);
  }
  &.current ~ &:not(.first):not(.last)::after, &:not(.first):not(.last):not(.current)::after {
    left: 0;
    top: var(--sco-spacing-s);
    width: 100%;
    height: 2px;
  }
  &.current::before, &.first:not(.current)::after, &:not(.first):not(.last):not(.current)::after {
    background-color: var(--sco-color-grey-600);
  }
  &.current::after, &.current ~ &:not(.first):not(.last)::after, &.current ~ &.last::after, &.last:not(.current)::after {
    background-color: var(--sco-color-grey-400);
  }
  &.last.current::after, &.first.current::before {
    background-color: transparent;
  }
  &:not(.first):not(.last) {
    padding: 0 var(--sco-spacing-xs);
  }
  &::before, &::after {
    content: "";
    position: absolute;
    z-index: 1;
  }

  &.sco-stepper__list-element-submit {
    cursor: pointer;
  }
}

.sco-stepper__step {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  color: white;
  background-color: var(--sco-color-grey-600);
}

.current .sco-stepper__step {
  background: var(--sco-color-primary);
}

.current ~ .sco-stepper__list-element .sco-stepper__step {
  background: var(--sco-color-grey-100);
  color: var(--sco-color-grey-600);
}

.current ~ .sco-stepper__list-element-submit:hover .sco-stepper__step {
  background: var(--sco-color-grey-200);
  color: var(--sco-color-grey-600);
}

.sco-stepper__list-element-submit:hover .sco-stepper__step {
  background: var(--sco-color-grey-800);
  color: white;
}

.sco-stepper__step-name {
  margin-top: var(--sco-spacing-xs);
  font-size: var(--sco-font-size-headline6);
}