
.ci-calendar-component {
  //margin-bottom: $ci-spacer-75;
  //float: left;
  width: 100%;
  margin-top: -44px;
}

.responsive{

  @include screen-xs {
    .fc-toolbar{
      margin: 0;
      margin-right: 200px;
      margin-left: calc( 50% - 200px );

      @include screen-m {
        margin: auto;
        margin-top: 50px;
      }
    }
  }
}

.fc {
  .fc-toolbar-title {
    font-size: var(--sco-font-size-headline5);
  }

  .fc-timegrid-now-indicator-line{
    right: 0;
    border: none;
    padding-top: 1px;
    margin: 0px;
    background-color: var(--sco-color-red-600);
    z-index: 5;
  }

  .fc-scroller-harness:has(.fc-daygrid-body) {
    background-color: var(--sco-color-grey-300);
  }

  .fc-scroller-harness:has(.fc-col-header) {
    background-color: var(--sco-color-white);
  }

  .fc-timegrid-slot {
    height: 21px !important;
    line-height: 1.2em !important;
    border-bottom: 0 !important;
  }

  .fc-timegrid-slot.fc-scrollgrid-shrink {
    height: 1.5em !important;
    line-height: 1.5em !important;
    border-bottom: 0 !important;
  }

  .fc-timegrid-now-indicator-arrow {
    border-radius: 5px;
    border-color: var(--sco-color-white);
    border-width: 1px;
    background: var(--sco-color-red-600);
    margin: -4px 0px 0px -4px;
    width: 8px;
    height: 8px;
    left:0;
    z-index: 5;
  }

  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
  }

  .fc-col-header {
    margin-bottom: 0;
  }

  .fc-scrollgrid-sync-table {
    margin-bottom: 0;
  }

  .fc-scroller {
    overflow: auto!important;
  }

  .fc-event-title {
    font-family: var(--sco-font-family-lato-black);
  }

  .fc-button:focus {
    box-shadow: none;
  }

  .fc-timeGridDay-view, .fc-timeGridWorkWeek-view, .fc-timeGridFullWeek-view {
    // Hintergrundfarben des Timegrids
    .fc-scrollgrid {
      border-top: none;
    }

    .fc-timegrid-cols {
      & .fc-day-past {
        background-color: var(--sco-color-grey-100);
      }

      & .fc-day-today {
        border-left-color: var(--sco-color-primary);
        border-right-color: var(--sco-color-primary);
        background-color: color-mix(in srgb, var(--sco-color-white), var(--sco-color-primary) 3%);
      }

      & .fc-day-future {
        background-color: var(--sco-color-white);
      }

      & .fc-timegrid-axis {
        background-color: var(--sco-color-white);
      }

      &:has(.fc-day-today) {
        .fc-day-past {
          border-right: none;
        }
      }
    }

    // Hintergrundfarben des Timegrids ganztaegig
    .fc-daygrid-body {
      & .fc-day-past {
        background-color: var(--sco-color-grey-100);
      }

      & .fc-day-today {
        border-left-color: var(--sco-color-primary);
        border-right-color: var(--sco-color-primary);
        background-color: color-mix(in srgb, var(--sco-color-white), var(--sco-color-primary) 3%);
      }

      & .fc-day-future {
        background-color: var(--sco-color-white);
      }

      & .fc-timegrid-axis {
        background-color: var(--sco-color-white);
      }

      &:has(.fc-day-today) {
        .fc-day-past {
          border-right: none;
        }
      }
    }

    .fc-daygrid-body {
      .c-calendar--event-content-first, .c-calendar--event-content-second, .c-calendar--event-content-third {
        max-height: 1lh;
      }
    }

    .fc-timegrid-axis {
      background-color: var(--sco-color-white);
    }

    // Spaltenueberschriften mit Datum
    .fc-col-header {
      border-top: 1px solid var(--fc-border-color);

      & .fc-day-past {
        background-color: var(--sco-color-grey-100);
      }

      & .fc-day-today {
        border-left-color: var(--sco-color-primary);
        border-right-color: var(--sco-color-primary);
        border-top: 1px solid var(--sco-color-primary);
        background-color: color-mix(in srgb, var(--sco-color-white), var(--sco-color-primary) 3%);
      }

      & .fc-day-future {
        background-color: var(--sco-color-white);
      }

      &:has(.fc-day-today) {
        .fc-day-past {
          border-right: none;
        }
      }
    }
  }

  // Monatsansicht
  .fc-dayGridMonth-view {
    .fc-daygrid-body {
      tr:not(:last-of-type) {
        .fc-daygrid-day {
          border-bottom: none;
        }
      }

      tr:has(.fc-day-today) {
        .fc-day-past {
          border-right: none;
        }
      }
    }

    & .fc-day-past {
      background-color: var(--sco-color-white);
    }

    & .fc-day-today {
      background-color: color-mix(in srgb, var(--sco-color-white), var(--sco-color-primary) 3%);
      border: 1px solid var(--sco-color-primary) !important;
    }

    & .fc-day-future {
      background-color: var(--sco-color-white);
    }

    & .fc-timegrid-axis {
      background-color: var(--sco-color-white);
    }
  }

  // Anzeige der Termine
  .fc-timeGridDay-view, .fc-timeGridWorkWeek-view, .fc-timeGridFullWeek-view, .fc-dayGridMonth-view, .fc-popover-body  {
    .fc-event {
      border-radius: 8px;

      .fc-event-main {
        overflow: hidden;
        padding: 0;
        border-radius: 7px;
      }

      .c-calendar--event-wrapper {
        display: grid;
        height: 100%;
        grid-template-columns: [first-line] 20px [line2] auto [line3] 20px [last-line];
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas:
          "icon-first  content-first  status"
          "icon-second content-second content-second"
          "icon-third  content-third  content-third";

        /* Area Zuordnungen */
        .c-calendar--event-icon-first {
          grid-area: icon-first;
        }

        .c-calendar--event-icon-second {
          grid-area: icon-second;
        }

        .c-calendar--event-icon-third {
          grid-area: icon-third;
        }

        .c-calendar--event-content-first {
          grid-area: content-first;
        }

        .c-calendar--event-content-second {
          grid-area: content-second;
        }

        .c-calendar--event-content-third {
          grid-area: content-third;
        }

        .c-calendar--event-icon {
          padding-top: var(--sco-spacing-4);
        }

        .c-calendar--event-content {
          padding-top: var(--sco-spacing-4);
        }

        .c-calendar--event-icon {
          color: var(--sco-color-white);
          text-align: center;
        }

        .c-calendar--event-content {
          margin-left: var(--sco-spacing-4);
          text-overflow: ellipsis;
          font-family: var(--sco-font-family-lato-regular);
        }

        &.c-calendar--event-type-kunde {
          .c-calendar--event-icon {
            color: var(--sco-color-kunde-text);
            background-color: var(--sco-color-kunde-background);
          }

          &.c-calendar--event-art-dgb {
            .c-calendar--event-icon {
              color: var(--sco-color-white);
            }
          }
        }

        &.c-calendar--event-type-planer {
          .c-calendar--event-icon {
            color: var(--sco-color-planer-text);
            background-color: var(--sco-color-planer-background);
          }

          &.c-calendar--event-art-dgb {
            .c-calendar--event-icon {
              color: var(--sco-color-white);
            }
          }
        }

        &.c-calendar--event-type-projekt {
          .c-calendar--event-icon {
            color: var(--sco-color-projekt-text);
            background-color: var(--sco-color-projekt-background);
          }

          &.c-calendar--event-art-dgb {
            .c-calendar--event-icon {
              color: var(--sco-color-white);
            }
          }
        }

        &.c-calendar--event-type-neutral {
          .c-calendar--event-icon {
            color: var(--sco-color-white);
            background-color: var(--sco-color-grey-600);
          }
        }
      }

      &.c-calendar--event-cancelled {
        text-decoration: line-through;
      }

      &.event-ms365-appointment {
        .c-calendar--event-type-neutral {
          .c-calendar--event-icon {
            color: var(--sco-color-blue-300);
            background-color: var(--fc-event-bg-color);

            &.c-calendar--event-icon-second {
              color: var(--sco-color-black);
            }
          }
        }
      }

      &:not(.event-ms365-appointment) .c-calendar--event-status-corner {
        position: absolute;
        top: 0px;
        right: 0px;
        border-style: solid;
        border-width: 24px 24px 0 0;
        transform: rotate(-90deg);

        &.icon-status-completed {
          border-color: transparent var(--sco-color-primary) transparent transparent;
        }

        &.icon-status-open {
          border-color: transparent var(--sco-color-grey-700) transparent transparent;
        }

        &.icon-status-cancelled {
          border-color: transparent var(--sco-color-red-600) transparent transparent;
        }

        &.icon-status-overdue {
          border-color: transparent var(--sco-color-red-600) transparent transparent;
        }
      }
    }
  }

  .fc-more-popover {
    z-index: 900;

    &.fc-day.fc-day-fri {
      right: 0;
      left: unset !important;
    }
  }

}


.fc-dragging,
.fc-selected,
.fc-time-grid-event.fc-selected {
  overflow: hidden;
}

/* fonts*/
.fc table {
  font-size: var(--sco-font-size-headline6);
  line-height: 1em;
}

.fc-time, .fc-axis{
  color: var(--sco-color-grey-600);
  font-weight: normal;
}

/* Headline, Buttons, Arrows */
.fc-toolbar {
  margin-bottom: 0;
  width: 250px;
  margin: auto;

  h2 {
    font-size:var(--sco-font-size-headline5);
    color: var(--sco-color-grey-600);
    font-weight: normal;
    margin-top: 5px;
  }

  .fc-button {
    border: none;
    background: none;
    text-shadow: none;
    box-shadow: none;
    color: var(--sco-color-primary);
  }

  .fc-state-hover{
    color: var(--sco-color-grey-600);
  }

  .fc-state-active{
    color: var(--sco-color-grey-600);
  }

  .fc-icon {
    font-family: 'Font Awesome Pro 6';
    font-size: var(--sco-font-size-headline5);
    height: 1.2em;

  }

  .fc-icon-left-single-arrow {
    &:after {
      top: 1px;
      content: "\F104";
      font-weight: normal;
    }
  }
  .fc-icon-right-single-arrow {
    &:after {
      top: 1px;
      content: "\F105";
      font-weight: normal;
    }
  }

}

.fc-header-toolbar{
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. Works despite error marking*/
  width: -moz-available;          /* WebKit-based browsers will ignore this. Works despite error marking*/
}

.fc-header-chunk{
  display: flex;
  align-items: center;
}

/*correct display of navigation toolbar*/
.fc-toolbar-chunk:has(h2){
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--sco-spacing-40);
}


:root {
  --fc-small-font-size: var(--sco-font-size-headline6);
  --fc-page-bg-color: var(--sco-color-white);
  --fc-neutral-bg-color: var(--sco-color-white);         // Trennlinie zw. ganztaegig und events
  //--fc-neutral-text-color: var(--sco-color-grey-600);
  --fc-border-color: var(--sco-color-grey-230);             // Rahmen der Tage

  --fc-button-text-color: var(--sco-color-white);
  --fc-button-bg-color: var(--sco-color-primary);
  --fc-button-border-color: var(--sco-color-primary);
  --fc-button-hover-bg-color: var(--sco-color-primary);
  --fc-button-hover-border-color: var(--sco-color-primary);
  --fc-button-active-bg-color: var(--sco-color-primary);
  --fc-button-active-border-color: none;

  --fc-event-bg-color: var(--sco-color-grey-100);                       // Hintergrundfarbe der Events
  --fc-event-border-color: var(--sco-color-black);                   // Rahmenfarbe der Events
  --fc-event-text-color: var(--sco-color-grey-600);                  // Textfarbe des Events und der Eventuhrzeit
  //--fc-event-selected-overlay-color: var(--sco-color-grey-300);

  --fc-more-link-bg-color: var(--sco-color-grey-300);
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  //--fc-non-business-color: var(--sco-color-grey-300);
  //--fc-bg-event-color: var(--sco-color-white);
  //--fc-bg-event-opacity: 0.5;
  --fc-highlight-color: var(--sco-color-grey-300);
  //--fc-today-bg-color: var(--sco-color-blue-200);                       // Hintergrundfarbe des heutigen Tages
  --fc-now-indicator-color: var(--sco-color-red-600);
}


/* Wide if week of year is shown */
.fc-toolbar-wide {
  width: 290px;
}

/* Calendar head */
.fc-head {
  .fc-head-container {
    background: var(--sco-color-white);
    &, .fc-widget-header {
      border: none;
    }
    .fc-day-header {
      padding: 8px 8px;
      font-size: var(--sco-font-size-headline6);
      color: var(--sco-color-grey-600);
       
      font-weight: normal;
    }
    .fc-row.fc-widget-header {
      .fc-agendaDay-view & {
        margin-right: 0 !important;
      }
    }
  }
  .fc-axis {
    .fc-agendaDay-view & {
      width: 0 !important;
    }
  }
}

/* calendar body */
.fc-view-harness {
  background-color: var(--sco-color-grey-100);
  .fc-view {
    border-width: 0;

    /* now indecator */
    .fc-now-indicator-arrow{
      right: 0;
      border: none;
      padding-top: 1px;
      margin: 0px;
      background-color: red;
      z-index: 5;
    }

    .fc-now-indicator-line{
      border-radius: 5px;
      border-color: var(--sco-color-white);
      border-width: 1px;
      background: var(--sco-color-red-600);
      margin: -4px 0px 0px -4px;
      width: 8px;
      height: 8px;
      left:0;
      z-index: 5;
    }

    /* all day row */

    .fc-day-label-custom{
      position: absolute;
      left: 6px;
      top: 48px;
      line-height: 12px;
      color: var(--sco-color-grey-600);
       
      font-weight: normal;
      z-index: 3;
      background: var(--sco-color-grey-300);
    }

    .fc-agenda-view {
      min-height: 23px;
    }

    .fc-day-grid {
      background: var(--sco-color-grey-300);
      //max-height: 46px;
      //min-height: 23px;
      overflow-y: scroll;
      overflow-x: hidden;

      .fc-row.fc-widget-content{
        margin-right: 0px !important;
      }

      .fc-widget-content.fc-state-highlight{
        border-left: 1px solid var(--sco-color-grey-300);
      }

      .fc-row{
        min-height: 23px;
      }



      .fc-bg {

        /* different styles in month view */

        }
        th, td {
          border: none;
          background: var(--sco-color-grey-300);
          &.fc-today {
            background: var(--sco-color-white);
            opacity: 0.4;
          }

          /* different styles in month view */
          .fc-month-view & {
            background: var(--sco-color-grey-100);
            border: 1px solid  var(--sco-color-white);
            &.fc-today {
              background:  var(--sco-color-white);
              opacity: 0.4;
            }
          }
        }

        .fc-widget-content {
          border-right-width: 0 !important;
        }
      }
      .fc-content-skeleton {
        padding: 0px;
        .fc-day-number {
          font-size: var(--sco-font-size-headline4);
          font-weight: normal;
        }
      }
    }

    /* divider */
    .fc-divider {
      border-color:  var(--sco-color-white);
      background:  var(--sco-color-white);
    }

    /* time grid */
    .fc-time-grid-container {
      .fc-slats {
        .fc-minor {
          td {
            border-bottom: 3px solid  var(--sco-color-white);
          }
        }
      }
      .fc-bg {
        background: var(--sco-color-grey-100);
        td {
          border-right: 1px solid  var(--sco-color-white);
          //background: red;
        }
      }
    }
  }

  /* minor borders */
  .fc-minor {
    //border-bottom: 2px solid  var(--sco-color-white);
    .fc-widget-content {
      border-width: 0;
    }
  }

  /* event container */
  .fc-event-container {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */

    .fc-day-grid-event,
    .fc-time-grid-event {
      border-radius: 0;
      border: 1px solid var(--sco-color-grey-700);
      border-left-width: 4px;

      /* bg colors for day and time events */
      &, .fc-bg {
        background:  var(--sco-color-white);
        opacity: 1;
      }

      .fc-content {
        color: var(--sco-color-grey-700);
        font-size: var(--sco-font-size-headline6);
        padding: 2px 4px;

        .fc-time {

        }

        .fc-title {
          font-weight: bold;
        }

        /* custom class */
        .fc-type {
          .fc-month-view & {
            //display: none;
          }
        }
      }

      .fc-resizer.fc-end-resizer {
        color: $color-black;
      }

      &.eventNotErfasser {
        //border-color: $color-black !important;
        .fc-bg {
          background: var(--sco-color-grey-100);
        }
        .fc-content {

        }
      }

      &.eventAbsent {
        //border-color: $ci-color-dark-orange !important;
        .fc-bg {
          background: $color-dark--violet;
          opacity: 0.2;
        }
        .fc-content {

        }
      }
    }
  }


.fc-bg .fc-today {
  background:  var(--sco-color-white);
  opacity: 0.4 !important;
}

/**************************************/
/* event type colors got from backend */
.fc-day-grid-event.fc-daggable {

  * {
    background: $color-red--default !important;
    border: 4px solid $color-green--default !important;
  }

}

.fc-popover.fc-more-popover {
  box-shadow: none;
  border: 1px solid $color-green--default !important;
  .fc-header {
    background: var(--sco-color-primary);
    .fc-title {
      color:  var(--sco-color-white);
    }
  }
}

.fc-daygrid-body .fc-timegrid-axis .fc-timegrid-axis-frame {
  justify-content: center;
}
