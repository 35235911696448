.s-bis-list {
  table#bisProjects {
    td.status {
      white-space: nowrap;
      text-align: center;
      button {
        min-width: auto;
        width: 2em;
        height: 2em;
      }
      p {
        margin: 2px 0 2px 0
      }
    }
    td.quelle, td.notiz {
      text-align: center;
      font-size: var(--sco-font-size-headline5);
    }
  }
  .c-upload--simple {
    display: flex;
    align-items: center;
  }
}
