.c-icon {

}
.c-icon--mirror {
  transform: scaleX(-1) scaleY(1); // spiegeln
}

.c-icon--crossed-out {
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px; /* Adjust thickness if needed */
    background-color: red; /* Adjust color if needed */
    transform: rotate(45deg);
    transform-origin: center;
  }
}