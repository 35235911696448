.s-gebietsreform-log {


  .s-gebietsreform-log__head {
    .s-gebietsreform-log__company-type-selector {

    }
    .c-dropdown--wrapper {
      min-width: 150px;
    }
    .s-gebietsreform-log__autocomplete {
      .c-dropdown--wrapper {
        min-width: 300px;
      }
    }
  }

  .s-gebietsreform-log__resultlist-icon {
    flex-grow: 0;
    flex-shrink: 0;

    border-radius: 50%;
    width: 25px;
    height: 25px;
    flex-basis: 25px;
    min-width: unset;
    font-size: var(--sco-font-size-headline6);

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--sco-color-white);
  }

  .s-gebietsreform-log__resultlist-priority {
    padding-top: 7px;
    display: inline-block;
    margin-right: 10px;
  }

  #s-gebietsreform-log__resultlist .c-card--header {
    padding: 0 15px;
  }

  #s-gebietsreform-log__resultlist .c-card--header-title {
    display: flex;
    align-items: center;
  }

  #s-gebietsreform-log__resultlist .c-card--header-right > * {
    flex:1;
  }

  .s-gebietsreform-log__resultlist-item-content {
    display: flex;
    justify-content: space-between;
  }

}

