.c-upload--advanced {
  table {
    margin-bottom: 0px;
  }
  tr.template-upload td.name, tr.template-upload td.size, tr.template-upload td.schutz {
    padding: 0 5px;
    vertical-align: middle;
  }

  td {
    padding: 10px 10px;
    height: 58px;
  }

  tr.template-upload td.schutz {
    text-align: center;
  }

  /* fileupload css */
  .tpl-container {
    display: none;
  }

  .tpl {
    display: none;
  }

  .dropzone {
    background: #EEE;
    border: 2px solid #DDD;
  }

  .dropzone .hint {
    &:before {
      content: fa-content($fa-var-cloud-upload);
      line-height: 50px;
      font-size: 50px;
      font-family: "Font Awesome 6 Pro";
      display: block;
      text-align: center;
      margin-bottom: 10px;
    }
    padding: 40px 0;
    text-align: center;
  }

  .drag-over {
    border-color: #76B828;
    .hint:before {
      color: var(--sco-color-primary);
    }
  }

  .drag-not-allowed {
    border-color: orangered;
    cursor: not-allowed !important;
  }

  .status {
    text-align: center !important;
  }

  .status * {
    margin: 0;
    display: inline-block;
    float: none;
  }

  .progress-bar {
    margin: 4px 0;
    background: #78b928;
    width: 0%;
    height: 4px;
  }
}

.c-upload--simple {
  max-width: 300px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  input[type=file] {
    display: none;
  }

  .c-input--text-wrapper {
    width: calc(100% - 70px);
    display:inline-block;
    margin-right: 10px;
  }
  .c-upload--simple-choose-btn {
    position: relative;
    display: inline-block;
    padding: 10px 16px 8px 16px;
    color: #ffffff;
    font-size: var(--sco-font-size-headline6);
    line-height: 1.4;
    background-color: var(--sco-color-primary);
    text-decoration: none !important;
    border: 0px;

    &:hover {
      background: var(--sco-color-green-800);
      cursor:pointer;
    }

    &:before {
      content: fa-content($fa-var-cloud-upload);
      font-family: "Font Awesome 6 Pro";
      display: inline-block;
      text-align: center;
    }
  }

  .c-input--text-wrapper {
    font-family: var(--sco-font-family-lato-light);

    input[disabled] ~ label {
      top: 16px;
      font-size: var(--sco-font-size-paragraph);
    }

    input:focus ~ label,
    input:not(.c-input--empty) ~ label,
    input[placeholder] ~ label {
      font-size: 9px;
      top: 0px;
    }


    input:focus ~ label
    {
      color: $color-border--focus;
    }

    input[disabled] {
      border-bottom: 1px solid $color-border--normal;
    }

    &:hover,
    input:disabled {
      cursor: pointer;
    }

    &:hover {
      input[disabled] {
        border-bottom: 2px solid $color-border--hover;
      }
    }
  }
}